import ajaxService from "./ajax-service";

class VendorService {
  async fetch(page) {
    const response = await ajaxService.get(`/vendors?page${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async fetchAll() {
    const response = await ajaxService.get(`/vendors?all=true`);
    if (response ?? false) {
      return response;
    }
  }
  async fetchCategories() {
    const response = await ajaxService.get(`/vendors-categories`);
    console.log(response)
    if (response ?? false) {
      return response;
    }
  }
  async viewVendor(id) {
    const response = await ajaxService.get(`/vendors/${id}`);
    if (response ?? false) {
      return response;
    }
  }
  async addVendor(data) {
    const response = await ajaxService.post(`/vendors/`, data);
    if (response ?? false) {
      return response;
    }
  }
  async updateVendor(id, data) {
    const response = await ajaxService.put(`/vendors/`, id, data);
    if (response ?? false) {
      return response;
    }
  }
  async deleteVendor(id) {
    const response = await ajaxService.delete(`/vendors/`, id);
    if (response ?? false) {
      return response;
    }
  }
}

const vendorService = new VendorService();
export default vendorService;

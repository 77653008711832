import ajaxService from "./ajax-service";

class InventoryReport {
  async fetch(page, data) {
    const response = await ajaxService.post(
      `/reports/inventory?page=${page}`,
      data
    );
    if (response ?? false) {
      return response;
    }
  }
}

const inventoryReportService = new InventoryReport();
export default inventoryReportService;

import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Input,
  Row,
  DatePicker,
  Table,
  Button,
  notification,
} from "antd";
import { Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { CiSquarePlus } from "react-icons/ci";
import { v4 as uuidv4 } from "uuid";
import {
  dropdownService,
  invoiceReceiptService,
} from "../../../../../services";


function AddRecepit() {
  const [customers, setCustomers] = useState([]);
  const [paymentModes, setPaymentModes] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [advanceRemaining, setAdvanceRemaining] = useState(0);
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [showBankField, setShowBankField] = useState(false);
  const [form] = Form.useForm();
  const [data2, setData2] = useState([
    {
      Id: uuidv4(),
      Advance_number: "",
      Description: "",
      "Collection Amount($)": "",
    },
  ]);
  const [data, setData] = useState([
    {
      Id: uuidv4(),
      "#": 1,
      Invoice_Number: null,
      Description: "",
      "Invoice_Amount($)": "",
      "Advance_Amount($)": "",
      "Collection_Amount($)": "",
      "Balance($)": "",
      "Payment Status": "",
    },
  ]);
  const calculateBalance = (invoiceAmount, advanceAmount, collectionAmount) => {
    const invoice = parseFloat(invoiceAmount) || 0;
    const advance = parseFloat(advanceAmount) || 0;
    const collection = parseFloat(collectionAmount) || 0;
    return (invoice - advance - collection).toFixed(2);
  };


  const calculateAdvanceLeft = () => {
    const totalAdvanceAmount = data.reduce(
      (total, row) => total + (parseFloat(row["Advance_Amount($)"]) || 0),
      0
    );

    return invoices.advance_remaining - totalAdvanceAmount || 0; 
  };

  const handleChange = (value, fieldName, record, dataType) => {
    const setDataFunction = dataType === 1 ? setData : setData2;
    const dataToModify = dataType === 1 ? data : data2;

    const updatedData = dataToModify.map((row) => {
      if (row.key === record.key) {
        return { ...row, [fieldName]: value }; 
      }
      return row;
    });

    setDataFunction(updatedData); 
  };
  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Invoice Number",
      dataIndex: "Invoice_Number",
      key: "Invoice_Number",
      render: (text, record) => (
        <Select
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(value) => {
            handleInvoiceChange(value, record);
          }} 
        >
          {invoices?.invoices?.map((fi) => (
            <Select.Option key={fi.id} value={fi.id}>
              {fi.id}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      render: (text, record) => (
        <Input.TextArea
          defaultValue={text}
          style={{ width: "100%" }}
          rows={2}
          onChange={(e) =>
            handleChange(e.target.value, "Description", record, 1)
          } 
        />
      ),
    },
    {
      title: "Invoice Amount($)",
      dataIndex: "Invoice_Amount($)",
      key: "Invoice_Amount($)",
      render: (text) => {
        return <Input value={text} style={{ width: "100%" }} readOnly />;
      },
    },
    {
      title: "Advance Amount($)",
      dataIndex: "Advance_Amount($)",
      key: "Advance_Amount($)",
      render: (text, record) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) =>
            handleChange(e.target.value, "Advance_Amount($)", record, 1)
          }
        />
      ),
    },
    {
      title: "Collection Amount($)",
      dataIndex: "Collection_Amount($)",
      key: "Collection_Amount($)",
      render: (text, record) => (
        <Input
          value={text}
          style={{ width: "100%" }}
          onChange={(e) => {
            const newValue = e.target.value;
            handleChange(newValue, "Collection_Amount($)", record, 1); 
            setData((prevData) =>
              prevData.map((row) =>
                row.key === record.key
                  ? {
                      ...row,
                      "Collection_Amount($)": newValue,
                      "Balance($)": calculateBalance(
                        row["Invoice_Amount($)"],
                        row["Advance_Amount($)"],
                        newValue
                      ), 
                    }
                  : row
              )
            );
          }}
        />
      ),
    },
    {
      title: "Balance($)",
      dataIndex: "Balance($)",
      key: "Balance($)",
      render: (text) => (
        <Input value={text} style={{ width: "100%" }} readOnly />
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "Payment Status",
      key: "Payment Status",
      render: (text, record) => {
        return (
          <Select
            defaultValue={text}
            style={{ width: "100%" }}
            onChange={(value) =>
              handleChange(value, "Payment Status", record, 1)
            } 
          >
            <Select.Option value={0}>Not Paid</Select.Option>
            <Select.Option value={1}>Partial</Select.Option>
            <Select.Option value={2}>Complete</Select.Option>
          </Select>
        );
      },
    },
    {
      title: (
        <div>
          <DeleteOutlined style={{ fontSize: "18px" }} />
        </div>
      ),
      key: "Delete",
      render: (text, record, index) => {
        if (data.length === 1) return null;
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleDeleteRow(record.Id)}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];

  const columns2 = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Advanced Number",
      dataIndex: "Advance_number",
      key: "Advance_number",
      render: (text, record) => (
        <Input
          value={text}
          style={{ width: "100%" }}
          onChange={(e) =>
            handleChange(e.target.value, "Advance_number", record, 2)
          } 
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      render: (text, record) => (
        <Input.TextArea
          defaultValue={text}
          style={{ width: "100%" }}
          rows={2}
          onChange={(e) =>
            handleChange(e.target.value, "Description", record, 2)
          } 
        />
      ),
    },
    {
      title: "Collection Amount($)",
      dataIndex: "Collection_Amount($)",
      key: "Collection_Amount($)",
      render: (text, record) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) =>
            handleChange(e.target.value, "Collection_Amount($)", record, 2)
          } // Tracking change in data2
        />
      ),
    },
    {
      title: (
        <div>
          <DeleteOutlined style={{ fontSize: "18px" }} />
        </div>
      ),
      key: "Delete",
      render: (text, record, index) => {
        if (data2.length === 1) return null;
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleDeleteRow2(record.Id)}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];

  const handleAddRow = () => {
    setData((prevData) => [
      ...prevData,
      {
        Id: uuidv4(),
        "#": (prevData.length + 1).toString(),
        Invoice_Number: null,
        Description: "",
        "Invoice_Amount($)": "",
        "Advance_Amount($)": "",
        "Collection_Amount($)": "",
        "Balance($)": "",
        "Payment Status": "",
      },
    ]);
  };

  const handleAddRow2 = () => {
    const lastAdvanceNumber =
      data2.length > 0 ? data2[data2.length - 1].Advance_number : 0;

    setData2([
      ...data2,
      {
        Id: uuidv4(),
        Advance_number: lastAdvanceNumber + 1,
        Description: "",
        "Collection Amount($)": "",
      },
    ]);
  };
  const handleDeleteRow = (Id) => {
    setData(data.filter((item) => item.Id !== Id));
  };
  const handleDeleteRow2 = (Id) => {
    setData2(data2.filter((item) => item.Id !== Id));
  };

  const handlePaymentChange = (value) => {
    if (value === 1) {
      setShowBankField(true);
    } else {
      setShowBankField(false);
    }
  };
  const getCompanies = async () => {
    const response = await dropdownService.fetchCompanies();
    if (response.success) {
      setCompanies(response.data);
    }
  };
  const getCustomer = async () => {
    const response = await dropdownService.fetchCustomers();
    if (response.success) {
      const filter = response.data
        .filter((data) => data.isActive === 1)
        .sort((a, b) =>
          a.name.toUpperCase().localeCompare(b.name.toUpperCase())
        );

      const capitalizedFilter = filter.map((customer) => ({
        ...customer,
        name: customer.name.toUpperCase(),
      }));
      setCustomers(capitalizedFilter);
    }
  };

  const getPaymentModes = async () => {
    const response = await dropdownService.fetchPaymentModes();
    if (response.success) {
      setPaymentModes(response.data);
    }
  };

  const getBanks = async () => {
    const response = await dropdownService.fetchBanks();
    if (response.success) {
      setBanks(response.data);
    }
  };

  const getInvoices = async (id) => {
    const response = await dropdownService.fetchRemainingInvoices(id);
    if (response.success) {
      setInvoices(response.data);
      setData2((prevData) => [
        {
          ...prevData[0],
          Advance_number: response.data.advance_payment_id,
        },
        ...prevData.slice(1),
      ]);
      setAdvanceRemaining(response.data.advance_remaining);
    }
  };
  const handleChangeCustomer = async (value) => {
    getInvoices(value);
  };
  const handleInvoiceChange = (value, record) => {
    const selectedInvoice = invoices?.invoices?.find(
      (invoice) => invoice.id === value
    );
    if (selectedInvoice) {
      const updatedData = data.map((row) =>
        row.Id === record.Id
          ? {
              ...row,
              Invoice_Number: selectedInvoice.id,
              "Invoice_Amount($)": selectedInvoice.total || "",
              "Collection_Amount($)":
                selectedInvoice.total - selectedInvoice.amounts || "",
              "Payment Status": selectedInvoice.status || "",
            }
          : row
      );
      setData(updatedData);
    }
  };

  const onFinish = async (values) => {
    const payload = {
      ...values,
      receipt_date: values.receipt_date.format("YYYY-MM-DD"),
      payment_date: values.payment_date.format("YYYY-MM-DD"),
      payment_mode_name: paymentModes.find(
        (mode) => mode.id === values.payment_mode
      ).name,
      invoice_id: data.map((item) => item.Invoice_Number),
      description: data.map((item) => item.Description),
      adjust_amount: data.map((item) => item["Advance_Amount($)"]),
      amount: data.map((item) => item["Collection_Amount($)"]),
      balance: data.map((item) => item["Balance($)"]),
      status: data.map((item) => item["Payment Status"]),
      advance_payment: data2.map((item) => item.Advance_number),
      advance_description: data2.map((item) => item.Description),
      advance_amount: data2.map((item) => item["Collection_Amount($)"]),
    };
    console.log(payload);

    // const response = await invoiceReceiptService.addreceipt(payload);
    // if (response.success) {
    //   notification.success({
    //     message: "Added Successfully!",
    //     description: response.message,
    //     placement: "topRight",
    //   });
    // }
  };

  const initialize = async () => {
    getBanks();
    getCustomer();
    getPaymentModes();
    getCompanies();
  };

  useEffect(() => {
    initialize();
  }, []);
  return (
    <div
      style={{
        padding: "0 14px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row
        gutter={26}
        style={{
          // backgroundColor: '#FBFBFB',
          marginBottom: 15,
          padding: "10px",
          borderRadius: "4px",
          borderBottom: "1px solid #E8E8E9",
        }}
      >
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Add Receipt</h1>
        </Col>
      </Row>

      <Form
        requiredMark={false}
        layout="vertical"
        onFinish={onFinish}
        form={form}
      >
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="Customer Name"
              name="user_id"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Select
                placeholder="No Customer"
                showSearch
                optionFilterProp="children"
                onChange={(value) => handleChangeCustomer(value)}
              >
                {customers?.map((cust) => (
                  <Select.Option key={cust.id} value={cust.id}>
                    {cust.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Customer Ref. #" name="reference_number">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Receipt Date" name="receipt_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Collection Date" name="payment_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Collection Mode"
              name="payment_mode"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Select
                placeholder="No Customer"
                onChange={(value) => handlePaymentChange(value)}
              >
                {paymentModes?.map((mode) => (
                  <Select.Option key={mode.id} value={mode.id}>
                    {mode.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {showBankField && (
            <Col span={8}>
              <Form.Item
                label="Bank"
                name="bank"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <Select placeholder="No Customer">
                  {banks?.map((bank) => (
                    <Select.Option key={bank.id} value={bank.id}>
                      {bank.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={8}>
            <Form.Item
              label="Inter-Company"
              name="company"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Select placeholder="No Customer">
                {companies?.map((comp) => (
                  <Select.Option key={comp.id} value={comp.id}>
                    {comp.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={22} style={{ textAlign: "right" }}>
            <p style={{ fontWeight: "bold", fontSize: "16px" }}>
              Advance in Balance:
              <span style={{ fontWeight: "normal" }}>
                {" "}
                {invoices?.advance_remaining
                  ? `$${invoices?.advance_remaining}`
                  : 0}
              </span>
            </p>
          </Col>
          <Col span={24}>
            <div
              style={{
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                marginTop: 20,
              }}
            >
              <h1 style={{ fontWeight: "bold" }}>Invoice Collection</h1>
              <Table
                columns={columns}
                scroll={{ x: "max-content" }}
                dataSource={data}
                pagination={false}
                rowKey="Id"
                style={{ marginTop: "10px" }}
              />
              <div
                style={{ marginTop: 20 }}
                className="flex flex-col justify-center items-center"
              >
                <CiSquarePlus
                  style={{ cursor: "pointer", fontSize: "25px" }}
                  onClick={handleAddRow}
                />
              </div>
            </div>
          </Col>
          <Col span={22} style={{ marginTop: 20, textAlign: "right" }}>
            <p style={{ fontWeight: "bold", fontSize: "16px" }}>
              Advance Left:
              <span style={{ fontWeight: "normal" }}>
                {" "}
                ${calculateAdvanceLeft()}
              </span>
            </p>
          </Col>
          <Col span={24}>
            <div
              style={{
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                marginTop: 20,
              }}
            >
              <h1 style={{ fontWeight: "bold" }}>Advanced Collection </h1>
              <Table
                columns={columns2}
                scroll={{ x: "max-content" }}
                dataSource={data2}
                pagination={false}
                rowKey="id"
                style={{ marginTop: "10px" }}
              />
              <div
                style={{ marginTop: 20 }}
                className="flex flex-col justify-center items-center"
              >
                <CiSquarePlus
                  style={{ cursor: "pointer", fontSize: "25px" }}
                  onClick={handleAddRow2}
                />
              </div>
            </div>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button
              type="primary"
              style={{ margin: 20 }}
              htmlType="submit"
              loading={loading}
            >
              Add Receipt
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default AddRecepit;

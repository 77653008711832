import React, { useState } from "react";
import {
  Table,
  Row,
  Col,
  Form,
  DatePicker,
  Space,
  Input,
  Button,
  Typography,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { PiMicrosoftExcelLogo } from "react-icons/pi";
import { LiaFilePdfSolid } from "react-icons/lia";
import { RiFileCopyLine } from "react-icons/ri";
import { AiOutlinePrinter } from "react-icons/ai";
import { FaRegFile } from "react-icons/fa";
import { reportService } from "../../../services";
const { Title } = Typography;

function InvoiceStatus() {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [payload, setPayload] = useState(null);

  const [form] = Form.useForm();

  const columns = [
    {
      title: "Invoice",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Container Number",
      dataIndex: "container_number",
      key: "container_number",
    },
    {
      title: "Freight($)",
      dataIndex: "freight_charges",
      key: "freight_charges",
    },
    {
      title: "Chassis($)",
      dataIndex: "total_chasis_charges",
      key: "total_chasis_charges",
    },
    {
      title: "Loading($)",
      dataIndex: "total_loading_charges",
      key: "total_loading_charges",
    },
    {
      title: "Towing($)",
      dataIndex: "total_towing_charges",
      key: "total_towing_charges",
    },

    {
      title: "Yard($)",
      dataIndex: "total_yard_charges",
      key: "total_yard_charges",
    },
    {
      title: "Charges($)",
      dataIndex: "extra_charge_amount",
      key: "extra_charge_amount",
    },
    {
      title: "Total($)",
      dataIndex: "total",
      key: "total",
    },
  ];

  const data = [];

  const onFinish = (values) => {
    setPayload({
      from_date: values.from_date.format("YYYY-MM-DD"),
      to_date: values.to_date.format("YYYY-MM-DD"),
    });
    const payload = {
      from_date: values.from_date.format("YYYY-MM-DD"),
      to_date: values.to_date.format("YYYY-MM-DD"),
    };
    initialize(pagination.current, pagination.pageSize, payload);
  };

  const initialize = async (
    page = 1,
    pageSize = pagination.pageSize,
    payload = null
  ) => {
    setLoading(true);
    const { success, data } = await reportService.InvoiceStatus(page, payload);
    setLoading(false);
    if (success) {
      setInvoices(data);
      setPagination((prev) => ({
        ...prev,
        total: data.data.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };
  return (
    <>
      <Title level={5}>Invoice Status</Title>
      <Form
        // requiredMark={false}
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={24} className="mt-8">
          <Col span={8}>
            <Form.Item label="From date" name="from_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="To date" name="to_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Form.Item label={<></>}>
              <Button type="primary" htmlType="submit">
                <SearchOutlined style={{ fontSize: "15px" }} />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Col span={24}>
        <Row
          gutter={0}
          style={{
            padding: "8px",
            marginTop: "2px",
            background: "#F2F2F7",
            borderRadius: "4px",
          }}
          className="flex justify-between"
        >
          <Col span={12}>
            <div className="flex">
              <Button type="text" size="small" style={{ padding: "4px" }}>
                <AiOutlinePrinter style={{ fontSize: "24px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <FaRegFile style={{ fontSize: "20px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <PiMicrosoftExcelLogo style={{ fontSize: "24px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <LiaFilePdfSolid style={{ fontSize: "24px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <RiFileCopyLine style={{ fontSize: "24px" }} />
              </Button>
            </div>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              className="w-auto lg:w-2/5"
            />
          </Col>
        </Row>
        <Table
          columns={columns}
          scroll={{ x: "max-content" }}
          pagination={{
            current: pagination.current,
            total: pagination.total,
            pageSize: pagination.pageSize,
            onChange: (page) => initialize(page, pagination.pageSize, payload),
            showSizeChanger: false,
          }}
          loading={loading}
          dataSource={invoices?.data?.data}
          style={{ marginTop: "10px" }}
          summary={() => (
            <Table.Summary.Row className="font-bold">
              <Table.Summary.Cell colSpan={2}>Total: </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${parseFloat(invoices?.freightSum || 0)?.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${parseFloat(invoices?.chasisSum || 0)?.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${parseFloat(invoices?.loadingSum || 0)?.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${parseFloat(invoices?.towingSum || 0)?.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${parseFloat(invoices?.yardSum || 0)?.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${parseFloat(invoices?.extraSum || 0)?.toFixed(2)}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${invoices?.invoiceSum?.toFixed(2) || 0}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </Col>
    </>
  );
}

export default InvoiceStatus;

import React, { useState, useEffect } from "react";
import { Table, Space, Button, Row, Col, Input, Modal } from "antd";
import {
  EditOutlined,
  SearchOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { GoArrowSwitch } from "react-icons/go";
import { Link, useNavigate } from "react-router-dom";
import employeeService from "../../../services/employee-service";

function Employees() {
  const navigate = useNavigate();
  const editEmployee = (id) => navigate(`/administrations/employees/form/${id}`);
  const [loading, setLoading] = useState(false);
  const [isModalshow, setIsModalShow] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [employee, setEmployee] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [filteredEmployees, setFilteredEmployees] = useState(employee);

  const initialize = async (page = 1, pageSize = pagination.pageSize) => {
    setLoading(true);
    const { success, data } = await employeeService.fetch(page);
    setLoading(false);
    if (success) {
      setEmployee(data.data);
      setFilteredEmployees(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
  };
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    const filteredData = employee.filter(
      (employee) => employee.name.toLowerCase().includes(value.toLowerCase()) // Change "name" to whatever field you want to search by
    );
    setFilteredEmployees(filteredData);
  };
  const showModal = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };
  const handleOk = async () => {
    if (!selectedRecord) return;
    setLoading(true);
    if (isModalVisible) {
      const response = await employeeService.deleteEmployee(selectedRecord.id);
      if (response?.success) {
        setEmployee((prevData) =>
          prevData.filter((item) => item.id !== selectedRecord.id)
        );
        setFilteredEmployees((prevData) =>
          prevData.filter((item) => item.id !== selectedRecord.id)
        );
        setIsModalVisible(false);
      }
    } else if (isModalshow) {
      const response = await employeeService.changeStatus(selectedRecord.id);
      if (response.success) {
        setEmployee((prevData) =>
          prevData.map((item) =>
            item.id === selectedRecord.id
              ? { ...item, status: item.status === 1 ? 0 : 1 }
              : item
          )
        );
        initialize(pagination.current, pagination.pageSize);
        setIsModalShow(false);
      }
    }
    setLoading(false);
    setSelectedRecord(null);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
    setIsModalShow(false);
  };
  const showModalHandler = (record, isDelete = false) => {
    setSelectedRecord(record);
    if (isDelete) {
      setIsModalVisible(true);
    } else {
      setIsModalShow(true);
    }
  };
  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "#",
    //   key: "#",
    //   render: (text, record, index) =>
    //     (pagination.current - 1) * pagination.pageSize + index + 1,
    // },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "Name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "Address",
    },
    {
      title: "CNIC",
      dataIndex: "cnic",
      key: "CNIC",
    },
    {
      title: "Date Of Birth",
      dataIndex: "dob",
      key: "DateOfBirth",
    },
    {
      title: "Date Of Joining",
      dataIndex: "joining_date",
      key: "DateOfJoining",
    },
    {
      title: "Qualification",
      dataIndex: "qualification",
      key: "Qualification",
    },
    {
      title: "Salary",
      dataIndex: "salary",
      key: "Salary",
    },

    {
      title: "Designation",
      dataIndex: "designation",
      key: "Designation",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "Username",
    },
    {
      title: "Status & Action",
      key: "status",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span
            style={{
              color: record.status === 1 ? "green" : "red",
              fontWeight: "bold",
            }}
          >
            {record.status === 1 ? "Active" : "Inactive"}
          </span>
          <Button
            style={{
              backgroundColor: "#2D3748",
              color: "white",
              borderRadius: "10px",
            }}
            onClick={() => showModalHandler(record)}
            size="small"
          >
            <GoArrowSwitch style={{ fontSize: "15px" }} />
          </Button>
        </div>
      ),
    },
    {
      title: "Operations",
      key: "Operations",
      fixed:"right",
      render: (text, record) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            onClick={() => editEmployee(record.id)}
          >
            <EditOutlined />
          </Button>

          <Link to={`/administrations/employees/view/${record.id}`}>
            <Button type="primary" size="small">
              <EyeOutlined />
            </Button>
          </Link>
          <Button type="primary" size="small">
            <DeleteOutlined onClick={() => showModalHandler(record, true)} />
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);

  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row
        gutter={24}
        style={{ padding: "10px", borderRadius: "4px" }}
        className="flex justify-between"
      >
        <Col span={10}>
          <Space direction="horizontal">
            <h5 className="font-bold"> Employee Table </h5>
          </Space>
        </Col>
        <Col span={14} style={{ textAlign: "right" }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
            value={searchTerm}
            onChange={handleSearch}
          />
        </Col>
      </Row>

      {/* action */}
      <Modal
        title={<div style={{ textAlign: "center" }}>Confirm</div>}
        open={isModalshow}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        style={{
          padding: "20px",
        }}
        width={300}
        footer={
          <div className="flex justify-center gap-3">
            <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
              Yes
            </Button>
            <Button
              type="default"
              onClick={handleCancel}
              style={{ width: "30%" }}
            >
              No
            </Button>
          </div>
        }
      >
        <p>
          Confirm change status to{" "}
          <strong>
            {selectedRecord?.status === 1 ? "Inactive" : "Active"}
          </strong>
          ?
        </p>
      </Modal>

      {/* delete */}
      <Modal
        title={<div style={{ textAlign: "center" }}>Confirm</div>}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        style={{
          padding: "20px",
        }}
        width={300}
        footer={
          <div className="flex justify-center gap-3">
            <Button
              type="default"
              onClick={handleCancel}
              style={{ width: "30%" }}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: "center" }}>
          Are you sure you want to delete this?
        </p>
      </Modal>

      <Table
        columns={columns}
        loading={loading}
        dataSource={filteredEmployees}
        scroll={{ x: "max-content" }}
        style={{ marginTop: "5px" }}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        rowKey="id"
      />
    </div>
  );
}
export default Employees;

// const [data, setData] = useState([
//   {
//     Id: "13574",
//     Name: "obaid",
//     Address:
//       "Plot no 17 Shahid Manzil opp Risala police station nishtar road karachi.",
//     CNIC: "4220187613459",
//     DateOfBirth: "1981-03-25",
//     DateOfJoining: "2022-03-01",
//     Qualification: "Graduate ",
//     Salary: "20000",
//     Designation: "Data",
//     Username: "obaid123@gmail.com",
//     status: "Inactive",
//   },
//   {
//     Id: "13574",
//     Name: "obaid",
//     Address:
//       "Plot no 17 Shahid Manzil opp Risala police station nishtar road karachi.",
//     CNIC: "4220187613459",
//     DateOfBirth: "1981-03-25",
//     DateOfJoining: "2022-03-01",
//     Qualification: "Graduate ",
//     Salary: "20000",
//     Designation: "Data",
//     Username: "obaid123@gmail.com",
//     status: "Inactive",
//   },
//   {
//     Id: "13574",
//     Name: "obaid",
//     Address:
//       "Plot no 17 Shahid Manzil opp Risala police station nishtar road karachi.",
//     CNIC: "4220187613459",
//     DateOfBirth: "1981-03-25",
//     DateOfJoining: "2022-03-01",
//     Qualification: "Graduate ",
//     Salary: "20000",
//     Designation: "Data",
//     Username: "obaid123@gmail.com",
//     status: "Inactive",
//   },
//   {
//     Id: "13574",
//     Name: "obaid",
//     Address:
//       "Plot no 17 Shahid Manzil opp Risala police station nishtar road karachi.",
//     CNIC: "4220187613459",
//     DateOfBirth: "1981-03-25",
//     DateOfJoining: "2022-03-01",
//     Qualification: "Graduate ",
//     Salary: "20000",
//     Designation: "Data",
//     Username: "obaid123@gmail.com",
//     status: "Inactive",
//   },
//   {
//     Id: "13574",
//     Name: "obaid",
//     Address:
//       "Plot no 17 Shahid Manzil opp Risala police station nishtar road karachi.",
//     CNIC: "4220187613459",
//     DateOfBirth: "1981-03-25",
//     DateOfJoining: "2022-03-01",
//     Qualification: "Graduate ",
//     Salary: "20000",
//     Designation: "Data",
//     Username: "obaid123@gmail.com",
//     status: "Inactive",
//   },
//   {
//     Id: "13574",
//     Name: "obaid",
//     Address:
//       "Plot no 17 Shahid Manzil opp Risala police station nishtar road karachi.",
//     CNIC: "4220187613459",
//     DateOfBirth: "1981-03-25",
//     DateOfJoining: "2022-03-01",
//     Qualification: "Graduate ",
//     Salary: "20000",
//     Designation: "Data",
//     Username: "obaid123@gmail.com",
//     status: "Inactive",
//   },
// ]);

import React, { useState } from "react";
import { Avatar, Dropdown, Input, Layout, Menu, Space, Modal, Form, Button } from "antd";
import { SearchOutlined, UserOutlined, MailOutlined, PoweroffOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';
import { IoCalendar } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { IoSettingsSharp } from "react-icons/io5";
import { IoMdNotifications } from "react-icons/io";

const { Header } = Layout;
const specificDate = moment(); // Gets the current date and time
const formattedDate = specificDate.format('DD MMMM YYYY');


const MainHeader = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();

    const handleMenuClick = ({ key }) => {
        if (key === 'email') {
            navigate('/emails');
        }
        if (key === 'profile') {
            navigate('/edit-profiles');
        }
        if (key === 'password') {
            setIsModalVisible(true);
        }
        if (key === 'logout') {
            localStorage.removeItem("token")
            localStorage.removeItem("role")
            navigate('/login');
        }
    };
    const handleCancel = () => {
        setIsModalVisible(false)
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="profile" icon={<UserOutlined />}>Edit Profile</Menu.Item>
            {/* <Menu.Item key="email" icon={<MailOutlined />}>
                Email
            </Menu.Item> */}
            <Menu.Item key="password" icon={<EditOutlined />}>Change Password</Menu.Item>
            <Menu.Item key="logout" icon={<PoweroffOutlined />}>Logout</Menu.Item>
        </Menu>
    );

    return (
        <Header style={{ padding: '0 10px', width: '100%', background: '#FBFBFB' }}>
            <div className="flex justify-between">
                <div className="flex">
                    <Space direction="horizontal" size={8}>
                        <IoCalendar />
                        {formattedDate}
                    </Space>
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <Space direction="horizontal" size={16} >
                        <Input placeholder="search" prefix={<SearchOutlined />} style={{ width: 150, borderRadius: "20px" }} />
                        <IoSettingsSharp style={{fontSize:"18px",color:"gray"}}/>
                        <IoMdNotifications style={{fontSize:"20px",color:"gray"}}/>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <Avatar
                                size="medium"
                                src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                                style={{ cursor: "pointer" }} // Makes the avatar look clickable

                            />
                        </Dropdown>
                    </Space>

                </div>

                <Modal
                    title={
                        <div style={{ textAlign: "center", width: "100%" }}>Change Password</div>
                    }
                    open={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                    bodyStyle={{
                        padding: "20px", // Adjust padding for smaller size
                    }}
                    width={400} // Set modal width
                    style={{ top: 100 }} // Adjust modal vertical position
                >
                    <Form
                        requiredMark={false}
                        layout="vertical"
                        name="change_password"
                        autoComplete="off"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center", // Center-align form items
                        }}
                    >
                        <Form.Item
                            label="Old Password"
                            name="oldPassword"
                            rules={[{ required: true, message: "Please enter your old password!" }]}
                            style={{ width: "100%", maxWidth: "300px" }} // Set field width
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="New Password"
                            name="newPassword"
                            rules={[{ required: true, message: "Please enter your new password!" }]}
                            style={{ width: "100%", maxWidth: "300px" }} // Set field width
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Confirm Password"
                            name="confirmPassword"
                            rules={[{ required: true, message: "Please confirm your new password!" }]}
                            style={{ width: "100%", maxWidth: "300px" }} // Set field width
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item style={{ width: "100%", maxWidth: "300px" }}>
                            <Button type="primary" block>
                                Update Password
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

            </div>
        </Header>
    )
}

export default MainHeader
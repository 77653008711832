import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Row, Col, Input, Select, Modal } from 'antd';
import { EyeOutlined, SearchOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { GoArrowSwitch } from "react-icons/go";
import { customerService } from '../../../services';

const options = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
];

function Customer() {
  const editCustomer = (id) => navigate(`/operation/customers/form/${id}`);
  const navigate = useNavigate()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalshow, setIsModalShow] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedDeleteRecord, setSelectedDeleteRecord] = useState(null);
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, total: 0, pageSize: 10 });
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState(customers);

  const showModalHandler = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };
  const showModal = (record) => {
    setSelectedDeleteRecord(record)
    setIsModalShow(true)
  }
  const handleCancel = () => {
    setIsModalShow(false)
    setIsModalVisible(false);
    setSelectedRecord(null);
  };
  const initialize = async (page = 1, pageSize = pagination.pageSize) => {
    setLoading(true);
    const { success, data } = await customerService.fetch(page);
    setLoading(false);
    if (success) {
      setCustomers(data.data);
      setFilteredCustomers(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
  };
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    const filteredData = customers.filter((customer) =>
      customer.name.toLowerCase().includes(value.toLowerCase()) 
    );
    setFilteredCustomers(filteredData);
  };
  
  const handleDelete = async () => {
    if (!selectedDeleteRecord || !selectedDeleteRecord.id) {
      console.error("No customer selected for deletion.");
      return;
    }
    const response = await customerService.deleteCustomer(selectedDeleteRecord.id);

    if (response.success) {
      setCustomers((prevData) => prevData.filter((item) => item.id !== selectedDeleteRecord.id));
      initialize()
    } else {
      console.error("Failed to delete the customer.");
    }
    setIsModalShow(false);

  };
  const handleOk = async () => {
    if (!selectedRecord) return;
    setLoading(true);
    const response = await customerService.fetchCustomerStatus(selectedRecord.id);
    if (response.success) {
      setCustomers((prevData) =>
        prevData.map((item) =>
          item.id === selectedRecord.id
            ? { ...item, isActive: item.isActive === 1 ? 0 : 1 }
            : item
        )
      );
      initialize(pagination.current, pagination.pageSize);
      setLoading(false);
      setIsModalVisible(false);
      setSelectedRecord(null);

    };
  }
  const columns = [
    // {
    //   title: '#',
    //   dataIndex: '#',
    //   key: '#',
    //   render: (text, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
    // },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (text) => (
        <p>{text ? text : '-'}</p>
      ),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Agreement',
      dataIndex: 'document',
      key: 'document',
      render: (_, record) => (
        <div style={{ padding: "5px" }}>
          {record.document ? (
            <Link to={record.document} target="_blank" rel="noopener noreferrer">
              <p style={{ border: "1px solid #ccc", backgroundColor: '#2D3748', padding: "5px", borderRadius: "12px", color: 'white' }}>Agreement</p>
            </Link>
          ) : (
            <p>-</p>
          )}
        </div>
      ),
    },
    {
      title: 'Status & Action',
      key: 'isActive',
      render: (_, record) => (
        <div style={{ display: 'flex', justifyContent: "space-around", alignItems: "center" }}>
          <span
            style={{
              color: record.isActive === 1 ? 'green' : 'red',
              fontWeight: 'bold',
            }} >
            {record.isActive === 1 ? 'Active' : 'Inactive'}
          </span>
          <Button
            style={{ backgroundColor: '#2D3748', color: 'white', borderRadius: "10px" }}
            onClick={() => showModalHandler(record)}
            size='small' >
            <GoArrowSwitch style={{ fontSize: "15px" }} />
          </Button>
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'Action',
      render: (text, record) => (
        <Space size="small">
        
            <Button type="primary" size="small" onClick={() => editCustomer(record.id)}> 
              <EditOutlined />
            </Button>
   
          <Button type="primary" size="small" onClick={() => navigate('/operation/customers/view/' + record.id)}>
            <EyeOutlined />
          </Button>

          <Button type="primary" size="small" onClick={() => showModal(record)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);

  return (
    <div style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#FFFFFF' }}>
      <Row gutter={16} style={{ paddingTop: '5px' }}>
        <Col span={12}>
          <h1 style={{ fontWeight: 'bold' }} className='pl-2 pt-3'>Browse Customer</h1>
        </Col>
      </Row>

      <Row
        gutter={8}
        style={{
          marginTop: '10px',
          background: '#F2F2F7',
          padding: '5px',

          alignItems: 'center' // Ensures alignment of items within the row
        }}
      >
        {/* First Column: Left-Aligned */}
        <Col span={4} xs={24} sm={12} md={6} lg={4}
          style={{ display: 'flex', alignItems: 'center' }}>
          <Space direction="horizontal" size={10}>
            <p style={{ margin: 0 }}>Show</p>
            <Select
              defaultValue={pagination.pageSize.toString()}
              options={options}
              size="small"
              style={{ width: 70, fontSize: 14 }}
              onChange={(value) => {
                setPagination((prev) => ({ ...prev, pageSize: parseInt(value) }));
                initialize(1, parseInt(value));
              }}
            />
            <p style={{ margin: 0 }}>entries</p>
          </Space>
        </Col>

        {/* Second Column: Right-Aligned Search */}
        <Col span={20} xs={24} sm={12} md={18} lg={20}
          style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{
              width: '30%',
              borderRadius: '4px',
              fontSize: 14,
            }}
            value={searchTerm}
            onChange={handleSearch}
          />
        </Col>
      </Row>

      <Row gutter={24} style={{ marginTop: '20px', borderRadius: '4px' }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={filteredCustomers}
            loading={loading}
            pagination={{
              current: pagination.current,
              total: pagination.total,
              pageSize: pagination.pageSize,
              onChange: (page) => initialize(page, pagination.pageSize),
              showSizeChanger: false, 
            }}
            rowKey="id"
          />
        </Col>
      </Row>

      <Modal
        title={<div style={{ textAlign: 'center' }}>Confirm</div>}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        styles={{
          body: {
            padding: "20px", // Adjust padding for smaller size
       }
        }}
        width={300}
        footer={
          <div className="flex justify-center gap-3">
            <Button type="default" onClick={handleCancel} style={{ width: '30%' }}>
              No
            </Button>
            <Button type="primary" onClick={handleOk} style={{ width: '30%' }}>
              Yes
            </Button>
          </div>
        }
      >
        <div style={{ textAlign: 'center' }}>
          Do you want to change status?
        </div>
      </Modal>

      <Modal
        title={<div style={{ textAlign: 'center' }}>Confirm</div>}
        open={isModalshow}
        onOk={handleDelete}
        onCancel={handleCancel}
        centered
        styles={{
          body: {
            padding: "20px",

          }
        }}
        width={300} 
        footer={
          <div className='flex justify-center gap-3'>
            <Button type="default" onClick={handleCancel} style={{ width: "30%" }}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleDelete} style={{ width: "30%" }}>
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: 'center' }}>Are you sure you want to delete this?</p>
      </Modal>

    </div>
  );
}

export default Customer;

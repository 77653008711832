import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Row,
  Col,
  Input,
  Select,
  Typography,
  Pagination,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { reportService } from "../../../services";

const { Title } = Typography;
function MonthlyCashFlow() {
  const { month } = useParams();
  const [loading, setLoading] = useState(false);
  const [collectionDetails, setCollectionDetails] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [collectionPagination, setCollectionPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [paymentPagination, setPaymentPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const options = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "9",
      label: "9",
    },
  ];
  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "#   ",
      render: (text, record, index) =>
        (collectionPagination.current - 1) * collectionPagination.pageSize +
        index +
        1,
    },
    {
      title: "Collection Date",
      dataIndex: "collection_date",
      key: "Collection_Date",
    },
    {
      title: "Invoice",
      dataIndex: "invoice_id",
      key: "invoice_id",
    },
    {
      title: "Collection Form",
      dataIndex: "collection_from",
      key: "collection_from",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => "$" + Number(text).toLocaleString(),
    },
  ];
  const columns2 = [
    {
      title: "#",
      dataIndex: "#",
      key: "#   ",
      render: (text, record, index) =>
        (paymentPagination.current - 1) * paymentPagination.pageSize +
        index +
        1,
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
    },
    {
      title: "Voucher#",
      dataIndex: "voucher_number",
      key: "voucher_number",
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type",
      key: "payment_type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => "$" + Number(text).toLocaleString(),
    },
  ];
  const initializeCollection = async (
    page = 1,
    pageSize = collectionPagination.current
  ) => {
    const type = 0;
    setLoading(true);
    const { success, data } = await reportService.MonthlyCashFlow(
      page,
      month,
      type
    );
    if (success) {
      setCollectionDetails(data.collectionDetails.data);
      setCollectionPagination((prev) => ({
        ...prev,
        total: data.collectionDetails.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };
  const initializePayments = async (
    page = 1,
    pageSize = paymentPagination.current
  ) => {
    const type = 1;
    setLoading(true);
    const { success, data } = await reportService.MonthlyCashFlow(
      page,
      month,
      type
    );
    if (success) {
      setPaymentDetails(data.paymentDetails.data);
      setPaymentPagination((prev) => ({
        ...prev,
        total: data.paymentDetails.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };

  useEffect(() => {
    initializePayments(paymentPagination.current, paymentPagination.pageSize);
  }, [paymentPagination.current, paymentPagination.pageSize]);

  useEffect(() => {
    initializeCollection(
      collectionPagination.current,
      collectionPagination.pageSize
    );
  }, [collectionPagination.current, collectionPagination.pageSize]);
  return (
    <>
      <Title level={5} style={{ textAlign: "center" }}>
        MONTHLY CASH FLOW
      </Title>

      <Row gutter={24} wrap={false}>
        {/* First Column */}
        <Col flex="1">
          <div
            style={{
              padding: "15px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Row gutter={0}>
              <Col span={24}>
                <h1 style={{ fontWeight: "bold" }}>
                  Collection Details for the Month Of January
                </h1>
              </Col>
            </Row>
            <Row
              gutter={8}
              style={{
                marginTop: "15px",
                background: "#F2F2F7",
                padding: "5px",

                alignItems: "center", // Ensures alignment of items within the row
              }}
            >
              <Col
                span={4}
                xs={24}
                sm={12}
                md={6}
                lg={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Space direction="horizontal" size={10}>
                  <p style={{ margin: 0 }}>Show</p>
                  <Select
                    defaultValue={10}
                    options={options}
                    size="small"
                    style={{ width: 70, fontSize: 14 }}
                  />
                  <p style={{ margin: 0 }}>entries</p>
                </Space>
              </Col>

              {/* Second Column: Right-Aligned Search */}
              <Col
                span={20}
                xs={24}
                sm={12}
                md={18}
                lg={20}
                style={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  style={{
                    width: "30%",
                    borderRadius: "4px",
                    fontSize: 14,
                  }}
                />
              </Col>
            </Row>

            <Table
              columns={columns}
              scroll={{ x: "max-content" }}
              dataSource={collectionDetails}
              loading={loading}
              pagination={{
                current: collectionPagination.current,
                total: collectionPagination.total,
                pageSize: collectionPagination.pageSize,
                onChange: (page) =>
                  initializeCollection(page, collectionPagination.pageSize),
                showSizeChanger: false,
              }}
              style={{ marginTop: "10px" }}
            />
          </div>
        </Col>
        {/* Second Column */}
        <Col flex="1">
          <div
            style={{
              padding: "15px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Row gutter={0}>
              <Col span={24}>
                <h1 style={{ fontWeight: "bold" }}>
                  Collection Details for the Month Of January
                </h1>
              </Col>
            </Row>
            <Row
              gutter={8}
              style={{
                marginTop: "15px",
                background: "#F2F2F7",
                padding: "5px",

                alignItems: "center", // Ensures alignment of items within the row
              }}
            >
              <Col
                span={4}
                xs={24}
                sm={12}
                md={6}
                lg={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Space direction="horizontal" size={10}>
                  <p style={{ margin: 0 }}>Show</p>
                  <Select
                    defaultValue={10}
                    options={options}
                    size="small"
                    style={{ width: 70, fontSize: 14 }}
                  />
                  <p style={{ margin: 0 }}>entries</p>
                </Space>
              </Col>

              {/* Second Column: Right-Aligned Search */}
              <Col
                span={20}
                xs={24}
                sm={12}
                md={18}
                lg={20}
                style={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  style={{
                    width: "30%",
                    borderRadius: "4px",
                    fontSize: 14,
                  }}
                />
              </Col>
            </Row>
            <Table
              columns={columns2}
              scroll={{ x: "max-content" }}
              dataSource={paymentDetails}
              loading={loading}
              pagination={{
                current: paymentPagination.current,
                total: paymentPagination.total,
                pageSize: paymentPagination.pageSize,
                onChange: (page) =>
                  initializePayments(page, paymentPagination.pageSize),
                showSizeChanger: false,
              }}
              style={{ marginTop: "10px" }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
export default MonthlyCashFlow;

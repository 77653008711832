import React, { useEffect, useState } from "react";
import { Table, Space, Button, Row, Col, Input } from "antd";
import { EditOutlined, SearchOutlined, EyeOutlined } from "@ant-design/icons";
import { imoService } from "../../../services";
import { Link } from "react-router-dom";
function LoadingImo() {
  const [loading, setLoading] = useState(false);
  const [imo, setIMO] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "_id",
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Transportation No",
      dataIndex: "transportation_no",
      key: "Transportation No",
    },
    {
      title: "Carrier No",
      dataIndex: "booking_no",
      key: "CarrierNo",
    },
    {
      title: "Container No",
      dataIndex: "ctu_id",
      key: "ContainerNo",
    },
    {
      title: "Seal No",
      dataIndex: "seal_no",
      key: "SealNo",
    },
    {
      title: "Actions",
      key: "Actions",
      render: (text, record, { _id }) => (
        <Space size="small">
          <Link to={`/operation/loadings/imos/edit/${record.id}`}>
            <Button type="primary" size="small">
              <EditOutlined />
            </Button>
          </Link>
          <Link to={`/operation/loadings/imos/view/${record.id}`}>
            <Button type="primary" size="small">
              <EyeOutlined />
            </Button>
          </Link>
        </Space>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      TransportationNo: "230197307",
      CarrierNo: "NAM6299735",
      ContainerNo: "MSKU1140851",
      SealNo: "307315",
    },
    {
      key: "1",
      TransportationNo: "230197307",
      CarrierNo: "NAM6299735",
      ContainerNo: "MSKU1140851",
      SealNo: "307315",
    },
    {
      key: "1",
      TransportationNo: "230197307",
      CarrierNo: "NAM6299735",
      ContainerNo: "MSKU1140851",
      SealNo: "307315",
    },
    {
      key: "1",
      TransportationNo: "230197307",
      CarrierNo: "NAM6299735",
      ContainerNo: "MSKU1140851",
      SealNo: "307315",
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    try {
      const { success, data } = await imoService.fetch(page);
      if (success) {
        setIMO(data.data);
        setPagination((prev) => ({
          ...prev,
          total: data.total,
          current: page,
          pageSize,
        }));
      } else {
        console.error("Fetch failed", data);
      }
    } catch (error) {
      console.error("Error fetching IMO data:", error);
    } finally {
      setLoading(false);
    }
  };

  // const initialize = async (page = 1, pageSize = pagination.current) => {
  //   setLoading(true);
  //   const { success, data } = await imoService.fetch(page);
  //   if (success) {
  //     setIMO(data.data);
  //     setPagination((prev) => ({
  //       ...prev,
  //       total: data.total,
  //       current: page,
  //       pageSize,
  //     }));
  //   }
  //   setLoading(false);
  // };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={24}>
        <Col span={10}>
          <h5 className="font-bold">IMO Generated</h5>
        </Col>
        <Col span={14} style={{ textAlign: "right" }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={imo}
        scroll={{ x: "max-content" }}
        style={{ marginTop: "5px" }}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        loading={loading}
      />
    </div>
  );
}
export default LoadingImo;

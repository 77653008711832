import { Button, Card, Col, Row, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import TodayLoadedContainer from './TodayLoadedContainer';
import AtYardVehicle from './AtYardVehicle';
import customerDashboardService from '../../../services/customer-dashboard-service';

function CustomerDashbroad() {
    const [data, setData] = useState([])
    const loadedCustomerDashboard = async () => {
        const { success, data } = await customerDashboardService.fetchDashbord()
        if (success) {
            setData(data)
        }
    }
    useEffect(() => {
        loadedCustomerDashboard()
    }, [])

    const cardData = [
        { title: 'Vehicles at Yard', count: data.total_vehicles_yard, imgSrc: '/images/c1.png', path: '/customers-portal/atvechile' },
        { title: 'Vehicles with Title at Yard', count: data.total_vehicles_with_title, imgSrc: '/images/c1.png', path: '/customers-portal/atvechile' },
        { title: 'Vehicles without Title at Yard', count: data.total_vehicles_without_title, imgSrc: '/images/c1.png', path: '/customers-portal/atvehicle-without-title' },
        { title: 'Vehicles without Keys at Yard', count: data.total_vehicles_without_keys, imgSrc: '/images/c1.png', path: '/customers-portal/atvehicle-without-key' },
        { title: 'BOS Vehicles at Yard', count: data.total_bos_vehicles, imgSrc: '/images/c1.png', path: '/customers-portal/vehicle-with-bos' },
        { title: 'Vehicles in Load Plans', count: data.total_vehicles_load_plan, imgSrc: '/images/c1.png', path: '/customers-portal/vehicle-loadplan' },
        { title: 'Other Vehicles', count: data.total_other_vehicles, imgSrc: '/images/c1.png', path: '/customers-portal/other-vehicles' },
        { title: 'Vehicles Loaded', count: 0, imgSrc: '/images/c1.png', path: "/customers-portal/loaded-vehicle" },
    ];

    const gradientStyle = {
        background: 'linear-gradient(95.24deg, #EA6262 1.26%, #B83D3D 50.63%, #F04949 100%)',
        width: '100%',
        flex: 1,
    };

    const cardBodyStyle = {
        padding: '10px 20px',
        color: '#FFFFFF',
    };

    return (
        <div>
            <div className='customer-dashboard'>
                <img src="/images/customer.png" alt="W3Schools.com"  ></img>
            </div>
            <div>
                <h1 style={{ fontSize: "16px", fontWeight: "bold" }} className='mt-10 mb-6'>Vehicle Status</h1>
                <Row gutter={[24, 24]}>
                    {cardData.map((data, index) => (
                        <Col span={8} key={index}> {/* This will ensure only 3 cards per row */}
                            <Link to={data.path}>
                                <Card
                                    style={gradientStyle}
                                    bodyStyle={cardBodyStyle}
                                >
                                    <div className="flex gap-5" style={{ height: '100%', fontSize: '14px' }}>
                                        <img src={data.imgSrc} alt="icon" height="90px" width="55px" />
                                        <div>
                                            <h1>{data.title}</h1>
                                            <h2>{data.count}</h2>
                                        </div>
                                    </div>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            </div>
            <div>
                <div className='mb-10'>
                    <h1 style={{ fontSize: "16px", fontWeight: "bold" }} className='mt-10 mb-6'>Balance Status</h1>
                    <Row gutter={[24, 24]} >
                        <Col span={8}> {/* This will ensure only 3 cards per row */}
                            <Card
                                style={gradientStyle}
                                bodyStyle={cardBodyStyle}>
                                <div className="flex gap-5" style={{ height: '100%', fontSize: '14px' }}>
                                    <img src="/images/c1.png" alt="icon" height="90px" width="55px" />
                                    <div>
                                        <h1>Unpaid Invoices Balance</h1>
                                        <h2>{data.total_unpaid_invoices}</h2>
                                    </div>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="flex flex-col gap-12 mt-10">
                <TodayLoadedContainer load={data.today_loaded_containers}/>
                <AtYardVehicle atyard={data.at_yard_vehicles} />
            </div>
        </div>

    )
}

export default CustomerDashbroad

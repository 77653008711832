import React, { useState, useEffect } from "react";
import { Table, Space, Button, Row, Col } from "antd";
import {
  EditOutlined,
  FilePdfOutlined,
  FileAddOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { loadingPlanService } from "../../../services";
function SaveContainer() {
  const [loading, setLoading] = useState(false);
  const [savedPlan, setSavedPlan] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "_id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Container ID",
      dataIndex: "container_id",
      key: "ContainerID",
    },
    {
      title: "Container Number",
      dataIndex: "c_container_number",
      key: "ContainerNumber",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "Location",
    },

    {
      title: "Actions",
      key: "Actions",
      render: (text, record) => (
        <Space direction="horizontal" size="small">
          <Space size="small">
            <Link to={`/operation/loadings/save-containers/edits/${record.container_id}`}>
              <Button type="primary" size="small">
                <EditOutlined />
              </Button>
            </Link>
            <Link to={`/operation/loadings/save-containers/views/${record.container_id}`}>
              <Button type="primary" size="small">
                <EyeOutlined />
              </Button>
            </Link>

            <Button type="primary" size="small">
              <DeleteOutlined />
            </Button>
            <Link to={"/loadings/generates-plans"}>
              <Button type="primary" size="small">
                <FilePdfOutlined />
              </Button>
            </Link>
            <Button type="primary" size="small">
              <FileAddOutlined />
            </Button>
          </Space>

          {record.isDockReceiptGenerated && (
            <span
              style={{
                color: "red",
                fontWeight: "bold",
                border: "1px solid #ccc",
                padding: 5,
              }}
            >
              "Dock Receipt Generated"
            </span>
          )}
        </Space>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      ContainerID: "HOUSTON 755-102",
      ContainerNumber: "TCNU5226866",
      Location: "HOUSTON 755",
      isDockReceiptGenerated: true,
    },
    {
      key: "1",
      ContainerID: "HOUSTON 755-102",
      ContainerNumber: "TCNU5226866",
      Location: "HOUSTON 755",
      isDockReceiptGenerated: false,
    },
    {
      key: "1",
      ContainerID: "HOUSTON 755-102",
      ContainerNumber: "TCNU5226866",
      Location: "HOUSTON 755",
      isDockReceiptGenerated: false,
    },
    {
      key: "1",
      ContainerID: "HOUSTON 755-102",
      ContainerNumber: "TCNU5226866",
      Location: "HOUSTON 755",
      isDockReceiptGenerated: true,
    },
    {
      key: "1",
      ContainerID: "HOUSTON 755-102",
      ContainerNumber: "TCNU5226866",
      Location: "HOUSTON 755",
      isDockReceiptGenerated: false,
    },
    {
      key: "1",
      ContainerID: "HOUSTON 755-102",
      ContainerNumber: "TCNU5226866",
      Location: "HOUSTON 755",
      isDockReceiptGenerated: true,
    },
    {
      key: "1",
      ContainerID: "HOUSTON 755-102",
      ContainerNumber: "TCNU5226866",
      Location: "HOUSTON 755",
      isDockReceiptGenerated: false,
    },
  ];
  const initialize = async (page = 1, pageSize = pagination.current) => {
    try {
      setLoading(true);

      const { success, data, message } = await loadingPlanService.fetchsaved(
        page
      );

      if (success) {
        if (data && data.data && data.data.length > 0) {
          setSavedPlan(data.data);
          setPagination((prev) => ({
            ...prev,
            total: data.total,
            current: page,
            pageSize,
          }));
        } else {
          console.warn("No saved plans found.");
          setSavedPlan([]);
        }
      } else {
        console.error(`Error: ${message || "Failed to fetch saved plans."}`);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={16}>
        <Col span={10}>
          <h5 className="font-bold"> Saved Container</h5>
        </Col>
      </Row>
      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={savedPlan}
        loading={loading}
        style={{ marginTop: "10px" }}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
      />
    </div>
  );
}
export default SaveContainer;

import ajaxService from "./ajax-service";

class InvoiceService {
  async fetch(page) {
    const response = await ajaxService.get(`/invoices?page=${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async viewInvoice(id) {
    const response = await ajaxService.get(`/invoices/${id}`);
    if (response ?? false) {
      return response;
    }
  }
  async getInvoiceCharges(id) {
    const response = await ajaxService.get(`/invoices/charges/${id}`);
    if (response ?? false) {
      return response;
    }
  }
  async approveInvoice(id) {
    const response = await ajaxService.get(`/invoices/approve/${id}`);
    if (response ?? false) {
      return response;
    }
  }
  async makePayment(data) {
    const response = await ajaxService.post(`/accounts/create-transaction`, data);
    if (response ?? false) {
      return response;
    }
  }
  async generateInvoice(data) {
    const response = await ajaxService.post(`/invoices/charges`, data);
    if (response ?? false) {
      return response;
    }
  }
  async updateInvoice(id, data) {
    const response = await ajaxService.put(`/invoices/`,id, data);
    if (response ?? false) {
      return response;
    }
  }

  /* Customer Invoice API */
  async fetchCustomerInvoices(page) {
    const response = await ajaxService.get(`/client-invoices?page=${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async viewCustomerInvoice(id) {
    const response = await ajaxService.get(`/client-invoices/${id}`);
    if (response ?? false) {
      return response;
    }
  }
}

const invoiceService = new InvoiceService();

export default invoiceService;

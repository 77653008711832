import React, { useEffect, useState, Suspense } from "react";
import { Button, Col, Form, Input, Row, DatePicker, Checkbox, notification, } from "antd";
import { Select, Typography, Spin } from "antd";
import { useParams } from "react-router-dom";
import { vehicalService, customerService, emailService, dropdownService, countryService, } from "../../../services";
import moment from "moment";

const { Title } = Typography;

function CustomerForm() {
  const { id } = useParams();
  const isEditMode = id !== undefined;
  const [form] = Form.useForm();
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [vehicle_col, setVehicle_col] = useState([]);
  const [customer, setCustomer] = useState({});
  const [countries, setCountries] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [emailTypes, setEmailTypes] = useState([]);
  const [emailValues, setEmailValues] = useState({});
  const [sendEmailChecked, setSendEmailChecked] = useState(false);
  const [viewInvoiceChecked, setViewInvoiceChecked] = useState(false);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleSelectChange = (value) => {
    setSelectedEntities(value);
  };

  const calculateWidth = (dataLength) => {
    const baseWidth = 400;
    const additionalWidth = dataLength * 20;
    return baseWidth + additionalWidth;
  };

  const initialize = async () => {
    try {
      const {
        success,
        data: vehicleData,
        message: vehicleMessage,
      } = await vehicalService.getColumns();
      if (success) {
        setVehicle_col(vehicleData);
      } else {
        console.error(
          `Error fetching vehicle columns: ${vehicleMessage || "Unknown error"}`
        );
      }

      const {
        success: emailSuccess,
        data: emailData,
        message: emailMessage,
      } = await emailService.fetch();
      if (emailSuccess) {
        setEmailTypes(emailData);
        const initialInputs = {};
        emailData.forEach((type) => {
          initialInputs[type.id] = "";
        });
        setEmailValues(initialInputs);
      } else {
        console.error(
          `Error fetching email types: ${emailMessage || "Unknown error"}`
        );
      }

      if (isEditMode) {
        const {
          success: customerSuccess,
          data: cusData,
          message: customerMessage,
        } = await customerService.view(id);
        if (customerSuccess) {
          setCustomer(cusData.customer);
          setSendEmailChecked(cusData.customer.send_email);
          setViewInvoiceChecked(cusData.customer.view_invoice);
          setAgreementChecked(cusData.customer.is_agreement_required);
          setSelectedEntities(cusData.customer.columns_view);

          form.setFieldsValue({
            ...cusData.customer,
            contract_start_date: cusData.customer.contract_start_date
              ? moment(cusData.customer.contract_start_date)
              : null,
            contract_end_date: cusData.customer.contract_end_date
              ? moment(cusData.customer.contract_end_date)
              : null,
          });

          const updatedEmailValues = { ...emailValues };
          cusData?.customer.user_email_types?.forEach((emailType) => {
            updatedEmailValues[emailType.email_type_id] = emailType.email || "";
          });
          setEmailValues(updatedEmailValues);
        } else {
          console.error(
            `Error fetching customer data: ${customerMessage || "Unknown error"
            }`
          );
        }
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  const getCustomers = async () => {
    const response = await dropdownService.fetchCustomers();
    if (response.success) {
      setCustomers(response.data);
    }
  };
  const getCountries = async () => {

    const { success, data } = await countryService.countryGet();
    if (success) {
      setTimeout(() => {
        setCountries(data);
        setLoading(false);
      }, 2000);
    }
  };
  // const initialize = async () => {
  //   setLoading(true);
  //   const { success, data: vehicleData } = await vehicalService.getColumns();
  //   if (success) {
  //     setVehicle_col(vehicleData);
  //   }
  //   const { success: emailSuccess, data: emailData } =
  //     await emailService.fetch();
  //   if (success) {
  //     setEmailTypes(emailData);
  //     const initialInputs = {};
  //     emailData.forEach((type) => {
  //       initialInputs[type.id] = "";
  //     });
  //     setEmailValues(initialInputs);
  //   }
  //   setLoading(false);
  //   if (isEditMode) {
  //     const { success: customerSuccess, data: cusData } =
  //       await customerService.view(id);
  //     // console.log(cusData);
  //     setCustomer(cusData.customer);
  //     if (customerSuccess) {
  //       setSendEmailChecked(cusData.customer.send_email);
  //       setViewInvoiceChecked(cusData.customer.view_invoice);
  //       setAgreementChecked(cusData.customer.is_agreement_required);
  //       setSelectedEntities(cusData.customer.columns_view);
  //       // const updatedEmailValues = { ...emailValues };
  //       // cusData.customer.user_email_types.forEach((emailType) => {
  //       //   updatedEmailValues[emailType.email_type_id] = emailType.email || "";
  //       // });
  //       // setEmailValues(updatedEmailValues);
  //       form.setFieldsValue({
  //         ...cusData.customer,
  //         contract_start_date: cusData.customer.contract_start_date
  //           ? moment(cusData.customer.contract_start_date)
  //           : null,
  //         contract_end_date: cusData.customer.contract_end_date
  //           ? moment(cusData.customer.contract_end_date)
  //           : null,
  //       });
  //       const updatedEmailValues = { ...emailValues };
  //       cusData.customer.user_email_types.forEach((emailType) => {
  //         updatedEmailValues[emailType.email_type_id] = emailType.email || "";
  //       });
  //       setEmailValues(updatedEmailValues);
  //     }
  //   }
  // };

  useEffect(() => {
    initialize();
    getCustomers();
    getCountries()
  }, []);
  useEffect(() => {
    // Update emailValues once both emailTypes and customer data are loaded
    if (emailTypes.length > 0 && customer.user_email_types) {
      const updatedEmailValues = { ...emailValues };
      customer.user_email_types.forEach((emailType) => {
        updatedEmailValues[emailType.email_type_id] = emailType.email || "";
      });
      setEmailValues(updatedEmailValues);
    }
  }, [customer]);

  const handleEmailChange = (typeId, value) => {
    setEmailValues((prev) => ({
      ...prev,
      [typeId]: value,
    }));
  };
  const onFinish = async (values) => {

    const emailTypeIds = emailTypes.map((emailType) => emailType.id.toString());
    const emailTypeValues = emailTypes.map((emailType) => emailValues[emailType.id] || "");
    const checkboxStates = {
      send_email: sendEmailChecked ? 1 : 0,
      view_invoice: viewInvoiceChecked ? 1 : 0,
      is_agreement_required: agreementChecked ? 1 : 0,
    };

    const data = {
      ...values,
      contract_start_date: moment(values.contract_start_date).format(
        "YYYY-MM-DD"
      ),
      contract_end_date: moment(values.contract_end_date).format("YYYY-MM-DD"),
      columns_view: selectedEntities,
      email_type: emailTypeIds,
      email_values: emailTypeValues,
      ...checkboxStates,
    };

    const response = isEditMode ? await customerService.editCustomer(id, data) : await customerService.addCustomer(data);

    if (response?.success ?? false) {
      notification.success({
        message: "Success",
        description: isEditMode ? "Customer updated successfully!" : "Customer added successfully",
        placement: "topRight",
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Error Info: ' + errorInfo)
  }


  return (
    <div
      style={{
        padding: "0 14px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row
        gutter={26}
        style={{
          backgroundColor: "#FBFBFB",
          marginBottom: 15,
          padding: "10px",
          borderRadius: "4px",
          borderBottom: "1px solid #E8E8E9",
        }}
      >
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>
            {isEditMode ? "Edit Customer" : "Add Customer"}
          </h1>
        </Col>
      </Row>

      <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="Name" name="name">
              <Input placeholder={isEditMode ? "Customer Name" : "MAHMOOD"} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Email" name="email">
              <Input
                placeholder={
                  isEditMode ? "Customer Email" : "wally724@yahoo.com"
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Password" name="password">
              <Input.Password
                placeholder="Enter password"
                rules={
                  !isEditMode
                    ? [{ required: true, message: "Please input password!" }]
                    : []
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Confirm Password"
              name="password_confirmation"
              dependencies={["password"]}
              rules={
                !isEditMode
                  ? [
                    {
                      required: true,
                      message: "Please input confirm password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Passwords do not match!")
                        );
                      },
                    }),
                  ]
                  : []
              }
            >
              <Input.Password placeholder="Confirm password" />
            </Form.Item>
          </Col>

          {/* Other fields */}
          <Col span={6}>
            <Form.Item label="Address" name="address">
              <Input placeholder="Sharjah" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Town" name="town">
              <Input placeholder="Sharjah" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="City" name="city">
              <Input placeholder="Sharjah" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: true, message: "Please input!" }]} >

              <Select placeholder="Select a country"
                dropdownRender={(menu) => {
                  // Show spinner when loading
                  if (loading) {
                    return (
                      <>
                        {/* {menu} */}
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '10px',
                          }}
                        >
                          <Spin size="small" />
                        </div>
                      </>
                    );
                  }
                  return menu; // If not loading, return the default menu
                }}
              >
                {countries.map((country) => (
                  <Select.Option key={country.id} value={country.iso_code} >
                    {country.name}
                  </Select.Option>
                ))}
              </Select>

            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Phone" name="phone">
              <Input placeholder="+12345678900" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Secondary Email" name="secondary_email">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Parent Customer"
              name="parent_id"
            // rules={[{ required: true, message: "Please select a customer" }]}
            >
              <Select placeholder="Select Customer">
                {customers.map((cust) => (
                  <Select.Option key={cust.id} value={cust.id}>
                    {cust.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Contact Start" name="contract_start_date">
              <DatePicker style={{ width: "100%" }} placeholder="mm/dd/yyyy" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Contact End" name="contract_end_date">
              <DatePicker style={{ width: "100%" }} placeholder="mm/dd/yyyy" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Contract Days" name="contract_days">
              <Input placeholder="0" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Contact Charges/day" name="contract_charges">
              <Input placeholder="0" />
            </Form.Item>
          </Col>

          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                width: calculateWidth(vehicle_col.length),
                margin: "30px",
              }}
            >
              <Form.Item>
                <Title level={5}>Vehicle Columns</Title>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select entities"
                  value={selectedEntities}
                  onChange={handleSelectChange}
                >
                  {vehicle_col.map((entity, index) => (
                    <Select.Option key={index} value={entity}>
                      {entity}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>

      {/* Email and Agreement Section */}
      <div
        style={{
          margin: "40px",
          padding: "0 14px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF",
          marginTop: "20px",
          paddingBottom: 20,
        }}
      >
        <Suspense fallback={<Spin />}>
          <Row gutter={24}>
            <Col
              span={24}
              style={{
                paddingBottom: 10,
                paddingTop: 10,
                borderBottom: "1px solid #E8E8E9",
                backgroundColor: "#FBFBFB",
              }}
            >
              <Row style={{ backgroundColor: "#FBFBFB" }}>
                <Col span={6}>
                  <h3 style={{ fontWeight: "bold" }}>Email</h3>
                </Col>
                <Col span={18}>
                  <p style={{ fontWeight: "bold" }}>
                    Emails (use comma to add multiple email IDs)
                  </p>
                </Col>
              </Row>
            </Col>

            {emailTypes.map((emailItem, index) => (
              <Col
                key={emailItem.id}
                span={24}
                style={{
                  paddingBottom: 10,
                  borderBottom: "1px solid #E8E8E9",
                  backgroundColor: index % 2 === 0 ? "#F2F2F7" : "#F4F7FE",
                  paddingTop: 10,
                }}
              >
                <Row>
                  <Col span={6}>
                    <h4 style={{ margin: 0 }}>{emailItem.name} Emails</h4>
                  </Col>
                  <Col span={18}>
                    <Input
                      placeholder={`Enter ${emailItem.name} Email`}
                      key={
                        customer?.user_email_types?.find(
                          (email) => email.email_type_id === emailItem.id
                        )?.email || ""
                      }
                      defaultValue={
                        customer?.user_email_types?.find(
                          (email) => email.email_type_id === emailItem.id
                        )?.email || ""
                      }
                      onChange={(e) =>
                        handleEmailChange(emailItem.id, e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            ))}

            {/* Checkbox Section */}
            <Col span={24} style={{ marginBottom: "20px" }}>
              <Row>
                <Col span={24} className="flex flex-row justify-around mt-5">
                  <Checkbox
                    checked={sendEmailChecked}
                    style={{ marginRight: "20px" }}
                    onChange={(e) => setSendEmailChecked(e.target.checked)}
                  >
                    Send Email
                  </Checkbox>
                  <Checkbox
                    checked={viewInvoiceChecked}
                    style={{ marginRight: "20px" }}
                    onChange={(e) => setViewInvoiceChecked(e.target.checked)}
                  >
                    Invoice
                  </Checkbox>
                  <Checkbox
                    checked={agreementChecked}
                    onChange={(e) => setAgreementChecked(e.target.checked)}
                  >
                    Agreement
                  </Checkbox>
                </Col>
              </Row>
            </Col>

            {/* Add Customer Button */}
            <Col span={24} style={{ textAlign: "center" }}>
              <Button
                type="primary"
                style={{ width: "50%" }}
                // htmlType="submit"
                onClick={() => form.submit()}
              >
                {isEditMode ? "Update Customer" : "Add Customer"}
              </Button>
            </Col>
          </Row>
        </Suspense>
      </div>
    </div >
  );
}

export default CustomerForm;

// add customer Dashboard
// const [form] = Form.useForm();
// const [formLayout, setFormLayout] = useState('vertical');
// const onFormLayoutChange = ({ layout }) => {
//   setFormLayout(layout);  }
// return (

// <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px' ,backgroundColor:"#FFFFFF"}}>
// <Form
// requiredMark={false}
//   layout={formLayout}
//   form={form}
//   initialValues={{
//     layout: formLayout,
//   }}
//   onValuesChange={onFormLayoutChange}
// >
// <Row gutter={24} >
// <Col span={8}>
//   <Form.Item label="Invoice Number">
//     <Input placeholder="5536" />
//   </Form.Item>
// </Col >
// <Col span={8}>
//   <Form.Item label="Invoice Amount">
//     <Input placeholder="150" />
//   </Form.Item>
// </Col >

// <Col span={8}>
//   <Form.Item label="Ref. Number">
//     <Input placeholder="input placeholder" />
//   </Form.Item>
// </Col >
// <Col span={8}>
//   <Form.Item label="Payment Date">
//     <Input placeholder="28-Oct-2024" />
//   </Form.Item>
// </Col >
// <Col span={8}>
//   <Form.Item label="Transaction Amount">
//     <Input placeholder="0" />
//   </Form.Item>
// </Col >
// <Col span={8}>
//   <Form.Item label="Balance">
//     <Input placeholder="0" />
//   </Form.Item>
// </Col >

// <Col span={8}>
// <Form.Item
//     label="Mode Of Payment"
//     name="Mode Of Payment"
//     rules={[
//         {
//             required: true,
//             message: 'Please input!',
//         },
//     ]}
//     >
//     <Select placeholder="Bank Transfer"/>
//   </Form.Item>
//       </Col>
//       <Col span={8}>
// <Form.Item
//     label="Bank"
//     name="Bank"
//     rules={[
//         {
//             required: true,
//             message: 'Please input!',
//         },
//     ]}
//     >
//     <Select placeholder="" />
//   </Form.Item>
//       </Col>

//       <Col span={8}>
// <Form.Item
//     label="Payment Status"
//     name="Payment Status"
//     rules={[
//         {
//             required: true,
//             message: 'Please input!',
//         },
//     ]}
//     >
//     <Select placeholder="Not paid" />
//   </Form.Item>
//       </Col>
//       <Col span={24}>
//       <Form.Item style={{ textAlign: 'center',marginTop:30 }}>
//         <Button type="primary" style={{ width: '50%' }}>Update</Button>
//       </Form.Item>
//     </Col>
// </Row>
// </Form>
// </div>

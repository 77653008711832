import React, { useState } from "react";
import { Modal, Form, Row, Col, Input, Select, DatePicker, Button } from "antd";

const MakePayment = ({
  isModalOpen,
  handleOk,
  handleCancel,
  form2,
  handlePayment,
  pendingInvoices,
  paymentmodes,
  banks,
  invoice,
  disable,
  setDisable
}) => {
  const [showBankField, setShowBankField] = useState(false);
  const handleChange = (value) => {
    if (value === 1) {
      setShowBankField(true);
    } else {
      setShowBankField(false);
    }
  };
  const handleBalance = (e) => {
    const balance = invoice?.invoice?.total;
    const transactionAmount = parseFloat(e.target.value) || 0;
    const updatedBalance = balance - transactionAmount;
    if (updatedBalance === 0) {
      setDisable(false);
      form2.setFieldsValue({ status: 2 });
    } else if (transactionAmount === 0) {
      setDisable(true);
      form2.setFieldsValue({ status: 0 });
    } else {
      setDisable(false);
      form2.setFieldsValue({ status: 1 });
    }
    form2.setFieldsValue({ due_amount: updatedBalance });
  };
  return (
    <Modal
      title={<div style={{ width: "100%" }}>Add Transaction</div>}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={true}
      width={700}
      style={{ padding: "15px" }}
    >
      <Form
        layout="vertical"
        form={form2}
        onFinish={handlePayment}
        requiredMark={false}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Invoice"
              name="invoice_id"
              rules={[
                {
                  required: true,
                  message: "Please select an invoice",
                },
              ]}
            >
              <Select
                placeholder="Select Invoice"
                showSearch
                optionFilterProp="children"
              >
                {pendingInvoices.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.id}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Ref. Number"
              name="reference_number"
              rules={[
                {
                  required: true,
                  message: "Please enter the number of allowed appointments",
                },
              ]}
            >
              <Input type="number" placeholder="" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Payment Date"
              name="payment_date"
              rules={[
                { required: true, message: "Please enter the start time" },
              ]}
            >
              <DatePicker
                style={{ width: "100%" }}
                placeholder={"mm/dd/yyyy"}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Invoice Amount" name="amount">
              <Input disabled style={{ color: "Black" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Transaction Amount"
              name="balance"
              rules={[{ required: true, message: "Please enter the end time" }]}
              onChange={(e) => handleBalance(e)}
            >
              <Input placeholder="0.00" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Balance" name="due_amount">
              <Input
                defaultValue={invoice?.invoice?.total}
                disabled
                style={{ color: "Black" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Mode Of Payment"
              name="payment_mode"
              rules={[{ required: true, message: "Please enter the end time" }]}
            >
              <Select
                placeholder="Select Mode"
                onChange={(value) => handleChange(value)}
              >
                {paymentmodes.map((mode) => (
                  <Select.Option key={mode.id} value={mode.id}>
                    {mode.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {showBankField && (
            <Col span={8}>
              <Form.Item
                label="Bank"
                name="bank"
                rules={[
                  { required: true, message: "Please enter the end time" },
                ]}
              >
                <Select placeholder="BANK OF AMERICA">
                  {banks.map((bank) => (
                    <Select.Option key={bank.id} value={bank.id}>
                      {bank.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={8}>
            <Form.Item
              label="Payment Status"
              name="status"
              rules={[{ required: true, message: "Please enter the end time" }]}
            >
              <Select placeholder="Payment Status">
                <Select.Option value={0}>Not Paid</Select.Option>
                <Select.Option value={1}>Partial</Select.Option>
                <Select.Option value={2}>Complete</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <div style={{ textAlign: "center", marginTop: "12px" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "60%" }}
                  disabled={disable}
                >
                  Add Transaction
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default MakePayment;

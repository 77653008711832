import React, { useState, useEffect } from 'react'
import { Table, Space, Button, Row, Col, Modal, Form, Checkbox, Input, notification } from 'antd';
import { CiSquarePlus } from "react-icons/ci";
import { EditOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons'
import containerService from '../../../services/container-service';
function ContainerSize() {
    const [form] = Form.useForm();
    const [showModal, setShowModal] = useState(false);
    const [selectId, setSelectId] = useState(0)

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDeleteRecord, setSelectedDeleteRecord] = useState(null);

    const [containers, setContainer] = useState([])
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, total: 1, pageSize: 10 });

    const openModal = (record) => {
        setSelectedDeleteRecord(record)
        setIsModalVisible(true)
    }
    const handleOk = async () => {
        if (!selectedDeleteRecord || !selectedDeleteRecord.id) {
            console.error("No record selected for deletion.");
            return;
        }
        setLoading(true); // Show loading indicator
        const response = await containerService.delete(selectedDeleteRecord.id);
        if (response?.success) {
            initialize()
            console.log("Record deleted successfully.");
            notification.success({
                message: response.message,
                description: "Record deleted successfully.",
                duration: 2,
            });
        } else {
            console.error("Failed to delete the record.");
        }
        setLoading(false)
        setIsModalVisible(false)
    }
    const initialize = async (page = 1, pageSize = pagination.pageSize) => {
        setLoading(true);
        const { success, data } = await containerService.fetch(page);
        setLoading(false);
        if (success) {
            setContainer(data.data);
            setPagination((prev) => ({
                ...prev,
                total: data.total,
                current: page,
                pageSize,
            }));
        }
    }; 
    const handleOpenModal = async (id = 0) => {
        if (id !== 0) {
            const response = await containerService.view(id);
            console.log("data", response.data)
            if (response?.success) {
                const { status, name } = response.data
                form.setFieldsValue({ status: status, name: name })
            }
        }
        setSelectId(id)
        setShowModal(true)
    };
    const handleCancel = () => {
        setSelectId(0)
        setShowModal(false);
        form.resetFields();
        setIsModalVisible(false);
    };
    const onFinish = async (values) => {
        const data = {
            name: values.name,
            status: values.status ? 1 : 0,
        };

        setLoading(true);
        let response;
        if (selectId !== 0) {
            response = await containerService.edit(selectId, data);
        } else {
            response = await containerService.add(data);
        }
        setLoading(false);
        if (response?.success) {
            handleCancel();
            initialize()
        };
    }
    const onFinishFailed = (errorInfo) => {
        console.error("Form Submission Failed:", errorInfo);
    };
    const columns = [
        // {
        //     title: "#",
        //     dataIndex: '#',
        //     key: '#',
        //     render: (text, record, index) => index + 1,
        // },
        {
            title: "Id",
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: "Sizes",
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Status",
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Button
                    style={{
                        backgroundColor: record.status === 1 ? "#06D652" : "#FF4D4F",
                        color: "white"
                    }}
                >
                    {record.status === 1 ? "Active" : "InActive"}
                </Button>
            )
        },
        {
            title: 'Actions',
            key: 'Actions',
            render: (text, record) => (
                <Space size='small'>
                    <Button type='primary' size="small" onClick={() => handleOpenModal(record.id)}>
                        <EditOutlined />
                    </Button>
                    <Button type='primary' size="small">
                        <EyeOutlined />
                    </Button>
                    <Button type='primary' size="small" onClick={() => openModal(record)}>
                        <DeleteOutlined />
                    </Button>
                </Space >
            )
        },
    ];
    useEffect(() => {
        initialize();
    }, [])

    return (
        <div style={{ padding: '5px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Row
                gutter={30}
                style={{ padding: '5px', borderRadius: '4px' }}
                className="flex items-center">
                <Col flex="auto">
                    <Space direction="horizontal" size={10}>
                        <h5 className="font-bold">Container sizes</h5>
                    </Space>
                </Col>

                <Col flex="none" style={{ display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'flex-end' }}>
                    {/* Plus Icon */}

                    <CiSquarePlus size={30} style={{ cursor: 'pointer' }} onClick={() => handleOpenModal()} />

                </Col>
            </Row>


            {/* Edit container size */}
            <Modal
                title={<div style={{ textAlign: "center", width: "100%" }}> {selectId !== 0 ? "EDIT CONTAINER SIZE" : "ADD CONTAINER SIZE"} </div>}
                width={450}
                styles={{
                    body: {
                        padding: "10px",
                    }
                }}
                open={showModal}
                onCancel={handleCancel}
                footer={null}>

                <Form layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}   >

                    <Form.Item label="Size" name="name" rules={[{ required: true, message: "Please enter a size" }]}>
                        <Input placeholder="Enter size" />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        valuePropName="checked"
                        initialValue={false}>
                        <Checkbox>Status</Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loading}>
                            {selectId !== 0 ? "Update" : "Add"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title={<div style={{ textAlign: 'center' }}>Confirm</div>}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                styles={{
                    body: {
                        padding: "20px",
                    }
                }}
                width={300}
                footer={
                    <div className='flex justify-center gap-3'>
                        <Button type="default" onClick={handleCancel} style={{ width: "30%" }}>
                            Cancel
                        </Button>
                        <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
                            Confirm
                        </Button>
                    </div>
                }
            >
                <p style={{ textAlign: 'center' }}>Are you sure you want to delete this?</p>
            </Modal>

            <Table
                columns={columns}
                scroll={{ x: "max-content" }}
                style={{ marginTop: '5px' }}
                loading={loading}
                dataSource={containers}
                pagination={{
                    current: pagination.current,
                    total: pagination.total,
                    pageSize: pagination.pageSize,
                    onChange: (page) => initialize(page),
                    showSizeChanger: false,

                }}
            />
        </div>
    )
}
export default ContainerSize

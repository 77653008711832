import {
  Table,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Modal,
  Form,
  DatePicker,
} from "antd";
import {
  SearchOutlined,
  SendOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { CiSquarePlus } from "react-icons/ci";
import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import AddCreditNoteModal from "./AddCreditNote";
import { creditNoteService, dropdownService } from "../../../../../services";

function CreditNotes() {
  const [loading, setLoading] = useState(false);
  const [creditNote, setCreditNote] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [invoices, setInvoices] = useState([]);
  const [isModalShow, setIsModalShow] = useState(false);

  const showModal = () => setIsModalShow(true);

  // const handleOk = () => {
  //   setIsModalShow(false);
  // };
  // const handleCancel = () => {
  //   setIsModalShow(false);
  // };
  const options = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "9",
      label: "9",
    },
  ];
  const options2 = [
    {
      value: "Print",
      label: "Print",
    },
    {
      value: "Pdf",
      label: "Pdf",
    },
    {
      value: "CSV",
      label: "CSV",
    },
    {
      value: "Copy",
      label: "Copy",
    },
  ];
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Credit Number",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Issue Date",
      dataIndex: "issue_date",
      key: "issue_date",
    },
    {
      title: "Invoice",
      dataIndex: "invoice_id",
      key: "invoice_id",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      title: "Action",
      key: "Action",
      render: (text, { _id }) => (
        <Space size="small">
          <Button type="primary" size="small">
            <SendOutlined />
          </Button>
          <Button type="primary" size="small">
            <FilePdfOutlined />
          </Button>
        </Space>
      ),
    },
  ];
  const data = [
    {
      key: "id",
      CreditNumber: "7117",
      IssueDate: "0254-04-22",
      Invoice: "4458",
      Amount: "-75",
      Description:
        "CREDIT NOTE FOR EXTRA PAYMENT RECEIVED ON WIRE FOR CONTAINER NO HASU4292421 Send",
    },
    {
      key: "id",
      CreditNumber: "7117",
      IssueDate: "0254-04-22",
      Invoice: "4458",
      Amount: "-75",
      Description:
        "CREDIT NOTE FOR EXTRA PAYMENT RECEIVED ON WIRE FOR CONTAINER NO HASU4292421 Send",
    },
    {
      key: "id",
      CreditNumber: "7117",
      IssueDate: "0254-04-22",
      Invoice: "4458",
      Amount: "-75",
      Description:
        "CREDIT NOTE FOR EXTRA PAYMENT RECEIVED ON WIRE FOR CONTAINER NO HASU4292421 Send",
    },
    {
      key: "id",
      CreditNumber: "7117",
      IssueDate: "0254-04-22",
      Invoice: "4458",
      Amount: "-75",
      Description:
        "CREDIT NOTE FOR EXTRA PAYMENT RECEIVED ON WIRE FOR CONTAINER NO HASU4292421 Send",
    },
    {
      key: "id",
      CreditNumber: "7117",
      IssueDate: "0254-04-22",
      Invoice: "4458",
      Amount: "-75",
      Description:
        "CREDIT NOTE FOR EXTRA PAYMENT RECEIVED ON WIRE FOR CONTAINER NO HASU4292421 Send",
    },
  ];
  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    const { success, data } = await creditNoteService.fetch(page);
    if (success) {
      setCreditNote(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };

  const getInvoices = async () => {
    const response = await dropdownService.fetchInvoices();
    if (response.success) {
      const filteredInvoices = response.data.filter((i) => i.is_partial != 2);
      setInvoices(filteredInvoices);
    }
  };
  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
    getInvoices();
  }, [pagination.current, pagination.pageSize]);
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Credit Notes</h1>
        </Col>
      </Row>
      <Divider style={{ marginTop: 10 }} />
      <Row
        gutter={8}
        style={{
          marginTop: "15px",
          background: "#F2F2F7",
          padding: "5px",
          alignItems: "center", // Ensures alignment of items within the row
        }}
      >
        <Col
          span={4}
          xs={24}
          sm={12}
          md={6}
          lg={4}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Space direction="horizontal" size={10}>
            <p style={{ margin: 0 }}>Show</p>
            <Select
              defaultValue={10}
              options={options}
              size="small"
              style={{ width: 70, fontSize: 14 }}
            />
            <p style={{ margin: 0 }}>entries</p>
          </Space>
        </Col>

        {/* Second Column: Right-Aligned Search */}
        <Col
          span={20}
          xs={24}
          sm={12}
          md={18}
          lg={20}
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <CiSquarePlus
            size={30}
            style={{ cursor: "pointer" }}
            onClick={showModal}
          />
        </Col>
      </Row>
      <Row
        gutter={8}
        style={{
          marginTop: "6px",
          background: "#F2F2F7",
          padding: "10px",
          borderRadius: "4px",
        }}
        className="flex justify-between"
      >
        <Col span={12}>
          <Space direction="horizontal" size={12}>
            <Select
              defaultValue="Print"
              options={options2}
              size="large"
              // dropdownMatchSelectWidth={false} // Prevent dropdown from inheriting the small width
              style={{
                width: "200px", // Ensure the select is square-like
                height: 30, // Equal height for a square appearance
                fontSize: 14,
              }}
            />
          </Space>
        </Col>

        <Col span={12} style={{ textAlign: "right" }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>
      <AddCreditNoteModal
        isVisible={isModalShow}
        setIsModalShow={setIsModalShow}
        invoices={invoices}
        creditNote={creditNote}
        initialize={() => initialize(pagination.current, pagination.pageSize)}
      />

      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={creditNote}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        loading={loading}
        style={{ marginTop: "10px" }}
      />
    </div>
  );
}
export default CreditNotes;

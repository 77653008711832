import React, { useState, useRef } from "react";
import "antd/dist/reset.css";
import CalendarApp from "../../adminPortal/appoinments/Calendar";
import AppointmentForm from "../../adminPortal/appoinments/Form";

function CustomerAddAppointment() {
  const [selectedDate, setSelectedDate] = useState(null); // Track selected date
  const [selectedSlot, setSelectedSlot] = useState(null); // Track selected time slot
  const [isAppointmentFormVisible, setIsAppointmentFormVisible] = useState(false); // Manage form visibility
  const formRef = useRef(null); // Create a ref for the AppointmentForm

  const timeSlots = [
    "9:00 AM",
    "9:15 AM",
    "9:30 AM",
    "9:45 AM",
    "10:00 AM",
    "10:15 AM",
    "10:30 AM",
  ];

  const handleDateClick = (date) => {
    setSelectedDate(date); // Set the selected date
    setSelectedSlot(null); // Reset time slot when date changes
    setIsAppointmentFormVisible(false); // Hide form when the date changes
  };

  const handleSlotClick = (slot) => {
    setSelectedSlot(slot); // Update selected slot
    setIsAppointmentFormVisible(true); // Show the appointment form
    setTimeout(() => {
      formRef.current?.scrollIntoView({ behavior: "smooth" }); // Scroll to the AppointmentForm
    }, 0);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          padding: "5px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF",
          marginBottom:"5px"
        }}
      >
        <div style={{ paddingLeft: "20px", paddingRight: "20px", borderRight: "1px solid #ccc", width: "40%", }}>
          <div className="mb-20">
          <div className="mt-16 mb-10">
            <img src="/images/ctrade-logo.png" alt="" style={{ width: "140px", height: "75px" }} />
            <h1 style={{ fontSize: "15px", fontWeight: "bolder" }}>One Car Appointment</h1>
          </div>
          <div className="flex gap-1 mb-5">
            <img src="/images/Icon.png" alt="" width={"10px"} />
            <p>3902 SWINGLE RD HOUSTON TX 77047</p>
          </div>
          <div className="mt-4 mb-8" style={{ fontSize: "12px" }}>
            <p>Please be on time for your appointment.</p>
            <p>Please sign in at the receiving, when you arrive.</p>
          </div>
          <div>
            <ul
              style={{
                listStyleType: "decimal",
                paddingLeft: "10px",
                lineHeight: "1.8",
                fontSize: "12px",
                gap: "15px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <li>
                <p>Please note that if trucker/driver fails to show up as per the scheduled appointment,</p>
                <p>The Appointment will be considered as cancelled, and the customer will have to reschedule.</p>
              </li>
              <li>The trucker/driver must bring the hard copy of the dispatch sheet at the time of delivery.</li>
              <li>Ctrade will not accept the vehicle/s if the aforementioned conditions are not fulfilled.</li>
            </ul>
          </div>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
          <div style={{ padding: "20px", width: "52%", marginBottom: "50px" }}>
            <h1 style={{ marginBottom: "20px", fontSize: "24px" }}>Select Date And Time</h1>
            <CalendarApp onDateClick={handleDateClick} />
          </div>

          {selectedDate && (
            <div className="mt-16 " style={{ width: "32%", }}>
              <h1 style={{ fontSize: "15px" }}>
                {new Date(selectedDate).toLocaleString("default", { weekday: "long", month: "short", day: "numeric" })}
              </h1>
              <div>
                {timeSlots.map((slot, index) => (
                  <p
                    key={index}
                    onClick={() => handleSlotClick(slot)}
                    style={{
                      border: "1px solid #B83D3D",
                      padding: "10px",
                      textAlign: "center",
                      borderRadius: "8px",
                      marginBottom: "18px",
                      // width:"150px",
                      width:"65%",
                      cursor: "pointer",
                      color: selectedSlot === slot ? "white" : "black",
                      backgroundColor: selectedSlot === slot ? "#F04949" : "transparent",
                      transition: "background-color 0.3s ease",
                    }}
                  >
                    {slot}
                  </p>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Appointment Form */}
      <div ref={formRef} style={{ padding: "15px", backgroundColor: "#FFFFFF" }}>
        {isAppointmentFormVisible && selectedSlot && <AppointmentForm />}
      </div>
    </>
  );
}

export default CustomerAddAppointment;

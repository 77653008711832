import ajaxService from "./ajax-service";
class DriverService {

    async driverGet() {
        const response = await ajaxService.get('/drivers')
        if (response ?? false) {
            return response;
        }
    }
}

const driverService = new DriverService()
export default driverService
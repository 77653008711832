import React, { useEffect, useState } from "react";
import { Table, Row, Col, Modal, Space, Input, Typography } from "antd";
import {
  FilePdfOutlined,
  PrinterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { reportService } from "../../../services";

const { Title } = Typography;
function ReportInvoice() {
  const [loading, setLoading] = useState(false);
  const [dailyFlow, setDailyFlow] = useState([]);
  const [details, setDetails] = useState([]);
  const [isModalShow, setIsModalShow] = useState(false);
  const showModal = (month) => {
    getDetails(month);
    setIsModalShow(true);
  };
  const getDetails = async (month) => {
    setLoading(true);
    const { success, data } = await reportService.DailyCashFlowDetails(month);
    if (success) {
      // Transform API response to table data format
      const transformedDetails = Object.entries(data.monthlyData).map(
        ([date, data]) => ({
          Date: new Date(date)
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "short",
              year: "numeric",
            })
            .replace(" ", ","), 
          Sales: `$${data.sales || 0}`, 
          Collections: `$${data.collection || 0}`, 
          Payments: `$${data.payments || 0}`, 
        })
      );
      setDetails(transformedDetails);
    }
    setLoading(false);
  };
  const handleOk = () => {
    setIsModalShow(false);
  };
  const handleCancel = () => {
    setIsModalShow(false);
  };
  const columns = [
    {
      title: "Month",
      dataIndex: "Month",
      key: "Month",
    },
    {
      title: "Sales",
      dataIndex: "Sales",
      key: "Sales",
    },
    {
      title: "Collections",
      dataIndex: "Collections",
      key: "Collections",
    },
    {
      title: "Payments",
      dataIndex: "Payments",
      key: "Payments",
    },
    {
      title: "Details",
      dataIndex: "Details",
      key: "Details",
      render: (text, record, { _id }) => (
        <p
          style={{ color: "#B83D3D", cursor: "pointer" }}
          onClick={() => showModal(record.Month)}
        >
          Details
        </p>
      ),
    },
  ];
  const columns2 = [
    {
      title: "Date",
      dataIndex: "Date",
      key: "Date",
    },
    {
      title: "Sales",
      dataIndex: "Sales",
      key: "Sales",
    },
    {
      title: "Collections",
      dataIndex: "Collections",
      key: "Collections",
    },
    {
      title: "Payments",
      dataIndex: "Payments",
      key: "Payments",
    },
  ];
  const data2 = [
    {
      Date: "01 Jan,2024",
      Sales: "$0.00",
      Collections: "$112,047.40",
      Payments: "$0.00",
      Details: "Detail",
    },
    {
      Date: "01 Jan,2024",
      Sales: "$0.00",
      Collections: "$112,047.40",
      Payments: "$0.00",
      Details: "Detail",
    },
    {
      Date: "01 Jan,2024",
      Sales: "$0.00",
      Collections: "$112,047.40",
      Payments: "$0.00",
      Details: "Detail",
    },
    {
      Date: "01 Jan,2024",
      Sales: "$0.00",
      Collections: "$112,047.40",
      Payments: "$0.00",
      Details: "Detail",
    },
    {
      Date: "01 Jan,2024",
      Sales: "$0.00",
      Collections: "$112,047.40",
      Payments: "$0.00",
      Details: "Detail",
    },
  ];
  const data = [
    {
      Month: "Jan",
      Sales: "$0.00",
      Collections: "$112,047.40",
      Payments: "$0.00",
      Details: "Detail",
    },
    {
      Month: "Jan",
      Sales: "$0.00",
      Collections: "$112,047.40",
      Payments: "$0.00",
      Details: "Detail",
    },
    {
      Month: "Jan",
      Sales: "$0.00",
      Collections: "$112,047.40",
      Payments: "$0.00",
      Details: "Detail",
    },
    {
      Month: "Jan",
      Sales: "$0.00",
      Collections: "$112,047.40",
      Payments: "$0.00",
      Details: "Detail",
    },
    {
      Month: "Jan",
      Sales: "$0.00",
      Collections: "$112,047.40",
      Payments: "$0.00",
      Details: "Detail",
    },
  ];

  const initialize = async () => {
    setLoading(true);
    const { success, data } = await reportService.DailyCashFlow();
    if (success) {
      setDailyFlow(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize();
  }, []);
  return (
    <>
      <Title level={5}>MONTHLY FLOW</Title>

      <Modal
        title={
          <div style={{ width: "100%" }}>
            DAILY CASH FLOW{" "}
            <div
              style={{
                borderTop: "1px solid #ccc",
                marginTop: "15px",
                marginBottom: "12px",
              }}
            ></div>{" "}
          </div>
        }
        open={isModalShow}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        width={550}
        bodyStyle={{ padding: "10px" }}
      >
        <div
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "8px",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Col span={24} className="mt-2 mb-2">
            <h1 style={{ fontWeight: "bold" }}>
              Details for the Month Of January
            </h1>
          </Col>
          <Row
            gutter={0}
            style={{
              padding: "8px",
              marginTop: "2px",
              background: "#F2F2F7",
              borderRadius: "4px",
            }}
            className="flex justify-between"
          >
            <Col span={4}>
              <Space size={10}>
                <PrinterOutlined style={{ fontSize: 20 }} />
                <FilePdfOutlined style={{ fontSize: 18 }} />
              </Space>
            </Col>
            <Col span={20} style={{ textAlign: "right" }}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                className="w-auto lg:w-2/5"
              />
            </Col>
          </Row>
          <Table
            columns={columns2}
            scroll={{ x: "max-content" }}
            dataSource={details}
            loading={loading}
            style={{ marginTop: "5px" }}
          />
        </div>
      </Modal>

      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={dailyFlow}
        pagination={false}
        style={{ marginTop: "10px" }}
      />
    </>
  );
}

export default ReportInvoice;

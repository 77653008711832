import React, { useEffect, useState } from 'react'
import { Table, Space, Button, Row, Col, Input, Modal, Form, Select, Checkbox, notification } from 'antd';
import { CiSquarePlus } from "react-icons/ci";
import { EditOutlined, SearchOutlined, EyeOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons'
import destinationService from '../../../services/destination-service';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountries } from '../../../redux/slices/countriesSlice';

function Setting() {
    const [form] = Form.useForm();
    const [destinations, setDestination] = useState([])
    const [filteredDestination, setFilteredDestination] = useState([])
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [pagination, setPagination] = useState({ current: 1, total: 1, pageSize: 10 });
    const [showModal, setShowModal] = useState(false);
    const [selectId, setSelectId] = useState(0)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedDeleteRecord, setSelectedDeleteRecord] = useState(null);

    const dispatch = useDispatch();
    const { data: countries, status, error } = useSelector((state) => state.countries);

    const openModal = (record) => {
        setSelectedDeleteRecord(record)
        setIsModalVisible(true)
    }
    const handleOk = async () => {
        if (!selectedDeleteRecord || !selectedDeleteRecord.id) {
            console.error("No record selected for deletion.");
            return;
        }
        setLoading(true); // Show loading indicator
        const response = await destinationService.delete(selectedDeleteRecord.id);
        if (response?.success) {
            initialize()
            console.log("Record deleted successfully.");
            notification.success({
                message: response.message,
                description: "Record deleted successfully.",
                duration: 2,
            });
        } else {
            console.error("Failed to delete the record.");
        }
        setLoading(false)
        setIsModalVisible(false)
    }
    const initialize = async (page = 1, pageSize = pagination.pageSize) => {
        setLoading(true);
        const { success, data } = await destinationService.fetch(page);
        setLoading(false);
        if (success) {
            setDestination(data.data);
            setFilteredDestination(data.data);
            setPagination((prev) => ({
                ...prev,
                total: data.total,
                current: page,
                pageSize,
            }));
        }
    };
    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchTerm(value);

        const filteredData = destinations.filter((destination) =>
            destination.name.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredDestination(filteredData);
    };
    const handleOpenModal = async (id = 0) => {
        if (id !== 0) {
            const response = await destinationService.view(id);
            if (response?.success) {
                const { status, name, country } = response.data
                form.setFieldsValue({ status: status, name: name, country: country })
            }
        }

        setSelectId(id)
        setShowModal(true)
    };
    const handleCancel = () => {
        setSelectId(0)
        setShowModal(false);
        form.resetFields();
    };
    const onFinish = async (values) => {
        const data = {
            country: values.country,
            pod: values.name,
            status: values.status ? 1 : 0,
        };
        setLoading(true);
        let response;
        if (selectId !== 0) {
            response = await destinationService.edit(selectId, data);
        } else {
            response = await destinationService.add(data);
        }
        setLoading(false);
        if (response?.success) {
            handleCancel();
            initialize()
        };
    }
    const onFinishFailed = (errorInfo) => {
        console.error("Form Submission Failed:", errorInfo);
    };
    const columns = [
        // {
        //     title: "#",
        //     dataIndex: '#',
        //     key: '#',
        //     render: (text, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
        // },
        {
            title: "Id",
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: "Country",
            dataIndex: 'country',
            key: 'country',
        },
        {
            title: "Name",
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Status",
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                <Button
                    style={{
                        backgroundColor: record.status === 1 ? "#06D652" : "#FF4D4F",
                        color: "white"
                    }}
                >
                    {record.status === 1 ? "Active" : "InActive"}
                </Button>
            )
        },
        {
            title: 'Actions',
            key: 'Actions',
            render: (text, record) => (
                <Space size='small'>
                    <Button type='primary' size="small" onClick={() => handleOpenModal(record.id)}>
                        <EditOutlined />
                    </Button>
                    <Button type='primary' size="small" >
                        <EyeOutlined />
                    </Button>
                    <Button type='primary' size="small" onClick={() => openModal(record)}>
                        <DeleteOutlined />
                    </Button>
                </Space >
            )
        },
    ];
    useEffect(() => {
        initialize();
        dispatch(fetchCountries());
    }, [dispatch])
    return (
        <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Row
                gutter={30}
                style={{ padding: '5px', borderRadius: '4px' }}
                className="flex items-center"
            >
                {/* Left Section: Destinations Heading */}
                <Col flex="auto">
                    <Space direction="horizontal" size={15}>
                        <h5 className="font-bold">Destinations</h5>
                    </Space>
                </Col>

                {/* Right Section: Search Input and "+" Icon */}
                <Col flex="none" style={{ display: 'flex', alignItems: 'center', gap: '15px', justifyContent: 'flex-end' }}>
                    {/* Search Input */}
                    <Input
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                        style={{ width: 200 }}
                        value={searchTerm}
                        onChange={handleSearch}
                    />
                    {/* Plus Icon */}
                    <CiSquarePlus size={30} style={{ cursor: 'pointer' }} onClick={() => handleOpenModal()} />

                </Col>
            </Row>

            <Modal
                open={showModal}
                onCancel={handleCancel}
                onOk={handleOk}
                footer={null}
                closable={true}
                width={450}
                styles={{
                    body: {
                        padding: "20px",
                    }
                }}
            >
                <Form layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>

                    <Form.Item label="Country" name="country"
                        rules={[{ required: true, message: "Please select a country" }]}>
                        <Select placeholder="Select a country">
                            {countries.map((country) => (
                                <Select.Option key={country.id} value={country.id}>
                                    {country.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="POD" name="name"
                        rules={[{ required: true, message: "Please enter POD" }]}
                    >
                        <Input placeholder="Enter POD" />
                    </Form.Item>
                    <Form.Item
                        name="status"
                        valuePropName="checked"
                        initialValue={false}>
                        <Checkbox>Status</Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loading}  >
                            {selectId !== 0 ? "Update Destination" : "Add Destination"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* delete */}
            <Modal
                title={<div style={{ textAlign: 'center' }}>Confirm</div>}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                styles={{
                    body: {
                        padding: "20px",
                    }
                }}
                width={300}
                footer={
                    <div className='flex justify-center gap-3'>
                        <Button type="default" onClick={handleCancel} style={{ width: "30%" }}>
                            Cancel
                        </Button>
                        <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
                            Confirm
                        </Button>
                    </div>
                }
            >
                <p style={{ textAlign: 'center' }}>Are you sure you want to delete this?</p>
            </Modal>

            <Table columns={columns} scroll={{ x: "max-content" }} loading={loading} dataSource={filteredDestination}
                pagination={{
                    current: pagination.current,
                    total: pagination.total,
                    pageSize: pagination.pageSize,
                    onChange: (page) => initialize(page),
                    showSizeChanger: false,
                }}
                style={{ marginTop: '5px' }} />
        </div>
    )
}
export default Setting

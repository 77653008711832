import React, { useState, useEffect } from "react";
import { Table, Space, Row, Col, Input, Select, Button, Modal, notification, } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { vehicalService } from "../../../services";

function Vechical() {
  const [isModalshow, setIsModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [updatedColumns, setUpdatedColumns] = useState([]);
  const [column, setColumn] = useState();
  const [selectedRecord, setSelectedRecord] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, total: 0, pageSize: 10 });

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedRecord(null);
  };

  const handleOk = async () => {
    if (!selectedRecord) return;
    setLoading(true);
    const response = await vehicalService.deleteVehicle(selectedRecord.id);
    if (response?.success) {
      setVehicles((prevData) =>
        prevData.filter((item) => item.id !== selectedRecord.id)
      );
      notification.success({
        message: "Deleted Successfully",
        description: response.message,
        placement: "topRight",
      });
      setIsModalVisible(false);
    }
    setLoading(false);
    setSelectedRecord(null);
  };

  const handleDelete = async () => {
    setIsModalShow(false);
  };

  const options = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "9",
      label: "9",
    },
  ];
  
  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "#",
    //   key: "_id",
    //   render: (text, record, index) =>
    //     (pagination.current - 1) * pagination.pageSize + index + 1,
    // },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Free Days",
      dataIndex: "storage_days",
      key: "storage_days",
    },
    {
      title: "Per Day Charges($)",
      dataIndex: "storage_charges",
      key: "storage_charges",
    },
    {
      title: "Days Left",
      dataIndex: "leftDays",
      key: "leftDays",
    },
    {
      title: "Storage Charges($)",
      dataIndex: "StorageCharges",
      key: "StorageCharges",
    },

    {
      title: "Action",
      key: "Action",
      fixed: "right",
      render: (text, record, { _id }) => (
        <Space size="small">
          <Link to={`/operation/vehicles/edit/${record.id}`}>
            <Button type="primary" size="small">
              <EditOutlined />
            </Button>
          </Link>

          <Link to={`/operation/vehicles/view/${record.id}`}>
            <Button type="primary" size="small">
              <EyeOutlined />
            </Button>
          </Link>

          <Button type="primary" size="small" onClick={() => showModal(record)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.pageSize) => {
    try {
      setLoading(true);
      const { success, data, message } = await vehicalService.getVehicles(page);

      if (success) {
        if (data && data.vehicles && data.vehicles.data.length > 0) {
          setVehicles(data?.vehicles?.data);
          setColumn(data?.columns);

          // Merge hardcoded columns with backend columns
          const filteredBackendColumns = data.columns.filter(
            (column) => column !== "id"
          );
          const mappedColumns = filteredBackendColumns.map((column) => ({
            title: column
              .replace(/_/g, " ")
              .replace(/\b\w/g, (char) => char.toUpperCase()),
            dataIndex: column,
            key: column,
          }));
          const storageChargesIndex = columns.findIndex(
            (col) => col.key === "StorageCharges"
          );
          const updatedColumnsTemp = [...columns];
          if (storageChargesIndex !== -1) {
            updatedColumnsTemp.splice(
              storageChargesIndex + 1,
              0,
              ...mappedColumns
            );
          }
          setUpdatedColumns(updatedColumnsTemp);

          setPagination((prev) => ({
            ...prev,
            total: data.vehicles.total,
            current: page,
            pageSize,
          }));
        } else {
          console.warn("No vehicles data found.");
          setVehicles([]); // Clear vehicle list if no data is present
        }
      } else {
        console.error(`Error: ${message || "Failed to fetch vehicles."}`);
        // Optionally, show the error message to the user via a notification or modal
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      // Optionally, show the error to the user via a notification or modal
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      {/* delete */}
      <Modal
        title={<div style={{ textAlign: "center" }}>Confirm</div>}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        style={{
          padding: "20px",
        }}
        width={300}
        footer={
          <div className="flex justify-center gap-3">
            <Button
              type="default"
              onClick={handleCancel}
              style={{ width: "30%" }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleOk}
              loading={loading}
              style={{ width: "30%" }}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: "center" }}>
          Are you sure you want to delete this?
        </p>
      </Modal>

      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Vehicles</h1>
        </Col>
      </Row>

      <Row
        gutter={8}
        style={{
          marginTop: "15px",
          background: "#F2F2F7",
          padding: "5px",

          alignItems: "center", // Ensures alignment of items within the row
        }}
      >
        <Col
          span={4}
          xs={24}
          sm={12}
          md={6}
          lg={4}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Space direction="horizontal" size={10}>
            <p style={{ margin: 0 }}>Show</p>
            <Select
              defaultValue={10}
              options={options}
              size="small"
              style={{ width: 70, fontSize: 14 }}
            />
            <p style={{ margin: 0 }}>entries</p>
          </Space>
        </Col>

        {/* Second Column: Right-Aligned Search */}
        <Col
          span={20}
          xs={24}
          sm={12}
          md={18}
          lg={20}
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{
              width: "30%",
              borderRadius: "4px",
              fontSize: 14,
            }}
          />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <Table
            columns={updatedColumns}
            dataSource={vehicles}
            loading={loading}
            scroll={{ x: "max-content" }}
            pagination={{
              current: pagination.current,
              total: pagination.total,
              pageSize: pagination.pageSize,
              onChange: (page) => initialize(page, pagination.pageSize),
              showSizeChanger: false,
            }}
            style={{ marginTop: "10px" }}
          />
        </Col>
      </Row>

      <Modal
        title={<div style={{ textAlign: "center" }}>Confirm</div>}
        visible={isModalshow}
        onOk={handleDelete}
        onCancel={handleCancel}
        centered
        styles={{
          body: {
            padding: "20px",
          },
        }}
        width={300}
        footer={
          <div className="flex justify-center gap-3">
            <Button
              type="default"
              onClick={handleCancel}
              style={{ width: "30%" }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleDelete}
              style={{ width: "30%" }}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: "center" }}>
          Are you sure you want to delete this?
        </p>
      </Modal>
    </div>
  );
}

export default Vechical;

import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Row, Col, notification } from "antd";
import { towingTariffService } from "../../../services";

const ModalForm = ({ visible, onClose, editData }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const isEditMode = editData?.id !== undefined;

  // console.log(isEditMode)
  // console.log(recordId)
  // Set initial values for edit mode
  useEffect(() => {
    if (editData) {
      form.setFieldsValue({ ...editData });
    } else {
      form.resetFields();
    }
  }, [editData, form]);

  const handleSubmit = async (values) => {
    setLoading(true)
    const response = isEditMode
      ? await towingTariffService.updateTariff(editData?.id, values)
      : await towingTariffService.addTariff(values);
    if (response.success ?? false) {
      notification.success({
        message: "Success",
        description: isEditMode
          ? "Towing Tariff updated successfully!"
          : "Towing Tariff added successfully",
        placement: "topRight",
      });
    } else {
      const errors = response.data?.errors || [response.message];
      notification.error({
        message: "Error",
        description: `Error: ${errors.join(", ")}`,
        placement: "topRight",
      });
    }
    setLoading(false)
    onClose();
  };

  return (
    <Modal
      title={editData ? "Edit Tariff" : "Add Tariff"}
      open={visible}
      onCancel={onClose}
      footer={null}
      centered
      
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Auction"
              name="auctions"
              rules={[{ required: true, message: "Please input Auction!" }]}
            >
              <Input placeholder="Enter auction name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="USD"
              name="usd"
              rules={[{ required: true, message: "Please input USD!" }]}
            >
              <Input placeholder="Enter USD" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="State"
              name="state"
              rules={[{ required: true, message: "Please input State!" }]}
            >
              <Input placeholder="Enter state" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Code"
              name="state_code"
              rules={[{ required: true, message: "Please input Code!" }]}
            >
              <Input placeholder="Enter state code" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Ports"
              name="ports"
              rules={[{ required: true, message: "Please input Ports!" }]}
            >
              <Input placeholder="Enter ports" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "50%" }}
                loading={loading}
              >
                {editData ? "Update Tariff" : "Add Tariff"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalForm;

import React, { useEffect, useState } from "react";
import {
  Table,
  Row,
  Col,
  Card,
  Divider,
  Button,
  Form,
  Select,
  Input,
  Modal,
  Tag,
  Space,
  Carousel,
} from "antd";
import { useLocation, useParams } from "react-router-dom";
import { appointmentService, authService } from "../../../services";
import { useDispatch } from "react-redux";
import { fetchCountries } from "../../../redux/slices/countriesSlice";
const { Meta } = Card;

function ViewAppointments() {
  const [form] = Form.useForm();
  // const location = useLocation();
  const { id } = useParams();
  // const loggedInUser  = location.state || {};
  // console.log(loggedInUser)
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [isModalopen, setIsModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState([]);
  const [status, setStatus] = useState();
  const dispatch = useDispatch();

  const handleRemarksChange = (value) => {
    setIsOtherSelected(value === "Other");
    if (value !== "Other") {
      form.setFieldsValue({ Other: undefined });
    }
  };

  const showModal = () => setIsModalShow(true);
  const openModal = async (id, status) => {
    const data = {
      id,
      mainid: vehicles[0].appointment_id,
      status: status,
    };
    const response = await appointmentService.carStatus(data);
    if (response.success) {
      initialize();
      setIsModalOpen(true);
    }
    // console.log(data)
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      const data = {
        id,
        status,
        remarks: values.Remarks,
      };
      if (values.Remarks === "Other") {
        data.other = values.Other;
      }
      //   const response = await appointmentService.cancelAppointment(data);
      //   if (response.success) {
      //     notification.success({
      //       message: "Appointment Cancelled.",
      //       description: response.message,
      //       placement: "topRight",
      //     });
      //   }
      console.log(data);
      setIsModalShow(false);
      form.resetFields();
    } catch (error) {
      console.log("Validation Failed:", error);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalShow(false);
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Customer",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Title",
      dataIndex: "isTitle",
      key: "isTitle",
      render: (text, record, index) => {
        let title =
          record.isTitle === 1 ? "Yes" : record.isTitle === 0 ? "No" : "N/A";
        return title;
      },
    },
    {
      title: "Key",
      dataIndex: "isKey",
      key: "isKey",
      render: (text, record, index) => {
        let Key =
          record.isKey === 1 ? "Yes" : record.isKey === 0 ? "No" : "N/A";
        return Key;
      },
    },
    {
      title: "Running",
      dataIndex: "isRun ",
      key: "isRun",
      render: (text, record, index) => {
        let run =
          record.isRun === 1 ? "Yes" : record.isRun === 0 ? "No" : "N/A";
        return run;
      },
    },
    {
      title: "Type",
      dataIndex: "delivery_type",
      key: "delivery_type",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Vin Number",
      dataIndex: "vin",
      key: "vin",
    },
    {
      title: "Make Year Model",
      dataIndex: "Make_Year_Model",
      key: "Make_Year_Model",
      render: (_, record) =>
        `${record.make} - ${record.model} - (${record.year})`,
    },

    {
      title: "Image",
      dataIndex: "Image",
      key: "Image",
      render: (text, record) => (
        <Carousel
          arrows={true}
          style={{
            width: "75px",
            height: "50px",
            border: "none",
            outline: "none",
          }}
        >
          {record.Image?.map((imgUrl, index) => (
            <div
              key={index}
              style={{
                border: "none",
                outline: "none",
              }}
            >
              <img
                src={imgUrl}
                alt={`Vehicle-${index}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  borderRadius: "4px",
                  border: "none",
                }}
              />
            </div>
          ))}
        </Carousel>
      ),
    },
    {
      title: "Dispatch Sheet",
      dataIndex: "dispatch",
      key: "dispatch",
      render: (text, record) => (
        <a
          href={record.dispatch} // URL or file path from the backend
          target="_blank" // Open in a new tab
          rel="noopener noreferrer" // Security
        >
          Download Dispatch Sheet
        </a>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "Status",
      render: (text, record) => (
        <Space>
          {record.status === 2 && (
            <Button
              style={{
                backgroundColor: "#06D652",
                color: "white",
                fontSize: "12px",
                padding: "2px 12px",
                borderRadius: "8px",
              }}
              onClick={() => openModal(record.id, 1)}
            >
              Confirm
            </Button>
          )}
          {record.status === 0 && (
            <>
              <Button
                style={{
                  backgroundColor: "#06D652",
                  color: "white",
                  fontSize: "12px",
                  padding: "2px 12px",
                  borderRadius: "8px",
                }}
                onClick={() => openModal(record.id, 1)}
              >
                Confirm
              </Button>
              <Button
                style={{
                  backgroundColor: "#F04949",
                  color: "white",
                  fontSize: "12px",
                  padding: "2px 12px",
                  borderRadius: "8px",
                }}
                onClick={() => openModal(record.id, 2)}
              >
                Reject
              </Button>
            </>
          )}
        </Space>
      ),
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.pageSize) => {
    setLoading(true);
    const response = await dispatch(fetchCountries());
    setCountries(response.payload);
    const { success, data } = await appointmentService.viewAppointment(
      id,
      page
    );
    if (success) {
      setStatus(data.appointments.data[0].status);
      setVehicles(data.appointments.data);
      setCustomer(data.customer);
      setPagination((prev) => ({
        ...prev,
        total: data.appointments.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };

  const getLoggedInUser = async () => {
    const response = await authService.getLoggedInUser();
    if (response.success) {
      setLoggedInUser(response.data);
    }
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);
  useEffect(() => {
    getLoggedInUser();
  }, []);
  return (
    <>
      <Row gutter={24} wrap={false}>
        {/* First Column */}
        <Col flex="310px">
          <Card style={{ padding: "5px" }}>
            {/* First Section: Name and Email */}
            <Meta title={customer?.name} style={{ textAlign: "center" }} />
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              <p>{customer?.email}</p>
            </div>
            <Divider />
            <div className="profile-info">
              <p>
                <strong>Phone:</strong> {customer?.phone || "N/A"}
              </p>
              <p>
                <strong>Address:</strong> {customer?.address || "N/A"}
              </p>
              <p>
                <strong>Town:</strong> {customer?.town || "N/A"}
              </p>
              <p>
                <strong>City:</strong> {customer?.city || "N/A"}
              </p>
              <p>
                <strong>Country:</strong>{" "}
                {countries?.find((country) =>
                  country.iso_code.includes(customer?.country)
                )?.name || "N/A"}
              </p>
              <p>
                <strong>Secondary Email:</strong>{" "}
                {customer?.secondary_email ? customer?.secondary_email : "N/A"}
              </p>
            </div>
            <Divider />
            {(loggedInUser.isDriver === 1 ||
              loggedInUser.employee_id != 0 ||
              loggedInUser.id != 1) && (
              <div style={{ textAlign: "center" }}>
                {(vehicles[0]?.status === 2 || vehicles[0]?.status === 0) && (
                  <Button type="primary" size="middle" onClick={showModal}>
                    Cancel
                  </Button>
                )}
              </div>
            )}
          </Card>
        </Col>
        {/* Second Column */}
        <Col flex="1">
          <div
            style={{
              padding: "15px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Row gutter={12}>
              <Col span={12}>
                <h1 style={{ fontWeight: "bold" }}>
                  Appointment Vehicles Details
                </h1>
              </Col>
            </Row>

            <Table
              columns={columns}
              scroll={{ x: "max-content" }}
              dataSource={vehicles}
              style={{ marginTop: "10px" }}
              pagination={{
                current: pagination.current,
                total: pagination.total,
                pageSize: pagination.pageSize,
                onChange: (page) => initialize(page, pagination.pageSize),
                showSizeChanger: false,
              }}
              loading={loading}
            />
          </div>
        </Col>
      </Row>

      <Modal
        title={
          <div style={{ width: "100%" }}>
            Cancel Appointment
            <div
              style={{
                borderTop: "1px solid #ccc",
                marginTop: "15px",
                marginBottom: "12px",
              }}
            ></div>{" "}
          </div>
        }
        open={isModalShow}
        onCancel={handleCancel}
        closable={true}
        width={500}
        style={{ padding: "10px" }}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}>
            <Button
              type="default"
              size="middle"
              style={{ width: "30%" }}
              onClick={handleCancel}
            >
              Close
            </Button>
            <Button
              type="primary"
              size="middle"
              style={{ width: "30%" }}
              onClick={handleOk}
            >
              Confirm Cancel
            </Button>
          </div>
        }
      >
        <Form
          layout="vertical"
          requiredMark={false}
          form={form}
          initialValues={{ Remarks: undefined, Other: undefined }}
        >
          <Row gutter={0}>
            <Col span={24}>
              <Form.Item
                label="Remarks"
                name="Remarks"
                rules={[
                  {
                    required: true,
                    message: "Please select an appointment type",
                  },
                ]}
              >
                <Select
                  placeholder="Select Remarks"
                  onChange={handleRemarksChange}
                >
                  <Select.Option value="Vehicle Not Ready">
                    Vehicle Not Ready
                  </Select.Option>
                  <Select.Option value="Trucker change">
                    Trucker change
                  </Select.Option>
                  <Select.Option value="Trucker Cancelled">
                    Trucker Cancelled
                  </Select.Option>
                  <Select.Option value="Other">Other</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {isOtherSelected && (
              <Col span={24}>
                <Form.Item
                  label="Other"
                  name="Other"
                  rules={[
                    {
                      required: true,
                      message: "Please enter additional details",
                    },
                  ]}
                >
                  <Input placeholder="Enter details for 'Other'" allowClear />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </Modal>

      <Modal
        open={isModalopen}
        onCancel={handleCancel}
        closable={true}
        width={350}
        footer={null}
        styles={{
          body: {
            padding: "5px",
          },
        }}
      >
        <h1
          style={{
            color: "#06D652",
            fontSize: "16px",
            textAlign: "center",
            fontWeight: "bolder",
          }}
        >
          {" "}
          Status Successfully Updated
        </h1>
        <p style={{ textAlign: "center" }}>
          Lorem ipsum dolor, sit amet consectetur adipisicing
        </p>
      </Modal>

      <style>
        {`
  .profile-info {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 15px; /* Space between each <p> element */
  }

  .profile-info p {
    display: flex; /* Makes each <p> a flex container */
     justify-content:space-between;
  }

  .profile-info strong {
    margin-right: 25px; /* Space between <strong> and <p> content */
    width: 100px; /* You can adjust this value to ensure equal space between all labels */
    text-align: left; /* Optional: Align the <strong> to the left */
  }
`}
      </style>
    </>
  );
}

export default ViewAppointments;

import React from 'react';
import { Col, Form, Input, Row, DatePicker, Table, } from 'antd';
import { SearchOutlined,  } from '@ant-design/icons'

function VehicleLoadPlan() {
    const [form] = Form.useForm();
    const columns = [
        {
            title: "#",
            dataIndex: '#',
            key: '#',
            // render: (text, record, index) => index + 1,
        },
        {
            title: "CAR STATUS",
            dataIndex: 'CAR_STATUS',
            key: 'CAR_STATUS',
        },
        {
            title: "BOOKING NUMBER",
            dataIndex: 'BOOKING_NUMBER',
            key: 'BOOKING_NUMBER',
        },
        {
            title: "RECIEVED DATE",
            dataIndex: "RECIEVED_DATE",
            key: "RECIEVED_DATE",
        },
        {
            title: "VEHICLES",
            dataIndex: "VEHICLES",
            key: "VEHICLES",
        },
        {
            title: "VIN NUMBER",
            dataIndex: "VIN_NUMBER",
            key: "VIN_NUMBER",

        }, {
            title: "KEY",
            dataIndex: "KEY",
            key: "KEY",

        }, {
            title: "TITLE",
            dataIndex: "TITLE",
            key: "TITLE",
        },
        
    ]
    const data = [
    ]

    return (
        <div
            style={{
                padding: '0 14px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#FFFFFF',
            }} >

            <Form
                requiredMark={false}
                layout="vertical"
                form={form} >
                <Row gutter={24} className='mt-8'>

                    <Col span={8}>
                        <Form.Item label="From date">
                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="To date">
                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" />
                        </Form.Item>
                    </Col>

                    <div style={{ display: 'flex', alignItems: 'center',  }}>
                        <img src="/images/search.png" alt="" width={31} />
                    </div>

                </Row>
            </Form>
            <Row
                gutter={0}
                style={{
                    marginTop: 20,
                    padding: '10px',
                    borderRadius: '4px',
                    border: '1px solid #E8E8E9',
                }}>
                <Col span={24}>
                    <h1 style={{ fontWeight: 'bolder' }}>Vehicles W/O Container In Load Plan</h1>
                </Col>
            </Row>

            <Col span={24}>
                <Row gutter={0} style={{ padding: '10px', marginTop: '2px', background: "#F2F2F7", borderRadius: '4px' }} className='flex justify-between'>
                    <Col span={24} style={{ textAlign: "right" }} >
                        <Input
                            placeholder="Search"
                            prefix={<SearchOutlined />}
                            style={{ width: 200 }}
                        />
                    </Col>
                </Row>
                <div>
                    <Table
                        columns={columns}
                        pagination={true}
                        scroll={{ x: "max-content" }}
                        dataSource={data}
                        rowKey="Id"
                        style={{ marginTop: '5px' }}/>
                </div >
            </Col>

        </div >
    );
}

export default VehicleLoadPlan;

import React, { useEffect, useState } from "react";
import "./Calendar.css";
import { appointmentService } from "../../../services";

const CalendarApp = ({ onDateClick }) => {
  const [activeDates, setActiveDates] = useState([]);
  const [inactiveDates, setInactiveDates] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentDate, setCurrentDate] = useState(new Date());

  const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

  const firstDayOfMonth = (month, year) => new Date(year, month, 1).getDay();

  const handleDateClick = (dateString, isActive) => {
    if (isActive) {
      onDateClick(dateString);
    }
  };

  const renderDays = () => {
    const days = [];
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const totalDays = daysInMonth(month, year);
    const firstDay = firstDayOfMonth(month, year);

    for (let i = 0; i < (firstDay === 0 ? 6 : firstDay - 1); i++) {
      days.push(<li key={`placeholder-${i}`} className="placeholder"></li>);
    }

    for (let day = 1; day <= totalDays; day++) {
      const dateString = `${year}-${String(month + 1).padStart(
        2,
        "0"
      )}-${String(day).padStart(2, "0")}`;
      let className = "";
      let isClickable = false;

      if (activeDates.includes(dateString)) {
        className = "active";
        isClickable = true;
      } else if (inactiveDates.includes(dateString)) {
        className = "inActive";
      }

      days.push(
        <li key={day}>
          <span
            style={{
              cursor: isClickable ? "pointer" : "default",
            }}
            className={className}
            onClick={() =>
              handleDateClick(dateString, className === "active")
            }
          >
            {day}
          </span>
        </li>
      );
    }
    return days;
  };

  // Navigate to the previous or next month
  const changeMonth = (offset) => {
    const newDate = new Date(
      currentDate.setMonth(currentDate.getMonth() + offset)
    );
    setCurrentDate(newDate);
    setCurrentMonth(newDate)
  };

  const fetchScheduleDates = async (month) => {
    try {
      const response = await appointmentService.getScheduleDates(month);
      const { data } = response;

      const active = data.map((doc) => doc.date);

      // Generate all dates for the month manually
      const [year, monthNumber] = month.split("-").map(Number);
      const daysInMonth = new Date(year, monthNumber, 0).getDate();

      const allDates = [];
      for (let day = 1; day <= daysInMonth; day++) {
        const date = `${year}-${monthNumber.toString().padStart(2, "0")}-${day
          .toString()
          .padStart(2, "0")}`;
        allDates.push(date);
      }

      // Calculate inactive dates
      const inactive = allDates.filter((date) => !activeDates.includes(date));

      // const inactive = data
      //   .filter((doc) => doc.status === "inactive")
      //   .map((doc) => doc.day);

      setActiveDates(active);
      setInactiveDates(inactive);
    } catch (error) {
      console.error("Error fetching schedule dates:", error);
    }
  };
  useEffect(() => {
    const formattedMonth = currentMonth.toISOString().slice(0, 7);
    fetchScheduleDates(formattedMonth);
  }, [currentMonth]);

  return (
    <div className="calendarcontainer">
      <div className="month">
        <h4>
          {currentDate.toLocaleString("default", { month: "long" })}{" "}
          {currentDate.getFullYear()}
        </h4>
        <ul style={{ cursor: "pointer" }}>
          <li className="prev" onClick={() => changeMonth(-1)}>
            &#10094;
          </li>
          <li className="next" onClick={() => changeMonth(1)}>
            &#10095;
          </li>
        </ul>
      </div>

      <ul className="weekdays">
        <li>Mo</li>
        <li>Tu</li>
        <li>We</li>
        <li>Th</li>
        <li>Fr</li>
        <li>Sa</li>
        <li>Su</li>
      </ul>

      <ul className="days">{renderDays()}</ul>
    </div>
  );
};

export default CalendarApp;

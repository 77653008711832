import ajaxService from "./ajax-service";
class BlformService {
  async fetch(page) {
    const response = await ajaxService.get(`/bl-forms?page=${page}`);
    if (response.success ?? false) {
      return response;
    }
  }
  async view(id) {
    const response = await ajaxService.get(`/bl-forms/${id}`);
    if (response.success ?? false) {
      return response;
    }
  }
  async updateForm(id, data) {
    const response = await ajaxService.put(`/bl-forms/`, id, data);
    if (response.success ?? false) {
      return response;
    }
  }
  async deleteForm(id) {
    const response = await ajaxService.delete(`/bl-forms/`, id);
    if (response.success ?? false) {
      return response;
    }
  }
}
const blformService = new BlformService();
export default blformService;

import React, { useState } from "react";
import {
    Table,
    Space,
    Row,
    Col,
    Input,
    Select,
    Button,
    Modal,
} from "antd";
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

function CustomerVechical() {

    const options = [
        {
            value: "10",
            label: "10",
        },
        {
            value: "9",
            label: "9",
        },
    ];
    const columns = [
        {
            title: "Id",
            dataIndex: "Id",
            key: "_id",
            // render: (text, record, index) => index + 1,
        },
        {
            title: "Customer",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Free Days",
            dataIndex: "storage_days",
            key: "storage_days",
        },
        {
            title: "Per Day Charges($)",
            dataIndex: "storage_charges",
            key: "storage_charges",
        },
        {
            title: "Days Left",
            dataIndex: "leftDays",
            key: "leftDays",
        },
        {
            title: "Storage Charges($)",
            dataIndex: "StorageCharges",
            key: "StorageCharges",
        },
        {
            title: "Car Status",
            dataIndex: "Car_Status",
            key: "Car_Status",
        }, {
            title: "User Id",
            dataIndex: "User_Id",
            key: "User_Id",
        }, {
            title: "Lot #",
            dataIndex: "Lot",
            key: "Lot",
        }, {
            title: "Invoice No",
            dataIndex: "Invoice_No",
            key: "Invoice_No",
        }, {
            title: "Invoice Amount",
            dataIndex: "Invoice_Amount",
            key: "Invoice_Amount",
        }, {
            title: "Cutting Car",
            dataIndex: "Cutting_Car",
            key: "Cutting_Car",
        }, {
            title: "Product",
            dataIndex: "Product",
            key: "Product",
        }, {
            title: "Vehicle",
            dataIndex: "Vehicle",
            key: "Vehicle",
        }, {
            title: "Vehicle Type",
            dataIndex: "Vehicle_Type",
            key: "Vehicle_Type",
        }, {
            title: "Engine Type",
            dataIndex: "Engine_Type",
            key: "Engine_Type",
        }, {
            title: "Year",
            dataIndex: "Year",
            key: "Year",
        }, {
            title: "Chassis No",
            dataIndex: "Chassis_No",
            key: "Chassis_No",
        }, {
            title: "Date Rcd",
            dataIndex: "Date_Rcd",
            key: "Date_Rcd",
        }, {
            title: "Value",
            dataIndex: "Value",
            key: "Value",
        }, {
            title: "Weight",
            dataIndex: "Weight",
            key: "Weight",
        }, {
            title: "Operating Port",
            dataIndex: "Operating_Port",
            key: "Operating_Port",
        },
        {
            title: "Towing Location",
            dataIndex: "Towing_Location",
            key: "Towing_Location",
        },{
            title: "Booking Number",
            dataIndex: "Booking_Number",
            key: "Booking_Number",
        },{
            title: "Notes",
            dataIndex: "Notes",
            key: "Notes",
        },{
            title: "Carrier Name",
            dataIndex: "Carrier_Name",
            key: "Carrier_Name",
        },{
            title: "Storage",
            dataIndex: "Storage",
            key: "Storage",
        },
        {
            title: "Bol",
            dataIndex: "Bol",
            key: "Bol",
        },
        {
            title: "Title Owner",
            dataIndex: "Title_Owner",
            key: "Title_Owner",
        },
        {
            title: "Title",
            dataIndex: "Title",
            key: "Title",
        },  {
            title: "Title Number",
            dataIndex: "Title_Number",
            key: "Title_Number",
        },  {
            title: "Title State",
            dataIndex: "Title_State",
            key: "Title_State",
        },  {
            title: "Keys",
            dataIndex: "Keys",
            key: "Keys",
        },  {
            title: "Notes",
            dataIndex: "Notes",
            key: "Notes",
        },  {
            title: "Container Number",
            dataIndex: "Container_Number",
            key: "Container_Number",
        },
        {
            title: "Tracking Number",
            dataIndex: "Tracking_Number",
            key: "Tracking_Number",
        }, {
            title: "Arrival Date",
            dataIndex: "Arrival_Date",
            key: "Arrival_Date",
        }, {
            title: "Release Status",
            dataIndex: "Release_Status",
            key: "Release_Status",
        }, {
            title: "Destination",
            dataIndex: "Destination",
            key: "Destination",
        }, {
            title: "Container Size",
            dataIndex: "Container_Size",
            key: "Container_Size",
        }, {
            title: "File Ref",
            dataIndex: "File_Ref",
            key: "File_Ref",
        },
        {
            title: "File Ref",
            dataIndex: "File_Ref",
            key: "File_Ref",
        },
        {
            title: "Shipping Class Id",
            dataIndex: "Shipping_Class_Id",
            key: "Shipping_Class_Id",
        },{
            title: "Price",
            dataIndex: "Price",
            key: "Price",
        },{
            title: "Towing Charges",
            dataIndex: "Towing_Charges",
            key: "Towing_Charges",
        },
        {
            title: "Fright Charges",
            dataIndex: "Fright_Charges",
            key: "Fright_Charges",
        },
        {
            title: "Buying Charges",
            dataIndex: "Buying_Charges",
            key: "Buying_Charges",
        },  {
            title: "Selling Charges",
            dataIndex: "Selling_Charges",
            key: "Selling_Charges",
        },  {
            title: "Yard Charges",
            dataIndex: "Yard_Charges",
            key: "Yard_Charges",
        },  {
            title: "Loading Charges",
            dataIndex: "Loading_Charges",
            key: "Loading_Charges",
        },  {
            title: "Transportation",
            dataIndex: "Transportation",
            key: "Transportation",
        },
        {
            title: "Labour",
            dataIndex: "Labour",
            key: "Labour",
        },
        {
            title: "Materail",
            dataIndex: "Materail",
            key: "Materail",
        },
        {
            title: "Chassis",
            dataIndex: "Chassis",
            key: "Chassis",
        },
        {
            title: "Quotation Id",
            dataIndex: "Quotation_Id",
            key: "Quotation_Id",
        },
        {
            title: "Action",
            key: "Action",
            render: (text, record, { _id }) => (
                <Space size="small">
                    <Link to="/vehicles/edit">
                        <Button type="primary" size="small">
                            <EditOutlined />
                        </Button>
                    </Link>

                    <Link to="/vehicles/view/:id?">
                        <Button type="primary" size="small">
                            <EyeOutlined />
                        </Button>
                    </Link>
                    {/* onClick={() => showModal(record)} */}
                    <Button type="primary" size="small" >
                        <DeleteOutlined />
                    </Button>
                </Space>
            ),
        },
    ];
    const data = [{

    }]
    return (
        <div
            style={{

                border: "1px solid #ccc",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
            }}>


            <Row gutter={0} className="pl-4 pt-6">
                <Col span={12}>
                    <h1 style={{ fontWeight: "bold" }}>Vehicles</h1>
                </Col>
            </Row>

            <Row
                gutter={0}
                style={{
                    marginTop: "10px",
                    background: "#F2F2F7",
                    alignItems: "center", // Ensures alignment of items within the row
                }}
                className="pl-4 pr-4 pt-1 pb-1"
            >
                <Col
                    span={4}
                    xs={24}
                    sm={12}
                    md={6}
                    lg={4}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Space direction="horizontal" size={10}>
                        <p style={{ margin: 0 }}>Show</p>
                        <Select
                            defaultValue={10}
                            options={options}
                            size="small"
                            style={{ width: 70, fontSize: 14 }}
                        />
                        <p style={{ margin: 0 }}>entries</p>
                    </Space>
                </Col>

                {/* Second Column: Right-Aligned Search */}
                <Col
                    span={20}
                    xs={24}
                    sm={12}
                    md={18}
                    lg={20}
                    style={{
                        textAlign: "right",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <Input
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                        style={{
                            width: "30%",
                            borderRadius: "4px",
                            fontSize: 14,
                        }}
                    />
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    <Table
                        columns={columns}
                        dataSource={data}
                        scroll={{ x: "max-content" }}
                        style={{ marginTop: "10px" }}
                    />
                </Col>
            </Row>


        </div>
    );
}

export default CustomerVechical;

import React, { useEffect, useState } from "react";
import { Button, Row, Col, Table, Space, Select, Input, Divider, Carousel } from "antd";
import { EditOutlined, SearchOutlined, SendOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import moment from "moment";
import { loadingPlanService } from "../../../services";

function SaveViewContainer() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [savedPlan, setSavedPlan] = useState([]);
  const [Containers, setContainers] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const fieldData = [
    { label: "Container ID", value: savedPlan.c_id },
    { label: "Location", value: savedPlan.location },
    { label: "Destination", value: savedPlan.destination },
    { label: "Container Size", value: savedPlan.container_size },
    { label: "Booking No", value: savedPlan.c_booking_number },
    { label: "Container No", value: savedPlan.c_container_number },
    {
      label: "Posted on",
      value: moment(savedPlan.created_at).format("MMM DD, YYYY"),
    },
    {
      label: "Last Updated",
      value: moment(savedPlan.updated_at).format("MMM DD, YYYY"),
    },
  ];
  const columns = [
    {
      title: " S.no",
      dataIndex: " S.no",
      key: " S.no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Vehicle",
      dataIndex: ["vehicle", "vehicle"],
      key: "Vehicle",
    },
    {
      title: "Chassis_no",
      dataIndex: ["vehicle", "chasis_no"],
      key: "Chassis_no",
    },
    {
      title: "Engine Type",
      dataIndex: ["vehicle", "engine_type"],
      key: "Engine_Type",
    },
    {
      title: "Value",
      dataIndex: ["vehicle", "value"],
      key: "Value",
    },
    {
      title: "Weight",
      dataIndex: ["vehicle", "weight"],
      key: "Weight",
    },

    {
      title: "Action",
      key: "Action",
      render: (text, { _id }) => (
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <span
            style={{
              backgroundColor: "#2D3748",
              borderRadius: "5px",
              color: "white",
              padding: "2px 5px",
              fontSize: "12px",
            }}
          >
            0 Off Load
          </span>
          <Button type="primary" size="small">
            <EditOutlined />
          </Button>
        </div>
      ),
    },
  ];
  const data = [
    {
      key: "13574",
      Vehicle: "FORD",
      Chassis_no: "1FDUF5HT0RDA27180",
      Engine_Type: "",
      Value: "",
      Weight: "3439",
    },
    {
      key: "13574",
      Vehicle: "FORD",
      Chassis_no: "1FDUF5HT0RDA27180",
      Engine_Type: "",
      Value: "",
      Weight: "3439",
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.current) => {
    const { success, data } = await loadingPlanService.View(id, page);
    if (success) {
      setSavedPlan(data.container);
      if (savedPlan) {
        setImages(data.container.myimages);
      }
      setContainers(data.containers.data);
      setPagination((prev) => ({
        ...prev,
        total: data.containers.total,
        current: page,
        pageSize,
      }));
    }
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);

  const CustomArrow = ({ className, style, onClick, isLeft }) => (
    <div
      className={className}
      style={{ ...style, color: "#B83D3D", fontSize: "40px", zIndex: 1 }}
      onClick={onClick}
    >
      {isLeft ? "" : ""} {/* Customize the arrow symbol */}
    </div>
  );

  return (
    <>
      <Row gutter={24} wrap={false}>
        <Col flex="280px">
          <div
            style={{
              border: "1px solid #ccc",
              padding: 10,
              borderRadius: 5,
              backgroundColor: "#FFFFFF",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Carousel
                arrows
                arrowSize={200}
                // infinite={false}
                prevArrow={<CustomArrow isLeft />}
                nextArrow={<CustomArrow />}
              >
                {images.map((image) => (
                  <img
                    src={image.image}
                    alt="Profile"
                    style={{
                      // width: "320px",
                      // height: "200px",
                      borderRadius: "8px",
                      objectFit: "cover",
                    }}
                  />
                ))}
              </Carousel>
              {/* <Button type="primary" style={{ marginTop: "10px" }}>
                Download Image
              </Button> */}
            </div>
            <Divider />
            <div style={{ textAlign: "center" }}>
              <SendOutlined
                style={{
                  fontSize: "20px",
                  border: "1px solid #ccc",
                  padding: 8,
                  borderRadius: "10px",
                }}
              />
            </div>
          </div>
        </Col>

        <Col flex="1">
          <div
            style={{
              padding: "15px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Row gutter={24}>
              {fieldData.map((field, index) => (
                <Col span={12}>
                  <div
                    style={{
                      display: "flex",
                      width: 350,
                      border: "1px solid #d9d9d9",
                      borderRadius: 4,
                      marginBottom: 2,
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        padding: "4px 11px",
                        backgroundColor: "#f5f5f5",
                        borderRight: "1px solid #d9d9d9",
                      }}
                    >
                      {field.label}
                    </div>
                    <p
                      style={{
                        flex: 1,
                        margin: 0,
                        padding: "4px 11px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {field.value}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
            <div>
              <Row
                gutter={24}
                style={{
                  marginTop: "50px",
                  background: "#FBFBFB",
                  padding: "10px",
                  borderRadius: "4px",
                }}
                className="flex justify-between"
              >
                <Col span={10}>
                  <Space direction="horizontal" size={10}>
                    <p>Show</p>
                    <Select
                      defaultValue="10"
                      //   options={options}
                      size="small"
                      style={{
                        width: 60,
                        height: 25,
                        fontSize: 14,
                      }}
                    />
                    <p>entries</p>
                  </Space>
                </Col>

                <Col span={14} style={{ textAlign: "right" }}>
                  <Input
                    placeholder="Search"
                    prefix={<SearchOutlined />}
                    className="w-auto lg:w-2/5"
                  />
                </Col>
              </Row>
              <Table
                columns={columns}
                scroll={{ x: "max-content" }}
                dataSource={Containers}
                style={{ marginTop: "5px" }}
              />
            </div>
          </div>
        </Col>
      </Row>

      <style>
        {`
  .profile-info {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 5px; /* Space between each <p> element */
  }

  .profile-info p {
    display: flex; /* Makes each <p> a flex container */
  
  }

  .profile-info strong {
   margin-right: 10px; /* Space between <strong> and <p> content */
    width: 100px; /* You can adjust this value to ensure equal space between all labels */
  }

  
`}
      </style>
    </>
  );
}

export default SaveViewContainer;

import ajaxService from "./ajax-service";

class CostingExpenses {
  async fetch(page) {
    const response = await ajaxService.get(`/expenses?page=${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async viewExpense(id) {
    const response = await ajaxService.get(`/expenses/${id}`);
    if (response ?? false) {
      return response;
    }
  }
  async editExpense(id, data) {
    const response = await ajaxService.post(`/expenses/update/`, data, id);
    if (response ?? false) {
      return response;
    }
  }
}

const costingExpenseService = new CostingExpenses();
export default costingExpenseService;

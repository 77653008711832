import { validationErrors } from "../utils";
import ajaxService from "./ajax-service";

class ProfileService {

    async updateProfile(id ,data) {
        const response = await ajaxService.put(`/auth/user/`, id, data);
        if (response ?? false) {
            return response;
        }
    }

    async fetchPermissions() {
        const response = await ajaxService.get(`/columns_view`);
        if (response ?? false) {
            return response;
        }
    }
    async fetchLoggedUser() {
        const response = await ajaxService.get(`/auth/user`);
        if (response ?? false) {
            return response;
        }
    }
}

const profileService = new ProfileService();
export default profileService;
import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, Table, Radio, notification, } from "antd";
import { CiSquarePlus } from "react-icons/ci";
import { DeleteOutlined, } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { containerService, customerService, destinationService, quotationService, shippingLineService, vehicalService, } from "../../../services";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export default function QuotationForm() {
  const { id } = useParams(); // Get 'id' from the URL
  const [quotations, setQuotations] = useState({
    details: [],
  });
  const [customers, setCustomers] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [destinations, setDestinations] = useState([]);
  const [shippingLine, setShippingLine] = useState([]);
  const [filteredshippingLine, setfilteredShippingLine] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [containerSizes, setContainerSizes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isTableVisible, setIsTableVisible] = useState(false)
  const [form] = Form.useForm();

  const isEditMode = id !== undefined;

  const columns = [
    {
      title: "Id",
      dataIndex: "uuid",
      key: "Id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Shipping Line",
      dataIndex: "shipping_line_id",
      key: "ShippingLine",
      width: 200,
      render: (text, record, index) => (
        <Select
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(value) => {
            const updatedDetails = [...quotations.details];
            updatedDetails[index].shipping_line_id = value;
            setQuotations((prev) => ({ ...prev, details: updatedDetails }));
          }}
        >
          {shippingLine.map((shippingLine) => (
            <Select.Option key={shippingLine.id} value={shippingLine.id}>
              {shippingLine.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "Destination",
      width: 200,
      render: (text, record, index) => (
        <Select
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(value) => {
            const updatedDetails = [...quotations.details];
            updatedDetails[index].destination_id = value;
            setQuotations((prev) => ({ ...prev, details: updatedDetails }));
          }}
        >
          {destinations.map((destination) => (
            <Select.Option key={destination.id} value={destination.id}>
              {destination.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Container Size",
      dataIndex: "containersize_id",
      key: "ContainerSize",
      width: 200,
      render: (text, record, index) => (
        <Select
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(value) => {
            const updatedDetails = [...quotations.details];
            updatedDetails[index].containersize_id = value;
            setQuotations((prev) => ({ ...prev, details: updatedDetails }));
          }}
        >
          {containerSizes.map((containerSizes) => (
            <Select.Option key={containerSizes.id} value={containerSizes.id}>
              {containerSizes.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Fright Charges",
      dataIndex: "charges",
      key: "FrightCharges",
      width: 200,
      render: (text, record, index) => (
        <Input
          value={text || ""}
          onChange={(e) => {
            const updatedDetails = [...quotations.details];
            updatedDetails[index].charges = e.target.value;
            setQuotations((prev) => ({ ...prev, details: updatedDetails }));
          }}
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "No Of Vehicles",
      dataIndex: "no_of_vehicles",
      key: "NoOfVehicles",
      width: 200,
      render: (text, record, index) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) => {
            const updatedDetails = [...quotations.details];
            updatedDetails[index].no_of_vehicles = e.target.value;
            setQuotations((prev) => ({ ...prev, details: updatedDetails }));
          }}
        />
      ),
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicle_type",
      key: "Vehicle Type",
      width: 200,
      render: (text, record, index) => (
        <Select
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(value) => {
            const updatedDetails = [...quotations.details];
            updatedDetails[index].vehicle_type = value;
            setQuotations((prev) => ({ ...prev, details: updatedDetails }));
          }}
        >
          {vehicleTypes.map((vehicletype) => (
            <Select.Option key={vehicletype} value={vehicletype}>
              {vehicletype}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Loading Charges",
      dataIndex: "loading_charges",
      key: "LoadingCharges",
      width: 200,
      render: (text, record, index) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) => {
            const updatedDetails = [...quotations.details];
            updatedDetails[index].loading_charges = e.target.value;
            setQuotations((prev) => ({ ...prev, details: updatedDetails }));
          }}
        />
      ),
    },
    {
      title: "Is DG",
      dataIndex: "is_dg",
      key: "Is DG",
      render: (text, record, index) => (
        <Checkbox
          defaultChecked={text}
          onChange={(e) => {
            const updatedDetails = [...quotations.details];
            updatedDetails[index].is_dg = e.target.checked ? 1 : 0;
            setQuotations((prev) => ({ ...prev, details: updatedDetails }));
          }}
        />
      ),
    },
    {
      title: (
        <div>
          <DeleteOutlined style={{ fontSize: "18px" }} />
        </div>
      ),
      key: "Delete",
      render: (text, record, index) => {
        if (quotations.details.length === 1) return null;
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleDeleteRow(record.id)}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];
  const handleAddRow = () => {
    setQuotations((prev) => ({
      ...prev,
      details: [
        ...(prev?.details || []),
        {
          id: uuidv4(),
          shipping_line_id: null,
          destination_id: null,
          containersize_id: null,
          vehicle_type: null,
          charges: "",
          no_of_vehicles: "",
          loading_charges: "",
          is_dg: false,
        },
      ],
    }));
    setIsTableVisible(true)
  };
  // console.log(quotations)
  const handleDeleteRow = (Id) => {
    setQuotations((prev) => {
      const updatedDetails = (prev?.details || []).filter((item) => item.id !== Id);

      // Update table visibility based on the remaining rows
      if (updatedDetails.length === 0) {
        setIsTableVisible(false);
      }

      return {
        ...prev,
        details: updatedDetails,
      };
    });
  };

  const initialize = async () => {
    if (isEditMode) {
      const { success, data } = await quotationService.view(id);
      if (success) {
        const updatedData = {
          ...data,
          details: data.details.map((detail) => ({
            ...detail,
            uuid: uuidv4(),
          })),
        };
        const shippingline = data.details.map((item) => item.shipping_line_id);

        setfilteredShippingLine(shippingline);
        setQuotations(updatedData);
        setSelectedValue(data.userQuotation.payment_collection_type_id);
        form.setFieldsValue({
          ...data.userQuotation,
          paymentType:
            data.userQuotation.payment_collection_type_id,
          date: moment(data.userQuotation.date),
          validity: moment(data.userQuotation.validity_date),
          chasis_days: data.details[0].chasis_days,
          chasis_charges: data.details[0].chasis_charges,
          storage_charges: data.details[0].storage_charges,
          storage_days: data.details[0].storage_days,
        });
      }
    }
  };

  const getCustomers = async () => {
    const { success, data } = await customerService.fetchCustomers();
    if (success) {
      setCustomers(data);
    }
  };
  const getDestinations = async () => {
    const { success, data } = await destinationService.fetchDestination();
    if (success) {
      setDestinations(data);
    }
  };
  const getShippingLine = async () => {
    const { success, data } = await shippingLineService.fetch();
    if (success) {
      setShippingLine(data);
    }
  };
  const getVehicleType = async () => {
    const { success, data } = await vehicalService.getTypes();
    if (success) {
      setVehicleTypes(data);
    }
  };
  const getContainerSize = async () => {
    const { success, data } = await containerService.fetchsizes();
    if (success) {
      setContainerSizes(data);
    }
  };

  const handleRadioChange = (e) => {
    setSelectedValue(e.target.value);
    form.setFieldsValue({
      paymentcollectiontype: e.target.value,
    });
  };

  const onFinish = async (values) => {
    setLoading(true);
    const shippingLineIds = quotations.details
      .map((detail) => detail.shipping_line_id)
      .filter((id) => id);
    const destinationIds = quotations.details
      .map((detail) => detail.destination_id)
      .filter((id) => id);
    const containerIds = quotations.details
      .map((detail) => detail.containersize_id)
      .filter((id) => id);
    const vehicleTypesArray = quotations.details.map(
      (detail) => detail.vehicle_type
    );

    const freightChargesArray = quotations.details.map(
      (detail) => detail.charges || 0
    );

    const noOfVehiclesArray = quotations.details.map(
      (detail) => detail.no_of_vehicles
    );

    const loadingChargesArray = quotations.details.map(
      (detail) => detail.loading_charges
    );

    const isDGArray = quotations.details.map((detail) => detail.is_dg);

    const payload = {
      ...values,
      date: moment(values.date).format("YYYY-MM-DD"),
      validity: moment(values.validity).format("YYYY-MM-DD"),
      paymentType: selectedValue,
      shippingline: shippingLineIds,
      destination: destinationIds,
      vehicle_type: vehicleTypesArray,
      container: containerIds,
      freight_charges: freightChargesArray,
      no_of_vehicles: noOfVehiclesArray,
      load_charges: loadingChargesArray,
      is_dg: isDGArray,
    };
    const response = isEditMode
      ? await quotationService.editQuotation(id, payload)
      : await quotationService.addQuotation(payload);
    if (response.success) {
      notification.success({
        message: "Success",
        description: isEditMode
          ? "Quotation updated successfully!"
          : "Quotation added successfully",
        placement: "topRight",
      });
    } else {
      const errors = response.data?.errors || [response.message];
      notification.error({
        message: "Error",
        description: `Error: ${errors.join(", ")}`,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize();
    getCustomers();
    getDestinations();
    getShippingLine();
    getVehicleType();
    getContainerSize();
  }, []);

  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Form
        requiredMark={false}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ layout: "vertical" }}
      >
        <Row gutter={24}>
          <Col span={24}>
            <h1 style={{ fontWeight: "bold" }} className="pt-2  pb-5">
              {" "}
              {isEditMode ? "Edit Quotation" : "Add Quotation "}
            </h1>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Select Customer "
              name="user_id"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Select placeholder="Select a customer">
                {customers.map((customer) => (
                  <Select.Option key={customer.id} value={customer.id}>
                    {customer.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Quotation Date" name="date">
              <DatePicker style={{ width: "100%" }} placeholder="mm/dd/yyyy" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Valid Date" name="validity">
              <DatePicker style={{ width: "100%" }} placeholder="mm/dd/yyyy" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Chassis Days" name="chasis_days">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Chassis Charges" name="chasis_charges">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Storage Charges" name="storage_charges">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Storage Days" name="storage_days">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Payment Days" name="payment_days">
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} style={{ marginTop: "10px" }}>
          <Col span={24}>
            <p>Payment Collection Type</p>
          </Col>
          <Col span={4}>
            <Form.Item name="paymentType">
              <Radio.Group
                value={selectedValue}
                onChange={handleRadioChange}
                className="flex flex-row gap-4 justify-center items-center w-[200px]"
              >
                <Radio value={1}>ETA</Radio>
                <Radio value={2}>Invoice Terms</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <div
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "8px",
            backgroundColor: "#FFFFFF",
            margin: 40,
            marginTop: 10,
          }}
        >
            {isTableVisible && (
          <Table
            columns={columns}
            scroll={{ x: "max-content" }}
            dataSource={quotations.details}
            pagination={false}
            rowKey="Id"
            style={{ marginTop: "10px" }}
          />
            )}
          <div
            style={{ marginTop: 5 }}
            className="flex flex-col justify-center items-center"
          >
            <CiSquarePlus
              style={{ cursor: "pointer", fontSize: "25px" }}
              onClick={handleAddRow}
            />
          </div>
        </div>
        <Form.Item style={{ textAlign: "center", marginTop: 30 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "50%" }}
            loading={loading}
          >
            {" "}
            {isEditMode ? "EDIT QUOTATION" : "ADD QUOTATION "}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

// import React, { useState } from 'react'
// import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, Table } from 'antd';
// import { CiSquarePlus } from "react-icons/ci";
// import { Option } from 'antd/es/mentions';
// import { DeleteOutlined } from '@ant-design/icons'
// import { useParams } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';

// export default function QuotationForm() {
//     const { id } = useParams();  // Get 'id' from the URL
//     const [form] = Form.useForm();
//     const [data, setData] = useState([
//         {
//             Id: uuidv4(),
//             key: '13574',
//             ShippingLine: '',
//             Destination: '',
//             ContainerSize: '',
//             Vehicle: '',
//             FrightCharges: '',
//             NoOfVehicles: '',
//             LoadingCharges: "",

//         },
//         {
//             Id: uuidv4(),
//             key: '13574',
//             ShippingLine: '',
//             Destination: '',
//             ContainerSize: '',
//             Vehicle: '',
//             FrightCharges: '',
//             NoOfVehicles: '',
//             LoadingCharges: "",

//         },
//         {
//             Id: uuidv4(),
//             key: '13574',
//             ShippingLine: '',
//             Destination: '',
//             ContainerSize: '',
//             Vehicle: '',
//             FrightCharges: '',
//             NoOfVehicles: '',
//             LoadingCharges: "",

//         },
//     ])

//     const isEditMode = id !== undefined;
//     const columns = [
//         {
//             title: "Id",
//             dataIndex: 'Id',
//             key: 'Id',
//             render: (text, record, index) => index + 1,
//         },
//         {
//             title: "Shipping Line",
//             dataIndex: 'ShippingLine',
//             key: 'ShippingLine',
//             render: (text, record) => (
//                 <Select
//                     defaultValue={text}
//                     style={{ width: '100%' }}
//                 >
//                     <Option value="User1">User1</Option>
//                     <Option value="User2">User2</Option>
//                     <Option value="User3">User3</Option>
//                 </Select>
//             ),
//         },
//         {
//             title: "Destination",
//             dataIndex: 'Destination',
//             key: 'Destination',
//             render: (text, record) => (
//                 <Select
//                     defaultValue={text}
//                     style={{ width: '100%' }}
//                 >
//                     <Option value="User1">User1</Option>
//                     <Option value="User2">User2</Option>
//                     <Option value="User3">User3</Option>
//                 </Select>
//             ),
//         },

//         {
//             title: "Container Size",
//             dataIndex: 'Container Size',
//             key: 'ContainerSize',
//             render: (text, record) => (
//                 <Select
//                     defaultValue={text}
//                     style={{ width: '100%' }} >
//                     <Option value="SUV">SUV</Option>
//                     <Option value="Sedan">Sedan</Option>
//                     <Option value="Truck">Truck</Option>
//                 </Select>
//             ),
//         },
//         {
//             title: "Fright Charges",
//             dataIndex: "Fright Charges",
//             key: "FrightCharges",
//             render: (text) => (
//                 <Input
//                     defaultValue={text}
//                     style={{ width: "100%" }}
//                 />
//             ),
//         },
//         {
//             title: "No Of Vehicles",
//             dataIndex: "No Of Vehicles",
//             key: "NoOfVehicles",
//             render: (text) => (
//                 <Input
//                     defaultValue={text}
//                     style={{ width: "100%" }}
//                 />
//             ),
//         },
//         {
//             title: "Vehicle Type",
//             dataIndex: 'Vehicle Type',
//             key: 'Vehicle Type',
//             render: (text, record) => (
//                 <Select
//                     defaultValue={text}
//                     style={{ width: '100%' }}
//                 >
//                     <Option value="Petrol">Petrol</Option>
//                     <Option value="Diesel">Diesel</Option>
//                     <Option value="Electric">Electric</Option>
//                 </Select>
//             ),
//         },
//         {
//             title: "Loading Charges",
//             dataIndex: "Loading Charges",
//             key: "LoadingCharges",
//             render: (text) => (
//                 <Input
//                     defaultValue={text}
//                     style={{ width: "100%" }}
//                 />
//             ),
//         },
//         {
//             title: "Is DG", // New checkbox column
//             dataIndex: "Is DG",
//             key: "Is DG",
//             render: (text, record) => (
//                 <Checkbox
//                     defaultChecked={text}
//                     onChange={(e) => {
//                         // Handle checkbox change if needed (e.g. update the record)
//                         console.log('Checkbox value:', e.target.checked);
//                     }}
//                 />
//             ),
//         },
//         {
//             title: (<div>
//                 <DeleteOutlined style={{ fontSize: "18px" }} />
//             </div>),
//             key: 'Delete',
//             render: (text, record, index) => {
//                 if (data.length === 1) return null;
//                 return (
//                     <Button type="primary" size="small" onClick={() => handleDeleteRow(record.Id)}>
//                         <DeleteOutlined />
//                     </Button>
//                 );
//             },
//         },
//     ]
//     const handleAddRow = () => {
//         setData([
//             ...data,
//             {
//                 Id: uuidv4(),
//                 key: '13574',
//                 Destination: '',
//                 ContainerSize: '',
//                 FrightCharges: '',
//             },
//         ]);
//     };
//     const handleDeleteRow = (Id) => {
//         setData(data.filter((item) => item.Id !== Id));
//     };

//     return (
//         <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
//             <Form
//                 requiredMark={false}
//                 form={form}
//                 layout="vertical"
//                 initialValues={{ layout: "vertical" }}
//             >
//                 <Row gutter={24} >
//                     <Col span={24}>
//                         <h1 style={{ fontWeight: 'bold', }} className='pt-2  pb-5'> {isEditMode ? 'Edit Quotation' : 'Add Quotation '}</h1>
//                     </Col>
//                     <Col span={6}>
//                         <Form.Item
//                             label="Select Customer "
//                             name="Select Customer"
//                             rules={[
//                                 {
//                                     required: true,
//                                     message: 'Please input!',
//                                 },
//                             ]}
//                         >
//                             <Select placeholder="OTHER" />
//                         </Form.Item>
//                     </Col>
//                     <Col span={6}>
//                         <Form.Item label="Quotation Date ">
//                             <DatePicker style={{ width: '100%' }} placeholder="mm/dd/yyyy" />
//                         </Form.Item>
//                     </Col>
//                     <Col span={6}>
//                         <Form.Item label="Valid Date ">
//                             <DatePicker style={{ width: '100%' }} placeholder="mm/dd/yyyy" />
//                         </Form.Item>
//                     </Col>
//                     <Col span={6}>
//                         <Form.Item label="Chassis Days">
//                             <Input placeholder="" />
//                         </Form.Item>
//                     </Col >
//                     <Col span={6}>
//                         <Form.Item label="Chassis Charges">
//                             <Input placeholder="" />
//                         </Form.Item>
//                     </Col >
//                     <Col span={6}>
//                         <Form.Item label="Storage Charges">
//                             <Input placeholder="" />
//                         </Form.Item>
//                     </Col >
//                     <Col span={6}>
//                         <Form.Item label="Storage Days">
//                             <Input placeholder="" />
//                         </Form.Item>
//                     </Col >
//                     <Col span={6}>
//                         <Form.Item label="Payment Days  ">
//                             <Input placeholder="" />
//                         </Form.Item>
//                     </Col >

//                 </Row>

//                 <Row gutter={24} style={{ marginTop: '10px' }}>
//                     <Col span={24}>
//                         <p>Payment Collection Type</p>
//                     </Col>
//                     <Col span={3}>
//                         <Form.Item name="ETA" valuePropName="checked">
//                             <Checkbox>ETA</Checkbox>
//                         </Form.Item>
//                     </Col>
//                     <Col span={4}>
//                         <Form.Item name="InvoiceTerms" valuePropName="checked">
//                             <Checkbox>Invoice Terms</Checkbox>
//                         </Form.Item>
//                     </Col>
//                 </Row>
//                 <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF", margin: 40, marginTop: 10 }}>
//                     <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} pagination={false} rowKey="Id" style={{ marginTop: '10px' }} />

//                     <div style={{ marginTop: 20 }} className='flex flex-col justify-center items-center'>
//                         <CiSquarePlus style={{ cursor: 'pointer', fontSize: '25px' }} onClick={handleAddRow} />
//                     </div>

//                 </div>
//                 <Form.Item style={{ textAlign: 'center', marginTop: 30 }}>
//                     <Button type="primary" style={{ width: '50%' }}> {isEditMode ? 'EDIT QUOTATION' : 'ADD QUOTATION '}</Button>
//                 </Form.Item>

//             </Form>
//         </div>
//     )
// }

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Space, Row, Col, Card, Divider, Button } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { vendorService } from "../../../services";

const { Meta } = Card;

function ViewVendor() {
  const { id } = useParams();
  const navigate=useNavigate();
  const [vendor, setVendor] = useState([]);

  const initialize = async () => {
    const { success, data } = await vendorService.viewVendor(id);
    if (success) {
      setVendor(data);
    }
  };

  useEffect(() => {
    initialize();
  }, []);
  return (
    <>
      <Row gutter={0} className='flex justify-center'> 

        <Col  flex="500px" >
          <Card
            style={{ padding: '10px' }}
          >
            <Meta
              title={vendor.name}
              style={{ textAlign: 'center' }}
            />
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <p> {vendor.email}</p>
            </div>
            <Divider />
            <div className='profile-info'>
              <p><strong>Phone:</strong> {vendor.phone}</p>
              <p><strong>Address:</strong> {vendor.address}</p>
              <p><strong>Town:</strong> {vendor.town}</p>
              <p><strong>City:</strong> {vendor.city}</p>
              <p><strong>Country:</strong> {vendor.country}</p>
              <p><strong>Secondary Email :</strong>{vendor.secondary_email || <span className="text-gray-400">none</span>}</p>

            </div>
            <Divider />
            <div style={{ textAlign: "center" }}>
              <Button type="primary" style={{ marginRight: "10px" }} onClick={() => navigate(`/operation/vendors/form/${id}`)}>
                <EditOutlined style={{ fontSize: "20px", cursor: "pointer" }} />
              </Button>
                {/* <DeleteOutlined
                  style={{ fontSize: "20px", cursor: "pointer" }}
                /> */}
            </div>
          </Card>
        </Col>

        {/* <Col flex="1">
          <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Row gutter={12}>
              <Col span={12}>
                <h1 style={{ fontWeight: "bold" }}>Browse Vendors</h1>
              </Col>
            </Row>
            <Table style={{ marginTop: "10px" }} />
          </div>
        </Col> */}

      </Row>

      <style>
        {`
  .profile-info {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 15px; /* Space between each <p> element */
  }

  .profile-info p {
    display: flex; /* Makes each <p> a flex container */
    width: 100%; /* Expand each <p> to fill the container */
    justify-content:space-between; /* Space between <strong> and <p> content */
  }
`}
      </style>
    </>
  );
}

export default ViewVendor;

import React from 'react';
import {Table, Typography } from 'antd';

const { Title } = Typography;
function AtYardVehicle({ atyard = {} }) {
    const columns = [
        {
            title: "#",
            dataIndex: 'index',
            key: 'index',
            width: 5,
            render: (text, record, index) => index + 1,
        },
        {
            title: "CAR STATUS",
            dataIndex: 'car_status',
            key: 'car_status',
            width: 90,
        },
        {
            title: "RECEIVED DATE",
            dataIndex: "date_rcd",
            key: "date_rcd",
            render: (text) => text ? new Date(text).toLocaleDateString() : "-",
            width: 50,
        },
        {
            title: "VEHICLE",
            dataIndex: "vehicle",
            key: "vehicle",
            width: 50,
        },
        {
            title: "VIN NUMBER",
            dataIndex: "chasis_no",
            key: "chasis_no",
            width: 50,
        },
        {
            title: "KEY",
            dataIndex: "keys",
            key: "keys",
            width: 50,
        },
        {
            title: "TITLE",
            dataIndex: "title",
            key: "title",
            width: 50,
        },
        {
            title: "STORAGE DAYS LEFT",
            dataIndex: "left_days",
            key: "left_days",
            width: 120,
        },

    ];

    return (
        <div style={{
            padding: '0 14px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            backgroundColor: '#FFFFFF',
        }}>

            <h1 style={{
                fontWeight: 'bolder',
                marginTop: 10,
                padding: '10px',
                textAlign: 'center',
                fontSize: '16px',
            }}>AT YARD VEHICLES</h1>

            {Object.entries(atyard).map(([category, vehicles]) => (
                <div key={category} className='text-center'>
                    <Table
                        dataSource={vehicles}
                        columns={columns}
                        rowKey={category} // Unique key for rows
                        pagination={false} // Adjust page size if needed
                        headerStyle={{ backgroundColor: '#4CAF50', color: 'white' }} // Customize header background
                        title={() => <Title level={5}>{category}</Title>} // Add title to table
                        className='custom-table'
                    />
                </div>
            ))}

            <style>
                {`
 .custom-table .ant-table-thead > tr > th {
  background-color: #B83D3D; /* Custom background color */
  color: #ffffff; /* Custom text color */
  font-weight: bold;
  text-align: center;
  padding: 10px;
}
`}
            </style>

        </div>
    )
}

export default AtYardVehicle;

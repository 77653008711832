import React, { useEffect, useRef, useState } from "react";
import "./Edit.css";
import { Button } from "antd";
import { Link, useParams } from "react-router-dom";
import { imoService } from "../../../services";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

function ImoView() {
  const { id } = useParams();
  const [input1, setInput1] = useState(" Provident neque lor");
  const [imo, setIMO] = useState([]);
  const pdfRef = useRef();

  const initialize = async () => {
    const { success, data } = await imoService.viewIMO(id);
    if (success) {
      setIMO(data);
    }
  };
  const downloadPDF = async () => {
    const element = pdfRef.current;
    const canvas = await html2canvas(element, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    // Calculate aspect ratio to fit A4 size
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("IMO.pdf");
  };
  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      <div className="flex justify-center mb-7 gap-5">
        <Link to={"/operation/loadings/imos"}>
          <Button style={{ backgroundColor: "#17A2B8", color: "white" }}>
            Back
          </Button>
        </Link>
        <Button style={{ backgroundColor: "#DC3545", color: "white" }} onClick={downloadPDF}>
          Download PDF
        </Button>
      </div>
      <div
        ref={pdfRef}
        style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}
        className="maincontainer"
      >
        <div className="container" style={{ backgroundColor: "#FFFFFF" }}>
          <h4 className="heading">IMO DANGEROUS GOODS DECLARATION</h4>
          <div className="topOne">
            <div className="topOne-left">
              <h5> 1. Shipper</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: imo?.shipper
                    ?.replace(/\r\n/g, "<br />") // Replace line breaks
                    ?.replace(/(EIN|REHAN):/g, "<strong>$1:</strong>"), // Bold labels like EIN and REHAN
                }}
              />
            </div>
            <div className="topOne-right">
              <h5>2. Transportation Document Number</h5>
              <p>{imo?.transportation_no}</p>
              {/* <input onChange={() => setInput1()} value={input1} /> */}
              <div className="topOne-right-inner">
                <div>
                  <h5>3. Carrier</h5>
                  <p>{imo?.booking_no}</p>
                </div>
                <div>
                  <h5>4. Shipper References</h5>
                  <p>{imo?.shipper_references}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="topTwo">
            <div className="topTwo-left">
              <h5>6. Consignee</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: imo?.consignee
                    ?.replace(/\r\n/g, "<br />") // Replace line breaks
                    ?.replace(/(TAX ID|PH|EMAIL):/g, "<strong>$1:</strong>"), // Bold specific labels
                }}
              />
            </div>
            <div className="topTwo-right">
              <h5>5. Freight Forwarder's Reference</h5>
              <p>{imo?.forwarder_refernece}</p>
              <h5>7. Carrier (to be declared by the Carrier)</h5>
              <p>{imo?.carrier_name}</p>
            </div>
          </div>
          <div className="topThree">
            <div>
              <h5> SHIPPER'S DECLARATION </h5>
              <p>
                I hereby declare that the contents of this consignment are fully
                and accurately described above by the proper shipping name(s),
                and are classified, packaged, marked and labeled/placarded, and
                are in all respects in proper condition for transport according
                to applicable international and national governmental
                regulations
              </p>
            </div>
          </div>
          <div className="topFour">
            <div className="topFour-left">
              <h5>10. Vessel/Flight and Date</h5>
              <p>{imo?.vessel}</p>
              <div className="topFour-right-inner">
                <div>
                  <h5>11. Port/Place Handling</h5>
                  <p>{input1}</p>
                </div>
                <div>
                  <h5>Port/Place Delivery</h5>
                  <p>{imo?.place_of_delivery}</p>
                </div>
              </div>
            </div>
            <div className="topFour-right">
              <h5>9. Additional Handling Information</h5>
              {/* <p>{imo?.instructions}</p> */}
              <p
                dangerouslySetInnerHTML={{
                  __html: imo?.instructions
                    ?.replace(/\r\n/g, "<br />") // Replace line breaks
                    ?.replace(
                      /(SHIPPER CCN|CONSIGNEE CCN)/g,
                      "<strong>$1</strong>"
                    ), // Bold specific labels
                }}
              ></p>
            </div>
          </div>
          <div className="topFive">
            <div className="topFive-left">
              <h5>14. Shipping Marks </h5>
              <div className="seperator"></div>
            </div>
            <div className="topFive-center">
              <h5>Number and Kind of Packages, Description of Goods </h5>
              <div className="seperator"></div>
              <p
                dangerouslySetInnerHTML={{
                  __html: imo?.description
                    ?.replace(/\r\n/g, "<br />") // Add line breaks
                    ?.replace(/(VIN\. [\w\d]+)/g, "<strong>$1</strong>") // Bold VIN details
                    ?.replace(/(UN\d+)/g, "<strong>$1</strong>"), // Bold UN codes
                }}
              />
            </div>
            <div className="topFive-right">
              <h5>GW (kg)</h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: imo?.weight?.replace(/\r\n/g, "<br />"), // Replace line breaks
                }}
              />
            </div>
          </div>
          <div className="topSix">
            <div className="topSix-inner">
              <h5>15 CTU ID No.</h5>
              <p>{imo?.ctu_id}</p>
            </div>
            <div className="topSix-inner">
              <h5>16 Seal No.</h5>
              <p>{imo?.seal_no}</p>
            </div>
            <div className="topSix-inner">
              <h5>17 CTU Size and Type</h5>
              <p>{imo?.ctu_size}</p>
            </div>
            <div className="topSix-inner">
              <h5>18 Tare Mass (kg)</h5>
              <p>{imo?.tare_mass}</p>
            </div>
            <div className="topSix-inner">
              <h5>19 Total Gross Mass (kg)</h5>
              <p>{imo?.total_gross_mass}</p>
            </div>
          </div>
          <div className="topSeven">
            <div className="topSeven-left">
              <h5>Container/Vehicle Packing Certificate</h5>
              <p>
                I hereby declare that the goods described above have been
                packed/loaded into the container/vehicle identified above in
                accordance with the applicable provisions
              </p>
            </div>
            <div className="topSeven-right">
              <h5> 21 Receiving Organization Receipt</h5>
              <p>
                Received the above number of packages/containers/trailers in
                apparent good order and condition, unless stated hereon:
                RECEIVING
              </p>
              <h5> ORGANIZATION REMARKS:</h5>
            </div>
          </div>
          <div className="topEight">
            <div className="topEight-inner">
              <h5>20 Name of Company</h5>
              <p>{imo?.name_of_company}</p>
            </div>
            <div className="topEight-inner">
              <h5>21 Hauler's Name</h5>
              <p>{imo?.haulers_name}</p>
            </div>
            <div className="topEight-inner">
              <h5>22 Name of Company Preparing Note</h5>
              <p>{imo?.name_of_company_preparing_note}</p>
            </div>
          </div>
          <div className="topEight">
            <div className="topEight-inner">
              <h5>Name/Status of Declarant Note</h5>
              <p>{imo?.name_status_declarant_note_1}</p>
            </div>
            <div className="topEight-inner">
              <h5>Vehicle Registration No.</h5>
              <p>{imo?.vehicle_registration_no}</p>
            </div>
            <div className="topEight-inner">
              <h5>Name/Status of Declarant</h5>
              <p>{imo?.name_status_declarant_note_2}</p>
            </div>
          </div>
          <div className="topEight">
            <div className="topEight-inner">
              <h5>Place and Date</h5>
              <p>{imo?.place_and_date_1}</p>
            </div>
            <div className="topEight-inner">
              <h5>Driver Name and Date</h5>
              <p>{imo?.driver_name_and_date}</p>
            </div>
            <div className="topEight-inner">
              <h5>Place and Date</h5>
              <p>{imo?.place_and_date_2}</p>
            </div>
          </div>
          <div className="topEight">
            <div className="topEight-inner">
              <h5>Signature of Declarant</h5>
            </div>
            <div className="topEight-inner">
              <h5>Driver's Signature</h5>
            </div>
            <div className="topEight-inner">
              <h5>Signature of Declarant</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImoView;

import ajaxService from "./ajax-service";

class EmailService {
  async fetch() {
    const response = await ajaxService.get(`/email/types`);
    if (response ?? false) {
      return response;
    }
  }
}

const emailService = new EmailService();
export default emailService;

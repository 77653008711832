
import ajaxService from "./ajax-service";

class CountryService {
    
    async countryGet() {
        const response = await ajaxService.get('/countries')
        if (response ?? false) {
            return response;
        }
    }
}
const countryService = new CountryService()

export default countryService
import ajaxService from "./ajax-service";

class LoadingClient {
  async fetch(page) {
    const response = await ajaxService.get(`/client-booking?page=${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async View(id) {
    const response = await ajaxService.get(`/client-booking/${id}`);
    if (response ?? false) {
      return response;
    }
  }
}

const loadingClient = new LoadingClient();

export default loadingClient;

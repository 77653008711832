import ajaxService from "./ajax-service";

class ShippingLineService {
  async fetch() {
    const response = await ajaxService.get("/shipping/lines");
    if (response ?? false) {
      return response;
    }
  }
}

const shippingLineService = new ShippingLineService();

export default shippingLineService;

import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Select,
  Table,
  Upload,
} from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import {
  awsService,
  containerService,
  dropdownService,
  loadingPlanService,
} from "../../../services";
import ImageUploader from "../../../components/ImageUploader";
import { CONTAINERS } from "../../../constants";

function LoadingAdd() {
  const [loading, setLoading] = useState(false);
  const [shippingLine, setShippingLine] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [containerSize, setContainerSize] = useState([]);
  const [newCarOptions, setNewCarOptions] = useState([]);
  const [reloadCarOptions, setReloadCarOptions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [container_number, setContainer_number] = useState("");

  const [fileList, setFileList] = useState([]);

  const handleChange = (info) => {
    let updatedFileList = [...info.fileList];
    setFileList(updatedFileList);
  };

  // API call function
  const fetchCarOptions = async (query, setOptions) => {
    if (!query) {
      setOptions([]);
      return;
    }
    try {
      const response = await dropdownService.fetchVehiclesOnSearch({ query });
      if (response.success) {
        setOptions(
          response.data.map((car) => ({
            value: `${car.vehicle} - ${car.chasis_no}`,
            label: `${car.vehicle} - ${car.chasis_no}`,
            data: car,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching vehicle options:", error);
      setOptions([]);
    }
  };

  // Handle selection from dropdown
  const handleSelect = (value, option) => {
    const { data } = option;
    const selectedCar = {
      id: data.id,
      name: data.vehicle,
      chasis_no: data.chasis_no,
    };
    setTableData((prev) => [...prev, selectedCar]);
    form.setFieldsValue({
      new_car: "",
      reload_car: "",
    });
  };
  // Delete handler
  const handleDelete = (id) => {
    setTableData((prevData) => prevData.filter((item) => item.id !== id));
  };
  const columns = [
    {
      title: "Vehicle",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Chassis Number",
      dataIndex: "chasis_no",
      key: "chasis_no",
    },
    {
      title: (
        <div>
          <DeleteOutlined style={{ fontSize: "18px" }} />
        </div>
      ),
      key: "Delete",
      render: (text, record) => (
        <Button
          type="primary"
          size="small"
          onClick={() => handleDelete(record.id)}
        >
          <DeleteOutlined />
        </Button>
      ),
    },
  ];

  const getShippingLine = async () => {
    const response = await dropdownService.fetchShippingLines();
    if (response.success) {
      setShippingLine(response.data);
    }
  };

  const getDestinations = async () => {
    const response = await dropdownService.fetchDestination();
    if (response.success) {
      setDestinations(response.data);
    }
  };
  const getContainerSize = async () => {
    const response = await dropdownService.fetchContainerSizes();
    if (response.success) {
      setContainerSize(response.data);
    }
    const res = await containerService.fetchlatestContainerId();
    if (res.success) {
      setContainer_number(res.data.id);
    }
  };

  const onFinish = async (val) => {
    setLoading(true);
    const data1 = form.getFieldsValue();
    const data2 = form2.getFieldsValue();
    const additionalData =
      val === "load"
        ? { load: "Load" }
        : val === "save"
          ? { save: "Save" }
          : {};
    const merged = { ...data1, ...data2 };
    const carid = tableData.map((car) => car.id);
    const { new_car, reload_car, ...values } = merged;
    const payload = {
      ...values,
      pull_out_date: values.pull_out_date.format("YYYY-MM-DD"),
      customised_date: values.customised_date.format("YYYY-MM-DD"),
      gate_in_date: values.gate_in_date.format("YYYY-MM-DD"),
      eta_date: values.eta_date.format("YYYY-MM-DD"),
      carid,
      ...additionalData,
    };

    let images = [];
    if (fileList.length > 0) {

      const newFiles = fileList.filter((file) => file.originFileObj);

      if (newFiles.length > 0) {
        images = await awsService.uploadS3Bucket(newFiles, false, payload.load_number, CONTAINERS);
      }
    }

    const data = { ...payload, images };

    const response = await loadingPlanService.addPlan(data);
    if (response.success) {
      notification.success({ message: "Successfully added!", description: response.message, Fplacement: "topRight" });
    }
    setLoading(false);
  };

  useEffect(() => {
    getShippingLine();
    getContainerSize();
    getDestinations();
  }, []);

  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={26} style={{ marginBottom: 10, padding: "5px" }}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Add Plans</h1>
        </Col>
      </Row>

      <Form
        requiredMark={false}
        form={form}
        layout="vertical"
        initialValues={{ layout: "vertical" }}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item label="Booking Number" name="booking_number">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Container Number" name="container_number">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Load Number" name="load_number">
              <Input placeholder="Enter Location to get Load Number" readOnly />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Add New Car" name="new_car">
              <AutoComplete
                options={newCarOptions}
                onSearch={(query) => fetchCarOptions(query, setNewCarOptions)}
                onSelect={(value, option) => handleSelect(value, option)}
                placeholder="Search and select a car"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Add Reload Car" name="reload_car">
              <AutoComplete
                options={reloadCarOptions}
                onSearch={(query) =>
                  fetchCarOptions(query, setReloadCarOptions)
                }
                onSelect={(value, option) => handleSelect(value, option)}
                placeholder="Search and select a car"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Shipping Line"
              name="shipping_line"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Select placeholder="">
                {shippingLine.map((ship) => (
                  <Select.Option key={ship.id} value={ship.id}>
                    {ship.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Location" name="location">
              <Input
                placeholder=""
                onChange={(e) => {
                  // Get the location input value
                  const locationValue = e.target.value;
                  // Append "-93000" to the location value to form the load number
                  const loadNumber = locationValue
                    ? `${locationValue}-${container_number}`
                    : "";
                  // Update the Load Number field dynamically
                  form.setFieldsValue({ load_number: loadNumber });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Form
        requiredMark={false}
        layout="vertical"
        form={form2}
        initialValues={{ layout: "vertical" }}
      >
        <Row gutter={24} style={{ padding: 20 }}>
          <Col span={8}>
            <Form.Item
              label="Destination"
              name="destination"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Select placeholder="BENGHAZI">
                {destinations.map((destination) => (
                  <Select.Option key={destination.id} value={destination.name}>
                    {destination.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Container Size"
              name="container_size"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Select placeholder="BENGHAZI">
                {containerSize.map((size) => (
                  <Select.Option key={size.id} value={size.name}>
                    {size.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Pull Out Date " name="pull_out_date">
              <DatePicker style={{ width: "100%" }} placeholder="mm/dd/yyyy" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Gate In Date" name="gate_in_date">
              <DatePicker style={{ width: "100%" }} placeholder="mm/dd/yyyy" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Customized Date" name="customised_date">
              <DatePicker style={{ width: "100%" }} placeholder="mm/dd/yyyy" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="ETA Date" name="eta_date">
              <DatePicker style={{ width: "100%" }} placeholder="mm/dd/yyyy" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <ImageUploader
              fileList={fileList}
              beforeUpload={true}
              handleChange={handleChange}
              length={80}
            />
          </Col>
        </Row>
      </Form>

      <Table
        columns={columns}
        pagination={false}
        rowKey="id"
        scroll={{ x: "max-content" }}
        dataSource={tableData}
        style={{ marginTop: "5px" }}
      />

      <Row
        gutter={24}
        style={{ marginTop: 40, display: "flex", justifyContent: "flex-end" }}
      >
        {/* <Col span={4}>
          <Upload id="uploadInput" action="/upload" listType="picture">
        <Col span={4}>
          <Upload
            id="uploadInput"
            beforeUpload={() => false}
            action="/upload"
            listType="picture"
          >
            <Button
              type="dashed"
              icon={<UploadOutlined />}
              style={{
                width: "100%",
                border: "1px solid #ccc",
                padding: 16,
              }}
            >
              Upload Image
            </Button>
          </Upload>
        </Col> */}

        <Col span={6}>
          <Button
            type="primary"
            style={{ width: "100%" }}
            onClick={() => onFinish("save")}
            loading={loading}
          >
            SAVE
          </Button>
        </Col>
        <Col span={6}>
          <Button
            type="primary"
            style={{ width: "100%" }}
            onClick={() => onFinish("load")}
            loading={loading}
          >
            LOAD
          </Button>
        </Col>
      </Row>
    </div>
  );
}
export default LoadingAdd;

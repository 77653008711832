import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Upload,
  Radio,
  Checkbox,
  notification,
} from "antd";
import { Select } from "antd";
import { CiSquareMinus, CiSquarePlus } from "react-icons/ci";
import { appointmentService, dropdownService } from "../../../services";

const options = [
  { label: "Car", value: "Car" },
  { label: "Motorcycle", value: "Motorcycle" },
  { label: "ATV", value: "ATV" },
  { label: "Jetski", value: "Jetski" },
  { label: "Machinery", value: "Machinery" },
  { label: "Boat", value: "Boat" },
  { label: "Other", value: "Other" },
];

function AppointmentForm({ selectedDate, selectedSlot }) {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [form] = Form.useForm();
  const [validating, setValidating] = useState({});

  const handleValidateVin = async (rule, value) => {
    if (!value) {
      return Promise.reject(new Error("VIN number is required."));
    }

    setValidating((prev) => ({ ...prev, [rule.field]: true }));
    try {
      const response = await appointmentService.checkVIN(value);

      // Check if the VIN already has an appointment
      if (response.data === 1) {
        setValidating((prev) => ({ ...prev, [rule.field]: false }));
        return Promise.reject(
          new Error("VIN number already has an appointment.")
        );
      }

      setValidating((prev) => ({ ...prev, [rule.field]: false }));
      return Promise.resolve();
    } catch (error) {
      setValidating((prev) => ({ ...prev, [rule.field]: false }));
      return Promise.reject(new Error(error.message));
    }
  };

  const handleCheckboxChange = (checkedValues) => {
    setShowOtherInput(checkedValues.includes("Other"));
  };

  const getUsers = async () => {
    const response = await dropdownService.fetchCustomers();
    if (response.success) {
      setCustomers(response.data);
    }
  };

  // Handle form submission
  const onFinish = async (values) => {
    // setLoading(true);
    const data = {
      date: selectedDate,
      time: selectedSlot,
      user_id: values.fields.map((field) => field.User),
      isTitle: values.fields.map((field) => field.Title),
      isKey: values.fields.map((field) => field.Key),
      isRun: values.fields.map((field) => field.RunAndDrive),
      company: values.fields.map((field) => field.Company),
      model: values.fields.map((field) => field.Model),
      vin: values.fields.map((field) => field.VIN),
      year: values.fields.map((field) => field.Year),
      make: values.fields.map((field) => field.Make),
      delivery_type: values.fields.map((field) => field.Delivering).flat(),
      keyImage: values.fields.map((field) => field.KeyImage),
      dispatchImage: values.fields.map((field) => field.DispatchSheet.fileList.map(file=>file.originFileObj)),
      invoiceImage: values.fields.map((field) => field.InvoiceImage),
    };
    console.log(data)
    // const response = await appointmentService.addAppointment(data);
    // setLoading(false);
    // if (response.success) {
    //   notification.success({
    //     message: "Successfully Added!",
    //     description: response.message,
    //     placement: "topRight",
    //   });
    // } else {
    //   notification.error({
    //     message: response.message,
    //     placement: "topRight",
    //   });
    // }
  };

  useEffect(() => {
    getUsers();
  }, []);
  return (
    <>
      <Row
        gutter={24}
        style={{
          marginBottom: 15,
          padding: "15px 0px",
          borderBottom: "1px solid #E8E8E9",
        }}
      >
        <Col span={12}>
          <h1 style={{ fontWeight: "bold", fontSize: 12 }}>Car Details</h1>
        </Col>
      </Row>
      <Form
        form={form}
        onFinish={onFinish}
        name="dynamic_form"
        initialValues={{ fields: [{}] }}
        layout="vertical"
      >
        <Form.List
          name="fields"
          initialValue={[{}]}
          rules={[
            {
              validator: async (_, fields) => {
                if (!fields || fields.length < 1) {
                  return Promise.reject(
                    new Error("At least one form is required.")
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <div key={name} className="mt-8">
                  <h1 style={{ fontWeight: "bold", fontSize: 16 }}>
                    Detail #{name + 1}
                  </h1>

                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item
                        label="User"
                        name={[name, "User"]}
                        rules={[
                          { required: true, message: "Please select a User" },
                        ]}
                      >
                        <Select placeholder="Select a User">
                          {/* Replace with your customer options */}
                          {customers.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Auction Company"
                        name={[name, "Company"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input your Auction Company!",
                          },
                        ]}
                      >
                        <Input placeholder="Auction Company" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        label="VIN"
                        name={[name, "VIN"]}
                        validateTrigger="onBlur"
                        rules={[{ validator: handleValidateVin }]}
                      >
                        <Input
                          placeholder="VIN"
                          disabled={validating[`vehicles[${name}].vin_number`]}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Year"
                        name={[name, "Year"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input your Year!",
                          },
                        ]}
                      >
                        <Input placeholder="Year" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Make"
                        name={[name, "Make"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input your Make!",
                          },
                        ]}
                      >
                        <Input placeholder="Make" />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Model"
                        name={[name, "Model"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input your Model!",
                          },
                        ]}
                      >
                        <Input placeholder="Model" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={6}>
                      <Form.Item
                        label="Title"
                        name={[name, "Title"]}
                        rules={[
                          {
                            required: true,
                            message: "Please choose your Title!",
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value={1}> Yes </Radio>
                          <Radio value={0}> No </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Key"
                        name={[name, "Key"]}
                        rules={[
                          {
                            required: true,
                            message: "Please choose your Key!",
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value={1}> Yes </Radio>
                          <Radio value={0}> No </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Is the Vehicle Run & drive?"
                        name={[name, "RunAndDrive"]}
                        rules={[
                          {
                            required: true,
                            message: "Please choose an option!",
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value={1}> Yes </Radio>
                          <Radio value={0}> No </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col span={15}>
                      <Form.Item
                        label="What are you delivering?"
                        name={[name, "Delivering"]}
                        rules={[
                          {
                            required: true,
                            message: "Please choose your Title!",
                          },
                        ]}
                      >
                        <Checkbox.Group onChange={handleCheckboxChange}>
                          <Row gutter={[24, 0]}>
                            {options.map((option) => (
                              <Col key={option.value}>
                                <Checkbox value={option.value}>
                                  {option.label}
                                </Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                    {showOtherInput && (
                      <Col
                        span={4}
                        className="mt-7"
                        style={{ textAlign: "left" }}
                      >
                        <Form.Item
                          name={[name, "OtherInput"]}
                          rules={[
                            {
                              required: true,
                              message: "Please input your Other option!",
                            },
                          ]}
                        >
                          <Input placeholder="Name Other Here...." />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>

                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item
                        label="Dispatch Sheet (Pdf/Image)"
                        name={[name, "DispatchSheet"]}
                        style={{ marginTop: "15px" }}rules={[
                          {
                            required: true,
                            message: "Please uoload Dispatch Sheet!",
                          },
                        ]}
                      >
                        <Upload
                          beforeUpload={() => false} // Disable auto upload
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <img
                              src="/images/Vector.png"
                              alt="Upload Icon"
                              width="25px"
                              height="10px"
                            />
                            <p className="mt-4">No file chosen</p>
                          </div>
                        </Upload>
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item
                        label="Invoice Bill of Sale Image (Image)"
                        name={[name, "InvoiceImage"]}
                        style={{ marginTop: "15px" }}
                      >
                        <Upload
                          beforeUpload={() => false} // Disable auto upload
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <img
                              src="/images/Vector.png"
                              alt="Upload Icon"
                              width="25px"
                              height="10px"
                            />
                            <p className="mt-4">No file chosen</p>
                          </div>
                        </Upload>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label="Key Image (Image)"
                        name={[name, "KeyImage"]}
                        style={{ marginTop: "15px" }}
                      >
                        <Upload beforeUpload={() => false}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <img
                              src="/images/Vector.png"
                              alt="Upload Icon"
                              width="25px"
                              height="10px"
                            />
                            <p className="mt-4">No file chosen</p>
                          </div>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>

                  <div
                    style={{ marginTop: 20 }}
                    className="flex flex-row justify-center items-center"
                  >
                    <CiSquarePlus
                      style={{ cursor: "pointer", fontSize: "26px" }}
                      onClick={() => add()}
                    />
                    {fields.length > 1 && (
                      <CiSquareMinus
                        style={{ cursor: "pointer", fontSize: "26px" }}
                        onClick={() => remove(name)}
                      />
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </Form.List>
        <div style={{ marginTop: 20, textAlign: "left" }}>
          <Button
            type="primary"
            style={{ width: "15%" }}
            htmlType="submit"
            loading={loading}
          >
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
}

export default AppointmentForm;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Table, Row, Col, Space, Spin } from "antd";
import { SendOutlined, PrinterOutlined } from "@ant-design/icons";
import { quotationService } from "../../../services";

function QuotationView() {
  const { id } = useParams();
  const [quotations, setQuotations] = useState([]);
  const [userQuotation, setuserQuotation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const columns = [
    {
      title: "S.No",
      dataIndex: "SNo",
      key: "SNo",
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "Destination",
    },
    {
      title: "Container",
      dataIndex: "containersize",
      key: "Container",
    },
    {
      title: "No Of Vehicles",
      dataIndex: "no_of_vehicles",
      key: "NoOfVehicles",
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicle_type",
      key: "VehicleType",
    },
    {
      title: "Loading Charges",
      dataIndex: "loading_charges",
      key: "LoadingCharges",
    },
  ];

  const initialize = async () => {
    setLoading(true);
    const { success, data } = await quotationService.view(id);
    if (success) {
      setQuotations(data.details);
      setuserQuotation(data.userQuotation);
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <Spin spinning={loading} size={"large"} tip="Loading..." />
      </div>
    );
  }
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      {/* Header Content */}
      <Row gutter={16}>
        <Col span={20}>
          <h1 style={{ fontWeight: "bold" }}>Hello {userQuotation.name},</h1>
          <ul style={{ lineHeight: "1.8", margin: 0 }}>
            <li>LOADING/FREIGHT RATES ARE AS ATTACHED.</li>
            <li>
              ABOVE RATES INCLUSIVE OF 0 DAYS CHASIS CHARGES; THEREAFTER $0/DAY
              PER CHASIS.
            </li>
            <li>PAYMENT TERMS: 7 DAYS FROM THE DATE OF INVOICE.</li>
            <li>
              LOADING PICTURES WILL BE PROVIDED TO SATISFY THE CUSTOMERS IN
              ADVANCE.
            </li>
            <li>
              CHEQUE BOOK PROVIDED FOR ISSUANCE OF CHEQUES TO CUSTOMERS WITH
              THEIR OWN DISPATCH.
            </li>
            <li>DEDICATED CUSTOMER SERVICE FOR VOLUMETRIC CUSTOMERS.</li>
            <li>STORAGE POLICY: 60 DAYS FREE; THEREAFTER $7/DAY PER UNIT.</li>
            <li>
              DRIVERS MUST CONTACT PRIOR TO DELIVERY OR EMAIL THE DISPATCH
              SHEETS FOR PROPER DELIVERY AND NO DELAYS.
            </li>
            <li>ADVANCE BOOKINGS BASED ON VOLUME COMMITMENT.</li>
            <li>7 ACRES OF PARKING SPACE.</li>
            <li>
              WE REQUEST THAT THE LOADING PLAN BE SENT 48 TO 72 BUSINESS HOURS
              IN ADVANCE OF FIRST RECEIVING.
            </li>
            <li>
              IF CUSTOMERS NEED TO PICK UP VEHICLES, IT WILL BE SUBJECT TO $100
              PER VEHICLE, ALONG WITH STORAGE CHARGES AFTER 30 DAYS OF RECEIVING
              AT THE FACILITY.
            </li>
            <li>
              IN-HOUSE DRAYAGE NETWORK WITH OWN CHASIS FOR SMOOTH OPERATIONS.
            </li>
          </ul>
        </Col>
      </Row>

      <div
        style={{
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          marginTop: "20px",
        }}
      >
        <Table
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={quotations}
          loading={loading}
          pagination={{
            current: pagination.current,
            total: pagination.total,
            pageSize: pagination.pageSize,
            onChange: (page) => initialize(page, pagination.pageSize),
            showSizeChanger: false,
          }}
        />
      </div>

      <div style={{ textAlign: "right", marginTop: "20px" }}>
        <Space size="large">
          <SendOutlined style={{ fontSize: "18px", cursor: "pointer" }} />
          <PrinterOutlined style={{ fontSize: "18px", cursor: "pointer" }} />
        </Space>
      </div>
    </div>
  );
}
export default QuotationView;

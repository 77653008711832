import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Table,
  Input,
  Form,
  DatePicker,
  TimePicker,
  notification,
} from "antd";
import { v4 as uuidv4 } from "uuid";
import { DeleteOutlined } from "@ant-design/icons";
import { CiSquarePlus } from "react-icons/ci";
import TextArea from "antd/es/input/TextArea";
import { blformService } from "../../../services";
import { useParams } from "react-router-dom";
import moment from "moment";

function Edit() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [blForm, setblForm] = useState([]);
  const [particulars, setParticulars] = useState([]);

  const handleInputChange = (e, index, field) => {
    const value = e.target.value;
    const updatedData = [...particulars]; // Assuming `data` is the current table data
    updatedData[index][field] = value; // Update the corresponding field
    setParticulars(updatedData); // Assuming `setData` is the function that updates the state
  };

  const columns = [
    {
      title:
        "Exporter (Principal or seller-licensee and address including ZIP Code)",
      dataIndex: "exporter_info",
      key: "exporter_info",
      width: 200,
      render: (text, record, index) => (
        <div>
          <Input
            placeholder="Shipper"
            value={record.shipper || ""}
            onChange={(e) => handleInputChange(e, index, "shipper")}
            style={{ width: "100%", marginBottom: "8px" }}
          />
          <Input
            placeholder="Shipper Email"
            value={record.shipper_email || ""}
            onChange={(e) => handleInputChange(e, index, "shipper_email")}
            style={{ width: "100%", marginBottom: "8px" }}
          />
          <TextArea
            placeholder="Shipper Address"
            value={record.shipper_address || ""}
            onChange={(e) => handleInputChange(e, index, "shipper_address")}
            style={{ width: "100%", marginBottom: "8px" }}
          />
          <Input
            placeholder="Shipper Phone"
            value={record.shipper_phone || ""}
            onChange={(e) => handleInputChange(e, index, "shipper_phone")}
            style={{ width: "100%" }}
          />
        </div>
      ),
    },
    {
      title: "Number of Packages",
      dataIndex: "quantity",
      key: "quantity",
      width: 200,
      render: (text, record, index) => (
        <Input
          value={record.quantity || ""}
          onChange={(e) => handleInputChange(e, index, "quantity")}
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Description of Commodities in Schedule B Detail",
      dataIndex: "description",
      key: "description",
      width: 200,
      render: (text, record, index) => (
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <TextArea
            value={record.description || ""}
            onChange={(e) => handleInputChange(e, index, "description")}
            placeholder="Description"
            style={{ marginBottom: "8px" }}
          />
          <Input
            value={record.itn_number || ""}
            onChange={(e) => handleInputChange(e, index, "itn_number")}
            placeholder="ITN Number"
            style={{ marginBottom: "8px" }}
          />
          <Input
            value={record.value || ""}
            onChange={(e) => handleInputChange(e, index, "value")}
            placeholder="Value"
            style={{ marginBottom: "8px" }}
          />
        </div>
      ),
    },
    {
      title: "Gross Weight",
      dataIndex: "weight",
      key: "weight",
      width: 200,
      render: (text, record, index) => (
        <Input
          value={record.weight || ""}
          onChange={(e) => handleInputChange(e, index, "weight")}
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Measurement",
      dataIndex: "measurements",
      key: "measurements",
      width: 200,
      render: (text, record, index) => (
        <Input
          value={record.measurements || ""}
          onChange={(e) => handleInputChange(e, index, "measurements")}
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Delete",
      key: "Delete",
      render: (text, record, index) => {
        if (particulars.length === 1) return null;
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleDeleteRow(record.id)}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];

  const handleAddRow = () => {
    setParticulars([
      ...particulars,
      {
        id: uuidv4(),
        shipper: "",
        shipper_address: "",
        shipper_email: "",
        shipper_phone: "",
        quantity: "",
        description: "",
        itn_number: "",
        value: "",
        weight: "",
        measurements: "",
      },
    ]);
  };

  const handleDeleteRow = (id) => {
    setParticulars(particulars.filter((item) => item.id !== id));
  };

  const onFinish = async (values) => {
    setLoading(true)
    const data = {
      ...values,
      arrived_date: values.arrived_date
        ? values.arrived_date.format("YYYY-MM-DD")
        : null,
      arrived_time: values.arrived_time
        ? values.arrived_time.format("HH:mm")
        : null,
      unloaded_date: values.unloaded_date
        ? values.unloaded_date.format("YYYY-MM-DD")
        : null,
      unloaded_time: values.unloaded_time
        ? values.unloaded_time.format("HH:mm")
        : null,
      receiving_date: values.receiving_date
        ? values.receiving_date.format("YYYY-MM-DD")
        : null,
      shipper_id: particulars.map((item) => {
        const id = String(item.id);
        const isUUID = id.includes("-");
        return isUUID ? "" : item.id;
      }),
      shipper: particulars.map((item) => item.shipper),
      shipper_address: particulars.map((item) => item.shipper_address),
      shipper_email: particulars.map((item) => item.shipper_email),
      shipper_phone: particulars.map((item) => item.shipper_phone),
      quantity: particulars.map((item) => item.quantity),
      description: particulars.map((item) => item.description),
      weight: particulars.map((item) => item.weight),
      measurements: particulars.map((item) => item.measurements),
      itn_number: particulars.map((item) => item.itn_number),
      value: particulars.map((item) => item.value),
    };
    const response = await blformService.updateForm(id, data);
    setLoading(false)
    if(response.success){
      notification.success({
        message: "Successfully Updated!",
        description: response.message,
        placement:"topRight"
      })
    }
  };

  const initialize = async () => {
    const { success, data } = await blformService.view(id);
    if (success) {
      setblForm(data);
      form.setFieldsValue({
        ...data.form,
        arrived_date:
          data.form.arrived_date && data.form.arrived_date !== "0000-00-00"
            ? moment(data.form.arrived_date)
            : null,
        arrived_time:
          data?.form?.arrived_time && data.form.arrived_time !== "00:00:00"
            ? moment(data.form.arrived_time, "HH:mm:ss") // valid time format
            : data?.form?.arrived_time === "00:00:00"
            ? null
            : moment("00:00:00", "HH:mm:ss"), // default to 12:00 AM

        unloaded_date:
          data.form.unloaded_date && data.form.unloaded_date !== "0000-00-00"
            ? moment(data.form.unloaded_date)
            : null,
        unloaded_time:
          data?.form?.unloaded_time && data?.form?.unloaded_time !== "00:00:00"
            ? moment(data?.form?.unloaded_time, "HH:mm:ss")
            : data?.form?.unloaded_time === "00:00:00"
            ? null // valid time format
            : moment("00:00:00", "HH:mm:ss"), // default to 12:00 AM
        receiving_date:
          data.form.receiving_date && data.form.receiving_date !== "0000-00-00"
            ? moment(data.form.receiving_date)
            : null,
      });
      setParticulars(data.particulars);
    }
  };

  useEffect(() => {
    initialize();
  }, []);
  return (
    <>
      <Row gutter={24} wrap={false}>
        <Col flex="280px">
          <div
            style={{
              paddingTop: "8px",
              paddingRight: "8px",
              paddingLeft: 8,
              border: "1px solid #ccc",
              borderRadius: 5,
              backgroundColor: "#FFFFFF",
            }}
          >
            <Row gutter={24} style={{ padding: 10 }}>
              <Col xs={24} sm={24} lg={24} className="mb-4">
                <h1 style={{ fontWeight: "bolder" }}>
                  Exporter (Principal or seller-licensee and address including
                  ZIP Code)
                </h1>
                <p className="mt-5">
                  US TRANS LLC / CTRADE USA INC KAREN MNOIAN/CTRADE USA INC
                  GLOBAL TRANS LLC / CTRADE USA INC
                </p>
              </Col>
              <Col xs={24} sm={24} lg={24}>
                <h1 style={{ fontWeight: "bolder" }}>
                  Document No. (Carrier Booking No.)
                </h1>
                <p className="mt-5">69314970</p>
              </Col>
            </Row>
          </div>
        </Col>
        <Col flex="1">
          <div
            style={{
              padding: "15px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Form
              requiredMark={false}
              layout="vertical"
              form={form}
              onFinish={onFinish}
              labelCol={{ flex: "50px" }} // Allocate a fixed width for labels
              wrapperCol={{ flex: "1" }}
            >
              <Row gutter={24} style={{ padding: 20 }}>
                <Col span={8}>
                  <Form.Item label="Consigned To:" name="consignee">
                    <TextArea placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Notify Party / Intermediate Consignee (Name and Address):"
                    name="Notify Party"
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Bill of Loading No" name="loading_no">
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Export References "
                    name="export_references"
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Reference No" name="reference_no">
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Forwarding Agent (Name and Address)"
                    name="agent"
                  >
                    <TextArea placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Point (State) or Origin or FTZ Number "
                    name="point"
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Also Notify and/or Additional Routing Instructions"
                    name="party_instructions"
                  >
                    <TextArea placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Loading Pier / Terminal"
                    name="loading_pier_terminal"
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Type of Move" name="type_of_movement">
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Containerized(Vessel only) "
                    name="containerized"
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Pre-Carriage By" name="Pre-Carriage By">
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Place of Receipt by Pre-Carrier "
                    name="carriage"
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Exporting Carrier" name="exporting_carrier">
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Port of Loading/Export " name="port">
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Foreign Port of Unloading (vessel and air only)"
                    name="discharge"
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Place of Delivery by On-Carrier"
                    name="place_of_delivery"
                  >
                    <Input placeholder="" />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                gutter={24}
                style={{ margin: "6px", backgroundColor: "#FBFBFB" }}
              >
                <Col span={24}>
                  <div className="p-2" style={{ border: "1px solid #ccc" }}>
                    <h1>Particulars Furnished by Shipper Add Shipper</h1>
                  </div>
                  <div style={{ border: "1px solid #ccc" }}>
                    <Table
                      columns={columns}
                      dataSource={particulars}
                      scroll={{ x: "max-content" }}
                      pagination={false}
                      style={{ marginTop: "5px" }}
                    />
                  </div>
                  <div
                    style={{ marginTop: 20 }}
                    className="flex flex-col justify-center items-center"
                  >
                    <CiSquarePlus
                      style={{ cursor: "pointer", fontSize: "25px" }}
                      onClick={handleAddRow}
                    />
                  </div>
                </Col>
              </Row>

              <Row gutter={24} style={{ padding: 20 }}>
                <h1
                  style={{ fontWeight: "bolder", fontSize: "14px" }}
                  className="mt-5 ml-3 mb-5"
                >
                  Marks and Numbers
                </h1>

                <Col span={24}>
                  <Form.Item label="CONTAINER NO:" name="container_nos">
                    <Input placeholder="" style={{ width: "35%" }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="SEAL:" name="seal_nos">
                    <Input placeholder="" style={{ width: "35%" }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="" name="note_1">
                    <TextArea
                      placeholder="This is to certify that all the vehicles inside this container have been drained of fuel and run until stalled.
Batteries have been disconnected and cables taped back to prevent movement & electrical short."
                      rows={4}
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="" name="note_2">
                    <TextArea placeholder="Special Note" rows={4} />
                  </Form.Item>
                </Col>

                <h1
                  style={{ fontWeight: "bolder", fontSize: "14px" }}
                  className="mt-5 ml-3 mb-5"
                >
                  Delivered By:
                </h1>

                <Col span={24}>
                  <Form.Item label="LIGHER TRUCK" name="lighter_truck">
                    <Input placeholder="" style={{ width: "35%" }} />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item label="ARRIVED DATE:" name="arrived_date">
                    <DatePicker
                      placeholder="mm/dd/yyyy"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item label="ARRIVED - TIME" name="arrived_time">
                    <TimePicker
                      placeholder="12:00 AM"
                      style={{ width: "100%" }}
                      format="hh:mm A" // 12-hour format with AM/PM
                    />
                  </Form.Item>
                </Col>

                <Col span={10}>
                  <Form.Item label="UNLOADED - DATE" name="unloaded_date">
                    <DatePicker
                      placeholder="mm/dd/yyyy"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item label="UNLOADED - TIME" name="unloaded_time">
                    <TimePicker
                      placeholder="12:00 AM"
                      style={{ width: "100%" }}
                      format="hh:mm A" // 12-hour format with AM/PM
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="CHECKED BY" name="checked_by">
                    <Input placeholder="" style={{ width: "35%" }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="PLACED IN SHIP LOCATION"
                    name="place_in_ship_location"
                  >
                    <Input placeholder="" style={{ width: "35%" }} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="" name="SEAL">
                    <p
                      style={{
                        border: "1px solid #ccc",
                        padding: "12px",
                        borderRadius: "20px",
                      }}
                    >
                      RECEIVED THE ABOVE DESCRIBED GOODS OR PACKAGES SUBJECT TO
                      ALL THE TERMS OF THE UNDERSIGNED'S REGULAR FORM OF DOCK
                      RECEIPT AND BILL OF LADING WHICH SHALL CONSTITUTE THE
                      CONTRACT UNDER WHICH THE GOODS ARE RECEIVED, COPIES OF
                      WHICH ARE AVAILABLE FROM THE CARRIER ON REQUEST AND MAY BE
                      INSPECTED AT ANY OF ITS OFFICES.
                    </p>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <h1 style={{ fontSize: "14px" }}>FOR THE MASTER</h1>
                  <Form.Item label="BY" name="receiving_clerk">
                    <Input
                      placeholder="Receiving Clerk"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <h1 style={{ fontSize: "14px" }}>RECEIVING CLERK</h1>
                  <Form.Item label="DATE" name="receiving_date">
                    <DatePicker
                      placeholder="mm/dd/yyyy"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                gutter={24}
                style={{
                  marginTop: 40,
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "20px",
                }}
              >
                <Col span={6}>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={loading}
                    style={{ width: "100%", borderRadius: "16px" }}
                  >
                    Update - Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default Edit;

import ajaxService from "./ajax-service";

class PaymentMode {
  async fetch() {
    const response = await ajaxService.get("/payment-modes");
    if (response ?? false) {
      return response;
    }
  }
}

const paymentModeService = new PaymentMode();
export default paymentModeService;

import ajaxService from "./ajax-service";

class PNLService {
  async fetch(page, data) {
    const response = await ajaxService.post(
      `/accounts/profit-loss?page=${page}`,
      data
    );
    if (response ?? false) {
      return response;
    }
  }
}

const pnlService = new PNLService();
export default pnlService;

import React, { useEffect, useState } from "react";
import {
  Table,
  Row,
  Col,
  Select,
  Form,
  DatePicker,
  Space,
  Input,
  Button,
  Modal,
  Typography,
} from "antd";
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";
import { PiMicrosoftExcelLogo } from "react-icons/pi";
import { LiaFilePdfSolid } from "react-icons/lia";
import { RiFileCopyLine } from "react-icons/ri";
import { AiOutlinePrinter } from "react-icons/ai";
import { FaRegFile } from "react-icons/fa";
import { dropdownService, reportService } from "../../../services";
const { Title } = Typography;
function CustomerAgingReport() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [date, setDate] = useState(0);
  const [details, setDetails] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [isModalShow, setIsModalShow] = useState(false);

  const showModal = (record) => {
    fetchDetails(record);
    setIsModalShow(true);
  };

  const handleOk = () => {
    setIsModalShow(false);
  };
  const handleCancel = () => {
    setIsModalShow(false);
  };
  const columns = [
    {
      title: "View",
      key: "View",
      render: (text, record) => (
        <Button type="primary" size="small" onClick={() => showModal(record)}>
          <EyeOutlined />
        </Button>
      ),
    },
    {
      title: "Customers",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Total Recieveables$",
      dataIndex: "total_receivables",
      key: "total_receivables",
      render: (text) => text.toLocaleString(),
    },
    {
      title: "Due_Recieveables$",
      dataIndex: "total_due",
      key: "total_due",
      render: (text) => text.toLocaleString(),
    },
    {
      title: "1-15 DAYS",
      dataIndex: "due_1_15_days",
      key: "due_1_15_days",
      render: (text) => text.toLocaleString(),
    },
    {
      title: "16-30 DAYS",
      dataIndex: "due_16_30_days",
      key: "due_16_30_days",
      render: (text) => text.toLocaleString(),
    },
    {
      title: "31-45 DAYS",
      dataIndex: "due_31_45_days",
      key: "due_31_45_days",
      render: (text) => text.toLocaleString(),
    },
    {
      title: "45-60 DAYS",
      dataIndex: "due_46_60_days",
      key: "due_46_60_days",
      render: (text) => text.toLocaleString(),
    },
    {
      title: "1-15 DAYS",
      dataIndex: "due_61_90_days",
      key: "_DAYS5",
      render: (text) => text.toLocaleString(),
    },
    {
      title: "91+ DAYS",
      dataIndex: "due_91_days_plus",
      key: "_DAYS6",
      render: (text) => text.toLocaleString(),
    },
  ];
  const columns2 = [
    {
      title: "Invoices$",
      dataIndex: "invoices",
      key: "invoices",
    },
    {
      title: "Recieveables$",
      dataIndex: "receivable",
      key: "receivable",
    },
    {
      title: "Due_Recieveables$",
      dataIndex: "total_due",
      key: "total_due",
    },
    {
      title: "1-15 DAYS",
      dataIndex: "due_1_15_days",
      key: "due_1_15_days",
    },
    {
      title: "16-30 DAYS",
      dataIndex: "due_16_30_days",
      key: "due_16_30_days",
    },
    {
      title: "31-45 DAYS",
      dataIndex: "due_31_45_days",
      key: "due_31_45_days",
    },
    {
      title: "45-60 DAYS",
      dataIndex: "due_46_60_days",
      key: "due_46_60_days",
    },
    {
      title: "1-15 DAYS",
      dataIndex: "due_61_90_days",
      key: "due_61_90_days",
    },
    {
      title: "91+ DAYS",
      dataIndex: "due_91_days_plus",
      key: "due_91_days_plus",
    },
  ];
  const data2 = [
    {
      Invoices: "4993",
      Recieveables: "5,470",
      Due_Recieveables$: "4,637",
      _DAYS1: "0",
      _DAYS2: "0",
      _DAYS3: "0",
      _DAYS4: "0",
      _DAYS5: "0",
      _DAYS6: "0",
    },
    {
      Invoices: "4993",
      Recieveables: "5,470",
      Due_Recieveables$: "4,637",
      _DAYS1: "0",
      _DAYS2: "0",
      _DAYS3: "0",
      _DAYS4: "0",
      _DAYS5: "0",
      _DAYS6: "0",
    },
    {
      Invoices: "4993",
      Recieveables: "5,470",
      Due_Recieveables$: "4,637",
      _DAYS1: "0",
      _DAYS2: "0",
      _DAYS3: "0",
      _DAYS4: "0",
      _DAYS5: "0",
      _DAYS6: "0",
    },
    {
      Invoices: "4993",
      Recieveables: "5,470",
      Due_Recieveables$: "4,637",
      _DAYS1: "0",
      _DAYS2: "0",
      _DAYS3: "0",
      _DAYS4: "0",
      _DAYS5: "0",
      _DAYS6: "0",
    },
    {
      Invoices: "4993",
      Recieveables: "5,470",
      Due_Recieveables$: "4,637",
      _DAYS1: "0",
      _DAYS2: "0",
      _DAYS3: "0",
      _DAYS4: "0",
      _DAYS5: "0",
      _DAYS6: "0",
    },
    {
      Invoices: "4993",
      Recieveables: "5,470",
      Due_Recieveables$: "4,637",
      _DAYS1: "0",
      _DAYS2: "0",
      _DAYS3: "0",
      _DAYS4: "0",
      _DAYS5: "0",
      _DAYS6: "0",
    },
  ];

  const onFinish = (values) => {
    setDate(values.till_date || 0);
    const payload = Object.fromEntries(
      Object.entries({
        ...values,
        till_date: values.from_date?.format("YYYY-MM-DD"),
        user_id: values.to_date?.format("YYYY-MM-DD"),
      }).filter(([_, value]) => value != null)
    );
    initialize(payload);
  };
  const initialize = async (payload = null) => {
    setLoading(true);
    const { success, data } = await reportService.CustomerAging(payload);
    if (success) {
      setReports(data);
    }
    setLoading(false);
  };

  const getCustomers = async () => {
    const response = await dropdownService.fetchCustomers();
    if (response.success) {
      setCustomers(response.data);
    }
  };

  const fetchDetails = async (record) => {
    setLoading(true);
    const { success, data } = await reportService.CustomerAgingDetails(
      record.user_id,
      date
    );
    if (success) {
      setDetails(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCustomers();
    initialize();
  }, []);
  return (
    <>
      <Title level={5}>Customer Aging Report</Title>
      <Form
        requiredMark={false}
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={24} className="mt-8">
          <Col span={8}>
            <Form.Item label="Till date" name="till_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Customers" name="user_id">
              <Select placeholder="" showSearch optionFilterProp="children">
                {customers.map((cust) => (
                  <Select.Option key={cust.id} value={cust.id}>
                    {cust.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={2}>
            <Form.Item label={<></>}>
              <Button type="primary">
                <SearchOutlined style={{ fontSize: "15px" }} />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Col span={24}>
        <Row
          gutter={0}
          style={{
            padding: "8px",
            marginTop: "2px",
            background: "#F2F2F7",
            borderRadius: "4px",
          }}
          className="flex justify-between"
        >
          <Col span={12}>
            <div className="flex">
              <Button type="text" size="small" style={{ padding: "4px" }}>
                <AiOutlinePrinter style={{ fontSize: "24px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <FaRegFile style={{ fontSize: "20px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <PiMicrosoftExcelLogo style={{ fontSize: "24px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <LiaFilePdfSolid style={{ fontSize: "24px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <RiFileCopyLine style={{ fontSize: "24px" }} />
              </Button>
            </div>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              className="w-auto lg:w-2/5"
            />
          </Col>
        </Row>

        <div style={{ padding: "8px", marginTop: "2px", textAlign: "center" }}>
          <p>AMOUNT DUE</p>
        </div>

        <Table
          columns={columns}
          scroll={{ x: "max-content" }}
          pagination={false}
          loading={loading}
          dataSource={reports.userAgingReport}
          style={{ marginTop: "5px" }}
          summary={() => (
            <Table.Summary.Row className="font-bold bg-[#B83D3D] text-white">
              <Table.Summary.Cell></Table.Summary.Cell>
              <Table.Summary.Cell>Total:</Table.Summary.Cell>
              <Table.Summary.Cell>
                ${reports?.totals?.total_receivables?.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${reports?.totals?.total_due?.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${reports?.totals?.due_1_15_days?.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${reports?.totals?.due_16_30_days?.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${reports?.totals?.due_31_45_days?.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${reports?.totals?.due_46_60_days?.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${reports?.totals?.due_61_90_days?.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${reports?.totals?.due_91_days_plus?.toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </Col>

      <Modal
        title={
          <div style={{ width: "100%" }}>
            {" "}
            <div
              style={{
                borderTop: "1px solid #ccc",
                marginTop: "15px",
                marginBottom: "12px",
              }}
            ></div>
          </div>
        }
        open={isModalShow}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        width={950}
        style={{ padding: "8px" }}
      >
        <Col span={24} className="mt-2 mb-8">
          <h1 style={{ fontWeight: "bold" }}>AJEEB OVERSEAS AGING REPORT</h1>
        </Col>
        <Row
          gutter={0}
          style={{
            padding: "8px",
            marginTop: "2px",
            background: "#F2F2F7",
            borderRadius: "4px",
          }}
          className="flex justify-between"
        >
          <Col span={12}>
            <div className="flex">
              <Button type="text" size="small" style={{ padding: "4px" }}>
                <AiOutlinePrinter style={{ fontSize: "24px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <FaRegFile style={{ fontSize: "20px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <PiMicrosoftExcelLogo style={{ fontSize: "24px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <LiaFilePdfSolid style={{ fontSize: "24px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <RiFileCopyLine style={{ fontSize: "24px" }} />
              </Button>
            </div>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              className="w-auto lg:w-2/5"
            />
          </Col>
        </Row>

        <div style={{ padding: "8px", marginTop: "2px", textAlign: "center" }}>
          <p>AMOUNT DUE</p>
        </div>

        <Table
          columns={columns2}
          scroll={{ x: "max-content" }}
          dataSource={details.invoiceDetails}
          style={{ marginTop: "5px" }}
          summary={() => (
            <Table.Summary.Row className="font-bold bg-[#B83D3D] text-white text-left">
              <Table.Summary.Cell>Total:</Table.Summary.Cell>
              <Table.Summary.Cell>
                ${details?.totals?.total_receivables?.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${details?.totals?.total_due?.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${details?.totals?.due_1_15_days?.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${details?.totals?.due_16_30_days?.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${details?.totals?.due_31_45_days?.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${details?.totals?.due_46_60_days?.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${details?.totals?.due_61_90_days?.toLocaleString()}
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                ${details?.totals?.due_91_days_plus?.toLocaleString()}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          )}
        />
      </Modal>
    </>
  );
}

export default CustomerAgingReport;

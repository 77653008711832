import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, DatePicker, Table, Space, Tag, Button, } from "antd";
import { PiMicrosoftExcelLogo } from "react-icons/pi";
import { LiaFilePdfSolid } from "react-icons/lia";
import { RiFileCopyLine } from "react-icons/ri";
import { AiOutlinePrinter } from 'react-icons/ai';
import { FaRegFile } from "react-icons/fa";
import { Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { dropdownService, inventoryReportService } from "../../../services";
import { BsFillSendFill } from "react-icons/bs";

function InventoryReport() {
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [form] = Form.useForm();

  const vehicleStatus = [
    {
      id: "1",
      name: "AT YARD",
    },
    {
      id: "2",
      name: "LOADED",
    },
    {
      id: "3",
      name: "IN LOAD PLAN",
    },
    {
      id: "4",
      name: "RETURNED",
    },
  ];
  const vehicleTitle = [
    {
      id: "1",
      name: "Yes",
    },
    {
      id: "2",
      name: "No",
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Car Status",
      dataIndex: "Car Status",
      key: "Car Status",
      render: (text) => (
        <Space>
          <Tag>{text}</Tag>
        </Space>
      ),
    },
    {
      title: "Recieved Date",
      dataIndex: "Received Date",
      key: "Received Date",
    },
    {
      title: "Vehicles",
      dataIndex: "Vehicle",
      key: "Vehicle",
    },
    {
      title: "Vin#",
      dataIndex: "Vin #",
      key: "Vin #",
    },
    {
      title: "Booking#",
      dataIndex: "Booking #",
      key: "Booking #",
    },
    {
      title: "Key",
      dataIndex: "Key",
      key: "Key",
    },
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
    },
    {
      title: "Storage Days",
      dataIndex: "Storage Days",
      key: "Storage Days",
    },
  ];

  const onFinish = (values) => {
    const data = {
      ...values,
      from_date: values.from_date
        ? values.from_date.format("YYYY-MM-DD")
        : null,
      to_date: values.to_date ? values.from_date.format("YYYY-MM-DD") : null,
      status: values.status ? values.status : null,
      title: values.title ? values.title : null,
    };
    // Remove keys with undefined or null values
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value != null)
    );
    initialize(pagination.current, pagination.pageSize, data);
  };

  const sendMail = () => {
    console.log("Mail sent");
  };

  const initialize = async (
    page = 1,
    pageSize = pagination.current,
    data = null
  ) => {
    setLoading(true);
    const { success, data: Repdata } = await inventoryReportService.fetch(
      page,
      data
    );
    if (success) {
      setReport(Repdata.data);
      setPagination((prev) => ({
        ...prev,
        total: Repdata.pagination.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };

  const getCustomers = async () => {
    const response = await dropdownService.fetchCustomers();
    if (response.success) {
      const filteredCustomers = response.data.filter(
        (cust) => cust.isActive === 1
      );
      setCustomers(filteredCustomers);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <div
      style={{
        padding: "0 14px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Form
        requiredMark={false}
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={24} className="mt-4">
          <Col span={24} className="mb-4">
            <h1 style={{ fontWeight: "bolder", fontSize: "13px" }}>
              Select Date and Time
            </h1>
          </Col>

          <Col span={4}>
            <Form.Item
              label="Customers"
              name="user"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Select
                placeholder="Select User"
                showSearch
                optionFilterProp="children"
              >
                {customers?.map((cust) => (
                  <Select.Option key={cust.id} value={cust.id}>
                    {cust.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="From Receiving Date" name="from_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item label="To Receiving Date" name="to_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Vehicle Status"
              name="status"
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please input!",
            //     },
            //   ]}
            >
              <Select placeholder="Select Status">
                {vehicleStatus.map((status) => (
                  <Select.Option key={status.id} value={status.id}>
                    {status.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Vehicle Title"
              name="title"
            //   rules={[
            //     {
            //       required: true,
            //       message: "Please input!",
            //     },
            //   ]}
            >
              <Select placeholder="Select Title">
                {vehicleTitle.map((title) => (
                  <Select.Option key={title.id} value={title.id}>
                    {title.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={1}style={{ marginRight: '15px' }} >
            <Form.Item label={<></>}>
              <Button type="primary"
                htmlType="`submit">
                <SearchOutlined style={{ fontSize: '15px' }} /></Button>
            </Form.Item>
            </Col>
            <Col span={1} >
            <Form.Item label={<></>}>
              <Button onClick={sendMail} type="primary"> 
              <BsFillSendFill style={{ fontSize: '15px' }}  />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div
        style={{
          padding: "0 14px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF",
          marginBottom: "18px",
          marginTop: "20px",
        }}
      >
        <Col span={24}>
          <Row
            gutter={0}
            style={{ padding: "10px", borderRadius: "4px" }}
            className="flex justify-between "
          >
            <Col span={10}>
              <div className='flex' >
                <Button type='text' size='small' style={{ padding: '4px' }} >
                  <AiOutlinePrinter style={{ fontSize: "24px" }} />
                </Button>

                <Button type='text' size='small' style={{ padding: '4px' }}>
                  <FaRegFile style={{ fontSize: "20px" }} />
                </Button>

                <Button type='text' size='small' style={{ padding: '4px' }}>
                  <PiMicrosoftExcelLogo style={{ fontSize: "24px" }} />
                </Button>

                <Button type='text' size='small' style={{ padding: '4px' }}>
                  <LiaFilePdfSolid style={{ fontSize: "24px" }} />
                </Button>

                <Button type='text' size='small' style={{ padding: '4px' }}>
                  <RiFileCopyLine style={{ fontSize: "24px" }} />
                </Button>
              </div>
            </Col>
            <Col span={14} style={{ textAlign: "right" }}>
              <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                style={{ width: 200 }}
              />
            </Col>
          </Row>

          <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <Table
              columns={columns}
              dataSource={report}
              loading={loading}
              pagination={{
                current: pagination.current,
                total: pagination.total,
                pageSize: pagination.pageSize,
                onChange: (page) => initialize(page, pagination.pageSize),
                showSizeChanger: false,
              }}
              rowKey="Id"
              style={{ marginTop: "5px", marginBottom: "10px" }}
            />
          </div>
        </Col>
      </div>
    </div>
  );
}

export default InventoryReport;

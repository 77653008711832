import ajaxService from "./ajax-service";
import { validationErrors } from "../utils";

class TowingTariffService {
  async fetch(page) {
    const response = await ajaxService.get(`/tariffs?page=${page}`);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async view(id) {
    const response = await ajaxService.get(`/tariffs/${id}`);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async addTariff(data) {
    const response = await ajaxService.post(`/tariffs/`, data);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async updateTariff(id, data) {
    const response = await ajaxService.put(`/tariffs/`, id, data);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async deleteTariff(id) {
    const response = await ajaxService.delete(`/tariffs/`, id);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
}

const towingTariffService = new TowingTariffService();

export default towingTariffService;

import ajaxService from "./ajax-service";

class InvoiceReceipt {
  async fetch(page) {
    const response = await ajaxService.get(`/receipt?page=${page}`);
    if (response.success) {
      return response;
    }
  }
  async addreceipt(data) {
    const response = await ajaxService.post(`/receipt/store`, data);
    if (response.success) {
      return response;
    }
  }
}

const invoiceReceiptService = new InvoiceReceipt();
export default invoiceReceiptService;

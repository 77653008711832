import React from 'react';
import { Col, Row, Table, } from 'antd';

function TodayLoadedContainer({load}) {
    console.log(load)
    const columns = [
        {
            title: "#",
            dataIndex: '#',
            key: '#',
            render: (text, record, index) => index + 1,
        },
        {
            title: "CAR STATUS",
            dataIndex: 'CAR_STATUS',
            key: 'CAR_STATUS',
        },
        {
            title: "BOOKING",
            dataIndex: "BOOKING",
            key: "BOOKING",
        },
        {
            title: "CONTAINER",
            dataIndex: "CONTAINER",
            key: "CONTAINER",
        },
        {
            title: "ERD DATE",
            dataIndex: "ERD_DATE",
            key: "ERD_DATE",

        }, {
            title: "VEHICLE",
            dataIndex: "VEHICLE",
            key: "VEHICLE",

        }, {
            title: "VIN NUMBER",
            dataIndex: "VIN_NUMBER",
            key: "VIN_NUMBER",
        },
        {
            title: "KEY",
            dataIndex: "KEY",
            key: "KEY",
        },
        {
            title: "TITLE",
            dataIndex: "TITLE",
            key: "TITLE",
        },
        {
            title: "BOS",
            dataIndex: "BOS",
            key: "BOS",
        },
    ]
    const data=[{}]
    return (
        <div
            style={{
                padding: '0 14px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#FFFFFF',
            }} >
            <Row
                gutter={0}
                style={{
                    marginTop: 10,
                    padding: '10px',       
                }}>
                <Col span={24}>
                    <h1 style={{ fontWeight: 'bolder' }}>TODAY'S LOADED CONTAINERS</h1>
                </Col>
            </Row>

            <Col span={24}>
                <Table
                    columns={columns}
                    scroll={{ x: "max-content" }}
                    dataSource={load}
                    pagination={false}
                    rowKey="Id"
                    style={{ marginTop: '5px' }} />
            </Col>

        </div >
    );
}

export default TodayLoadedContainer;

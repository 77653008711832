import React, { useState, useEffect } from "react";
import {Table,Space,Button,Row,Col,Input,Modal,notification,} from "antd";
import { EditOutlined, SearchOutlined, EyeOutlined, DeleteOutlined,} from "@ant-design/icons";
import TariffModalForm from "./ModalForm";
import { towingTariffService } from "../../../services";

function TowingTarf() {
  const [isModalshow, setIsModalShow] = useState();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [tariff, setTariff] = useState([]);
  const [selectedDeleteRecordId, setSelectedDeleteRecordId] = useState();
  const [tariffRecord, setTariffRecord] = useState();
  let recordId;

  const getTariff = async (id) => {
    // console.log(selectedRecord)
    const { success, data } = await towingTariffService.view(id);
    if (success ?? false) {
      setTariffRecord(data);
    }
  };
  const handleEdit = (record) => {
    setEditData(record);
    setModalVisible(true);
  };
  const handleModalClose = () => {
    initialize(pagination.current, pagination.pageSize);
    setModalVisible(false);
  };
  const showModalHandler = (record) => {
    setSelectedDeleteRecordId(record);
    setIsModalShow(true);
  };
  const handleOk = async () => {
    const response = await towingTariffService.deleteTariff(
      selectedDeleteRecordId
    );
    if (response.success) {
      notification.success({
        message: "Success",
        description: "Towing Tariff deleted successfully!",
        placement: "topRight",
      });
      setTariff((prevData) =>
        prevData.filter((item) => item.id !== selectedDeleteRecordId)
      );
    } else {
      const errors = response.data?.errors || [response.message];
      notification.error({
        message: "Error",
        description: `Error: ${errors.join(", ")}`,
        placement: "topRight",
      });
    }
    initialize(pagination.current, pagination.pageSize);
    setIsModalShow(false);
  };
  const handleCancel = () => {
    setIsModalShow(false);
  };
  const handleOpenModal = (id) => {
    // recordId = id
    getTariff(id);
    setIsModalVisible(true);
  };
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "#",
    //   key: "#   ",
    //   render: (text, record, index) =>
    //     (pagination.current - 1) * pagination.pageSize + index + 1,
    // },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Auction",
      dataIndex: "auctions",
      key: "Auction",
    },
    {
      title: "USD",
      dataIndex: "usd",
      key: "USD",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "State",
    },
    {
      title: "Code",
      dataIndex: "state_code",
      key: "Code",
    },
    {
      title: "Ports",
      dataIndex: "ports",
      key: "Ports",
    },
    {
      title: "Actions",
      key: "Actions",
      render: (text, record) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              handleEdit(record);
            }}
          >
            <EditOutlined />
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              handleOpenModal(record.id);
              // handleEdit(record);
            }}
          >
            <EyeOutlined />
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => showModalHandler(record.id)}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];
  const deleteModal = (
    <Modal
      title={<div style={{ textAlign: "center" }}>Confirm</div>}
      open={isModalshow}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      style={{
        padding: "20px", // Adjust padding for smaller size
      }}
      width={300} // Adjust modal width
      footer={
        <div className="flex justify-center gap-3">
          <Button
            type="default"
            onClick={handleCancel}
            style={{ width: "30%" }}
          >
            Cancel
          </Button>
          <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
            Confirm
          </Button>
        </div>
      }
    >
      <p style={{ textAlign: "center" }}>
        Are you sure you want to delete this?
      </p>
    </Modal>
  );

  const initialize = async (page = 1, pageSize = pagination.current) => {
    try {
      setLoading(true);

      const { success, data, message } = await towingTariffService.fetch(page);

      if (success) {
        if (data && data.data && data.data.length > 0) {
          setTariff(data.data);
          setPagination((prev) => ({
            ...prev,
            total: data.total,
            current: page,
            pageSize,
          }));
        } else {
          console.warn("No tariff data found.");
          setTariff([]);
        }
      } else {
        console.error(
          `Error: ${message || "Failed to fetch towing tariff data."}`
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize])
  
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row
        gutter={24}
        style={{ padding: "5px", borderRadius: "4px" }}
        className="flex justify-between"
      >
        <Col span={10}>
          <Space direction="horizontal" size={15}>
            <h5 className="font-bold">Tariff</h5>
          </Space>
        </Col>
        <Col span={14} style={{ textAlign: "right" }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={tariff}
        style={{ marginTop: "10px" }}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        loading={loading}
      />

      <TariffModalForm
        visible={modalVisible}
        onClose={handleModalClose}
        editData={editData}
        isEditMode={true}
      />
      {/* <EditTariff visible={isModaView} onClose={CloseModal} /> */}

      {/* view */}
      <Modal
        title={
          <div style={{ display: "flex", justifyContent: "center" }}>
            <span>View Tariff</span>
          </div>
        }
        open={isModalVisible}
        onCancel={handleCloseModal}
        style={{
          padding: "40px", // Adjust padding for smaller size
        }}
        width={450}
        footer={null}
        centered
      >
        <Row gutter={24}>
          <Col span={24}>
            <div
              style={{
                display: "flex",
                width: 320,
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                marginBottom: 9,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  backgroundColor: "#f5f5f5",
                  borderRight: "1px solid #d9d9d9",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                Auction:
              </div>
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                {tariffRecord?.auctions}
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                display: "flex",
                width: 320,
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                marginBottom: 9,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  backgroundColor: "#f5f5f5",
                  borderRight: "1px solid #d9d9d9",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                State:
              </div>
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                {tariffRecord?.state}
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                display: "flex",
                width: 320,
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                marginBottom: 9,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  backgroundColor: "#f5f5f5",
                  borderRight: "1px solid #d9d9d9",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                Ports:
              </div>
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                {tariffRecord?.ports}
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                display: "flex",
                width: 320,
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                marginBottom: 14,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  backgroundColor: "#f5f5f5",
                  borderRight: "1px solid #d9d9d9",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                USD:
              </div>
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                {tariffRecord?.usd}
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                display: "flex",
                width: 320,
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                marginBottom: 20,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  backgroundColor: "#f5f5f5",
                  borderRight: "1px solid #d9d9d9",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                Code: SC
              </div>
              <div
                style={{
                  flex: 1,
                  padding: "4px 11px",
                  display: "flex",
                  alignItems: "left",
                }}
              >
                {tariffRecord?.state_code}
              </div>
            </div>
          </Col>
        </Row>
        <div
          className="flex justify-center"
          style={{ borderTop: "1px solid #d9d9d9", paddingTop: "20px" }}
        >
          <Button type="primary" size="small">

          <EditOutlined style={{ cursor: "pointer", fontSize: "16px" }} />
          </Button>
        </div>
      </Modal>

      {/* delete */}
      {deleteModal}
      {/* <FormModal visible={visible} onClose={onClose} /> */}
    </div>
  );
}
export default TowingTarf;

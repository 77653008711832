import ajaxService from "./ajax-service";

class VendorContainerPayment {
  async fetch(page, data) {
    const response = await ajaxService.post(
      `/vendors-container-payment?page=${page}`,
      data
    );
    if (response ?? false) {
      return response;
    }
  }
}

const VCPaymentService = new VendorContainerPayment();
export default VCPaymentService;

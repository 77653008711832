import ajaxService from "./ajax-service";

class IMOService {
  async fetch(page) {
    const response = await ajaxService.get(`/imo?page=${page}`);
    if (response.success) {
      return response;
    }
  }
  async viewIMO(id) {
    const response = await ajaxService.get(`/imo/${id}`);
    if (response.success) {
      return response;
    }
  }
  async updateIMO(id, data) {
    const response = await ajaxService.put(`/imo/`, id, data);
    if (response.success) {
      return response;
    }
  }
}

const imoService = new IMOService();

export default imoService;

import React, { useState } from "react";
import {
  Table,
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Button,
  Typography,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { PiMicrosoftExcelLogo } from "react-icons/pi";
import { LiaFilePdfSolid } from "react-icons/lia";
import { RiFileCopyLine } from "react-icons/ri";
import { AiOutlinePrinter } from "react-icons/ai";
import { FaRegFile } from "react-icons/fa";
import { reportService } from "../../../services";

const { Title } = Typography;
function LoadPlan() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadPlan, setLoadPlan] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [payload, setPayload] = useState(null);

  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
    },
    {
      title: "Container ID ",
      dataIndex: "Container Id",
      key: "Container Id",
    },
    {
      title: "Container Number",
      dataIndex: "Container Number",
      key: "Container Number",
    },
    {
      title: "Location",
      dataIndex: "Location",
      key: "Location",
    },

    {
      title: "Pullout Date",
      dataIndex: "Pullout Date",
      key: "Pullout Date",
    },
    {
      title: "GateIn Date",
      dataIndex: "GateIn Date",
      key: "GateIn Date",
    },
  ];
  const onFinish = (values) => {
    setPayload({
      from_date: values.from_date.format("YYYY-MM-DD"),
      to_date: values.to_date.format("YYYY-MM-DD"),
    });
    const payload = {
      from_date: values.from_date.format("YYYY-MM-DD"),
      to_date: values.to_date.format("YYYY-MM-DD"),
    };
    console.log(payload);
    initialize(pagination.current, pagination.pageSize, payload);
  };

  const initialize = async (
    page = 1,
    pageSize = pagination.pageSize,
    payload = null
  ) => {
    setLoading(true);
    const { success, data } = await reportService.LoadPlan(page, payload);
    setLoading(false);
    if (success) {
      setLoadPlan(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.pagination.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };
  return (
    <>
      <Title level={5}>Load Plan</Title>
      <Form
        requiredMark={false}
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={24} className="mt-8">
          <Col span={8}>
            <Form.Item label="From date" name="from_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="To date" name="to_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Form.Item label={<></>}>
              <Button type="primary" htmlType="submit">
                <SearchOutlined style={{ fontSize: "15px" }} />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Col span={24}>
        <Row
          gutter={0}
          style={{
            padding: "8px",
            marginTop: "2px",
            background: "#F2F2F7",
            borderRadius: "4px",
          }}
          className="flex justify-between"
        >
          <Col span={12}>
            <div className="flex">
              <Button type="text" size="small" style={{ padding: "4px" }}>
                <AiOutlinePrinter style={{ fontSize: "24px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <FaRegFile style={{ fontSize: "20px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <PiMicrosoftExcelLogo style={{ fontSize: "24px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <LiaFilePdfSolid style={{ fontSize: "24px" }} />
              </Button>

              <Button type="text" size="small" style={{ padding: "4px" }}>
                <RiFileCopyLine style={{ fontSize: "24px" }} />
              </Button>
            </div>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              className="w-auto lg:w-2/5"
            />
          </Col>
        </Row>
        <Table
          columns={columns}
          scroll={{ x: "max-content" }}
          pagination={{
            current: pagination.current,
            total: pagination.total,
            pageSize: pagination.pageSize,
            onChange: (page) => initialize(page, pagination.pageSize, payload),
            showSizeChanger: false,
          }}
          loading={loading}
          dataSource={loadPlan}
          style={{ marginTop: "10px" }}
        />
      </Col>
    </>
  );
}

export default LoadPlan;

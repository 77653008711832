import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Button,
  Row,
  Col,
  Input,
  Modal,
  notification,
} from "antd";
import {
  EditOutlined,
  SearchOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { vendorService } from "../../../services";

function Vendors() {
  const navigate = useNavigate();
  const editVendor = (id) => navigate(`/operation/vendors/form/${id}`);
  const [isModalshow, setIsModalShow] = useState();
  const [vendors, setVendors] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState(vendors);
  const [loading, setLoading] = useState(false);
  const [selectedDeleteRecord, setSelectedDeleteRecord] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [searchTerm, setSearchTerm] = useState("");

  const showModalHandler = (record) => {
    setSelectedDeleteRecord(record);
    setIsModalShow(true);
  };
  const handleCancel = () => {
    setIsModalShow(false);
  };
  const initialize = async (page = 1, pageSize = pagination.pageSize) => {
    try {
      setLoading(true);

      const { success, data, message } = await vendorService.fetch(page);

      if (success) {
        setVendors(data.data);
        setFilteredVendors(data.data);
        setPagination((prev) => ({
          ...prev,
          total: data.total,
          current: page,
          pageSize,
        }));
      } else {
        console.error(`Error fetching vendors: ${message || "Unknown error"}`);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    const filteredData = vendors.filter((vendor) =>
      vendor.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredVendors(filteredData);
  };
  const handleOk = async () => {
    if (!selectedDeleteRecord) return;
    const response = await vendorService.deleteVendor(selectedDeleteRecord.id);
    if (response?.success) {
      setVendors((prevData) =>
        prevData.filter((item) => item.id !== selectedDeleteRecord.id)
      );
      initialize();
      notification.success({
        message: "Success",
        description: "Vendor deleted successfully!",
        placement: "topRight",
      });
    } else {
      const errors = response.data?.errors || [response.message];
      notification.error({
        message: "Error",
        description: `Error: ${errors.join(", ")}`,
        placement: "topRight",
      });
    }

    setIsModalShow(false);
  };
  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "#",
    //   key: "#   ",
    //   render: (text, record, index) =>
    //     (pagination.current - 1) * pagination.pageSize + index + 1,
    // },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      render: (text) => <p>{text ? text : "-"}</p>,
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      render: (text) => <p>{text ? text : "-"}</p>,
    },
    {
      title: "Actions",
      key: "Actions",
      render: (text, record) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            onClick={() => editVendor(record.id)}
          >
            <EditOutlined />
          </Button>

          <Link to={`/operation/vendors/view/${record.id}`}>
            <Button type="primary" size="small">
              <EyeOutlined />
            </Button>
          </Link>

          <Button
            type="primary"
            size="small"
            onClick={() => showModalHandler(record)}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row
        gutter={24}
        style={{ padding: "10px", borderRadius: "4px" }}
        className="flex justify-between"
      >
        <Col span={10}>
          <Space direction="horizontal" size={15}>
            <h5 className="font-bold"> Browse Vendors</h5>
          </Space>
        </Col>
        <Col span={14} style={{ textAlign: "right" }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
            value={searchTerm}
            onChange={handleSearch}
          />
        </Col>
      </Row>

      <Modal
        title={<div style={{ textAlign: "center" }}>Confirm</div>}
        open={isModalshow}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        style={{
          padding: "20px", // Adjust padding for smaller size
        }}
        width={300} // Adjust modal width
        footer={
          <div className="flex justify-center gap-3">
            <Button
              type="default"
              onClick={handleCancel}
              style={{ width: "30%" }}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: "center" }}>
          Are you sure you want to delete this?
        </p>
      </Modal>

      <Table
        columns={columns}
        dataSource={filteredVendors}
        loading={loading}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
        }}
        rowKey="id"
        style={{ marginTop: "10px" }}
      />
    </div>
  );
}
export default Vendors;

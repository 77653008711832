import { Table, Space, Button, Row, Col, Input, Select } from "antd";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { costingExpenseService } from "../../../../../services";

function Coasting() {
  const [loading, setLoading] = useState(false);
  const [costing, setCosting] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const options = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "9",
      label: "9",
    },
  ];
  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Total Invoice Amount",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Total Transport Charges",
      dataIndex: "total_transportation_charges",
      key: "total_transportation_charges",
    },
    {
      title: "Total Labour Charges",
      dataIndex: "total_labour_charges",
      key: "total_labour_charges",
    },
    {
      title: "Total Material Charges",
      dataIndex: "total_material_charges",
      key: "total_material_charges",
    },
    {
      title: "Total/ Extra Buying Charges",
      dataIndex: "buying_charges",
      key: "buying_charges",
    },
    {
      title: "Total Selling Charges",
      dataIndex: "selling_charges",
      key: "selling_charges",
    },

    {
      title: "Action",
      key: "Action",
      fixed: "right",
      render: (text, record, { _id }) => (
        <Link to={`/accounts/coasting/edit/${record.id}`}>
          <Button type="primary" size="small">
            <EditOutlined />
          </Button>
        </Link>
      ),
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    const { success, data } = await costingExpenseService.fetch(page);
    if (success) {
      setCosting(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };
  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Col span={24}>
        <h1 style={{ fontWeight: "bold" }}>Costing Expenses</h1>
      </Col>

      <Divider style={{ marginTop: 15 }} />
      <Row
        gutter={8}
        style={{
          marginTop: "15px",
          background: "#F2F2F7",
          padding: "5px",

          alignItems: "center", // Ensures alignment of items within the row
        }}
      >
        <Col
          span={4}
          xs={24}
          sm={12}
          md={6}
          lg={4}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Space direction="horizontal" size={10}>
            <p style={{ margin: 0 }}>Show</p>
            <Select
              defaultValue={10}
              options={options}
              size="small"
              style={{ width: 70, fontSize: 14 }}
            />
            <p style={{ margin: 0 }}>entries</p>
          </Space>
        </Col>

        {/* Second Column: Right-Aligned Search */}
        <Col
          span={20}
          xs={24}
          sm={12}
          md={18}
          lg={20}
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{
              width: "30%",
              borderRadius: "4px",
              fontSize: 14,
            }}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={costing}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        loading={loading}
        style={{ marginTop: "10px" }}
      />
    </div>
  );
}
export default Coasting;

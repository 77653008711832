import React, { useState, useEffect } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select, Table, Checkbox, notification, } from "antd";
import { CiSquarePlus } from "react-icons/ci";
import { DeleteOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { containerService, destinationService, vendorQuotation, vendorService, } from "../../../services";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

export default function VendorQuotationForm() {
  const { id } = useParams(); // Get 'id' from the URL
  const [form] = Form.useForm();
  const [vendorQuotations, setVendorQuotations] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [containerSizes, setContainerSizes] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [isTableVisible, setIsTableVisible] = useState(false)
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "Id",
      render: (text, record, index) => index + 1,
    },

    {
      title: "Destination",
      dataIndex: "destination",
      key: "Destination",
      width: 200, 
      render: (text, record, index) => (
        <Select
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(value) => {
            const updatedDetails = [...vendorQuotations.details];
            updatedDetails[index].destination_id = value;
            setVendorQuotations((prev) => ({
              ...prev,
              details: updatedDetails,
            }));
          }}
        >
          {destinations.map((destination) => (
            <Select.Option key={destination.id} value={destination.id}>
              {destination.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },

    {
      title: "Container Size",
      dataIndex: "containersize_id",
      key: "Container Size",
      width: 200, 
      render: (text, record, index) => (
        <Select
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(value) => {
            const updatedDetails = [...vendorQuotations.details];
            updatedDetails[index].containersize_id = value;
            setVendorQuotations((prev) => ({
              ...prev,
              details: updatedDetails,
            }));
          }}
        >
          {containerSizes.map((containerSizes) => (
            <Select.Option key={containerSizes.id} value={containerSizes.id}>
              {containerSizes.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Fright Charges",
      dataIndex: "charges",
      key: "Fright Charges",
      width: 200, 
      render: (text, record, index) => (
        <Input
          value={text || ""}
          style={{ width: "100%" }}
          onChange={(e) => {
            const updatedDetails = [...vendorQuotations.details];
            updatedDetails[index].charges = e.target.value;
            setVendorQuotations((prev) => ({
              ...prev,
              details: updatedDetails,
            }));
          }}
        />
      ),
    },
    {
      title: "DG/NON-DG",
      dataIndex: "is_dg",
      key: "DG/NON-DG",
      render: (text, record, index) => (
        <Checkbox
          defaultChecked={text}
          onChange={(e) => {
            const updatedDetails = [...vendorQuotations.details];
            updatedDetails[index].is_dg = e.target.checked ? 1 : 0;
            setVendorQuotations((prev) => ({
              ...prev,
              details: updatedDetails,
            }));
          }}
        />
      ),
    },
    {
      title: "Delete",
      key: "Delete",
      render: (text, record, index) => {
        if (vendorQuotations.details.length === 1) return null;
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleDeleteRow(record.id)}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];
  const handleAddRow = () => {
    setVendorQuotations((prev) => ({
      ...prev,
      details: [
        ...(prev?.details || []),
        {
          id: uuidv4(),
          destination_id: null,
          containersize_id: null,
          charges: "",
          is_dg: false,
        },
      ],
    }));
    setIsTableVisible(true)
  };
  // console.log(quotations)
  const handleDeleteRow = (Id) => {
    setVendorQuotations((prev) => {
      const updatedDetails = (prev?.details || []).filter((item) => item.id !== Id);
      // Update table visibility based on the remaining rows
      if (updatedDetails.length === 0) {
        setIsTableVisible(false);
      }
      return {
        ...prev,
        details: updatedDetails,
      };
    });
  };
  //   const displayedData = showAllRows ? data : data.slice(0, 2);
  const isEditMode = id !== undefined;

  const initialize = async () => {
    if (isEditMode) {
      const { success, data } = await vendorQuotation.viewQuotation(id);
      if (success) {
        const updatedData = {
          ...data,
          details: data.details.map((detail) => ({
            ...detail,
            uuid: uuidv4(),
          })),
        };
        setVendorQuotations(updatedData);
        setSelectedVendorId(data.quotation.vendor_id);
        form.setFieldsValue({
          ...data.quotation,
          date: moment(data.quotation.date),
          validity: moment(data.quotation.validity_date),
        });
      }
    }
  };
  const getDestinations = async () => {
    const { success, data } = await destinationService.fetchDestination();
    if (success) {
      setDestinations(data);
    }
  };
  const getContainerSize = async () => {
    const { success, data } = await containerService.fetchsizes();
    if (success) {
      setContainerSizes(data);
    }
  };
  const getVendors = async () => {
    const { success, data } = await vendorService.fetchAll();
    if (success) {
      setVendors(data);
    }
  };
  const onFinish = async (values) => {
    setLoading(true);
    const destinationIds = vendorQuotations.details
      .map((detail) => detail.destination_id)
      .filter((id) => id);
    const containerIds = vendorQuotations.details
      .map((detail) => detail.containersize_id)
      .filter((id) => id);
    const freightChargesArray = vendorQuotations.details.map(
      (detail) => detail.charges || 0
    );

    const isDGArray = vendorQuotations.details.map((detail) => detail.is_dg);
    const { vendor_name, ...restvalues } = values;
    const payload = {
      ...restvalues,
      vendor_id: selectedVendorId,
      date: moment(restvalues.date).format("YYYY-MM-DD"),
      validity: moment(restvalues.validity).format("YYYY-MM-DD"),
      destination: destinationIds,
      container: containerIds,
      freight_charges: freightChargesArray,
      is_dg: isDGArray,
    };

    const response = isEditMode
      ? await vendorQuotation.updateQuotation(id, payload)
      : await vendorQuotation.addQuotation(payload);
    if (response.success) {
      notification.success({
        message: "Success",
        description: isEditMode
          ? "Quotation updated successfully!"
          : "Quotation added successfully",
        placement: "topRight",
      });
    } else {
      const errors = response.data?.errors || [response.message];
      notification.error({
        message: "Error",
        description: `Error: ${errors.join(", ")}`,
        placement: "topRight",
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    initialize();
    getDestinations();
    getContainerSize();
    getVendors();
  }, []);
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Form
        requiredMark={false}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ layout: "vertical" }}>
        <Row gutter={24}>
          <Col span={24}>
            <h1 style={{ fontWeight: "bold" }} className="pt-2  pb-5">
              {isEditMode ? "Edit Vendors Quotation" : "Add Vendors Quotation "}
            </h1>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Vendor "
              name="vendor_name"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Select
                placeholder="OTHER"
                onChange={(value) => setSelectedVendorId(value)}
              >
                {vendors.map((vendor) => (
                  <Select.Option key={vendor.id} value={vendor.id}>
                    {vendor.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Quotation Date " name="date">
              <DatePicker style={{ width: "100%" }} placeholder="mm/dd/yyyy" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Valid Date " name="validity">
              <DatePicker style={{ width: "100%" }} placeholder="mm/dd/yyyy" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Chassis Days" name="chassis_days">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Chassis Charges" name="chassis_charges">
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row>

        <div
          style={{
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "8px",
            backgroundColor: "#FFFFFF",
            margin: 50,
          }}
        >

          {isTableVisible && (
            <Table
              columns={columns}
              scroll={{ x: "max-content" }}
              dataSource={vendorQuotations.details}
              pagination={false}
              rowKey="Id"
              style={{ marginTop: "10px" }}
            />
          )}

          <div
            style={{ marginTop: 20 }}
            className="flex flex-col justify-center items-center"
          >
            <CiSquarePlus
              style={{ cursor: "pointer", fontSize: "22px" }}
              onClick={handleAddRow}
            />
            <p>Add Fright Container Charges</p>
          </div>
        </div>
        <Form.Item style={{ textAlign: "center", marginTop: 30 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "40%" }}
            loading={loading}
          >
            {" "}
            {isEditMode ? "Update Quotation" : "Add Quotation"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form, Input, Button, Typography, Checkbox, notification } from "antd";
import { MailOutlined,LockOutlined,CloseCircleOutlined,} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { authService } from "../services";
import { ROLES } from "../constants";

const { Title } = Typography;

const LoginForm = ({ onLogin }) => {
  const [loading, setLoading] = useState(false);
  let location = useLocation();
  const navigate = useNavigate();
  let role;

  const onFinish = async (values) => {
    setLoading(true);
    const response = await authService.loginPost(
      values.email,
      values.password,
      values.remember
    );
    setLoading(false);
    const { success, data } = response;
    if (success ?? false) {
      localStorage.setItem("token", data.token);
      role =
        (data.user.user_level === 10) & (data.user.employee_id === 0)
          ? ROLES.ADMIN
          : (data.user.user_level === 1) & (data.user.employee_id === 0)
          ? ROLES.CUSTOMER
          : "";
      localStorage.setItem("role", role);
    } else {
      notification.open({
        message: response.message,
        icon: <CloseCircleOutlined style={{ color: "red" }} />,
      });
    }
    // role === ROLES.ADMIN
    //   ? navigate("/")
    //   : role === ROLES.CUSTOMER
    //   ? navigate("/customers-portal")
    //   : navigate("/");
    onLogin();

    if (role === ROLES.ADMIN) {
      console.log("Hit admin");
      navigate("/");
    } else if (role === ROLES.CUSTOMER) {
      console.log("Hit Customers")
      navigate("/");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
  };

  return (
    <div className="login-container">
      <div style={{ marginBottom: 15 ,display: "flex", flexDirection: "column"}}>
          <img
            src="/images/Savannah.png"
            alt="ctrade-Logo"
            style={{ width: "370px", height: "120px" ,  marginBottom: 10,marginLeft:"0px",}}
          />
        <Title id="title" style={{ fontSize: "20px" }}>
          Sign in
        </Title>
      </div>

      <Form
        layout="vertical"
        style={{ width: "370px" }}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{ email: location.state?.email ?? null }}
      >
        <Form.Item
          className="login-form-item mb-4"
          name="email"
          rules={[
            { type: "email", message: "The input is not valid email!" },
            { required: true, message: "Please input your email!" },
          ]}
        >
          <Input
            prefix={<MailOutlined />}
            type="email"
            style={{ borderRadius: "16px", height: "40px" }}
            placeholder="Enter your email"
            allowClear
          />
        </Form.Item>

        <Form.Item
          className="login-form-item"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            style={{ borderRadius: "16px", height: "40px" }}
            placeholder="Enter your password"
            allowClear
          />
        </Form.Item>

        <Form.Item name="remember" valuePropName="checked" initialValue={true}>
          <Checkbox style={{ marginBottom: "10px" }}> Remember Me</Checkbox>

          <p style={{ fontSize: "12px", overflow: "hidden" }}>
            By signing in, you agree to Ctrade’s{" "}
            <a className="underline">User Agreement</a> and{" "}
            <a className="underline">Privacy Policy</a>
          </p>
        </Form.Item>
        <Form.Item className="login-form-item" style={{ marginBottom: 8 }}>
          <Button
            style={{
              width: "370px",
              borderRadius: "16px",
              height: "40px",
            }}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            LOGIN
          </Button>
        </Form.Item>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 0,
          }}
        >
          <Link to={"/SignUp"} className="text-blue hover:underline text-black">
            Create An Account?
          </Link>

          <Link href="/forget" className="text-blue hover:underline text-black">
            Forgot Password?
          </Link>
        </div>
      </Form>
      {/* ForgetPassword Modal */}
    </div>
  );
};
export default LoginForm;

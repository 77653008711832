import React, { useEffect, useState } from "react";
import { Table, Row, Col } from "antd";
import { useParams } from "react-router-dom";
import vendorQuotation from "../../../services/vendor-quotation-service";

import { DG, NON_DG } from "../../../constants";

function VendorQuotation() {
  const [viewBrowses, setViewBrowses] = useState([]);
  const [filteredViewBrowse, setFilteredViewBrowse] = useState(viewBrowses);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [quotation, setQuotation] = useState(null); // New state for storing quotation data

  let { id } = useParams();

  const initialize = async (page = 1, pageSize = pagination.pageSize) => {
    setLoading(true);
    const { success, data } = await vendorQuotation.viewQuotation(id);
    setLoading(false);
    if (success) {
      setQuotation(data.quotation);
      setViewBrowses(data.details);
      setFilteredViewBrowse(data.details);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
    },
    {
      title: "Container",
      dataIndex: "containersize",
      key: "containersize",
    },
    {
      title: "DG/ NON DG",
      dataIndex: "is_dg",
      key: "is_dg",
      render: (text, record) => <span>{record.is_dg === 1 ? DG : NON_DG}</span>,
    },
    {
      title: "Charges",
      dataIndex: "charges",
      key: "charges",
    },
  ];

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      {/* Header Content */}
      <Row gutter={16}>
        <Col span={20}>
          {quotation && (
            <div>
              <h3
                style={{ fontWeight: "bold", fontSize: "20px" }}
                className="mb-8"
              >
                Hello {quotation.vendor_name}{" "}
              </h3>
              <ul style={{ lineHeight: "1.8", margin: 0 }}>
                <li>LOADING/FREIGHT RATES ARE AS ATTACHED.</li>
                <li>
                  ABOVE RATES INCLUSIVE OF {quotation.chassis_days} THEREAFTER{" "}
                  {quotation.chassis_charges} PER CHASIS.
                </li>
                <li>PAYMENT TERMS: 7 DAYS FROM THE DATE OF INVOICE.</li>
                <li>
                  LOADING PICTURES WILL BE PROVIDED TO SATISFY THE CUSTOMERS IN
                  ADVANCE.
                </li>
                <li>
                  CHEQUE BOOK PROVIDED FOR ISSUANCE OF CHEQUES TO CUSTOMERS WITH
                  THEIR OWN DISPATCH.
                </li>
                <li>DEDICATED CUSTOMER SERVICE FOR VOLUMETRIC CUSTOMERS.</li>
                <li>
                  STORAGE POLICY: {quotation.chassis_days} THEREAFTER{" "}
                  {quotation.chassis_charges} PER UNIT.
                </li>
                <li>
                  DRIVERS MUST CONTACT PRIOR TO DELIVERY OR EMAIL THE DISPATCH
                  SHEETS FOR PROPER DELIVERY AND NO DELAYS.
                </li>
                <li>ADVANCE BOOKINGS BASED ON VOLUME COMMITMENT.</li>
                <li>7 ACRES OF PARKING SPACE.</li>
                <li>
                  WE REQUEST THAT THE LOADING PLAN BE SENT 48 TO 72 BUSINESS
                  HOURS IN ADVANCE OF FIRST RECEIVING.
                </li>
                <li>
                  IF CUSTOMERS NEED TO PICK UP VEHICLES, IT WILL BE SUBJECT TO
                  $100 PER VEHICLE, ALONG WITH STORAGE CHARGES AFTER 30 DAYS OF
                  RECEIVING AT THE FACILITY.
                </li>
                <li>
                  IN-HOUSE DRAYAGE NETWORK WITH OWN CHASIS FOR SMOOTH
                  OPERATIONS.
                </li>
              </ul>
            </div>
          )}
        </Col>
      </Row>
      {/* Table Section */}
      <div
        style={{
          margin: "20px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          marginTop: "20px",
        }}
      >
        <Table
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={filteredViewBrowse}
          loading={loading}
          pagination={false}
        />
      </div>
    </div>
  );
}
export default VendorQuotation;

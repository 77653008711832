import ajaxService from "./ajax-service";
import { validationErrors } from "../utils";

class CustomerDashbroadService {

    async fetchDashbord() {
        const response = await ajaxService.get(`/customer-dashboard`);
        if (response ?? false) {
            return response;
        } else {
            const errors = response.data.errors;
            return validationErrors(errors);
        }
    }
}

const customerDashboardService = new CustomerDashbroadService();
export default customerDashboardService;

import React from "react";
import { Modal, Button, Form, DatePicker, notification } from "antd";
import { vehicalService } from "../../../services";

const ExportModal = ({ isVisible, onCancel, onConfirm }) => {
  const onFinish = async (values) => {
    const { dateRange } = values;
    const start_date = dateRange[0].format("YYYY-MM-DD");
    const end_date = dateRange[1].format("YYYY-MM-DD");

    try {
      const response = await vehicalService.download({ start_date, end_date });

      if (response.success) {
        notification.success({
          message: "Success",
          description: "File Downloaded",
          placement: "topRight",
        });
        // console.log(response.message);
        onCancel();
      } else if(response.success === false) {
        // console.error("Error:", response.message);
        notification.error({
          message: "Not found",
          description: response.message,
          placement: "topRight",
        });
        // alert(response.message || "Failed to export data.");
      }
    } catch (error) {
      console.error("Error downloading file:", error.message);
      alert("Error downloading file");
    }
  };

  return (
    <Modal
      title={<div style={{ textAlign: "center" }}>Select Date Range</div>}
      open={isVisible} // Use the prop to control visibility
      onCancel={onCancel}
      footer={null}
      style={{ padding: "20px" }}
      width={400}
    >
      <Form layout="vertical" requiredMark={false} onFinish={onFinish}>
        <Form.Item
          label="Select Date Range"
          name="dateRange"
          rules={[{ required: true, message: "Please select a date range!" }]}
          className="mt-4"
        >
          <DatePicker.RangePicker style={{ width: "100%" }} />
        </Form.Item>
        <div className="mt-6">
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "100%" }}
            target="_blank"
          >
            Download
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ExportModal;

import React, { useMemo, useState } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Upload,
  Button,
  Divider,
  Table,
  Space,
  notification,
} from "antd";
import { CiSquarePlus } from "react-icons/ci";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { paymentVoucherService } from "../../../../../services";

const AddPaymentModal = ({
  visible,
  setIsModalShow,
  vendors,
  companies,
  paymentModes,
  banks,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showBankField, setShowBankField] = useState(false);
  const [data2, setData2] = useState([]);

  const onCancel = () => {
    form.resetFields();
    setData2([]);
    setIsModalShow(false);
  };
  const handleAddRow = () => {
    setData2([
      ...data2,
      {
        Id: uuidv4(),
        ExpenseAccount: "",
        Description: "",
        DueAmount: "",
        PayAmount: "",
        BalAmount: "",
      },
    ]);
  };
  const handleDeleteRow = (Id) => {
    setData2(data2.filter((item) => item.Id !== Id));
  };

  const handleChange = (value, field, recordId) => {
    setData2((prevData) =>
      prevData.map((record) => {
        if (record.Id === recordId) {
          const updatedRecord = { ...record, [field]: value };
          return updatedRecord;
        }
        return record;
      })
    );
  };

  const columns2 = [
    {
      title: "Expense Account",
      dataIndex: "ExpenseAccount",
      key: "ExpenseAccount",
      render: (text, record) => (
        <Select
          value={record.ExpenseAccount}
          onChange={(value) => {
            handleChange(value, "ExpenseAccount", record.Id);
          }}
          style={{ width: "100%" }}
        >
          {/* {vouchers
            .filter((voucher) => voucher.is_paid === 0)
            .map((voucher) => (
              <Select.Option key={voucher.id} value={voucher.voucher_number}>
                {voucher.voucher_number}
              </Select.Option>
            ))} */}
        </Select>
      ),
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      render: (text, record) => (
        <Input
          value={record.Description}
          onChange={(e) =>
            handleChange(e.target.value, "Description", record.Id)
          }
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Due Amount",
      dataIndex: "DueAmount",
      key: "DueAmount",
      render: (text, record) => (
        <Input value={record.DueAmount} style={{ width: "100%" }} readOnly />
      ),
    },
    {
      title: "Pay Amount",
      dataIndex: "PayAmount",
      key: "PayAmount",
      render: (text, record) => (
        <Input
          value={record.PayAmount}
          onChange={(e) => handleChange(e.target.value, "PayAmount", record.Id)}
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Bal. Amount",
      dataIndex: "Bal.Amount",
      key: "Bal.Amount",
      render: (text, record) => (
        <Input
          value={record.BalAmount}
          onChange={(e) => handleChange(e.target.value, "BalAmount", record.Id)}
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "Delete",
      key: "Delete",
      render: (text, record) => {
        if (data2.length === 1) return null;
        return (
          <Space size="small">
            <Button
              type="primary"
              size="small"
              onClick={() => handleDeleteRow(record.Id)}
            >
              <DeleteOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];
  const handlePayment = (value) => {
    if (value === 1) {
      setShowBankField(true);
    } else {
      setShowBankField(false);
    }
  };
  const onOk = async (values) => {
    setLoading(true);
    const expenseVoucherArray = data2.map((item) => item.ExpenseAccount);
    const descriptionArray = data2.map((item) => item.Description);
    const dueArray = data2.map((item) => item.DueAmount);
    const amountArray = data2.map((item) => item.PayAmount);
    const balanceArray = data2.map((item) => item.BalAmount);
    const data = {
      ...values,
      description: values.description ? values.description : "",
      bank_account: values.bank_account ? values.bank_account : "",
      payment_date: values.payment_date.format("YYYY-MM-DD"),
      voucher_date: values.voucher_date.format("YYYY-MM-DD"),
      expense_voucher: expenseVoucherArray,
      ev_description: descriptionArray,
      due_amount: dueArray,
      amount: amountArray,
      balance_amount: balanceArray,
      attachment: values.attachment ? values.attachment : null,
      totalVoucherAmount: totalPayingAmount,
    };
    const response = await paymentVoucherService.makePayment(data);
    if (response?.success) {
      notification.success({
        message: "Successfully Added!",
        description: response.message,
        placement: "topRight",
      });
    }
    onCancel();
  };
  const totalPayingAmount = useMemo(() => {
    return data2.reduce(
      (total, record) => total + Number(record.PayAmount || 0),
      0
    );
  }, [data2]);

  return (
    <Modal
      title={<div style={{ width: "100%" }}>Make a Payment</div>}
      open={visible}
      onCancel={onCancel}
      footer={null}
      closable={true}
      width={700}
      style={{ padding: "15px" }}
    >
      <Form layout="vertical" form={form} onFinish={onOk} requiredMark={false}>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Payment Voucher Number"
              name="voucher_number"
              rules={[
                {
                  required: true,
                  message: "Please enter the number of allowed appointments",
                },
              ]}
            >
              <Input type="number" placeholder="91099" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Inter-Company"
              name="company"
              rules={[
                {
                  required: true,
                  message: "Please select an appointment type",
                },
              ]}
            >
              <Select placeholder="Select Inter-Company">
                {companies.map((comp) => (
                  <Select.Option key={comp.id} value={comp.id}>
                    {comp.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Payment Mode"
              name="payment_mode"
              rules={[
                {
                  required: true,
                  message: "Please select an appointment type",
                },
              ]}
            >
              <Select
                placeholder="Cash"
                onChange={(value) => handlePayment(value)}
              >
                {paymentModes.map((mode) => (
                  <Select.Option key={mode.id} value={mode.id}>
                    {mode.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {showBankField && (
            <Col span={8}>
              <Form.Item
                label="Select Bank"
                name="bank_account"
                rules={[
                  {
                    required: true,
                    message: "Please select a bank",
                  },
                ]}
              >
                <Select placeholder="Select Bank">
                  {banks.map((bank) => (
                    <Select.Option key={bank.id} value={bank.id}>
                      {bank.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={8}>
            <Form.Item
              label="Voucher Date"
              name="voucher_date"
              rules={[{ required: true, message: "Please enter the end time" }]}
            >
              <DatePicker style={{ width: "100%" }} placeholder="2024-11-06" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Actual Payment Date"
              name="payment_date"
              rules={[{ required: true, message: "Please select a date" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Vendor"
              name="vendor"
              rules={[
                {
                  required: true,
                  message: "Please select an appointment type",
                },
              ]}
            >
              <Select placeholder="Select type">
                {vendors.map((vendor) => (
                  <Select.Option key={vendor.id} value={vendor.id}>
                    {vendor.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item label="Special Note:" name="description">
              <Input.TextArea
                placeholder="Enter special notes here..."
                rows={5}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Attachment" name="attachment">
              <Upload
                id="uploadInput"
                action="/upload"
                beforeUpload={() => false}
                listType="picture"
                style={{
                  width: "100%",
                }}
              >
                <Button
                  type="dashed"
                  icon={<UploadOutlined />}
                  style={{
                    width: "100%",
                    border: "1px solid #ccc",
                    padding: 18,
                  }}
                >
                  Upload File
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Table
              columns={columns2}
              scroll={{ x: "max-content" }}
              dataSource={data2}
              pagination={false}
              rowKey="Id"
              style={{ marginTop: "10px" }}
            />
            <div className="flex justify-between mt-5">
              <p>Total Paying Amount</p>
              <p>$0.00</p>
            </div>
            <Divider />
            <div
              style={{ marginTop: 20 }}
              className="flex flex-col justify-center items-center"
            >
              <CiSquarePlus
                style={{ cursor: "pointer", fontSize: "25px" }}
                onClick={handleAddRow}
              />
            </div>
          </Col>
        </Row>

        <Form.Item style={{ textAlign: "right", marginTop: 20 }}>
          <Button type="primary" htmlType="submit" style={{ width: "20%" }}>
            Make a Payment
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddPaymentModal;

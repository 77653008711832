import ajaxService from "./ajax-service";

class AppoinmentService {
  async fetch(page) {
    const response = await ajaxService.get(`/appointments?page=${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async viewAppointment(id, page) {
    const response = await ajaxService.get(`/appointments/${id}?page=${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async getScheduleDates(month) {
    const response = await ajaxService.get(
      `/appointment/schedule-dates?yearMonth=${month}`
    );
    if (response ?? false) {
      return response;
    }
  }

  async addAppointment(data) {
    const response = await ajaxService.post(`/appointments`, data);
    if (response ?? false) {
      return response;
    }
  }
  async cancelAppointment(data) {
    const response = await ajaxService.post(`/appointments/status`, data);
    if (response ?? false) {
      return response;
    }
  }
  async carStatus(data) {
    const response = await ajaxService.post(`/appointments/car-status`, data);
    if (response ?? false) {
      return response;
    }
  }
  async checkVIN(data) {
    const response = await ajaxService.get(
      `/appointment/vin_number?vin_number=${data}`
    );
    if (response ?? false) {
      return response;
    }
  }
}

const appointmentService = new AppoinmentService();

export default appointmentService;

import React, { useState } from "react";
import { Table, Row, Col, Form, DatePicker, Button, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { reportService } from "../../../services";

const { Title } = Typography;
function SalesExpensesReport() {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [payload, setPayload] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [collections, setCollections] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [invoicePagination, setInvoicePagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [collectionPagination, setCollectionPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [expensesPagination, setExpensesPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [form] = Form.useForm();

  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) => index + 1,
    },
    {
      title: "CUSTOMER NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "No. OF INVOICES",
      dataIndex: "qty",
      key: "qty",
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const data = [];
  const onFinish = (values) => {
    setPayload({
      from_date: values.from_date.format("YYYY-MM-DD"),
      to_date: values.to_date.format("YYYY-MM-DD"),
    });
    const payload = {
      from_date: values.from_date.format("YYYY-MM-DD"),
      to_date: values.to_date.format("YYYY-MM-DD"),
    };
    console.log(payload);
    initializeInvoice(
      invoicePagination.current,
      invoicePagination.pageSize,
      payload
    );
    initializeCollection(
      collectionPagination.current,
      collectionPagination.pageSize,
      payload
    );
    initializeExpenses(
      expensesPagination.current,
      expensesPagination.pageSize,
      payload
    );
  };

  const initializeInvoice = async (
    page = 1,
    pageSize = invoicePagination.pageSize,
    payload = null
  ) => {
    const type = 0;
    setLoading(true);
    const { success, data } = await reportService.SalesSummary(
      page,
      type,
      payload
    );
    if (success) {
      setInvoices(data);
      setInvoicePagination((prev) => ({
        ...prev,
        total: data.records.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };
  const initializeCollection = async (
    page = 1,
    pageSize = collectionPagination.pageSize,
    payload = null
  ) => {
    const type = 1;
    setLoading2(true);
    const { success, data } = await reportService.SalesSummary(
      page,
      type,
      payload
    );
    if (success) {
      setCollections(data);
      setCollectionPagination((prev) => ({
        ...prev,
        total: data.records.total,
        current: page,
        pageSize,
      }));
    }
    setLoading2(false);
  };
  const initializeExpenses = async (
    page = 1,
    pageSize = expensesPagination.pageSize,
    payload = null
  ) => {
    const type = 2;
    setLoading3(true);
    const { success, data } = await reportService.SalesSummary(
      page,
      type,
      payload
    );
    if (success) {
      setExpenses(data);
      setExpensesPagination((prev) => ({
        ...prev,
        total: data.records.total,
        current: page,
        pageSize,
      }));
    }
    setLoading3(false);
  };
  return (
    <>
      <Title level={5}>SALES COLLECTION EXPENSES REPORT</Title>
      <Form
        requiredMark={false}
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={24} className="mt-8">
          <Col span={8}>
            <Form.Item label="From date" name="from_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="To date" name="to_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Form.Item label={<></>}>
              <Button type="primary" htmlType="submit">
                <SearchOutlined style={{ fontSize: "15px" }} />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Col span={24}>
        <Col span={24} className="mt-10">
          <h1 style={{ fontWeight: "bold" }}>INVOICE SUMMARY</h1>

          <Table
            columns={columns}
            scroll={{ x: "max-content" }}
            dataSource={invoices?.records?.data}
            pagination={{
              current: invoicePagination.current,
              total: invoicePagination.total,
              pageSize: invoicePagination.pageSize,
              onChange: (page) =>
                initializeInvoice(page, invoicePagination.pageSize, payload),
              showSizeChanger: false,
            }}
            loading={loading}
            style={{ marginTop: "10px" }}
            summary={() => (
              <Table.Summary.Row className="font-bold bg-[#B83D3D] text-white">
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>Total</Table.Summary.Cell>
                <Table.Summary.Cell>
                  ${invoices?.totals?.quantity}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  ${invoices?.totals?.amounts}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Col>
        <Col span={24} className="mt-14">
          <h1 style={{ fontWeight: "bold" }}>COLLECTION SUMMARY</h1>

          <Table
            columns={columns}
            scroll={{ x: "max-content" }}
            pagination={{
              current: collectionPagination.current,
              total: collectionPagination.total,
              pageSize: collectionPagination.pageSize,
              onChange: (page) =>
                initializeCollection(
                  page,
                  collectionPagination.pageSize,
                  payload
                ),
              showSizeChanger: false,
            }}
            loading={loading2}
            dataSource={collections?.records?.data}
            style={{ marginTop: "10px" }}
            summary={() => (
              <Table.Summary.Row className="font-bold bg-[#B83D3D] text-white">
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>Total: $</Table.Summary.Cell>
                <Table.Summary.Cell>
                  ${collections?.totals?.quantity}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  ${collections?.totals?.amounts}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Col>
        <Col span={24} className="mt-14">
          <h1 style={{ fontWeight: "bold" }}>EXPENSES SUMMARY</h1>
          <Table
            columns={columns}
            scroll={{ x: "max-content" }}
            pagination={{
              current: expensesPagination.current,
              total: expensesPagination.total,
              pageSize: expensesPagination.pageSize,
              onChange: (page) =>
                initializeExpenses(page, expensesPagination.pageSize, payload),
              showSizeChanger: false,
            }}
            loading={loading3}
            dataSource={expenses?.records?.data}
            style={{ marginTop: "10px" }}
            summary={() => (
              <Table.Summary.Row className="font-bold bg-[#B83D3D] text-white">
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell>Total: $</Table.Summary.Cell>
                <Table.Summary.Cell>
                  ${expenses?.totals?.quantity}
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  ${expenses?.totals?.amounts}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Col>
      </Col>
    </>
  );
}

export default SalesExpensesReport;

import React, { useEffect, useState } from "react";
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Select,
  Table,
  Upload,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import LoadingAdd from "../../adminPortal/loadingplan/LoadingAdd";
import { containerService, dropdownService, loadingPlanService } from "../../../services";

function CreateLoadPlans() {
  const [loading, setLoading] = useState(false);
  const [shippingLine, setShippingLine] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [containerSize, setContainerSize] = useState([]);
  const [newCarOptions, setNewCarOptions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [container_number, setContainer_number] = useState("");
  const [form] = Form.useForm();
  // API call function
  const fetchCarOptions = async (query, setOptions) => {
    if (!query) {
      setOptions([]);
      return;
    }
    try {
      const response = await dropdownService.fetchVehiclesOnSearch({ query });
      if (response.success) {
        setOptions(
          response.data.map((car) => ({
            value: `${car.vehicle} - ${car.chasis_no}`,
            label: `${car.vehicle} - ${car.chasis_no}`,
            data: car,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching vehicle options:", error);
      setOptions([]);
    }
  };

  // Delete handler
  const handleDelete = (id) => {
    setTableData((prevData) => prevData.filter((item) => item.id !== id));
  };

  // Handle selection from dropdown
  const handleSelect = (value, option) => {
    const { data } = option;
    const selectedCar = {
      id: data.id,
      name: data.vehicle,
      chasis_no: data.chasis_no,
    };
    setTableData((prev) => [...prev, selectedCar]);
    form.setFieldsValue({
      new_car: "",
    });
  };
  const columns = [
    {
      title: "Vehicle",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Chassis Number",
      dataIndex: "chasis_no",
      key: "chasis_no",
    },
    {
      title: (
        <div>
          <DeleteOutlined style={{ fontSize: "18px" }} />
        </div>
      ),
      key: "Delete",
      render: (text, record) => (
        <Button
          type="primary"
          size="small"
          onClick={() => handleDelete(record.id)}
        >
          <DeleteOutlined />
        </Button>
      ),
    },
  ];

  const getShippingLine = async () => {
    const response = await dropdownService.fetchShippingLines();
    if (response.success) {
      setShippingLine(response.data);
    }
  };

  const getDestinations = async () => {
    const response = await dropdownService.fetchDestination();
    if (response.success) {
      const uniqueDestinations = Array.from(
        new Map(response.data.map((item) => [item.name, item])).values()
      );
      setDestinations(uniqueDestinations);
    }
  };
  const getContainerSize = async () => {
    const response = await dropdownService.fetchContainerSizes();
    if (response.success) {
      setContainerSize(response.data);
    }
    const res = await containerService.fetchlatestContainerId();
    if (res.success) {
      setContainer_number(res.data.id);
    }
  };
  const onFinish = async (values, val) => {
    setLoading(true);
    // console.log(val);
    // const data2 = form2.getFieldsValue();
    // if (Object.keys(data).length > 0) {
    // const data = form.getFieldsValue();
    const additionalData =
      val === "Send to Ctrade"
        ? { load: "Send to Ctrade" }
        : val === "save"
        ? { save: "Save" }
        : {};
    // const merged = { ...data1, ...data2 };
    const carid = tableData.map((car) => car.id);
    const { new_car, ...newValues } = values;
    const payload = {
      ...newValues,
      etd_date: values.etd_date.format("YYYY-MM-DD"),
      erd_date: values.erd_date.format("YYYY-MM-DD"),
      auto_cut_off_date: values.auto_cut_off_date.format("YYYY-MM-DD"),
      cargo_cut_off_date: values.cargo_cut_off_date.format("YYYY-MM-DD"),
      title_cut_off_date: values.title_cut_off_date.format("YYYY-MM-DD"),
      doc_cut_off_date: values.doc_cut_off_date.format("YYYY-MM-DD"),
      eta_date: values.eta_date.format("YYYY-MM-DD"),
      carid,
      ...additionalData,
    };
    // console.log(payload);
    // }
    const response = await loadingPlanService.addClientPlan(payload);
    if (response.success) {
      notification.success({
        message: "Successfully added!",
        description: response.message,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const handleSubmit = (val) => {
    form
      .validateFields()
      .then((values) => {
        onFinish(values, val);
      })
      .catch((errorInfo) => {
        console.error("Validation failed:", errorInfo);
      });
  };

  useEffect(() => {
    getShippingLine();
    getContainerSize();
    getDestinations();
  }, []);
  return (
    // <LoadingAdd />
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={26} style={{ marginBottom: 10, padding: "5px" }}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Add Plans</h1>
        </Col>
      </Row>
      <div style={{ padding: "15px" }}>
        <Form
          requiredMark={false}
          form={form}
          layout="vertical"
          onFinish={(values) => onFinish(values, null)}
          initialValues={{ layout: "vertical" }}
        >
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="Booking Number"
                name="booking_number"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <Input placeholder="" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Upload Booking Confirmation Sheet">
                <Upload
                  id="uploadInput"
                  action="/upload"
                  listType="picture"
                  style={{
                    width: "100%", // Ensures the upload spans the full width of its container
                  }}
                >
                  <Button
                    type="dashed"
                    style={{
                      width: "100%",
                      border: "1px solid #ccc",
                      // padding: ,
                    }}
                  >
                    <div
                      style={{
                        paddingRight: 10,
                        borderRight: "1px solid #ccc",
                      }}
                    >
                      <img src="/images/Group.png" alt="" width="24px" />
                    </div>
                    <span style={{ marginRight: "52px" }}>No File Chosen</span>
                  </Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label="Add New Car" name="new_car">
                <AutoComplete
                  options={newCarOptions}
                  onSearch={(query) => fetchCarOptions(query, setNewCarOptions)}
                  onSelect={(value, option) => handleSelect(value, option)}
                  placeholder="Search and select a car"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Shipping Line"
                name="shipping_line"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <Select placeholder="">
                  {shippingLine.map((ship) => (
                    <Select.Option key={ship.id} value={ship.id}>
                      {ship.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Location"
                name="location"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <Input
                  placeholder=""
                  onChange={(e) => {
                    // Get the location input value
                    const locationValue = e.target.value;
                    // Append "-93000" to the location value to form the load number
                    const loadNumber = locationValue
                      ? `${locationValue}-${container_number}`
                      : "";
                    // Update the Load Number field dynamically
                    form.setFieldsValue({ load_number: loadNumber });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Destination"
                name="destination"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <Select placeholder="BENGHAZI">
                  {destinations.map((destination) => (
                    <Select.Option
                      key={destination.id}
                      value={destination.name}
                    >
                      {destination.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Container Size"
                name="container_size"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <Select placeholder="BENGHAZI">
                  {containerSize.map((size) => (
                    <Select.Option key={size.id} value={size.name}>
                      {size.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="ETD Date"
                name="etd_date"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} placeholder="" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="ERD Date"
                name="erd_date"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} placeholder="" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Load Number" name="load_number">
                <Input placeholder="" readOnly />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Cargo Cut Off Date"
                name="cargo_cut_off_date"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} placeholder="" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Title Cut Off Date"
                name="title_cut_off_date"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} placeholder="" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Auto Cut Off Date"
                name="auto_cut_off_date"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} placeholder="" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Doc Cut Off Date"
                name="doc_cut_off_date"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} placeholder="" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="ETA Date"
                name="eta_date"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} placeholder="" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Booking Type"
                name="is_dg"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <Select placeholder="">
                  <Select.Option value={0}>Non-DG</Select.Option>
                  <Select.Option value={1}>DG</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Special Notes" name="client_loading_note">
                <Input.TextArea
                  placeholder="Enter special notes here..."
                  rows={5}
                />
              </Form.Item>
            </Col>
          </Row>

          <Table
            columns={columns}
            pagination={false}
            scroll={{ x: "max-content" }}
            dataSource={tableData}
            style={{ marginTop: "5px" }}
          />
          <Row
            gutter={12}
            style={{
              marginTop: 40,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Col span={6}>
              <Button
                type="primary"
                // htmlType="submit"
                style={{ width: "100%" }}
                onClick={() => handleSubmit("save")}
                loading={loading}
              >
                SAVE
              </Button>
            </Col>
            <Col span={6}>
              <Button
                type="primary"
                // htmlType="submit"
                style={{ width: "100%" }}
                onClick={() => handleSubmit("Send to Ctrade")}
                loading={loading}
              >
                SEND TO CTRADE
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
export default CreateLoadPlans;

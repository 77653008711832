import React, { useEffect, useState } from "react";
import {Table,Space,Button,Row,Col,Input,Modal,Form,Select,DatePicker,Tag,notification,} from "antd";
import { EditOutlined, SearchOutlined } from "@ant-design/icons";
import { transactionService } from "../../../../services";
import moment from "moment";
function BrowseTranscation() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transaction, setTransaction] = useState([]);
  const [banks, setBanks] = useState([]);
  const [paymentMode, setPaymentMode] = useState([]);
  const [isModalShow, setIsModalShow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });

  const showModal = async (id) => {
    const { success, data } = await transactionService.viewTransaction(id);
    if (success) {
      setSelectedRecord(data.ledger.id);
      setTransaction(data.ledger);
      setBanks(data.banks);
      setPaymentMode(data.payment_modes);
      form.setFieldsValue({
        ...data.ledger,
        payment_date: data.ledger.payment_date
          ? moment(data.ledger.payment_date)
          : null,
        bank: data.ledger.bank_id,
        payment_mode: data.ledger.payment_mode_id,
      });
    }
    setIsModalShow(true);
  };

  const handleOk = () => {
    setIsModalShow(false);
  };
  const handleCancel = () => {
    setIsModalShow(false);
  };
  const columns = [
    {
      title: "Transaction Id",
      dataIndex: "id",
      key: "id",
      // render: (text, record, index) => index + 1,
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_id",
      key: "invoice_id",
    },
    {
      title: "Mode Of Payment",
      dataIndex: "payment_mode",
      key: "payment_mode",
    },
    {
      title: "Payment Amount",
      dataIndex: "collected_amount",
      key: "collected_amount",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
    },
    {
      title: "Payment Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          type="primary"
          size="small"
          style={{
            backgroundColor:
              status === 1
                ? "orange"
                : status === 2
                ? "#1fd655"
                : status === 0
                ? "red"
                : "transparent",
          }}
          color="white"
        >
          {status === 0
            ? "Not paid"
            : status === 1
            ? "Partial"
            : status === 2
            ? "Complete"
            : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
    },
    {
      title: "Ref.Number",
      dataIndex: "reference_number",
      key: "reference_number",
    },
    {
      title: "Actions",
      key: "Actions",
      render: (text, record, { _id }) => (
        <Button
          type="primary"
          size="small"
          onClick={() => showModal(record.id)}
        >
          <EditOutlined />
        </Button>
      ),
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    const { success, data } = await transactionService.fetch(page);
    if (success) {
      setTransactions(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const data = {
      ...values,
      payment_date: values.payment_date.format("YYYY-MM-DD"),
    };

    const response = await transactionService.editTransaction(
      selectedRecord,
      data
    );
    if (response.success) {
      notification.success({
        message: "Updated Successfully!",
        description: response.message,
        placement: "topRight",
      });
      initialize(pagination.current, pagination.pageSize)
    } else {
      notification.error({
        message: "Error updating",
        description: response.message,
        placement: "topRight",
      });
    }
    setLoading(false);
    setIsModalShow(false);
  };
  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row
        gutter={24}
        style={{ padding: "10px 10px 0 10px", borderRadius: "4px" }}
        className="flex justify-between"
      >
        <Col span={10}>
          <Space direction="horizontal" size={15}>
            <h5 className="font-bold"> Transaction List</h5>
          </Space>
        </Col>
        <Col span={14} style={{ textAlign: "right" }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>

      <Modal
        title={<div style={{ width: "100%" }}>Edit Invoice</div>}
        open={isModalShow}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        width={700}
        style={{ padding: "15px" }}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
        >
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Invoice Number"
                name="invoice_id"
                rules={[
                  {
                    required: true,
                    message: "Please enter the number of allowed appointments",
                  },
                ]}
              >
                <Input type="number" placeholder="Enter number" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Ref. Number"
                name="reference_number"
                rules={[
                  { required: true, message: "Please enter the start time" },
                ]}
              >
                <Input placeholder="Enter start time" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Date"
                name="payment_date"
                rules={[{ required: true, message: "Please select a date" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Invoice Amount"
                name="invoice_amount"
                rules={[
                  { required: true, message: "Please enter the start time" },
                ]}
              >
                <Input placeholder="Enter start time" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Transaction Amount"
                name="amount"
                rules={[
                  { required: true, message: "Please enter the end time" },
                ]}
              >
                <Input placeholder="Enter end time" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Balance"
                name="balance"
                rules={[
                  { required: true, message: "Please enter the end time" },
                ]}
              >
                <Input placeholder="Enter end time" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Mode Of Payment"
                name="payment_mode"
                rules={[
                  {
                    required: true,
                    message: "Please select an appointment type",
                  },
                ]}
              >
                <Select placeholder="Select type">
                  {paymentMode.map((payment) => (
                    <Select.Option key={payment.id} value={payment.id}>
                      {payment.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Bank"
                name="bank"
                rules={[
                  {
                    required: true,
                    message: "Please select an appointment type",
                  },
                ]}
              >
                <Select placeholder="Select type">
                  {banks.map((bank) => (
                    <Select.Option key={bank.id} value={bank.id}>
                      {bank.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Payment Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: "Please select an appointment type",
                  },
                ]}
              >
                <Select placeholder="Select type">
                  <Select.Option value={0}>Not Paid</Select.Option>
                  <Select.Option value={1}>Partial</Select.Option>
                  <Select.Option value={2}>Complete</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {/* </Row> */}
            <Col span={24}>
              <Form.Item>
                <div style={{ textAlign: "center", marginTop: "12px" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "60%" }}
                    loading={loading}
                    // onClick={handleOk}
                  >
                    Update
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={transactions}
        style={{ marginTop: "10px" }}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        loading={loading}
      />
    </div>
  );
}
export default BrowseTranscation;

import React, { useState } from 'react';
import { Layout, theme, Typography } from 'antd';
import Sidebar from './sidebar';
import MainHeader from './header';
import { Breadcrumb } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ExportModal from '../pages/adminPortal/vechicals/ExportModal';
import ModalForm from '../pages/adminPortal/towingtarf/ModalForm';
import ScheduleForm from '../pages/adminPortal/schedule/ScheduleForm';

const { Content, Footer } = Layout;
const { Title } = Typography;

export default function MainLayout({ children, title }) {
  const navigate = useNavigate()
  const { token: { borderRadiusLG } } = theme.useToken();
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isAddModalSchedule, setIsAddModalSchedule] = useState(false);
  const [editData, setEditData] = useState(null);

  const [collapsed, setCollapsed] = useState(false);

  const location = useLocation();

  const showExportModal = () => {
    setIsExportModalVisible(true);
  };
  const showAddModal = () => {
    setIsAddModalVisible(true);
    setEditData(null);
  };
  const showAddschedule = () => {
    setIsAddModalSchedule(true)
    setEditData(null);
  }

  const handleScheduleCancel = () => {
    setIsAddModalSchedule(false)
  }

  // const handleScheduleConfirm = () => {
  //   setIsAddModalSchedule(false); // Close the modal
  // };

  const handleExportCancel = () => {
    setIsExportModalVisible(false);
  };

  const handleExportConfirm = () => {
    setIsExportModalVisible(false); // Close the modal
  };

  const handleAddCancel = () => {
    setIsAddModalVisible(false);
  };

  const breadcrumbMap = {
    "/operation/vehicles": [
      { title: "Browse", path: "/operation/vehicles" },
      { title: "Add", path: "/operation/vehicles/adds" },
      { title: "Export", onClick: () => showExportModal() },
    ],
    "/operation/vehicles/adds": [
      { title: "Browse", path: "/operation/vehicles" },
      { title: "Add", path: "/operation/vehicles/adds" },
      { title: "Export", onClick: () => showExportModal() },
    ],
    "/operation/vehicles/edit": [
      { title: "Browse", path: "/operation/vehicles" },
      { title: "Add", path: "/operation/vehicles/adds" },
      { title: "Export", onClick: () => showExportModal() },
    ],
    "/operation/vehicles/view/:id": [
      { title: "Browse", path: "/operation/vehicles" },
      { title: "Add", path: "/operation/vehicles/adds" },
      { title: "Export", onClick: () => showExportModal() },
    ],
    "/operation/tariffs": [
      { title: "Browse", path: "/operation/tariffs" },
      { title: "Add", onClick: () => showAddModal() },
    ],
    "/vehicles/views": [
      { title: "Browse", path: "/operation/vehicles" },
      { title: "Add", path: "/operation/vehicles/adds" },
      { title: "Export", path: "/operation/vehicles/adds" },
    ],
    "/operation/customers": [
      { title: "Browse", path: "/operation/customers" },
      { title: "Add", onClick: () => navigate("/operation/customers/form") },
    ],

    "/operation/customers/form": [
      { title: "Browse", path: "/operation/customers" },
      { title: "Add", onClick: () => navigate("/operation/customers/form"), path: "/operation/customers/form" },
    ],

    "/operation/appoinments": [
      { title: "Browse", path: "/operation/appoinments" },
      { title: "Add", path: "/operation/appoinments/add" },
    ],
    "/operation/appoinments/add": [
      { title: "Browse", path: "/operation/appoinments" },
      { title: "Add", path: "/operation/appoinments/add" },
    ],

    "/administrations/employees": [
      { title: "Browse", path: "/administrations/employees" },
      { title: "Add", onClick: () => navigate("/administrations/employees/form"), path: "/administrations/employees/form" },
    ],
    "/administrations/employees/form": [
      { title: "Browse", path: "/administrations/employees" },
      { title: "Add", onClick: () => navigate("/administrations/employees/form"), path: "/administrations/employees/form" },
    ],
    "/operation/loadings": [
      { title: "Load Plans", path: "/operation/loadings" },
      { title: "Saved Plans", path: "/operation/loadings/save-containers" },
      { title: "Add", path: "/operation/loadings/add" },
      { title: "Clients Request", path: "/operation/loadings/clients" },
      { title: "IMO’s", path: "/operation/loadings/imos" },
    ],
    "/operation/loadings/imos": [
      { title: "Load Plans", path: "/operation/loadings" },
      { title: "Saved Plans", path: "/operation/loadings/save-containers" },
      { title: "Add", path: "/operation/loadings/add" },
      { title: "Clients Request", path: "/operation/loadings/clients" },
      { title: "IMO’s", path: "/operation/loadings/imos" },
    ],

    "/operation/loadings/save-containers": [
      { title: "Load Plans", path: "/operation/loadings" },
      { title: "Saved Plans", path: "/operation/loadings/save-containers" },
      { title: "Add", path: "/operation/loadings/add" },
      { title: "Clients Request", path: "/operation/loadings/clients" },
      { title: "IMO’s", path: "/operation/loadings/imos" },
    ],
    "/operation/loadings/add": [
      { title: "Load Plans", path: "/operation/loadings" },
      { title: "Saved Plans", path: "/operation/loadings/save-containers" },
      { title: "Add", path: "/operation/loadings/add" },
      { title: "Clients Request", path: "/operation/loadings/clients" },
      { title: "IMO’s", path: "/operation/loadings/imos" },
    ],
    "/operation/loadings/clients": [
      { title: "Load Plans", path: "/operation/loadings" },
      { title: "Saved Plans", path: "/operation/loadings/save-containers" },
      { title: "Add", path: "/operation/loadings/add" },
      { title: "Clients Request", path: "/operation/loadings/clients" },
      { title: "IMO’s", path: "/operation/loadings/imos" },
    ],

    "/operation/vendors": [
      { title: "Browse Vendors", path: "/operation/vendors" },
      { title: "Add Vendors", onClick: () => navigate("/operation/vendors/form"), path: "/operation/vendors/form" },
      { title: "Browse Vendor Quotation", path: "/operation/vendors/quotations" },
      { title: "Add Vendor Quotation ", onClick: () => navigate("/operation/vendors/quotations/form"), path: "/operation/vendors/quotations/form" },
    ],
    "/operation/vendors/form": [
      { title: "Browse Vendors", path: "/operation/vendors" },
      { title: "Add Vendors", onClick: () => navigate("/operation/vendors/form"), path: "/operation/vendors/form" },
      { title: "Browse Vendor Quotation", path: "/operation/vendors/quotations" },
      { title: "Add Vendor Quotation ", onClick: () => navigate("/operation/vendors/quotations/form"), path: "/operation/vendors/quotations/form" },
    ],
    "/operation/vendors/quotations": [
      { title: "Browse Vendors", path: "/operation/vendors" },
      { title: "Add Vendors", onClick: () => navigate("/operation/vendors/form"), path: "/operation/vendors/form" },
      { title: "Browse Vendor Quotation", path: "/operation/vendors/quotations" },
      { title: "Add Vendor Quotation ", onClick: () => navigate("/operation/vendors/quotations/form"), path: "/operation/vendors/quotations/form" },
    ],
    "/operation/vendors/quotations/form": [
      { title: "Browse Vendors", path: "/operation/vendors" },
      { title: "Add Vendors", onClick: () => navigate("/operation/vendors/form"), path: "/operation/vendors/form" },
      { title: "Browse Vendor Quotation", path: "/operation/vendors/quotations" },
      { title: "Add Vendor Quotation ", onClick: () => navigate("/operation/vendors/quotations/form"), path: "/operation/vendors/quotations/form" },
    ],
    "/administrations/settings": [
      { title: "Destinations", path: "/administrations/settings" },
      { title: "Containers size", path: "/administrations/settings/containers" },
      { title: "Logs", path: "/administrations/settings/logs" },
    ],
    "/administrations/settings/containers": [
      { title: "Destinations", path: "/administrations/settings" },
      { title: "Containers size", path: "/administrations/settings/containers" },
      { title: "Logs", path: "/administrations/settings/logs" },
    ],
    "/administrations/settings/logs": [
      { title: "Destinations", path: "/administrations/settings" },
      { title: "Containers size", path: "/administrations/settings/containers" },
      { title: "Logs", path: "/administrations/settings/logs" },
    ],
    "/operation/books": [
      { title: "Browse", path: "/operation/books" },
      { title: "Add", onClick: () => showAddschedule() },
    ],
    "/invoices": [
      { title: "Browse", path: "/invoices" },
      { title: "Add", path: "/invoices/add" },
    ],
    "/invoices/add": [
      { title: "Browse", path: "/invoices" },
      { title: "Add", path: "/invoices/add" },
    ],
    "/operation/quotations": [
      { title: "Browse", path: "/operation/quotations" },
      { title: "Add", onClick: () => navigate("/operation/quotations/form"), path: "/operation/quotations/form" },
    ],
    "/operation/quotations/form": [
      { title: "Browse", path: "/operation/quotations" },
      { title: "Add", onClick: () => navigate("/operation/quotations/form"), path: "/operation/quotations/form" },
    ],

    // customer portal

    "/customers-portal/appoinments": [
      { title: "Browse", path: "/customers-portal/appoinments" },
      { title: "Add", path: "/customers-portal/add-appoinments" },
    ],

    "/customers-portal/add-appoinments": [
      { title: "Browse", path: "/customers-portal/appoinments" },
      { title: "Add", path: "/customers-portal/add-appoinments" },
    ],

    "/customers-portal/create-load-plans": [
      { title: "Create Load Plans", path: "/customers-portal/create-load-plans" },
      { title: "Open Saved Plans", path: "/customers-portal/open-save-plan" },
      { title: "Send Load Plan", path: "/customers-portal/send-load-plan" },
      { title: "Loaded Plan", path: "/customers-portal/loaded-plan" },
    ],
    "/customers-portal/open-save-plan": [
      { title: "Create Load Plans", path: "/customers-portal/create-load-plans" },
      { title: "Open Saved Plans", path: "/customers-portal/open-save-plan" },
      { title: "Send Load Plan", path: "/customers-portal/send-load-plan" },
      { title: "Loaded Plan", path: "/customers-portal/loaded-plan" },
    ],

    "/customers-portal/send-load-plan": [
      { title: "Create Load Plans", path: "/customers-portal/create-load-plans" },
      { title: "Open Saved Plans", path: "/customers-portal/open-save-plan" },
      { title: "Send Load Plan", path: "/customers-portal/send-load-plan" },
      { title: "Loaded Plan", path: "/customers-portal/loaded-plan" },
    ],
    "/customers-portal/loaded-plan": [
      { title: "Create Load Plans", path: "/customers-portal/create-load-plans" },
      { title: "Open Saved Plans", path: "/customers-portal/open-save-plan" },
      { title: "Send Load Plan", path: "/customers-portal/send-load-plan" },
      { title: "Loaded Plan", path: "/customers-portal/loaded-plan" },
    ],
  };

  const breadcrumbItems = breadcrumbMap[location.pathname] || [];

  return (
    <Layout hasSider style={{ minHeight: "100vh" }}>
      <Sidebar style={{ zIndex: "1000" }} collapsed={collapsed}
        setCollapsed={setCollapsed} />
      <Layout style={{
        marginLeft: collapsed ? 80 : 250, // Dynamically adjust based on collapse state
        transition: "margin-left 0.5s", // Smooth transition
        background: '#FBFBFB'
      }}>
        <MainHeader />
        {title && <Title style={{ padding: '16px 30px', fontSize: '20px', margin: '10px 0 0 0' }}>
          {title === "Dashboard" ? (
            <>
              <div className='flex flex-col gap-6'>
                <h3 className='flex flex-col gap-2'>
                  <span>
                    Hi <span className="text-base inline">Username,</span>
                  </span>
                  <div>
                    Welcome To <span style={{ color: '#B83D3D' }}>CTrade</span>
                  </div>
                </h3>
                <p>{title}</p>
              </div>
            </>
          ) : (
            title
          )}</Title>}
        <Content style={{ margin: '8px 30px 0', overflow: 'initial' }}>
          <div className="breadcrumb-container">
            <Breadcrumb separator="|">
              {breadcrumbItems.map((item, index) => (
                <Breadcrumb.Item key={index} className={`breadcrumb-item ${location.pathname === item.path ? 'active-link' : ''}`}>

                  {item.onClick ? (
                    <a onClick={(e) => {
                      e.preventDefault();
                      item.onClick(); // Call the onClick handler
                    }}
                    >
                      {item.title}
                    </a>
                  ) : (
                    <Link to={item.path}>{item.title}</Link>
                  )}
                </Breadcrumb.Item>))}
            </Breadcrumb>

            <ExportModal
              isVisible={isExportModalVisible}
              onCancel={handleExportCancel}
              onConfirm={handleExportConfirm}
            />
            <ModalForm
              visible={isAddModalVisible}
              onClose={handleAddCancel}
              editData={editData}
              isEditMode={false}
            />

            <ScheduleForm
              visible={isAddModalSchedule} onClose={handleScheduleCancel} editData={editData}
            />
          </div>
          <div style={{ padding: "5", background: '#FBFBFB', borderRadius: borderRadiusLG, objectFit: "fill" }} className="mt-3">
            {children}
            {/* <Outlet /> */}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', background: '#FBFBFB' }} className='mt-6'>
          © 2022 - {new Date().getFullYear()} CTrade
        </Footer>
      </Layout>
    </Layout>
  );
}

import React, { useEffect, useState } from "react";
import { Table, Space, Button, Row, Col, Input, Tag } from "antd";
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { loadingClient } from "../../../services";
function LoadingClient() {
  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "_id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Load Number",
      dataIndex: "container_id",
      key: "LoadNumber",
    },
    {
      title: "Booking Number",
      dataIndex: "c_booking_number",
      key: "BookingNumber",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "Location",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      // render: (text, record) => {
      //   const { is_client_approved_loadplan, reject } = record;
      //   let statusText = "";
      //   let backgroundColor = "";
      //   let color = "";

      //   if (is_client_approved_loadplan === 0 && reject === 0) {
      //     statusText = "Pending";
      //     backgroundColor = "yellow";
      //     color = "black";
      //   } else if (is_client_approved_loadplan === 1) {
      //     statusText = "Approved";
      //     backgroundColor = "green";
      //     color = "white";
      //   } else if (reject === 1) {
      //     statusText = "Rejected";
      //     backgroundColor = "red";
      //     color = "white";
      //   }

      //   return (
      //     <span
      //       style={{
      //         color: color,
      //         backgroundColor: backgroundColor,
      //         fontWeight: "bold",
      //         padding: "10px",
      //         borderRadius: "6px",
      //       }}
      //     >
      //       {statusText}
      //     </span>
      //   );
      // },
      render: (text, record,{ _id }) => {
          const { is_client_approved_loadplan, reject } = record;
        let statusText = "";
        let backgroundColor = "";
        let color = "";

        if (is_client_approved_loadplan === 0 && reject === 0) {
          statusText = "Pending";
          backgroundColor = "yellow";
          color = "black";
        } else if (is_client_approved_loadplan === 1) {
          statusText = "Approved";
          backgroundColor = "green";
          color = "white";
        } else if (reject === 1) {
          statusText = "Rejected";
          backgroundColor = "red";
          color = "white";
        }
        return(
        <Tag style={{ backgroundColor: backgroundColor, color: color, fontSize:"12px", padding:"5px 5px" }}>
          {statusText}
        </Tag>
      )},
    },
    {
      title: "Actions",
      key: "Actions",
      render: (text, record, { _id }) => (
        <Link to={`/operation/loadings/clients/views/${record.container_id}`}>
          <Button type="primary" size="small">
            <EyeOutlined />
          </Button>
        </Link>
      ),
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.current) => {
    try {
      setLoading(true);
      const { success, data, message } = await loadingClient.fetch(page);
      if (success) {
        if (data && data.data && data.data.length > 0) {
          setClients(data.data);
          setPagination((prev) => ({
            ...prev,
            total: data.total,
            current: page,
            pageSize,
          }));
        } else {
          console.warn("No loading plan data found.");
          setClients([]);
        }
      } else {
        console.error(
          `Error: ${message || "Failed to fetch loading plan data."}`
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={26}>
        <Col span={10}>
          <h5 className="font-bold">Client Load Plans</h5>
        </Col>
        <Col span={14} style={{ textAlign: "right" }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={clients}
        style={{ marginTop: "5px" }}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        loading={loading}
      />
    </div>
  );
}
export default LoadingClient;

import ajaxService from "./ajax-service";

class VendorQuotation {
  async fetch(page) {
    const response = await ajaxService.get(`/vendors-quotations?page${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async viewQuotation(id) {
    const response = await ajaxService.get(`/vendors-quotations/${id}`);
    if (response ?? false) {
      return response;
    }
  }
  async addQuotation(data) {
    const response = await ajaxService.post(`/vendors-quotations`, data);
    if (response  ?? false) {
      return response;
    }
  }
  async updateQuotation(id, data) {
    const response = await ajaxService.put(`/vendors-quotations/`, id, data);
    if (response ?? false) {
      return response;
    }
  }
  async deleteQuotation(id) {
    const response = await ajaxService.delete(`/vendors-quotations/`, id);
    if (response ?? false) {
      return response;
    }
  }
}

const vendorQuotation = new VendorQuotation();
export default vendorQuotation;

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Upload,
} from "antd";
import {UploadOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { expenseVoucherService } from "../../../../../services";
import moment from "moment";

function AddExpense({
  isVisible,
  setIsModalShow,
  vendors,
  setRefresh,
  subAccount,
  expenseAcc,
  voucher_number,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [filteredSubAccount, setFilteredSubAccount] = useState([]);
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [button, setButton] = useState(null);
  const [isSave, setIsSave] = useState(false);
  const [isDueDateEnabled, setIsDueDateEnabled] = useState(false);

  const handleSaveDueDate = () => {
    const dueDate = form.getFieldValue("DueDate");
    console.log("Due Date Saved:", dueDate);
  };

  const handleCancel = () => {
    setRefresh(true);
    form.resetFields();
    setIsSave(false);
    setIsModalShow(false);
  };

  const handleExpChange = (value) => {
    const { vendor_category_id, id } = JSON.parse(value);
    const filteredSubAcc = subAccount.filter(
      (subAccount) => subAccount.expense_category_id === id
    );
    setFilteredSubAccount(filteredSubAcc);
    const filteredVendors = vendors.filter(
      (vendor) => vendor.vendors_categorys_id === vendor_category_id
    );
    setFilteredVendors(filteredVendors);
  };

  const onFinish = async (values) => {
    setLoading(true);
    const expense = JSON.parse(values.expense_category);
    const vendor_cat_id = vendors.find(
      (vendor) => values.vendor == vendor.id
    )?.vendors_categorys_id;
    const data = {
      ...values,
      vendor_category: vendor_cat_id,
      expense_category: expense.id,
      due_date: values.due_date.format("YYYY-MM-DD"),
      voucher_date: values.voucher_date.format("YYYY-MM-DD"),
      is_future: button === "save" ? 1 : 0,
      attachment: values.attachment ? values.attachment : null,
      description: values.description ? values.description : "",
    };
    const response = await expenseVoucherService.addExpense(data);
    if (response.success) {
      notification.success({
        message: "Added Successfully!",
        description: response.message,
        placement: "topRight",
      });
    } else {
      notification.error({
        message: "Error adding",
        description: response.message,
        placement: "topRight",
      });
    }
    setLoading(false);
    handleCancel();
  };

  useEffect(() => {
    form.setFieldsValue({
      voucher_number: voucher_number,
    });
  }, [isVisible]);
  return (
    <Modal
      title={<div style={{ width: "100%" }}>Make Expense</div>}
      open={isVisible}
      onCancel={handleCancel}
      footer={null}
      closable={true}
      width={700}
      style={{ padding: "15px" }}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        requiredMark={false}
        initialValues={{
          due_date: moment(),
          voucher_date: moment(),
          // voucher_number: isEdit
          //   ? expense?.voucher_number
          //   : Number(vouchers[0]?.voucher_number) + 1,
        }}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Expense Account"
              name="expense_category"
              rules={[
                {
                  required: true,
                  message: "Please select an appointment type",
                },
              ]}
            >
              <Select
                placeholder="Select Inter-Company"
                onChange={(value) => handleExpChange(value)}
              >
                {expenseAcc.map((expense) => (
                  <Select.Option
                    key={expense.id}
                    value={JSON.stringify(expense)}
                  >
                    {expense.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Sub-Account"
              name="expense_subcategory"
              rules={[
                {
                  required: true,
                  message: "Please select an appointment type",
                },
              ]}
            >
              <Select placeholder="Select Inter-Company">
                {filteredSubAccount.map((subacc) => (
                  <Select.Option key={subacc.id} value={subacc.id}>
                    {subacc.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Voucher Number"
              name="voucher_number"
              rules={[{ required: true, message: "Please enter the end time" }]}
            >
              <Input placeholder="Enter Voucher Number" readOnly />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Voucher Date"
              name="voucher_date"
              rules={[{ required: true, message: "Please enter the end time" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Vendor Name"
              name="vendor"
              rules={[
                {
                  required: true,
                  message: "Please select an appointment type",
                },
              ]}
            >
              <Select placeholder="Select type">
                {filteredVendors?.map((vendor) => (
                  <Select.Option key={vendor.id} value={vendor.id}>
                    {vendor.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Vendor Invoice No"
              name="vendor_invoice_no"
              rules={[{ required: true, message: "Please enter the end time" }]}
            >
              <Input placeholder="123" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Amount"
              name="amount"
              rules={[
                {
                  required: true,
                  message: "Please select an appointment type",
                },
              ]}
            >
              <Input placeholder="Enter Amount" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Container No"
              name="container_number"
              rules={[{ required: true, message: "Please enter the end time" }]}
            >
              <Input placeholder="2024-11-06" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Booking No"
              name="booking_number"
              rules={[{ required: true, message: "Please enter the end time" }]}
            >
              <Input placeholder="2024-11-06" />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              label="Due Date"
              name="due_date"
              rules={[{ required: true, message: "Please enter the end time" }]}
            >
              {button === "save" ? (
                <DatePicker style={{ width: "100%" }} />
              ) : (
                <DatePicker style={{ width: "100%" }} disabled />
              )}
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Attachment" name="attachment">
              <Upload
                id="uploadInput"
                action="/upload"
                beforeUpload={() => false}
                listType="picture"
                style={{
                  width: "100%",
                }}
              >
                <Button
                  type="dashed"
                  icon={<UploadOutlined />}
                  style={{
                    width: "100%",
                    border: "1px solid #ccc",
                    padding: 18,
                  }}
                >
                  Upload File
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          {isDueDateEnabled && (
            <Col span={16} style={{ marginBottom: 10 }}>
              <Button
                type="primary"
                style={{ width: "30%" }}
                onClick={handleSaveDueDate}
              >
                Save
              </Button>
            </Col>
          )}
          <Col span={24}>
            <Form.Item label="Description" name="description">
              <Input.TextArea
                placeholder="Enter special notes here..."
                rows={5}
              />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}>
          {isSave && (
            <>
              <Col span={4} style={{ textAlign: "right" }}>
                <Form.Item style={{ marginTop: 10, textAlign: "right" }}>
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    loading={loading}
                    onClick={() => {
                      setIsSave(false);
                      setButton(null);
                      form.setFieldsValue({ DueDate: moment() });
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                <Form.Item style={{ marginTop: 10, textAlign: "right" }}>
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    htmlType="submit"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </>
          )}
          <Col span={8} style={{ textAlign: "right" }}>
            <Form.Item style={{ marginTop: 10, textAlign: "right" }}>
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={() => {
                  setButton("save");
                  setIsSave(true);
                }}
                loading={loading}
              >
                Save For Future Payment
              </Button>
            </Form.Item>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <Form.Item style={{ marginTop: 10, textAlign: "right" }}>
              <Button
                type="primary"
                style={{ width: "100%" }}
                htmlType="submit"
                onClick={() => setButton("pay")}
                loading={loading}
                disabled={isSave}
              >
                Make a Payment
              </Button>
            </Form.Item>
          </Col>
        </div>
      </Form>
    </Modal>
  );
}
export default AddExpense;

import React, { useEffect, useState } from "react";
import { Table, Space, Button, Row, Col, Input, Tag } from "antd";
import {
  SearchOutlined,
  SendOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { invoiceService } from "../../../services";
import { Link } from "react-router-dom";
function Invoice() {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "#",
    //   key: "_id",
    //   render: (text, record, index) =>
    //     (pagination.current - 1) * pagination.pageSize + index + 1,
    // },
    {
      title: "Id",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => name || "N/A",
    },
    {
      title: "Chasis Number",
      dataIndex: "vehicles",
      key: "ChasisNumber",
      render: (vehicles) => vehicles?.[0]?.chasis_no || "N/A",
    },
    {
      title: "Container Number",
      dataIndex: "container_number",
      key: "ContainerNumber",
      render: (container_number) => container_number || "N/A",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "Discount",
      render: (discount) => discount || "N/A",
    },
    {
      title: "Payment Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Tag
          type="primary"
          size="small"
          style={{
            backgroundColor:
              record.is_partial === 1
                ? "orange"
                : record.is_partial === 2
                ? "#06D652"
                : "#E4D766",
            color:
              record.is_partial === 1
                ? "white"
                : record.is_partial === 2
                ? "white"
                : "black",
          }}
        >
          {record.is_partial === 1
            ? "Partial"
            : record.is_partial === 2
            ? "Paid"
            : "Unpaid"}
        </Tag>
      ),
    },
    {
      title: "Invoice Status",
      dataIndex: "status",
      key: "InvoiceStatus",
      render: (status) => (
        <Tag
          type="primary"
          size="small"
          style={{
            backgroundColor:
              status === 1
                ? "#06D652"
                : status === 0
                ? "#B83D3D"
                : "transparent",
            borderColor:
              status === 1
                ? "#06D652"
                : status === 0
                ? "#B83D3D"
                : "transparent",
            color:
              status === 1 ? "white" : status === 0 ? "white" : "transparent",
          }}
        >
          {status === 1 ? "Approved" : "Not Approved"}
        </Tag>
      ),
    },

    {
      title: "Actions",
      key: "Actions",
      render: (text, record, { _id }) => (
        <Space size="small">
          <Button type="primary" size="small">
            <SendOutlined />
          </Button>
          <Link to={`/invoice/view/${record.id}`}>
            <Button type="primary" size="small">
              <EyeOutlined />
            </Button>
          </Link>
          <Link>
            <Button type="primary" size="small">
              <DeleteOutlined />
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.pageSize) => {
    setLoading(true);
    const { success, data } = await invoiceService.fetch(page);
    if (success) {
      setInvoices(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);
  return (
    <div
      style={{ padding: "10px", border: "1px solid #ccc", borderRadius: "8px" }}
    >
      <Row
        gutter={24}
        style={{ padding: "5px", borderRadius: "4px" }}
        className="flex justify-between"
      >
        <Col span={10}>
          <Space direction="horizontal" size={15}>
            <h5 className="font-bold">Invoices</h5>
          </Space>
        </Col>
        <Col span={14} style={{ textAlign: "right" }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={invoices}
        style={{ marginTop: "5px" }}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        loading={loading}
      />
    </div>
  );
}
export default Invoice;

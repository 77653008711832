import React from "react";
import './App.css';
import Navigation from "./navigation";
import store from './redux/store'
import { Provider } from 'react-redux'

function App() {
        return (
                <>
                        <Provider store={store}>
                                <Navigation />
                        </Provider>
                </>
        )
}
export default App;

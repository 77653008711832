import React, { useEffect, useState } from "react";
import {Table,Space,Button,Row,Col,Input,Select,Tag,Modal,notification} from "antd";
import {SearchOutlined, FilePdfOutlined, DeleteOutlined, EditOutlined,} from "@ant-design/icons";
import { Divider } from "antd";
import {dropdownService,expenseVoucherService,vendorService,} from "../../../../../services";
import AddExpense from "./AddExpense";
import PayablePayment from "./PayablePayments";
import EditExpense from "./EditExpense";

function ExpenseVoucher() {
  const [banks, setBanks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vouchers, setVouchers] = useState([]);
  const [expenseAcc, setExpenseAcc] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [subAccount, setSubAccount] = useState([]);
  const [paymentmodes, setPaymentmodes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [isModalShow, setIsModalShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [voucher_number, setVoucher_number] = useState(null);
  const [refresh, setRefresh] = useState(true);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [editRecord, setEditRecord] = useState(null);

  const openModal = () => setIsModalOpen(true);
  const showModal = () => setIsModalShow(true);
  const handleEdit = async (record) => {
    setEditModal(true);
  };

  const options = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "9",
      label: "9",
    },
  ];
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      // render: (text, record, index) => index + 1,
    },
    {
      title: "Voucher Number",
      dataIndex: "voucher_number",
      key: "voucher_number",
    },
    {
      title: "Expense Account",
      dataIndex: ["expense_category", "name"],
      key: "expense_category",
    },
    {
      title: "Expense Sub Account",
      dataIndex: ["expense_sub_category", "name"],
      key: "expense_sub_category",
    },
    {
      title: "Vendor",
      dataIndex: "vendor_name",
      key: "vendor_name",
    },
    {
      title: "Vendor Invoice",
      dataIndex: "vendor_invoice_no",
      key: "vendor_invoice_no",
    },
    {
      title: "Amount($)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Booking Container",
      dataIndex: "booking_container",
      key: "booking_container",
      render: (text, record) => (
        <>
          {record.booking_number} <br /> {record.container_number}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Tag
          type="primary"
          size="small"
          style={{
            backgroundColor:
              record.is_paid === 0
                ? "yellow"
                : record.is_paid === 1
                ? "#1fd655"
                : "transparent",
            color: record.is_paid === 0 ? "black" : "white",
          }}
        >
          {record.is_paid === 1 ? "Paid" : "Payable"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "Action",
      fixed:"right",
      render: (text, record) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              setEditRecord(text);
              handleEdit(text);
            }}
          >
            <EditOutlined />
          </Button>
          <Button type="primary" size="small">
            <FilePdfOutlined />
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              setSelectedRecord(record.id);
              setDeleteModal(true);
            }}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const handleDelete = async () => {
    setLoading(true);
    const response = await expenseVoucherService.deleteExpense(selectedRecord);
    if (response.success) {
      notification.success({
        message: "Deleted Successfully!",
        description: response.message,
        placement: "topRight",
      });
    }
    initialize(pagination.current, pagination.pageSize);
    setLoading(false);
    setSelectedRecord(null);
    setDeleteModal(false);
  };

  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    const { success, data } = await expenseVoucherService.fetch(page);
    if (success) {
      setVouchers(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
    setVoucher_number(Number(data?.data[0]?.voucher_number ?? 0) + 1);
    setLoading(false);
  };

  const getVendors = async () => {
    const response = await vendorService.fetchAll();
    if (response.success) {
      setVendors(response.data);
    }
  };

  const getExpenseCat = async () => {
    const response = await expenseVoucherService.fetchCategories();
    if (response.success) {
      setExpenseAcc(response.data);
    }
  };
  const getSubAccount = async () => {
    const response = await expenseVoucherService.fetchSubAccounts();
    if (response.success) {
      setSubAccount(response.data);
    }
  };

  const getPaymentModes = async () => {
    const response = await dropdownService.fetchPaymentModes();
    if (response.success) {
      setPaymentmodes(response.data);
    }
  };

  const getCompanies = async () => {
    const response = await dropdownService.fetchCompanies();
    if (response.success) {
      setCompanies(response.data);
    }
  };

  const getBanks = async () => {
    const response = await dropdownService.fetchBanks();
    if (response.success) {
      setBanks(response.data);
    }
  };

  useEffect(() => {
    if (refresh) {
      initialize(pagination.current, pagination.pageSize);
      setEditRecord(null);
    } else {
      setRefresh(false);
    }
    getVendors();
    getExpenseCat();
    getSubAccount();
    getPaymentModes();
    getCompanies();
    getBanks();
  }, [pagination.current, pagination.pageSize, refresh]);

  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={[24, 16]} align="middle">
        <Col xs={24} md={8}>
          <h1 style={{ fontWeight: "bold", margin: 0 }}>Expense Voucher</h1>
        </Col>
        <Col xs={24} md={16} style={{ textAlign: "right" }}>
          <Space wrap>
            <Button type="primary" onClick={showModal}>
              Add New Expense
            </Button>
            <Button type="primary" onClick={openModal}>
              Add Payable Payments
            </Button>
          </Space>
        </Col>
      </Row>
      <Divider style={{ marginTop: 15 }} />
      <Row
        gutter={8}
        style={{
          marginTop: "15px",
          background: "#F2F2F7",
          padding: "5px",

          alignItems: "center",
        }}>
        <Col
          span={4}
          xs={24}
          sm={12}
          md={6}
          lg={4}
          style={{ display: "flex", alignItems: "center" }}>
          <Space direction="horizontal" size={10}>
            <p style={{ margin: 0 }}>Show</p>
            <Select
              defaultValue={10}
              options={options}
              size="small"
              style={{ width: 70, fontSize: 14 }}
            />
            <p style={{ margin: 0 }}>entries</p>
          </Space>
        </Col>

        {/* Second Column: Right-Aligned Search */}
        <Col
          span={20}
          xs={24}
          sm={12}
          md={18}
          lg={20}
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{
              width: "30%",
              borderRadius: "4px",
              fontSize: 14,
            }}
          />
        </Col>
      </Row>

      <AddExpense
        isVisible={isModalShow}
        setIsModalShow={setIsModalShow}
        vendors={vendors}
        subAccount={subAccount}
        expenseAcc={expenseAcc}
        setRefresh={setRefresh}
        voucher_number={voucher_number}
        record={editRecord ? editRecord : undefined}
      />

      <PayablePayment
        isVisible={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        paymentmodes={paymentmodes}
        banks={banks}
        companies={companies}
        vendors={vendors}
        vouchers={vouchers}
        setRefresh={setRefresh}
      />
      <EditExpense
        isVisible={editModal}
        setEditModal={setEditModal}
        vendors={vendors}
        subAccount={subAccount}
        expenseAcc={expenseAcc}
        setRefresh={setRefresh}
        voucher_number={voucher_number}
        record={editRecord ? editRecord : undefined}
      />
      <Modal
        title={<div style={{ textAlign: "center" }}>Confirm</div>}
        open={deleteModal}
        onOk={handleDelete}
        onCancel={() => {
          setSelectedRecord(null);
          setDeleteModal(false);
        }}
        centered
        styles={{
          body: {
            padding: "20px",
          },
        }}
        width={300}
        footer={
          <div className="flex justify-center gap-3">
            <Button
              type="default"
              onClick={() => {
                setSelectedRecord(null);
                setDeleteModal(false);
              }}
              style={{ width: "30%" }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleDelete}
              style={{ width: "30%" }}
              loading={loading}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: "center" }}>
          Are you sure you want to delete this?
        </p>
      </Modal>

      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={vouchers}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        loading={loading}
        style={{ marginTop: "10px" }}
      />
    </div>
  );
}

export default ExpenseVoucher;

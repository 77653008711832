import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  DatePicker,
  Upload,
  notification,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Select, Typography, Spin } from "antd";
import { useParams } from "react-router-dom";
import moment from "moment";
import employeeService from "../../../services/employee-service";

const { Option } = Select;
const { Title } = Typography;

function EmployeeForm() {
  const { id } = useParams(); // Get 'id' from the URL
  const [form] = Form.useForm();
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [empInfo, setEmpInfo] = useState();
  const [submitLoad, setSubmitLoad] = useState(false);

  const initialize = async () => {
    const { success: fetchsuccess, data: fetchdata } =
      await employeeService.fetchPermissions();
    if (fetchsuccess) {
      setPermissions(fetchdata);
      //   setSelectedEntities(fetchdata.map((entity) => entity.id));
    }
    if (id) {
      const { success: viewsuccess, data: viewData } =
        await employeeService.viewEmployee(id);
      if (viewsuccess) {
        setEmpInfo(viewData);
        const selectedPermissionObjects = viewData.permissions;
        setSelectedEntities(selectedPermissionObjects);

        // Set initial values in the form
        form.setFieldsValue({
          name: viewData.name,
          address: viewData.address,
          cnic: viewData.cnic,
          dob: viewData.dob ? viewData.dob : null,
          joining_date: viewData.joining_date
            ? moment(viewData.joining_date)
            : null,
          qualification: viewData.qualification,
          salary: viewData.salary,
          designation: viewData.designation,
          username: viewData.username,
        });
      }
    }
  };

  const handleSelectChange = (value) => {
    const selectedObjects = value.map((id) =>
      permissions.find((permission) => permission.id === id)
    );
    setSelectedEntities(selectedObjects);
  };

  const calculateWidth = (dataLength) => {
    const baseWidth = 400;
    const additionalWidth = dataLength * 20;
    return baseWidth + additionalWidth;
  };

  const isEditMode = id !== undefined;

  useEffect(() => {
    initialize();
  }, [id]);

  // Form submission handler
  const handleSubmit = async () => {
    setSubmitLoad(true);
    try {
      const values = await form.validateFields();
      const formatDate = values.joining_date.toString();
      const joining_date = moment(formatDate).format("YYYY-MM-DD");
      const stringifiedEntities = selectedEntities.map((entity) =>
        entity.id.toString()
      );
      const updatedEmployee = {
        ...values,
        dob: values.dob ? values.dob : null,
        joining_date: values.joining_date ? joining_date : null,
        permissions: stringifiedEntities,
        image: {},
      };

      if (!isEditMode) {
        updatedEmployee.password = values.password;
      }

      let response;

      if (isEditMode) {
        // For editing an employee, include the id and send to editEmployee API
        response = await employeeService.editEmployee(id, updatedEmployee);
      } else {
        console.log(updatedEmployee);
        // For adding an employee, send the data to addEmployee API without an id
        response = await employeeService.addEmployee(updatedEmployee);
      }

      if (response.success) {
        setSubmitLoad(false);
        if (!isEditMode) {
          form.resetFields();
        }
        notification.success({
          message: "Success",
          description: isEditMode
            ? "Employee updated successfully!"
            : "Employee added successfully",
          placement: "topRight",
        });
      } else {
        notification.error({
          message: "Error",
          description: `Error: ${response.message}`,
          placement: "topRight",
        });
      }
    } catch (error) {
      console.log("Error during form submission:", error);
    }
  };

  return (
    <div
      style={{
        padding: "0 14px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row
        gutter={26}
        style={{
          backgroundColor: "#FBFBFB",
          marginBottom: 15,
          padding: "10px",
          borderRadius: "4px",
          borderBottom: "1px solid #E8E8E9",
        }}
      >
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>
            {" "}
            {isEditMode ? "Edit Employee" : "Add Employee"}
          </h1>
        </Col>
      </Row>

      <Form requiredMark={false} layout="vertical" form={form}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="Name" name="name">
              <Input placeholder="" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Address" name="address">
              <Input placeholder="" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="CNIC" name="cnic">
              <Input placeholder="28-Oct-2024" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Date of Birth" name="dob">
              <Input placeholder="0" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label=" Date of Joining" name="joining_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Qualification" name="qualification">
              <Input placeholder="" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Salary" name="salary">
              <Input placeholder="" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Designation" name="designation">
              <Input placeholder="" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Username" name="username">
              <Input placeholder="" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Password" name="password">
              <Input placeholder="" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Profile Image" style={{ width: "100%" }}>
              <Upload
                listType="picture"
                maxCount={1}
                beforeUpload={() => false}
                style={{ width: "100%" }}
              >
                <Button icon={<UploadOutlined />} style={{ width: "100%" }}>
                  Choose File
                </Button>
                {/* <Input
                  style={{
                    width: "100%",
                  }}
                /> */}
              </Upload>
            </Form.Item>
          </Col>

          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                width: calculateWidth(permissions.length),
                margin: "30px",
              }}
            >
              <Form.Item>
                <Title level={5}>Permissions</Title>
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select entities"
                  value={selectedEntities.map((entity) => entity.id)}
                  onChange={handleSelectChange}
                >
                  {permissions.map((permission) => (
                    <Option key={permission.id} value={permission.id}>
                      {permission.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </Col>

          <Col span={24}>
            <Form.Item style={{ textAlign: "center", marginTop: 10 }}>
              <Button
                type="primary"
                style={{ width: "50%" }}
                onClick={handleSubmit}
                loading={submitLoad}
              >
                {isEditMode ? "EDIT EMPLOYEE" : "ADD EMPLOYEE"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default EmployeeForm;

import { Button, Col, Form, Row, Upload } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas';
import { profileService } from '../../services';
import moment from 'moment';
// import SignaturePad from "react-signature-canvas";
function TermAndConditionCustomer() {
  const signatureRef = useRef(null);
  const [imageURL, setImageURL] = useState(null);
  const [user,setUser]=useState([])
  // const sigCanvas = useRef({});
  const clear = () => signatureRef.current.clear();
  const save = () => {
    const image = signatureRef.current.getTrimmedCanvas().toDataURL("image/png");
    console.log(image)
    setImageURL(image);
  }

const loadAuthUser=async()=>{
const response = await profileService.fetchLoggedUser()
if(response.data){
  setUser(response.data)
}
} 

  useEffect(() => {
    loadAuthUser()
    if (signatureRef.current) {
      const canvas = signatureRef.current.getCanvas();
      const ctx = canvas.getContext('2d');
      const dpr = window.devicePixelRatio || 1;

      // Adjust canvas size for DPR
      const width = canvas.clientWidth * dpr;
      const height = canvas.clientHeight * dpr;

      canvas.width = width;
      canvas.height = height;

      // Scale context to fix drawing position
      ctx.scale(dpr, dpr);
    }
  }, []);
  return (<>

    <div style={{ display: "flex", justifyContent: "center", marginBottom: "12px" }}>
      <img src="/images/ctrade-logo.png" alt="" width="140px" height="96px" />
    </div>

    <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
      <h4 style={{ fontWeight: "bold", textAlign: "center" }}>Negotiated Rate Agreement</h4>

      <p style={{ fontSize: '12px', fontWeight: "lighter" }}>
        This contract is made by and between:C TRADE USA INC (As SHIPPING/FORWARDING/EXPORTER AGENT ONLY) and {user.name} on {" "}
         {moment(user.created_at).format("DD-MMM-Y")} (as a shipper) for shipping requirements on agreed price according to email applied from the date of services offer / cargo delivered or handled.
      </p>

      <h5 className='mt-4' style={{ fontWeight: "bolder" }}> Terms and Conditions</h5>
      <div style={{ fontSize: '12px', fontWeight: "lighter", marginTop: "20px", display: "flex", flexDirection: "column", gap: "12px" }}>
        <ol>
          <li> Unless otherwise noted, all ocean freight quotations subject to third party/ are: valid for 30 days from the date of original quotation, subject to equipment availability, subject to any and all tariff additions, valid at time initial shipment is received. Inland freight quotations are: subject to third party increases valid at time initial shipment is received, subject to any fuel surcharges valid at time initial shipment is received, subject to weight limitations and weight distribution requirements in accordance with the local and national rules and regulations of the country (ies) of transit, subject to availability of inland carrier at time of booking. Loading, lashing, securing, blocking and bracing of cargo is for shipper’s account. Carrier reserves the right to stow cargo in the best interest of the Vessel and in compliance with local, national and international rules, regulations and conventions. On deck shipments at shipper’s risk. Dangerous cargo, as defined by 49 CFR or the IMDG Code, is subject to the line’s approval at time of booking. Kindly note all vessel dates are subject to changes. Equipment is subject to availability. See C TRADE USA INC ORDER FORM for shipment details to which such rate will apply. Service provided to this NRA is subject to Carrier’s governing rules tariff, which is accessible at in compliance with FMC Regulations as provided in 46 CFR 532.7
          </li>

          <br/>

          <li> 
            <b>INSURACE:</b> We advise all our customers to put insurance on their shipments as there is no insurance on container.
          </li>

          <br/>

          <li>
             Customs hold and inspection: C TRADE USA INC will not be responsible for any delay or Costs incurred due to custom investigation or hold. See bill of Lading Terms 1-34 in Rule 8 of carriers governing rules tariff.
          </li>

          <br/>

          <li> <b>RORO:</b> Vehicle shipments should be empty and operational.
          </li>

          <br/>

          <li>
             <b>PAYMENT:</b> We advise all customers to pay on time as agreed 15 days from the date of invoice. Please allow 3-7 business days before we can process your payment and send you your release. C TRADE USA INC is not responsible for any demurrage or detention occurs at destination due to late payment.
          </li>

          <br/>

          <li> 
            <b>ETA and ETD:</b> All dates of sailing and arriving are estimated and C TRADE USA INC will not be responsible for any delay. See bill of Lading Terms 1-34 in Rule 8 of carriers governing rules tariff.
          </li>

          <br/>

          <li>
             <b>Packing List:</b> Customer is responsible for providing C TRADE USA INC a proper packing list of goods to be loaded at customer location. C TRADE USA INC will not be responsible for any fines, fees and penalties due to failure to provide C TRADE USA INC a proper packing list of goods. Customer will be responsible for Charges incurred due to incorrect packing list.
          </li>

          <br/>

          <li> 
            <b>Declaring Values:</b> C TRADE USA INC requires that the shipper provides values for goods being shipped for Documentary/ Customs purposes. Customer should do their best to provide as accurate information as possible.
          </li>

          <br/>

          <li> 
            CTRADE USA INC WILL NOT REPSONSIBLE FOR ANY DELAY IN RELEASE OF STORAGE OCCURRED ON SHIPMENT ON FACILITATION OF THIRD PARTY.
          </li>

          <br/>

          <li> 
            Customer will be responsible for Charges incurred due to incorrect values or holds caused.
          </li>

          <br/>

          <li> 
            See C TRADE USA INC ORDER FORM for shipment details to which such rate will apply.
          </li>

          <br/>

          <li>
             Any cause of vessel delay / dates change/custom hold title resulting rollovers/per deim CTRADE USA INC will not be responsible for any additional charges occurs on same.
          </li>

          <br/>

          <li>
             <b>STORAGE POLICY IS FIRM Storage Policy:</b> 15 Days free at all facilities there after $ 10/Day/Car from the Day of receiving in and out charges will be $ 100/. Subject to storage charge
          </li>

          <br/>

          <li> 
            <b>Documentation for Title validation:</b> Customer is responsible to provide the all the relevant documents like BOS, notarized POA, passport copy etc. to fulfill the US Customs requirement. If customs holds the documents and in case of any rollover CTRADE USA INC will not be responsible.
            </li>
      </ol>
  
      <p><b>PORT Conditions:</b> CTRADE USA INC will not be responsible in case of any unforeseen reason at Houston Port that effects on the Shipping operations.</p>
      <hr />
    </div>
    <Form layout='vertical' >
      <Row gutter={0} style={{ display: "flex", justifyContent: "flex-start", }}>
        <Col span={24} style={{ textAlign: "" }}>
          <Form.Item>
            <Upload
              id="uploadInput"
              action="/upload"
              listType="picture"
              style={{
                width: '100%', // Ensures the upload spans the full width of its container

              }}>
              <Button
                type="dashed"
                style={{
                  width: '100%',
                  border: '1px solid #ccc',
                  padding: '18px',
                  paddingRight: '0px',
                  borderRadius: "14px"
                }}>
                <div style={{ paddingRight: 8 }}>
                  <img src="/images/Group.png" alt="" width="28px" />
                </div>
                <span style={{ marginRight: "53px" }}>
                  Upload Passport
                </span>
              </Button>
            </Upload>
          </Form.Item>
        </Col>

        <Col span={24} style={{ marginBottom: '8px' }}>
          <Form.Item label="Signature">
            <div
              style={{
                border: '1px solid #ccc',
                borderRadius: '14px',
                overflow: 'hidden',
              }}
            >
              <SignatureCanvas
                ref={signatureRef}
                // penColor="black" // Medium gray using hex code

                penColor="#808080" // Medium gray using hex code
                minWidth={1}
                maxWidth={3}
                canvasProps={{
                  width: 500,
                  height: 100,
                  className: 'sigCanvas',
                  style: {
                    width: '100%',
                    height: '100%',
                  },
                }}
              />
            </div>
          </Form.Item>
        </Col>

        <Col span={24} style={{ borderBottom: "1px solid #ccc" }}>
          <Form.Item >
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: '12%',
                padding: '15px',
                borderRadius: "16px"
              }}
              onClick={clear}>
              Clear Signature
            </Button>
          </Form.Item>
        </Col>
        {/* <Divider/> */}

        <Col span={24} style={{ marginTop: "12px", textAlign: "center" }}>
          <Form.Item >
            <Button
              htmlType="submit"
              style={{
                backgroundColor: "#06D652",
                color: "white",
                width: '10%',
                padding: '16px',
                borderRadius: "16px"
              }}
              onClick={save}
            >
              Save
            </Button>
            {imageURL ? (
              <img
                src={imageURL}
                alt="my signature"
                style={{
                  display: "block",
                  margin: "0 auto",
                  border: "1px solid black",
                  width: "150px"
                }}
              />
            ) : null}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </div >
  </>
  )
}

export default TermAndConditionCustomer

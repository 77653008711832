import ajaxService from "./ajax-service";

class ExpenseVoucher {
  async fetch(page) {
    const response = await ajaxService.get(`/expense-vouchers?page=${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async fetchCategories() {
    const response = await ajaxService.get(`/expense-categories`);
    if (response ?? false) {
      return response;
    }
  }
  async fetchSubAccounts() {
    const response = await ajaxService.get(`/expense-sub-categories`);
    if (response ?? false) {
      return response;
    }
  }

  async addExpense(data) {
    const response = await ajaxService.post(`/expense-vouchers`, data);
    if (response ?? false) {
      return response;
    }
  }
  async makePayment(data) {
    const response = await ajaxService.post(`/store-payable`, data);
    if (response  ?? false) {
      return response;
    }
  }
  async viewExpense(id) {
    const response = await ajaxService.get(`/expense-vouchers/${id}`);
    if (response ?? false) {
      return response;
    }
  }
  async editExpense(data) {
    const response = await ajaxService.post(`/expense-vouchers/update`, data);
    if (response ?? false) {
      return response;
    }
  }
  async deleteExpense(id) {
    const response = await ajaxService.delete(`/expense-vouchers/`, id);
    if (response  ?? false) {
      return response;
    }
  }
}

const expenseVoucherService = new ExpenseVoucher();
export default expenseVoucherService;

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  TimePicker,
  notification,
} from "antd";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { bookingService } from "../../../services";

export default function ScheduleForm({ visible, onClose, editData }) {
  const [form] = Form.useForm();
  const [appointmentActive, setAppointmentActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const isEditMode = editData?.id !== undefined;

  useEffect(() => {
    // console.log(editData?.date)
    if (editData) {
      setAppointmentActive(editData?.isActive === 1 ? true : false);
      form.setFieldsValue({
        ...editData,
        date: moment(editData.date),
        start_time: moment(editData?.start_time, "HH:mm:ss"),
        end_time: moment(editData?.end_time, "HH:mm:ss"),
        isActive: appointmentActive,
      });
    } else {
      form.resetFields();
    }
  }, [editData, form]);

  const handleSubmit = async (values) => {
    setLoading(true);
    const data = {
      ...values,
      date: moment(values.date).format("YYYY-MM-DD"),
      start_time: values.start_time.format("HH:mm:ss"),
      end_time: values.end_time.format("HH:mm:ss"),
      isActive: appointmentActive ? 1 : 0,
    };
    console.log(data);
    const response = isEditMode
      ? await bookingService.updatebooking(editData?.id, data)
      : await bookingService.addbooking(data);
    if (response.success) {
      notification.success({
        message: "Success",
        description: isEditMode
          ? "Booking updated successfully!"
          : "Booking added successfully",
        placement: "topRight",
      });
    } else {
      const errors = response.data?.errors || [response.message];
      notification.error({
        message: "Error",
        description: `Error: ${errors.join(", ")}`,
        placement: "topRight",
      });
    }
    setLoading(false);
    onClose();
  };

  return (
    <div>
      <Modal
        title={
          <div style={{ width: "100%" }}>
            {" "}
            {editData ? "Edit Booking Schedule" : "Add Booking Schedule"}
          </div>
        }
        open={visible}
        // onOk={handleOk}
        onCancel={onClose}
        footer={null}
        closable={true}
        width={600}
        style={{ padding: "15px" }}
      >
        <Form
          layout="vertical"
          form={form}
          requiredMark={false}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Date"
                name="date"
                rules={[{ required: true, message: "Please select a date" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Start time"
                name="start_time"
                rules={[
                  { required: true, message: "Please select a start time" },
                ]}
              >
                <TimePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="End time"
                name="end_time"
                rules={[
                  { required: true, message: "Please select an end time" },
                ]}
              >
                <TimePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Allowed appointments"
                name="allowed_appointments"
                rules={[
                  {
                    required: true,
                    message: "Please enter the number of allowed appointments",
                  },
                ]}
              >
                <Input type="number" placeholder="Enter number" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Checkbox
              checked={appointmentActive}
              onChange={(e) => setAppointmentActive(e.target.checked ? 1 : 0)}
              label="Active"
              name="isActive"
            >
              Is Active
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <div style={{ textAlign: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "60%", textAlign: "center" }}
                loading={loading}
              >
                {editData ? " Update Booking Schedule" : "Add Booking Schedule"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

import ajaxService from "./ajax-service";

class VendorPayment {
  async fetch(page) {
    const response = await ajaxService.get(
      `/accounts/vendor-payments?page=${page}`
    );
    if (response ?? false) {
      return response;
    }
  }
  async addTransaction(data) {
    const response = await ajaxService.post(
      `/accounts/vendor-payments/transaction`,
      data
    );
    if (response ?? false) {
      return response;
    }
  }
}

const vendorPaymentService = new VendorPayment();
export default vendorPaymentService;

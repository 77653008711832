import {Table,Space,Button,Row,Col, Input, Select, Modal,} from "antd";
import {SearchOutlined,FilePdfOutlined,DeleteOutlined,} from "@ant-design/icons";
import { CiSquarePlus } from "react-icons/ci";
import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import AddPaymentModal from "./AddPayment";
import {
  dropdownService,
  paymentVoucherService,
  vendorService,
} from "../../../../../services";

function PaymentVoucher() {
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [companies, setCompanies] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [paymentModes, setPaymentModes] = useState([]);
  const [banks, setBanks] = useState([]);
  const [isModalShow, setIsModalShow] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const showModal = () => setIsModalShow(true);

  const showDeleteModal = (record) => {
    setSelectedRecord(record);
    setDeleteModal(true);
  };

  // const [data2, setData2] = useState([]);

  const options = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "9",
      label: "9",
    },
  ];
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      // render: (text, record, index) => index + 1,
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
    },
    {
      title: "PV Number",
      dataIndex: "voucher_number",
      key: "voucher_number",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (text, record) => {
        const company = companies.find((comp) => comp.id === record.company_id);
        return company ? company.name : "Unknown Company";
      },
    },
    {
      title: "Vendor",
      dataIndex: "Vendor",
      key: "Vendor",
      render: (text, record) => {
        const vendor = vendors.find((vendor) => vendor.id === record.vendor_id);
        return vendor ? vendor.name : "Unknown Vendor";
      },
    },
    {
      title: "Total Amount($)",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Action",
      key: "Action",
      render: (text, record, { _id }) => (
        <Space size="small">
          <Button type="primary" size="small">
            <FilePdfOutlined />
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => showDeleteModal(record)}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    const { success, data } = await paymentVoucherService.fetch(page);
    if (success) {
      setPayments(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };

  const getCompanies = async () => {
    const response = await dropdownService.fetchCompanies();
    if (response.success) {
      setCompanies(response.data);
    }
  };
  const getVendors = async () => {
    const response = await vendorService.fetchAll();
    if (response.success) {
      setVendors(response.data);
    }
  };
  const getPaymentModes = async () => {
    const response = await dropdownService.fetchPaymentModes();
    if (response.success) {
      setPaymentModes(response.data);
    }
  };
  const getBanks = async () => {
    const response = await dropdownService.fetchBanks();
    if (response.success) {
      setBanks(response.data);
    }
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
    getCompanies();
    getVendors();
    getPaymentModes();
    getBanks();
  }, [pagination.current, pagination.pageSize]);

  const handleDelete = async () => {
    setLoading(true)
    if (!selectedRecord || !selectedRecord.id) {
      console.error("No payment voucher selected for deletion.");
      return;
    }
    const response = await paymentVoucherService.deletePayment(
      selectedRecord.id
    );

    if (response.success) {
      setPayments((prevData) =>
        prevData.filter((item) => item.id !== selectedRecord.id)
      );
      initialize(pagination.current, pagination.pageSize);
    } else {
      console.error("Failed to delete the customer.");
    }
    setLoading(false)
    setDeleteModal(false);
  };

  const handleCancel = () => {
    setSelectedRecord(null);
    setDeleteModal(false);
  };
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Payment Voucher</h1>
        </Col>
      </Row>
      <Divider style={{ marginTop: 15 }} />
      <Col
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <CiSquarePlus
          size={30}
          style={{ cursor: "pointer" }}
          onClick={showModal}
        />
      </Col>
      <Row
        gutter={8}
        style={{
          marginTop: "15px",
          background: "#F2F2F7",
          padding: "5px",

          alignItems: "center", // Ensures alignment of items within the row
        }}
      >
        <Col
          span={4}
          xs={24}
          sm={12}
          md={6}
          lg={4}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Space direction="horizontal" size={10}>
            <p style={{ margin: 0 }}>Show</p>
            <Select
              defaultValue={10}
              options={options}
              size="small"
              style={{ width: 70, fontSize: 14 }}
            />
            <p style={{ margin: 0 }}>entries</p>
          </Space>
        </Col>

        {/* Second Column: Right-Aligned Search */}
        <Col
          span={20}
          xs={24}
          sm={12}
          md={18}
          lg={20}
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{
              width: "30%",
              borderRadius: "4px",
              fontSize: 14,
            }}
          />
        </Col>
      </Row>

      <Modal
        title={<div style={{ textAlign: "center" }}>Confirm</div>}
        open={isDeleteModal}
        onCancel={handleCancel}
        centered
        styles={{
          body: {
            padding: "20px",
          },
        }}
        width={300}
        footer={
          <div className="flex justify-center gap-3">
            <Button
              type="default"
              onClick={handleCancel}
              style={{ width: "30%" }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleDelete}
              style={{ width: "30%" }}
              loading={loading}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: "center" }}>
          Are you sure you want to delete this?
        </p>
      </Modal>

      <AddPaymentModal
        visible={isModalShow}
        setIsModalShow={setIsModalShow}
        vendors={vendors}
        companies={companies}
        paymentModes={paymentModes}
        banks={banks}
      />

      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={payments}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        loading={loading}
        style={{ marginTop: "10px" }}
      />
    </div>
  );
}

export default PaymentVoucher;

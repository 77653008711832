import React, { useState, useEffect } from "react";
import { Table, Space, Button, Row, Col, Modal } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { quotationService } from "../../../services";

function Quotation() {
  const navigate = useNavigate();

  const [quotations, setQuotations] = useState([]);
  const [loading, setLoading] = useState(false);
  const editQuotation = (id) => navigate(`/operation/quotations/form/${id}`);
  const [isModalshow, setIsModalShow] = useState();
  const [selectedDeleteRecord, setSelectedDeleteRecord] = useState(null);

  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });

  const showModal = (record) => {
    setSelectedDeleteRecord(record);
    setIsModalShow(true);
  };

  const handleOk = async () => {
    setLoading(true);
    const response = await quotationService.deleteQuotation(
      selectedDeleteRecord.id
    );
    if (response.success) {
      setQuotations((prevData) =>
        prevData.filter((item) => item.id !== selectedDeleteRecord.id)
      );
      initialize();
    } else {
      console.error("Failed to delete the customer.");
    }
    setIsModalShow(false);
    setLoading(false);
  };

  const handleCancel = () => {
    setIsModalShow(false);
  };

  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "#",
    //   key: "#",
    //   render: (text, record, index) =>
    //     (pagination.current - 1) * pagination.pageSize + index + 1,
    // },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Validity",
      dataIndex: "validity_date",
      key: "validity_date",
    },

    {
      title: "Actions",
      key: "Actions",
      render: (text, record) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            onClick={() => editQuotation(record.id)}
          >
            <EditOutlined />
          </Button>

          <Link to={`/operation/quotations/views/${record.id}`}>
            <Button type="primary" size="small">
              <EyeOutlined />
            </Button>
          </Link>

          <Button type="primary" size="small" onClick={() => showModal(record)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.pageSize) => {
    setLoading(true);
    const { success, data } = await quotationService.fetch(page);
    if (success) {
      setQuotations(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize();
  }, []);
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Quotations</h1>
        </Col>
      </Row>
      {/* delete */}
      <Modal
        title={<div style={{ textAlign: "center" }}>Confirm</div>}
        open={isModalshow}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        style={{
          padding: "20px",
        }}
        width={300}
        footer={
          <div className="flex justify-center gap-3">
            <Button
              type="default"
              onClick={handleCancel}
              style={{ width: "30%" }}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: "center" }}>
          Are you sure you want to delete this Quotation?
        </p>
      </Modal>

      <Table
        columns={columns}
        loading={loading}
        scroll={{ x: "max-content" }}
        dataSource={quotations}
        style={{ marginTop: "20px" }}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
      />
    </div>
  );
}

export default Quotation;

import { Col, Row, Table, Watermark } from 'antd'
import React from 'react'

export default function LoadPlanPdf() {
    const columns = [
        {
            title: "S.NO",
            dataIndex: 'Sno',
            key: 'Sno',
            width: 30,
            render: (text, record, index) => index + 1,
        },
        {
            title: "REF#",
            dataIndex: 'Ref',
            key: 'Ref',
            width: 20,
        },
        {
            title: "YR",
            dataIndex: 'Year',
            key: 'Year',
            width: 20,
        },
        {
            title: "MAKE/MODEL",
            dataIndex: 'MakeModel',
            key: 'MakeModel',
            width: 150,
        },
        {
            title: "Vin#",
            dataIndex: 'VinNumbers',
            key: 'VinNumbers',
            width: 50,

        },
        {
            title: "KEY",
            dataIndex: 'Keys',
            key: 'Keys',
            // width: 20,
        },
    ]
    const data = [{
        Sno: "1",
        Ref: "LT-75",
        Year: "2024",
        MakeModel: "FORD / SUPER DUTY F550",
        VinNumbers: "1FDUF5HT0RDA27180",
        Keys: "YES",
    },
    {
        Sno: "2",
        Ref: "LT-75",
        Year: "2024",
        MakeModel: "FORD / SUPER DUTY F550",
        VinNumbers: "1FDUF5HT0RDA27180",
        Keys: "YES",
    }]

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", width: '100%', alignItems: "center", margin: "0 auto" }} >
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '70%',
                }}>

                    <img src="/images/ctrade-logo.png" alt="" width="130px" />

                    <h1 style={{ textAlign: "center", fontSize: 16, fontWeight: "bold", marginTop: 50, color: "#B83D3D" }}>
                        LOADING PLAN
                    </h1>
                </div>

                <div
                    style={{
                        position: 'relative',
                        border: '1px solid black',
                        width: "70%",
                        // minHeight: '100vh', // Ensures the container has height
                        backgroundColor: '#fff',
                        overflow: 'hidden', // Prevents content from spilling out
                    }}
                >
                    {/* Watermark Background */}
                    <div
                        style={{
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundImage: 'url("/images/ctrade-logo.png")', // Path to your image
                            backgroundSize: '600px', // Adjust the size of the watermark
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            opacity: 0.3, // Set transparency for the background image
                            zIndex: 0, // Ensures the watermark is behind the content
                        }}
                    ></div>

                    {/* Main Content */}
                    <div style={{ position: 'relative', zIndex: 1 ,opacity  : 0.9}}>
                        <p style={{ borderBottom: '1px solid black', padding: 3, textAlign: 'center' }}>
                            LOAD PLAN: HOUSTON-2723-71633
                        </p>
                        <p style={{ borderBottom: '1px solid black', padding: 3, textAlign: 'center' }}>
                            CONTAINER NO: MSKU4821530
                        </p>
                        <p style={{ borderBottom: '1px solid black', padding:3, textAlign: 'center' }}>
                            SEAL NO: 09275023
                        </p>
                        <p style={{ borderBottom: '1px solid black', padding: 3, textAlign: 'center' }}>
                            SIZE : 45
                        </p>

                        <Table columns={columns} dataSource={data} pagination={false} />

                        <p style={{ borderBottom: '1px solid black', padding:3, textAlign: 'center' }}>Notes</p>
                        <p style={{ borderBottom: '1px solid black', padding: 3, textAlign: 'center' }}>
                            Need 30 PICTURES MINIMUM FOR EACH CONTAINER LOADING PROCESS.
                        </p>
                        <p style={{ borderBottom: '1px solid black', padding: 3, textAlign: 'center' }}>
                            ALWAYS TAKE PICTURE OF CATALYTIC CONVERTER FOR EACH CAR WHILE LOADING. (4 OR 3 EXTRA PICTURES)
                        </p>
                        <p style={{ borderBottom: '1px solid black', padding:3, textAlign: 'center' }}>
                            PICTURE OF CAR GETTING INSIDE OF CONTAINER IS A MUST.
                        </p>
                        <p style={{ borderBottom: '1px solid black', padding: 3, textAlign: 'center' }}>
                            NEED A CLEAR PICTURE OF CAR REF#, CAR COLOR SHOULD BE VISIBLE (NO DARK PICTURES)
                        </p>
                        <p style={{ borderBottom: '1px solid black', padding: 3, textAlign: 'center' }}>
                            CONTAINER WILL NOT LEAVE THE DOCK UNTIL IT'S VERIFIED BY SYED HAIDER
                        </p>
                        <p style={{ textAlign: 'center' }}>
                            IT'S MANDATORY THAT AUTOMOBILE BATTERIES MUST BE DISCONNECTED AND INSULATED, AND THE AUTOMOBILE FUEL TANK MUST BE DRAINED.
                        </p>
                    </div>
                </div>




            </div>
        </>
    )
}

import React from "react";
import { Table, Button, Row, Col, Space, Input, Select } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined, SearchOutlined } from "@ant-design/icons";

function CustomerAppoinments() {
    const options = [
        {
            value: "10",
            label: "10",
        },
        {
            value: "9",
            label: "9",
        },
    ];
    const columns = [
        {
            title: "#",
            dataIndex: "#",
            key: "#   ",
            //   render: (text, record, index) =>
            //     (pagination.current - 1) * pagination.pageSize + index + 1,
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Time",
            dataIndex: "time",
            key: "time",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        //   render: (status, record) => {
        //     const { approved, reject, pending } = record;
        //     // Define the status map for colors and text
        //     const statusMap = {
        //       0: { color: "#D68606", text: "Pending", count: pending },
        //       1: { color: "#06D652", text: "Approved", count: approved },
        //       3: { color: "#B83D3D", text: "Reject", count: reject },
        //     };

        //     // Conditionally render tags based on the status
        //     return (
        //       <Space>
        //         {status === 2 && (
        //           // If status is 2, show "Canceled"
        //           <Tag
        //             color="#F04949"
        //             style={{ fontSize: "12px", padding: "5px 5px" }}
        //           >
        //             Canceled
        //           </Tag>
        //         )}
        //         {/* // Otherwise, show Approved, Reject, and Pending based on their
        //         counts */}
        //         <>
        //           {approved > 0 && (
        //             <Tag
        //               color="#06D652"
        //               style={{ fontSize: "12px", padding: "5px 5px" }}
        //             >
        //               Approved ({approved})
        //             </Tag>
        //           )}
        //           {reject > 0 && (
        //             <Tag
        //               color="#B83D3D"
        //               style={{ fontSize: "12px", padding: "5px 5px" }}
        //             >
        //               Reject ({reject})
        //             </Tag>
        //           )}
        //           {pending > 0 && (
        //             <Tag
        //               color="#D68606"
        //               style={{ fontSize: "12px", padding: "5px 5px" }}
        //             >
        //               Pending ({pending})
        //             </Tag>
        //           )}
        //         </>
        //       </Space>
        //     );
        //   },
        // },
        {
            title: "Actions",
            key: "Actions",
            render: (text, record) => (
                <Space size="small">
                    <Button type="primary" size="small">
                        <EditOutlined />
                    </Button>
                    <Button type="primary" size="small">
                        <EyeOutlined />
                    </Button>
                    <Button type="primary" size="small" >
                        <DeleteOutlined />
                    </Button>
                </Space>
            ),
        },
    ];
    const data = [
        {
        } ]
    return (
        <div
            style={{
                padding: "0px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
            }}
        >
            <Row gutter={16} className="pl-4 pt-6">
                <Col span={12}>
                    <h1 style={{ fontWeight: "bold" }}>Appointments</h1>
                </Col>
            </Row>
            <Row
                gutter={0}
                style={{
                    marginTop: "10px",
                    background: "#F2F2F7",
                    alignItems: "center", // Ensures alignment of items within the row
                }}
                className="pl-4 pr-4 pt-1 pb-1"
            >
                <Col
                    span={4}
                    xs={24}
                    sm={12}
                    md={6}
                    lg={4}
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <Space direction="horizontal" size={10}>
                        <p style={{ margin: 0 }}>Show</p>
                        <Select
                            defaultValue={10}
                            options={options}
                            size="small"
                            style={{ width: 70, fontSize: 14 }}
                        />
                        <p style={{ margin: 0 }}>entries</p>
                    </Space>
                </Col>

                {/* Second Column: Right-Aligned Search */}
                <Col
                    span={20}
                    xs={24}
                    sm={12}
                    md={18}
                    lg={20}
                    style={{
                        textAlign: "right",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <Input
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                        style={{
                            width: "30%",
                            borderRadius: "4px",
                            fontSize: 14,
                        }}
                    />
                </Col>
            </Row>
            <Table
                columns={columns}
                scroll={{ x: "max-content" }}
                dataSource={data}
                style={{ padding: "10px" }}
            />
        </div>
    );
}

export default CustomerAppoinments;

import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, DatePicker, Button, notification } from "antd";
import { Select } from "antd";
import { dropdownService, invoiceService } from "../../../services";

function AddInvoice() {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [form] = Form.useForm();

  const getCustomers = async () => {
    const response = await dropdownService.fetchCustomers();
    if (response.success) {
      setCustomers(response.data);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    const data = {
      ...values,
      date: values.date.format("YYYY-MM-DD"),
    };
    const response = await invoiceService.generateInvoice(data);
    if (response.success) {
      notification.success({
        message: "Invoice generated successfully!",
        description: response.message,
        placement: "topRight",
      });
    }
    form.resetFields();
    setLoading(false);
  };

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <>
      <div
        style={{
          padding: "0 14px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Col span={24} className="mt-5">
          <h1 style={{ fontWeight: "bold" }}>Statement Of Account</h1>
        </Col>

        <Form
          requiredMark={false}
          layout="vertical"
          form={form}
          onFinish={onFinish}
          style={{ padding: 10 }}
        >
          <Row gutter={24} className="mt-8">
            <Col span={8}>
              <Form.Item
                label="Invoice Date"
                name="date"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="mm/dd/yyyy"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="User"
                name="user_id"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <Select placeholder="select User">
                  {customers?.map((cust) => (
                    <Select.Option key={cust.id} value={cust.id}>
                      {cust.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Discount" name="discount">
                <Input type="number" placeholder="0" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label="Description" name="description">
                <Input.TextArea style={{ width: "100%" }} rows={5} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item style={{ textAlign: "center", marginTop: 10 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "50%" }}
                  loading={loading}
                >
                  Generate Invoice
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <p className="text-center mt-8">
        <span style={{ color: "red" }}> Note : </span>This Section is only for
        generating invoice against charges.
      </p>
    </>
  );
}

export default AddInvoice;

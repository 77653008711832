
import ajaxService from "./ajax-service";
import { validationErrors } from "../utils";
class DestinationService {

  async fetch(page) {
    const response = await ajaxService.get(`/destinations?page=${page}`);
    if (response ?? false) {
      return response
    }
  }
  async fetchDestination() {
    const response = await ajaxService.get(`/destinations?all=true`);
    if (response ?? false) {
      return response
    }
  }

  async add(data) {
    const response = await ajaxService.post(`/destinations`, data);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors)
    }
  }

  async edit(id, data) {
    const response = await ajaxService.put(`/destinations/`, id, data);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors)
    }
  }

  async delete(id) {
    const response = await ajaxService.delete(`/destinations/${id}`);
    if (response ?? false) {
      return response;
    }
  }

  async view(id) {
    const response = await ajaxService.get(`/destinations/${id}`);
    if (response ?? false) {
      return response;
    }
  }
}
const destinationService = new DestinationService();
export default destinationService;
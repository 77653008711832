import React, { useEffect, useRef, useState } from "react";
import "./view.css";
import { useParams } from "react-router-dom";
import { blformService } from "../../../services";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Button } from "antd";

export const DockReceipt = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [blform, setBlform] = useState([]);
  const pdfRef = useRef(); // Ref for the container to export

  const initialize = async () => {
    setLoading(true);
    const { success, data } = await blformService.view(id);
    if (success) {
      setBlform(data);
    }
    setLoading(false);
  };
  const downloadPDF = async () => {
    const element = pdfRef.current;
    const canvas = await html2canvas(element, { scale: 2 });
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");

    // Calculate aspect ratio to fit A4 size
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("dock_receipt.pdf");
  };

  useEffect(() => {
    initialize();
  }, []);
  return (
    <div>
      <Button type="primary" onClick={downloadPDF}>
        Download PDF
      </Button>

      <div
        ref={pdfRef}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "20px",
        }}
        className="contaimermain2"
      >
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: 8,
            border: "1px solid lightgray",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "15px",
              padding: 8,
              marginBottom: "5px",
              borderBottom: "1px solid lightgray",
            }}
          >
            {/* <img src="/images/vec.png" alt="" width={"13px"} height={"16px"} />
          <img src="/images/ed.png" alt="" width={"13px"} height={"16px"} />
          <img src="/images/vec.png" alt="" width={"13px"} height={"16px"} /> */}
          </div>

          <div style={{ border: "1px solid lightgray", marginTop: "12px" }}>
            <h1 style={{ textAlign: "center", fontWeight: "bold" }}>
              DOCK RECEIPT
            </h1>
          </div>

          <div
            style={{
              width: "800px",
              border: "1px solid lightgray",
              display: "flex",
            }}
          >
            <div className="left" style={{ width: "400px" }}>
              <div
                style={{ border: "1px solid lightgray", height: "80px" }}
                className="cont3"
              >
                <h6>
                  2. Exporter (Principal or seller-licensee and address
                  including ZIP Code)
                </h6>
                <p>
                  {blform?.particulars ? blform?.particulars[0]?.shipper : " "}{" "}
                  <br />
                  EMAIL:{" "}
                  {blform?.particulars
                    ? blform?.particulars[0]?.shipper_email
                    : " "}
                </p>
              </div>
              <div style={{ border: "1px solid lightgray", height: "100px" }}>
                <h6>3. Consigned To:</h6>
                <p>
                  {blform?.form?.consignee} <br />
                  <br />
                  EMAIL: {blform?.form?.shipper_email}
                </p>
              </div>
              <div style={{ border: "1px solid lightgray", height: "100px" }}>
                <h6>
                  4. Notify Party / Intermediate Consignee (Name and Address):
                  <br />
                  Tel:{blform?.form?.party_tel}
                </h6>
              </div>
              <div className="leftInner" style={{ display: "flex" }}>
                <div
                  style={{
                    height: "40px",
                    border: "1px solid lightgray",
                    width: "200px",
                  }}
                >
                  <h6>12. Pre-Carriage By</h6>
                  <p>{blform?.form?.carriage}</p>
                </div>
                <div
                  style={{
                    height: "40px",
                    border: "1px solid lightgray",
                    width: "200px",
                  }}
                >
                  <h6>13. Place of Receipt by Pre-Carrier</h6>
                  <p>{blform?.form?.place_of_reciept}</p>
                </div>
              </div>
              <div className="leftInner" style={{ display: "flex" }}>
                <div
                  style={{
                    height: "40px",
                    border: "1px solid lightgray",
                    width: "200px",
                  }}
                >
                  <h6>14. Exporting Carrier</h6>
                  <p>{blform?.form?.exporting_carrier}</p>
                </div>
                <div
                  style={{
                    height: "40px",
                    border: "1px solid lightgray",
                    width: "200px",
                  }}
                >
                  <h6>15. Port of Loading/Export</h6>
                  <p>{blform?.form?.port}</p>
                </div>
              </div>
              <div className="leftInner" style={{ display: "flex" }}>
                <div
                  style={{
                    height: "40px",
                    border: "1px solid lightgray",
                    width: "200px",
                  }}
                >
                  <h6>16. Foreign Port of Unloading (vessel and air only)</h6>
                  <p>{blform?.form?.discharge}</p>
                </div>
                <div
                  style={{
                    height: "40px",
                    border: "1px solid lightgray",
                    width: "200px",
                  }}
                >
                  <h6>17. Place of Delivery by On-Carrier</h6>
                  <p>{blform?.form?.place_of_delivery}</p>
                </div>
              </div>
            </div>
            <div className="right" style={{ width: "400px" }}>
              <div className="leftInner" style={{ display: "flex" }}>
                <div
                  style={{
                    height: "40px",
                    border: "1px solid lightgray",
                    width: "200px",
                  }}
                >
                  <h6>5. Document No. (Carrier Booking No.)</h6>
                  <p>{blform?.form?.booking_no}</p>
                </div>
                <div
                  style={{
                    height: "40px",
                    border: "1px solid lightgray",
                    width: "200px",
                  }}
                >
                  <h6>5a. Bill of Loading No</h6>
                  <p>{blform?.form?.loading_no}</p>
                </div>
              </div>
              <div className="leftInner" style={{ display: "flex" }}>
                <div
                  style={{
                    height: "40px",
                    width: "200px",
                  }}
                >
                  <h6>6. Export References</h6>
                  <p>
                    {blform?.particulars
                      ? blform?.particulars[0]?.itn_number
                      : " "}{" "}
                    <br />
                    {blform?.form?.export_references}
                  </p>
                </div>
                <div
                  style={{
                    height: "40px",
                    border: "1px solid lightgray",
                    width: "200px",
                  }}
                >
                  <h6>6a. Reference No.</h6>
                  <p>{blform?.form?.reference_no}</p>
                </div>
              </div>
              <div
                style={{
                  height: "57px",
                  width: "400px",
                }}
              >
                <h6>7. Forwarding Agent (Name and Address)</h6>
                <br />
                <p>{blform?.form?.agent}</p>
              </div>
              <div
                style={{
                  height: "40px",
                  border: "1px solid lightgray",
                  width: "400px",
                }}
              >
                <h6>8. Point (State) or Origin or FTZ Number</h6>
                <p>{blform?.form?.point}</p>
              </div>
              <div style={{ border: "1px solid lightgray", height: "80px" }}>
                <h6>9. Also Notify and/or Additional Routing Instructions</h6>
                <p>{blform?.form?.party_instructions}</p>
              </div>
              <div
                style={{ border: "1px solid lightgray", height: "60px" }}
              ></div>
              <div className="leftInner" style={{ display: "flex" }}>
                <div
                  style={{
                    height: "40px",
                    border: "1px solid lightgray",
                    width: "200px",
                  }}
                >
                  <h6>10. Loading Pier / Terminal</h6>
                  <p>{blform?.form?.loading_pier_terminal}</p>
                </div>
                <div
                  style={{
                    height: "40px",
                    border: "1px solid lightgray",
                    width: "200px",
                  }}
                >
                  <h6>10a. Closing Date</h6>
                  <p>{blform?.form?.closing_date}</p>
                </div>
              </div>
              <div className="leftInner" style={{ display: "flex" }}>
                <div
                  style={{
                    height: "41px",
                    border: "1px solid lightgray",
                    width: "200px",
                  }}
                >
                  <h6>11. Type of Move</h6>
                  <p>{blform?.form?.type_of_movement}</p>
                </div>
                <div
                  style={{
                    height: "41px",
                    border: "1px solid lightgray",
                    width: "200px",
                  }}
                >
                  <h6>11a. Containerized(Vessel only)</h6>
                  <p>{blform?.form?.containerized}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              border: "1px solid lightgray",
              width: "800px",
              display: "flex",
            }}
          >
            <div
              style={{
                border: "1px solid lightgray",
                width: "140px",
                height: "200px",
              }}
            >
              <h6>Marks and Numbers(18).</h6>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "lightgray",
                  marginTop: "1px",
                }}
              ></div>
              <p>CONTAINER NO. : {blform?.form?.container_nos}</p>
              <p>SEAL: {blform?.form?.seal_nos}</p>
            </div>
            <div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    border: "1px solid lightgray",
                    width: "140px",
                    height: "160px",
                  }}
                >
                  <h6>Number of Packages(19).</h6>
                  <div
                    style={{
                      height: "1px",
                      backgroundColor: "lightgray",
                      marginTop: "1px",
                      justifyItems: "center",
                    }}
                  >
                    <p>
                      {blform?.particulars
                        ? blform?.particulars[0]?.quantity
                        : " "}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid lightgray",
                    width: "300px",
                    height: "160px",
                  }}
                >
                  <h6 style={{ width: "280px" }}>
                    Description of Commodoities in Schedule B detail(20).
                  </h6>
                  <div
                    style={{
                      height: "1px",
                      backgroundColor: "lightgray",
                      marginTop: "1px",
                    }}
                  ></div>
                  <div
                    style={{
                      display: "flex",
                      width: "280px",
                      justifyContent: "space-between",
                    }}
                    className="column"
                  >
                    <div>
                      <p>
                        {blform?.particulars
                          ? blform?.particulars[0]?.description
                              .split("\n")
                              .map((line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              ))
                          : " "}
                      </p>
                    </div>
                    <div>
                      <p>
                        {blform?.particulars
                          ? blform?.particulars[0]?.value
                          : " "}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid lightgray",
                    width: "110px",
                    height: "160px",
                  }}
                >
                  {" "}
                  <h6 style={{ width: "90px" }}>Gross Weight(21).</h6>
                  <div
                    style={{
                      height: "1px",
                      backgroundColor: "lightgray",
                      marginTop: "1px",
                    }}
                    className="column"
                  >
                    <div>
                      {blform?.particulars
                        ? blform?.particulars[0]?.weight
                        : " "}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid lightgray",
                    width: "110px",
                    height: "160px",
                  }}
                >
                  {" "}
                  <h6 style={{ width: "90px" }}>Measurement(22).</h6>
                  <div
                    style={{
                      height: "1px",
                      backgroundColor: "lightgray",
                      marginTop: "1px",
                    }}
                  >
                    {blform?.particulars
                      ? blform?.particulars[0]?.measurements
                      : " "}
                  </div>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid lightgray",
                  width: "555px",
                  height: "40px",
                }}
              >
                <p>{blform?.form?.note_1}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DockReceipt;

import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import "./Edit.css";
import { Link, useParams } from "react-router-dom";
import { Button, Input, notification, Space } from "antd";
import { imoService } from "../../../services";

export const ImoEdit = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  // State to manage all input values
  const [formData, setFormData] = useState({
    shipper: " Provident neque lor",
    transportation_no: "",
    booking_no: "",
    shipper_references: "",
    consignee: "",
    forwarder_refernece: "",
    vessel: "",
    place_of_handling: "",
    place_of_delivery: "",
    instructions: "",
    description: "",
    weight: "",
    ctu_id: "",
    seal_no: "",
    ctu_size: "",
    tare_mass: "",
    total_gross_mass: "",
    name_of_company: "",
    haulers_name: "",
    name_of_company_preparing_note: "",
    name_status_declarant_note_2: "",
    vehicle_registration_no: "",
    place_and_date_1: "",
    place_and_date_2: "",
    driver_name_and_date: "",
    name_status_declarant_note_1: "",
  });

  // Update the state for a specific field
  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Handle the Update button click
  const handleUpdate = async () => {
    setLoading(true);
    const response = await imoService.updateIMO(id, formData);
    setLoading(false);
    if (response.success) {
      notification.success({
        message: "Updated Successfully!",
        description: response.message,
        placement: "topRight",
      });
    }
  };
  const initialize = async () => {
    const response = await imoService.viewIMO(id);
    if (response.success) {
      setFormData({ ...response.data });
    }
  };
  useEffect(() => {
    initialize();
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 20,
      }}
    >
      <div className="container" style={{ backgroundColor: "#FFFFFF" }}>
        <h4 className="heading">IMO DANGEROUS GOODS DECLARATION</h4>
        <div className="topOne">
          <div className="topOne-left">
            <h5> 1. Shipper</h5>
            <TextArea
              value={formData.shipper}
              onChange={(e) => handleInputChange("shipper", e.target.value)}
            />
          </div>
          <div className="topOne-right">
            <h5>2. Transportation Document Number</h5>
            <Input
              value={formData.transportation_no}
              onChange={(e) =>
                handleInputChange("transportation_no", e.target.value)
              }
            />
            <div className="topOne-right-inner">
              <div>
                <h5>3. Carrier</h5>
                <Input
                  value={formData.booking_no}
                  onChange={(e) =>
                    handleInputChange("booking_no", e.target.value)
                  }
                />
              </div>
              <div>
                <h5>4. Shipper References</h5>
                <Input
                  value={formData.shipper_references}
                  onChange={(e) =>
                    handleInputChange("shipper_references", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="topTwo">
          <div className="topTwo-left">
            <h5>6. Consignee</h5>
            <TextArea
              value={formData.consignee}
              onChange={(e) => handleInputChange("consignee", e.target.value)}
            />
          </div>
          <div className="topTwo-right">
            <h5>5. Freight Forwarder's Reference</h5>
            <Input
              value={formData.forwarder_refernece}
              onChange={(e) =>
                handleInputChange("forwarder_refernece", e.target.value)
              }
            />
            <h5>7. Carrier (to be declared by the Carrier)</h5>
            <Input
              value={formData.carrier_name}
              onChange={(e) =>
                handleInputChange("carrier_name", e.target.value)
              }
            />
          </div>
        </div>
        <div className="topThree">
          <div>
            <h5> SHIPPER'S DECLARATION </h5>
            <p>
              I hereby declare that the contents of this consignment are fully
              and accurately described above by the proper shipping name(s), and
              are classified, packaged, marked and labeled/placarded, and are in
              all respects in proper condition for transport according to
              applicable international and national governmental regulations
            </p>
          </div>
        </div>
        <div className="topFour">
          <div className="topFour-left">
            <h5>10. Vessel/Flight and Date</h5>
            <Input
              value={formData.vessel}
              onChange={(e) => handleInputChange("vessel", e.target.value)}
            />
            <div className="topFour-right-inner">
              <div>
                <h5>11. Port/Place Handling</h5>
                <Input
                  value={formData.place_of_handling}
                  onChange={(e) =>
                    handleInputChange("place_of_handling", e.target.value)
                  }
                />
              </div>
              <div>
                <h5>Port/Place Delivery</h5>
                <Input
                  value={formData.place_of_delivery}
                  onChange={(e) =>
                    handleInputChange("place_of_delivery", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="topFour-right">
            <h5>9. Additional Handling Information</h5>
            <TextArea
              value={formData.instructions}
              onChange={(e) =>
                handleInputChange("instructions", e.target.value)
              }
              rows={4}
            />
          </div>
        </div>
        <div className="topFive">
          <div className="topFive-left">
            <h5>14. Shipping Marks </h5>
            <div className="seperator"></div>
          </div>
          <div className="topFive-center">
            <h5>Number and Kind of Packages, Description of Goods </h5>
            <div className="seperator"></div>
            <TextArea
              value={formData.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              rows={4}
            />
          </div>
          <div className="topFive-right">
            <h5>GW (kg)</h5>
            <div className="seperator"></div>
            <TextArea
              value={formData.weight}
              onChange={(e) => handleInputChange("weight", e.target.value)}
              rows={4}
            />
          </div>
        </div>
        <div className="topSix">
          <div className="topSix-inner">
            <h5>15 CTU ID No.</h5>
            <Input
              value={formData.ctu_id}
              onChange={(e) => handleInputChange("ctu_id", e.target.value)}
              rows={4}
            />
          </div>
          <div className="topSix-inner">
            <h5>16 Seal No.</h5>
            <Input
              value={formData.seal_no}
              onChange={(e) => handleInputChange("seal_no", e.target.value)}
              rows={4}
            />
          </div>
          <div className="topSix-inner">
            <h5>17 CTU Size and Type</h5>
            <Input
              value={formData.ctu_size}
              onChange={(e) => handleInputChange("ctu_size", e.target.value)}
              rows={4}
            />
          </div>
          <div className="topSix-inner">
            <h5>18 Tare Mass (kg)</h5>
            <Input
              value={formData.tare_mass}
              onChange={(e) => handleInputChange("tare_mass", e.target.value)}
              rows={4}
            />
          </div>
          <div className="topSix-inner">
            <h5>19 Total Gross Mass (kg)</h5>
            <Input
              value={formData.total_gross_mass}
              onChange={(e) =>
                handleInputChange("total_gross_mass", e.target.value)
              }
              rows={4}
            />
          </div>
        </div>
        <div className="topSeven">
          <div className="topSeven-left">
            <h5>Container/Vehicle Packing Certificate</h5>
            <p>
              I hereby declare that the goods described above have been
              packed/loaded into the container/vehicle identified above in
              accordance with the applicable provisions
            </p>
          </div>
          <div className="topSeven-right">
            <h5> 21 Receiving Organization Receipt</h5>
            <p>
              Received the above number of packages/containers/trailers in
              apparent good order and condition, unless stated hereon: RECEIVING
            </p>
            <h5> ORGANIZATION REMARKS:</h5>
          </div>
        </div>
        <div className="topEight">
          <div className="topEight-inner">
            <h5>20 Name of Company</h5>
            <Input
              value={formData.name_of_company}
              onChange={(e) => handleInputChange("companyName", e.target.value)}
              rows={4}
            />
          </div>
          <div className="topEight-inner">
            <h5>21 Hauler's Name</h5>
            <Input
              value={formData.haulers_name}
              onChange={(e) => handleInputChange("haulerName", e.target.value)}
              rows={4}
            />
          </div>
          <div className="topEight-inner">
            <h5>22 Name of Company Preparing Note</h5>
            <Input
              value={formData.name_of_company_preparing_note}
              onChange={(e) =>
                handleInputChange("preparingNoteCompany", e.target.value)
              }
              rows={4}
            />
          </div>
        </div>
        <div className="topEight">
          <div className="topEight-inner">
            <h5>Name/Status of Declarant Note</h5>
            <Input
              value={formData.name_status_declarant_note_1}
              onChange={(e) => handleInputChange("nameOfNote", e.target.value)}
              rows={4}
            />
          </div>
          <div className="topEight-inner">
            <h5>Vehicle Registration No.</h5>
            <Input
              value={formData.vehicle_registration_no}
              onChange={(e) =>
                handleInputChange("vehicleRegistration", e.target.value)
              }
              rows={4}
            />
          </div>
          <div className="topEight-inner">
            <h5>Name/Status of Declarant</h5>
            <Input
              value={formData.name_status_declarant_note_2}
              onChange={(e) =>
                handleInputChange("declarantName", e.target.value)
              }
              rows={4}
            />
          </div>
        </div>
        <div className="topEight">
          <div className="topEight-inner">
            <h5>Place and Date</h5>
            <Input
              value={formData.place_and_date_1}
              onChange={(e) =>
                handleInputChange("placeAndDate", e.target.value)
              }
              rows={4}
            />
          </div>
          <div className="topEight-inner">
            <h5>Driver Name and Date</h5>
            <Input
              value={formData.driver_name_and_date}
              onChange={(e) =>
                handleInputChange("driverNameDate", e.target.value)
              }
              rows={4}
            />
          </div>
          <div className="topEight-inner">
            <h5>Place and Date</h5>
            <Input
              value={formData.place_and_date_2}
              onChange={(e) =>
                handleInputChange("placeAndDate2", e.target.value)
              }
              rows={4}
            />
          </div>
        </div>
        <div className="topEight">
          <div className="topEight-inner">
            <h5>Signature of Declarant</h5>
          </div>
          <div className="topEight-inner">
            <h5>Driver's Signature</h5>
          </div>
          <div className="topEight-inner">
            <h5>Signature of Declarant</h5>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "12px",
          gap: "12px",
          width: "900px",
        }}
      >
        <Space>
          <Link to={"/operation/loadings/imos"}>
            <Button
              style={{ backgroundColor: "#36404E", color: "white" }}
              loading={loading}
            >
              Back
            </Button>
          </Link>
          <Button
            style={{ backgroundColor: "#C62737", color: "white" }}
            onClick={handleUpdate}
            loading={loading}
          >
            Update
          </Button>
        </Space>
      </div>
    </div>
  );
};

export default ImoEdit;

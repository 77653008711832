import { validationErrors } from "../utils";
import ajaxService from "./ajax-service";

class DropdownService {
  async fetchCompanies() {
    const response = await ajaxService.get(`/companies`);
    if (response.success) {
      return response;
    }
  }
  async fetchPaymentModes() {
    const response = await ajaxService.get("/payment-modes");
    if (response.success) {
      return response;
    }
  }
  async fetchBanks() {
    const response = await ajaxService.get("/banks");
    if (response.success) {
      return response;
    }
  }
  async fetchCustomers() {
    const response = await ajaxService.get(`/customers?all=true`);
    if (response.success ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async fetchInvoices() {
    const response = await ajaxService.get(`/invoices?all=true`);
    if (response.success ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async fetchExpenses() {
    const response = await ajaxService.get(`/expense-sub-categories`);
    if (response.success) {
      return response;
    }
  }
  async fetchVendors() {
    const response = await ajaxService.get(`/vendors?all=true`);
    if (response.success ?? false) {
      return response;
    }
  }
  async VehicleTypes() {
    const response = await ajaxService.get("/vehicles-types");
    if (response.success) {
      return response;
    }
  }
  async towingLocation() {
    const response = await ajaxService.get(`/tariffs?all=true`);
    if (response.success ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }

  async fetchDestination() {
    const response = await ajaxService.get(`/destinations?all=true`);
    if (response.success ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }

  async fetchContainerSizes() {
    const response = await ajaxService.get(`/container-sizes?all=true`);
    if (response.success ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }

  async fetchShippingLines() {
    const response = await ajaxService.get("/shipping/lines");
    if (response.success ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }

  async fetchVehiclesOnSearch(data) {
    const response = await ajaxService.post("/container/vehicles-filter", data);
    if (response.success) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async fetchQuotationVendors() {
    const response = await ajaxService.get("/vendors-quotations-vendors");
    if (response.success) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async fetchRemainingInvoices(id) {
    const response = await ajaxService.get(`/receipt/user-pending-invoices?user_id=${id}`);
    if (response.success) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async fetchApprovedCars() {
    const response = await ajaxService.get(`/vehicles-appointment-cars`);
    if (response.success) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
}

const dropdownService = new DropdownService();
export default dropdownService;

import { notification } from "antd";
import ajaxService from "./ajax-service";
import { CloseCircleOutlined } from "@ant-design/icons";

class VehicalService {
  async getVehicles(page) {
    const response = await ajaxService.get(`/vehicles?page=${page}`);
    if (response.success) {
      return response;
    }
  }
  async view(id) {
    const response = await ajaxService.get(`/vehicles/${id}`);
    if (response.success) {
      return response;
    }
  }
  async getLatestID() {
    const response = await ajaxService.get(`/vehicles-latest-id`);
    if (response.success) {
      return response;
    }
  }
  async getColumns() {
    const response = await ajaxService.get("/vehicles-columns");
    if (response.success) {
      return response;
    }
  }
  async getTypes() {
    const response = await ajaxService.get("/vehicles-types");
    if (response.success) {
      return response;
    }
  }
  async download(data) {
    const response = await ajaxService.post("/vehicles-export", data);
    if (response.success) {
      return response;
    }
  }

  async addVehicle(data) {
    const response = await ajaxService.post(`/vehicles`, data);
    if (response.success ?? false) {
      return response;
    }
  }
  async deleteVehicle(id) {
    const response = await ajaxService.delete("/vehicles/", id);
    if (response.success) {
      return response;
    }
  }
  async updateVehicle(id, data) {
    const response = await ajaxService.put("/vehicles/", id, data);
    if (response.success) {
      return response;
    }
  }
  async post(url, data, id) {
    const response = await ajaxService.get(url);
    if (response.success) {
      return response;
    } else {
      notification.open({
        message: response.message,
        icon: <CloseCircleOutlined style={{ color: "red" }} />,
      });
    }
  }
}
const vehicalService = new VehicalService();

export default vehicalService;

import { Table, Space, Button, Row, Col, Input, Select, Typography } from "antd";
import { SearchOutlined, FilePdfOutlined } from "@ant-design/icons";
import { CiSquarePlus } from "react-icons/ci";
import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import AddModal from "./AddDebitNote";
import { debitNoteService, dropdownService } from "../../../../../services";

const { Title } = Typography
function DebitNotes() {
  const [loading, setLoading] = useState(false);
  const [debitNotes, setDebitNotes] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [companies, setCompanies] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [isModalShow, setIsModalShow] = useState(false);


  const showModal = () => setIsModalShow(true);

  // const handleOk = () => {
  //   setIsModalShow(false);
  // };
  const handleCancel = () => {
    setIsModalShow(false);
  };

  const options = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "9",
      label: "9",
    },
  ];
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Company",
      dataIndex: ["company", "name"],
      key: "company",
    },
    {
      title: "Customer",
      dataIndex: ["user", "name"],
      key: "user",
    },
    {
      title: "Invoice #",
      dataIndex: "invoice_id",
      key: "invoice_id",
      render: (text, record) => text || "N/A",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Expense",
      dataIndex: ["expense", "id"],
      key: "Expense",
    },
    {
      title: "Issue Date",
      dataIndex: "issue_date",
      key: "issue_date",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 300,
    },
    {
      title: "Action",
      key: "Action",
      fixed: "right",
      render: (text, { _id }) => (
        <Space size="small">
          <Button type="primary" size="small">
            <FilePdfOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    const { success, data } = await debitNoteService.fetch(page);
    if (success) {
      setDebitNotes(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };

  const getCompanies = async () => {
    const response = await dropdownService.fetchCompanies();
    if (response.success) {
      setCompanies(response.data);
    }
  };
  const getCustomer = async () => {
    const response = await dropdownService.fetchCustomers();
    if (response.success) {
      setCustomers(response.data);
    }
  };
  const getInvoices = async () => {
    const response = await dropdownService.fetchInvoices();
    if (response.success) {
      setInvoices(response.data);
    }
  };
  const getExpenses = async () => {
    const response = await dropdownService.fetchExpenses();
    if (response.success) {
      setExpenses(response.data);
    }
  };

  useEffect(() => {
    if (refresh) {
      initialize(pagination.current, pagination.pageSize);
    }
    setRefresh(false);
    getCompanies();
    getCustomer();
    getInvoices();
    getExpenses();
  }, [pagination.current, pagination.pageSize, refresh]);

  // useEffect(() => {
  //   if (refresh) {
  //     initialize(pagination.current, pagination.pageSize);
  //   }
  //   setRefresh(false);
  // }, [refresh]);

  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Title level={5}>Debit Notes</Title>

      <Divider style={{ marginTop: 6 }} />
      <Col
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <CiSquarePlus
          size={30}
          style={{ cursor: "pointer" }}
          onClick={showModal}
        />
      </Col>
      <Row
        gutter={8}
        style={{
          marginTop: "15px",
          background: "#F2F2F7",
          padding: "5px",

          alignItems: "center", // Ensures alignment of items within the row
        }}
      >
        <Col
          span={4}
          xs={24}
          sm={12}
          md={6}
          lg={4}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Space direction="horizontal" size={10}>
            <p style={{ margin: 0 }}>Show</p>
            <Select
              defaultValue={10}
              options={options}
              size="small"
              style={{ width: 70, fontSize: 14 }}
            />
            <p style={{ margin: 0 }}>entries</p>
          </Space>
        </Col>

        {/* Second Column: Right-Aligned Search */}
        <Col
          span={20}
          xs={24}
          sm={12}
          md={18}
          lg={20}
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{
              width: "30%",
              borderRadius: "4px",
              fontSize: 14,
            }}
          />
        </Col>
      </Row>
      <AddModal
        visible={isModalShow}
        setIsModalShow={setIsModalShow}
        onCancel={handleCancel}
        companies={companies}
        customers={customers}
        invoices={invoices}
        expenses={expenses}
        setRefresh={setRefresh}
      />

      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={debitNotes}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        loading={loading}
        style={{ marginTop: "10px" }}
      />
    </div>
  );
}

export default DebitNotes;

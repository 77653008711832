import { Button, Col, Form, Input, Row, Select, Table } from "antd";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loadingPlanService } from "../../../services";
import { useNavigate } from "react-router-dom";

function GenerateLoadPlan() {
  const navigate=useNavigate()
  const { id } = useParams();
  const [form] = Form.useForm()
  const [loading, setLoading] = useState([]);
  const [loadplan, setLoadplan] = useState([]);
  const [containers, setContainers] = useState([])
  const [pagination, setPagination] = useState({
      current: 1,
      total: 0,
      pageSize: 10,
    });

  const columns = [
    {
      title: "S.no",
      dataIndex: "Sno",
      key: "Sno",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Ref#",
      dataIndex: "Ref",
      key: "Ref",
    },
    {
      title: "Year",
      dataIndex: ["vehicle","year"],
      key: "Year",
    },
    {
      title: "Make/Model",
      dataIndex: ["vehicle", "vehicle"],
      key: "MakeModel",
    },
    {
      title: "Vin Numbers",
      dataIndex: ["vehicle","chasis_no"],
      key: "VinNumbers",
    },
    {
      title: "Keys",
      dataIndex: ["vehicle","keys"],
      key: "Keys",
    },
  ];
  const data = [
    {
      Sno: "1",
      Ref: "LT-75",
      Year: "2024",
      MakeModel: "FORD / SUPER DUTY F550",
      VinNumbers: "1FDUF5HT0RDA27180",
      Keys: "YES",
    },
    {
      Sno: "1",
      Ref: "LT-75",
      Year: "2024",
      MakeModel: "FORD / SUPER DUTY F550",
      VinNumbers: "1FDUF5HT0RDA27180",
      Keys: "YES",
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    const { success, data } = await loadingPlanService.View(id, page);
    setLoading(false);
    if (success) {
      setLoadplan(data.containers);
      setContainers(data.containers.data);
      setPagination((prev) => ({
        ...prev,
        total: data.containers.total,
        current: page,
        pageSize,
      }));
      form.setFieldsValue({...data.container})
    }
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);
  return (
    <div
      style={{
        padding: "12px 14px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        paddingBottom: 20,
      }}
    >
      <Form
        requiredMark={false}
        layout="vertical"
        form={form}
        initialValues={{ layout: "vertical" }}
      >
        <Row gutter={24}>
          <Col span={5}>
            <Form.Item label="Load Number" name="container_id">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Container Number" name="c_container_number">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Seal Number">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Container Size"
              name="container_size"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Select placeholder="" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Verifier Name">
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row>

        <Table
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={containers}
          style={{ marginTop: "20px" }}
        />

        <Row gutter={16} align="bottom">
          <Col span={12}>
            <Form.Item label="Special Note:">
              <Input.TextArea
                placeholder="Enter special notes here..."
                rows={5}
              />
            </Form.Item>
          </Col>

          <Col span={12} style={{ textAlign: "right" }}>
            <Form.Item>

              <Button type="primary" style={{ width: '70%' }} onClick={() => {navigate('/operation/loadings/pdf/:id?') }}>
                Generate PDF
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default GenerateLoadPlan;

import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "./Edit.css";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import { Link, useParams } from "react-router-dom";
import { dropdownService, invoiceService } from "../../../services";
import { ROLES } from "../../../constants";
import MakePayment from "./MakePayment";
import EditModal from "./EditModal";

export const ViewInvoice = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [charges, setCharges] = useState([]);
  const [disable, setDisable] = useState(true);
  const [paymentmodes, setPaymentModes] = useState([]);
  const [banks, setBanks] = useState([]);
  const [pendingInvoices, setPendingInvoices] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [isModalshow, setIsModalShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const role = localStorage.getItem("role");
  const columns = [
    {
      title: "Chasis#",
      dataIndex: "chasis_no",
      key: "chasis_no",
      render: (text, record, index) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) => handleInputChange(e, index, "chasis_no")}
        />
      ),
    },
    {
      title: "Freight",
      dataIndex: "freight_charges",
      key: "freight_charges",
      render: (text, record, index) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) => handleInputChange(e, index, "freight_charges")}
        />
      ),
    },
    {
      title: "Towing",
      dataIndex: "towing_charges",
      key: "towing_charges",
      render: (text, record, index) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) => handleInputChange(e, index, "towing_charges")}
        />
      ),
    },
    {
      title: "Yard",
      dataIndex: "yard_charges",
      key: "yard_charges",
      render: (text, record, index) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) => handleInputChange(e, index, "yard_charges")}
        />
      ),
    },
    {
      title: "loading",
      dataIndex: "loading_charges",
      key: "loading_charges",
      render: (text, record, index) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) => handleInputChange(e, index, "loading_charges")}
        />
      ),
    },
    {
      title: "chasis",
      dataIndex: "chasis",
      key: "chasis",
      render: (text, record, index) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) => handleInputChange(e, index, "chasis")}
        />
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "invoice_amount",
      key: "invoice_amount",
    },
  ];



  const openModal = () => setIsModalOpen(true);
  const visibleModal = () => setIsModalVisible(true);
  const showModal = () => {
    setIsModalShow(true);
  };

  const handleCancel = () => {
    setIsModalShow(false);
    setIsModalOpen(false);
    setIsModalVisible(false);
  };

  const handleInputChange = (e, index, field) => {
    const value = e.target.value;
    invoice.invoice.vehicles[index][field] = value;
  };


  const handleApprove = async () => {
    // const response = await invoiceService.approveInvoice(invoice?.invoice?.id);
    // if (response.success) {
    //   notification.success({
    //     message: "Invoice Approved!",
    //     description: response.message,
    //     placement: "topRight",
    //   });
    // }
    setIsModalShow(false);
  };



  const handleOk = () => {
    setIsModalOpen(false);
  };



  const initialize = async () => {
    if (role === ROLES.ADMIN) {
      const { success, data } = await invoiceService.viewInvoice(id);
      if (success) {
        // const response = await invoiceService.getInvoiceCharges(id);
        // if (response.success) {
        //   setCharges(response.data.extras)
        //   form.setFieldsValue({
        //     first: response.data.extras
        //   })
        // }
        setInvoice(data);
        form.setFieldsValue({
          discount: data?.invoice?.discount,
        });
        form2.setFieldsValue({
          amount: data.invoice.total,
        });
        if (data?.invoice?.total === 0) {
          setDisable(true);
          form2.setFieldsValue({
            status: 0,
          });
        }
      }
    } else if (role === ROLES.CUSTOMER) {
      const { success, data } = await invoiceService.viewCustomerInvoice(id);
      if (success) {
        setInvoice(data);
        form.setFieldsValue({
          discount: data?.invoice?.discount,
        });
      }
    }
  };



  const onFinish = async (values) => {
    const data = {
      discount: values.discount,
      extraCharge: values?.charges?.map((item) => item.first) || null,
      extraChargeAmount: values?.charges?.map((item) => item.last) || null,
      freight_charges: invoice?.invoice?.vehicles?.reduce((acc, item) => {
        acc[item.id] = item.freight_charges;
        return acc;
      }, {}),
      towing_charges: invoice?.invoice?.vehicles?.reduce((acc, item) => {
        acc[item.id] = item.towing_charges;
        return acc;
      }, {}),
      yard_charges: invoice?.invoice?.vehicles?.reduce((acc, item) => {
        acc[item.id] = item.yard_charges;
        return acc;
      }, {}),
      loading_charges: invoice?.invoice?.vehicles?.reduce((acc, item) => {
        acc[item.id] = item.loading_charges;
        return acc;
      }, {}),
      chasis: invoice?.invoice?.vehicles?.reduce((acc, item) => {
        acc[item.id] = item.chasis;
        return acc;
      }, {}),
      invoice_amount: invoice?.invoice?.vehicles?.reduce((acc, item) => {
        acc[item.id] = item.invoice_amount;
        return acc;
      }, {}),
    };
    // console.log(data);
    const response = await invoiceService.updateInvoice(
      invoice.invoice.id,
      data
    );
    if (response.success) {
      notification.success({
        message: "Invoice updated successfully!",
        description: response.message,
        placement: "topRight",
      });
    }
    initialize();
    setIsModalVisible(false);
  };



  const handlePayment = async (values) => {
    const data = {
      ...values,
      payment_date: values.payment_date.format("YYYY-MM-DD"),
      payment_mode_name: paymentmodes.find(
        (mode) => mode.id === values.payment_mode
      )?.name,
    };
    // console.log(data)
    const response = await invoiceService.makePayment(data);
    if (response.success) {
      notification.success({
        message: "Payment Made Successfully!",
        description: response.message,
        placement: "topRight",
      });
    }
    form2.resetFields();
    initialize();
    setIsModalOpen(false);
  };


  const getPaymentModes = async () => {
    const response = await dropdownService.fetchPaymentModes();
    if (response.success) {
      setPaymentModes(response.data);
    }
  };
  const getBanks = async () => {
    const response = await dropdownService.fetchBanks();
    if (response.success) {
      setBanks(response.data);
    }
  };
  const getInvoices = async () => {
    const response = await dropdownService.fetchInvoices();
    if (response.success) {
      const filteredInvoices = response.data.filter(
        (item) => item.is_partial !== 2
      );
      setPendingInvoices(filteredInvoices);
    }
  };
  useEffect(() => {
    initialize();
    getPaymentModes();
    getBanks();
    getInvoices();
  }, []);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          padding: "15px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF",
          width: "850px",
        }}
      >
        <div className="main">
          {/* /////top start//////// */}
          <div className="top">
            <div>
              <img
                src="/images/ctrade-logo.png"
                alt=""
                style={{ width: "100px", height: "55px" }}
              />
              <h3>CTRADE USA INC</h3>
            </div>
            <div style={{ fontSize: "14px" }}>
              3902 SWINGLE RD
              <br />
              HOUSTON TX 77047
              <br />
              UNITED STATES
              <br />
              Mobile: +1 832-819-2622 <br />
              Email: trade@ctrade.us
            </div>
          </div>
          {/* /////top end//////// */}
          <h1 className="heading">INVOICE Number: {invoice?.invoice?.id}</h1>
          <div className="seperator"></div>
          {/* /////second start//////// */}
          <div className="second">
            <h4 style={{ fontWeight: "lighter" }}>
              Bill-to Party:   {invoice?.customer?.name}
            </h4>
            <div>
              <h4 style={{ fontWeight: "lighter" }}>
                Invoice Date:   {invoice?.invoice?.date}
              </h4>
              <h4 style={{ fontWeight: "lighter" }}>
                Invoice Due:   {invoice?.invoice?.due_date}
              </h4>
              <h4 style={{ marginTop: "5vh", fontWeight: "lighter" }}>
                Payment terms:   Payable immediately
              </h4>
            </div>
          </div>
          {/* /////second end//////// */}
          <h3
            className="subheading"
            style={{ fontWeight: "500", fontSize: "18px" }}
          >
            BOOKING Number: {invoice?.invoice?.vehicles[0]?.booking_no}
          </h3>
          <div className="seperatorTwo"></div>
          {/* /////third start//////// */}
          <div className="third">
            <div style={{ marginLeft: "1vw" }}>
              <h4 style={{ fontWeight: "lighter" }}>POL: HOUSTON  </h4>
              <h4 style={{ fontWeight: "lighter" }}>POD:   </h4>
              <h4 style={{ fontWeight: "lighter" }}>
                Product Id: Ctrade U.S.A
              </h4>
            </div>
            <div style={{ marginLeft: "3vw" }}>
              <h4 style={{ fontWeight: "lighter" }}>
                Place of Receipt: Houston
              </h4>
              <h4 style={{ fontWeight: "lighter" }}> Place of Delivery:  </h4>
            </div>
          </div>
          {/* /////third end//////// */}
          <div className="seperator"></div>
          <h2 className="subheading" style={{ marginTop: "3vh" }}>
            TOTAL AMOUNT DUE
          </h2>
          {/* /////fourth start//////// */}
          <tr
            className="table"
            style={{
              border: "2px solid #B83D3D",
              paddingLeft: "1vw",
              paddingRight: "1vw",
              marginTop: "4vh",
            }}
          >
            <td style={{ fontWeight: "bold", fontSize: "14px" }}>Condition</td>
            <td style={{ fontWeight: "bold", fontSize: "14px" }}>Rate</td>
            <td style={{ fontWeight: "bold", fontSize: "14px" }}>BaseValue</td>
            <td style={{ fontWeight: "bold", fontSize: "14px" }}>
              Total (USD)
            </td>
          </tr>
          <tr
            className="table"
            style={{
              paddingLeft: "1vw",
              paddingRight: "1vw",
            }}
          >
            <td style={{ fontSize: "14px" }}>Net Value </td>
            <td></td>
            <td></td>
            <td style={{ fontSize: "14px" }}> {invoice?.invoice?.total}.00</td>
          </tr>
          <tr
            className="table"
            style={{
              paddingLeft: "1vw",
              paddingRight: "1vw",
            }}
          >
            <td style={{ fontSize: "14px" }}>Discount</td>
            <td></td>
            <td></td>
            <td style={{ fontSize: "14px" }}>
              {invoice?.invoice?.discount}.00
            </td>
          </tr>
          <tr
            className="table"
            style={{
              border: "2px solid #B83D3D",
              paddingLeft: "1vw",
              paddingRight: "1vw",
            }}
          >
            <td style={{ fontWeight: "bold", fontSize: "16px" }}>Amount Due</td>
            <td></td>
            <td></td>
            <td style={{ fontWeight: "bold" }}></td>
            <td style={{ fontWeight: "bold", fontSize: "16px" }}>
              ${invoice?.invoice?.total?.toLocaleString()}
            </td>
          </tr>

          <section className="vehicle-details" style={{ width: "800px" }}>
            <table>
              <thead>
                <tr
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    width: "800px",
                  }}
                >
                  <th
                    style={{
                      width: "170px",
                      fontSize: "12px",
                      textAlign: "start",
                    }}
                  >
                    Vehicle
                  </th>
                  <th
                    style={{
                      width: "90px",
                      fontSize: "12px",
                      textAlign: "start",
                    }}
                  >
                    Receiving Date
                  </th>
                  <th
                    style={{
                      width: "90px",
                      fontSize: "12px",
                      textAlign: "start",
                    }}
                  >
                    Loading Date
                  </th>
                  <th
                    style={{
                      width: "90px",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    Days
                  </th>
                  <th
                    style={{
                      width: "90px",
                      fontSize: "12px",
                      textAlign: "start",
                    }}
                  >
                    Storage
                  </th>
                  <th
                    style={{
                      width: "90px",
                      fontSize: "12px",
                      textAlign: "start",
                    }}
                  >
                    Freight Loading
                  </th>
                  <th
                    style={{
                      width: "90px",
                      fontSize: "12px",
                      textAlign: "start",
                    }}
                  >
                    Towing
                  </th>
                  <th
                    style={{
                      width: "90px",
                      fontSize: "12px",
                      textAlign: "start",
                    }}
                  >
                    Chassis
                  </th>
                  <th
                    style={{
                      width: "90px",
                      fontSize: "12px",
                      textAlign: "start",
                    }}
                  >
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <p>hell</p> */}
                {invoice?.invoice?.vehicles?.map((vehicle, index) => (
                  <>
                    <tr
                      key={index}
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                        width: "800px",
                      }}
                    >
                      <td
                        style={{
                          width: "170px",
                          fontSize: "12px",
                          textAlign: "start",
                        }}
                      >
                        <div>
                          {vehicle?.year} {vehicle?.vehicle}
                        </div>
                        <div
                          style={{
                            marginTop: "4px",
                            fontSize: "11px",
                            color: "#666",
                          }}
                        >
                          {vehicle?.chasis_no}
                        </div>
                      </td>
                      <td
                        style={{
                          width: "90px",
                          fontSize: "12px",
                          textAlign: "start",
                        }}
                      >
                        {vehicle.date_rcd.slice(0, 10)}
                      </td>
                      <td
                        style={{
                          width: "90px",
                          fontSize: "12px",
                          textAlign: "start",
                        }}
                      >
                        {invoice?.invoice?.customised_date}
                      </td>
                      <td
                        style={{
                          width: "90px",
                          fontSize: "12px",
                          textAlign: "center",
                        }}
                      >
                        {vehicle.days}
                      </td>
                      <td
                        style={{
                          width: "90px",
                          fontSize: "12px",
                          textAlign: "start",
                        }}
                      >
                        ${vehicle?.invoice_amount?.toLocaleString() || "0.00"}
                      </td>
                      <td
                        style={{
                          width: "90px",
                          fontSize: "12px",
                          textAlign: "start",
                        }}
                      >
                        ${vehicle?.freight_charges}.00
                      </td>
                      <td
                        style={{
                          width: "90px",
                          fontSize: "12px",
                          textAlign: "start",
                        }}
                      >
                        ${vehicle?.towing_charges}.00
                      </td>
                      <td
                        style={{
                          width: "90px",
                          fontSize: "12px",
                          textAlign: "start",
                        }}
                      >
                        ${vehicle?.chasis}.00
                      </td>
                      <td
                        style={{
                          width: "90px",
                          fontSize: "12px",
                          textAlign: "start",
                        }}
                      >
                        ${vehicle?.invoice_amount?.toLocaleString() || "0.00"}
                      </td>
                    </tr>
                    <hr style={{ padding: 10 }} />
                  </>
                ))}
              </tbody>
            </table>
          </section>
          {/* /////seven end//////// */}
          <div className="seperator" style={{ marginTop: "1vh" }}></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "150px auto", // Two columns: 150px for headings, auto for values
                gap: "10px 20px", // Row and column spacing
                alignItems: "center",
                fontSize: "12px",
                textAlign: "end",
              }}
            >
              <div style={{ fontWeight: "bold" }}>SubTotal:</div>
              <div>
                ${invoice?.invoice?.sub_total?.toLocaleString() || "0.00"}
              </div>

              <div style={{ fontWeight: "bold" }}>Discount:</div>
              <div>
                ${invoice?.invoice?.discount?.toLocaleString() || "0.00"}
              </div>

              <div style={{ fontWeight: "bold" }}>Total:</div>
              <div>${invoice?.invoice?.total?.toLocaleString() || "0.00"}</div>
            </div>
          </div>
          <div className="seperator" style={{ marginTop: "1vh" }}></div>
          {/* /////eight start//////// */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                width: "400px",
                marginLeft: "3vw",
              }}
            >
              <div
                style={{
                  width: "400px",

                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ width: "160px" }}>No </h5>
                <h5 style={{ width: "260px" }}>Container Number</h5>
                <h5 style={{ width: "120px" }}>Size</h5>
              </div>
              <div
                style={{
                  width: "400px",

                  display: "flex",
                  justifyContent: "space-between",
                  borderTop: "1px solid #666",
                }}
              >
                <h5 style={{ fontWeight: "lighter", width: "160px" }}>1</h5>
                <h5 style={{ fontWeight: "lighter", width: "260px" }}>
                  {invoice?.invoice?.vehicles[0]?.container_no}
                </h5>
                <h5 style={{ width: "120px", fontWeight: "lighter" }}>
                  {invoice?.invoice?.vehicles[0]?.container_size}
                </h5>
              </div>
            </div>
          </div>
          {/* /////eight end//////// */}
          <div className="seperator" style={{ marginTop: "3vh" }}></div>
          <h5
            style={{
              marginLeft: "0vw",
              marginBottom: "20px",
              fontWeight: "bolder",
              marginTop: "20px",
            }}
          >
            Account Information
          </h5>
          {/* /////nine start//////// */}
          <div
            style={{
              display: "flex",
              marginLeft: "0vw",
              height: "3vh",
            }}
          >
            <h5
              style={{
                fontWeight: "lighter",
                width: "15vw",
              }}
            >
              Account Name
            </h5>
            <h5 style={{ fontWeight: "lighter" }}> : CTRADE USA INC </h5>
          </div>
          <div style={{ display: "flex", marginLeft: "0vw", height: "3vh" }}>
            <h5
              style={{
                fontWeight: "lighter",
                width: "15vw",
              }}
            >
              Bank Name
            </h5>
            <h5 style={{ fontWeight: "lighter" }}> : BANK OF AMERICA </h5>
          </div>
          <div style={{ display: "flex", marginLeft: "0vw", height: "3vh" }}>
            <h5
              style={{
                fontWeight: "lighter",
                width: "15vw",
              }}
            >
              Account Number
            </h5>
            <h5 style={{ fontWeight: "lighter" }}> : 4881 2067 0589</h5>
          </div>
          <div style={{ display: "flex", marginLeft: "0vw", height: "3vh" }}>
            <h5
              style={{
                fontWeight: "lighter",
                width: "15vw",
              }}
            >
              Routing Number
            </h5>
            <h5 style={{ fontWeight: "lighter" }}>
              : 111000023/111000025 (PAPER & ELECTRONICS){" "}
            </h5>
          </div>
          <div style={{ display: "flex", marginLeft: "0vw", height: "3vh" }}>
            <h5
              style={{
                fontWeight: "lighter",
                width: "15vw",
              }}
            >
              Routing Number
            </h5>
            <h5 style={{ fontWeight: "lighter" }}>
              : 026009593 ( WIRE TRANSFER - DOMESTIC ){" "}
            </h5>
          </div>
          <div style={{ display: "flex", marginLeft: "0vw", height: "3vh" }}>
            <h5
              style={{
                fontWeight: "lighter",
                width: "15vw",
              }}
            >
              Swift Code
            </h5>
            <h5 style={{ fontWeight: "lighter" }}> : (US DOLLAR) BOFAUS3N </h5>
          </div>
          <div style={{ display: "flex", marginLeft: "0vw", height: "3vh" }}>
            <h5
              style={{
                fontWeight: "lighter",
                width: "15vw",
              }}
            >
              Branch Address
            </h5>
            <h5 style={{ fontWeight: "lighter" }}>
              : BANK OF AMERICA, N.A, 222 BROADWAY, NEWYORK, NY 10038
            </h5>
          </div>
          {/* /////nine end//////// */}
          <h5
            style={{
              fontWeight: "lighter",
              marginTop: "6vh",
              marginLeft: "0vw",
              marginRight: "0vw",
              fontWeight: "100",
              color: "#53575A",
            }}
          >
            We do not accept CARD payments, only cashier's cheques or direct
            bank transfers.Any additional charges Government charges if any are
            applied will be charged at actual Payment should fall into the
            CTrade USA Inc account within 10 days after loading the container.
            <br />
            Any dispute in invoice should be raised within 07 days from the
            issue date otherwise it will be considered as confirmed.
            <br />
            <br /> Any invoice delayed from the due date will be subject to $100
            as late fees15 Day free at facilities there after $15 Day/Car from
            the day of receiving in and out charges will be $100 subject to
            storage charges.
          </h5>
          <h5
            style={{
              marginLeft: "0vw",
              marginRight: "0vw",
              fontWeight: "bolder",
            }}
          >
            Note*: Payment against this invoice will be accepted as terms and
            conditions confirmed. In case of delay in payment ctrade usa will
            not be responsbile for any storage demarruages/detentions
          </h5>
          <div
            className="seperatorTwo"
            style={{
              marginTop: "3vh",
              height: "1px",
              backgroundColor: "#8F9CA6",
            }}
          ></div>
          <h5
            style={{
              textAlign: "center",
              color: "#8F9CA6",
              fontWeight: "lighter",
              marginTop: "12px",
            }}
          >
            THANK YOU FOR DOING BUSINESS WIITH C TRADE USA INC.
          </h5>
        </div>
      </div>
      <div
        style={{
          marginTop: "20px",
          marginBottom: "25px",
          display: "flex",
          justifyContent: "flex-end",
          width: "850px",
        }}
      >
        <Space>
          <Button type="primary" onClick={visibleModal}>
            <img src="/images/edit.png" alt="" /> Edit
          </Button>

          <Button type="primary">
            <img src="/images/print.png" alt="" /> Print
          </Button>
          <Button type="primary" onClick={showModal}>
            Approve
          </Button>
          <Button type="primary" onClick={openModal}>
            Make Payment
          </Button>
        </Space>
      </div>

      <EditModal
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        invoice={invoice}
        columns={columns}
        onFinish={onFinish}
        form={form}
      />


      <MakePayment
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        form2={form2}
        handlePayment={handlePayment}
        pendingInvoices={pendingInvoices}
        paymentmodes={paymentmodes}
        banks={banks}
        invoice={invoice}
        disable={disable}
        setDisable={setDisable}
      />

      <Modal
        title={<div style={{ textAlign: "center" }}>Confirm</div>}
        open={isModalshow}
        onOk={handleApprove}
        onCancel={handleCancel}
        centered
        styles={{
          body: {
            padding: "10px",
          },
        }}
        width={300}
        footer={
          <div className="flex justify-center gap-3">
            <Button
              type="default"
              onClick={handleCancel}
              style={{ width: "30%" }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleApprove}
              style={{ width: "30%" }}
            >
              Yes do it
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: "center" }}>
          You sure you want to approve this invoice and send to customer?
        </p>
      </Modal>
    </div>
  );
};

export default ViewInvoice;

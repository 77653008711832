import React from 'react'
import { ArrowDownOutlined } from '@ant-design/icons'
import { Button, Col, Dropdown, Menu, Row, Space } from 'antd'
import { Link } from 'react-router-dom';

const menuItems = [
    {
        key: '1',
        label: 'CAPITAL AND RESERVES',
        children: [
            {
                key: '1-1',
                label: 'CAPITAL',
                path: '/accounts/chart-account/capital',
            },
            {
                key: '1-2',
                label: 'PROFIT & LOSS ACCOUNT',
                path:"/accounts/chart-account/capital"
            },
            {
                key: '1-3',
                label: 'DRAWING',
                path:"/accounts/chart-account/capital"
            },
        ],
    },
    {
        key: '2',
        label: 'LIABILITIES',
        children: [
            {
                key: '2-1',
                label: 'ACCOUNT PAYABLES',
            },
            {
                key: '2-2',
                label: 'OTHER PAYABLE',
            },
            {
                key: '2-3',
                label: 'TAX PAYABLE',
            },
        ],
    },
    {
        key: '3',
        label: 'ASSETS',
        disabled: true, // Based on the structure in `items` for the disabled sub menu
        children: [
            {
                key: '3-1',
                label: 'FIXED ASSETS',
            },
            {
                key: '3-2',
                label: 'VEHICLE IN HAND',
            },
            {
                key: '3-3',
                label: 'CASH BALANCE',
            },
            {
                key: '3-4',
                label: 'BANK BALANCE',
            },
            {
                key: '3-5',
                label: 'RECEIVABLE FROM CUSTOMERS',
            },
            {
                key: '3-6',
                label: 'ADVANCE INCOME TAX',
            },
            {
                key: '3-7',
                label: 'SECURITY DEPOSIT',
            },
            {
                key: '3-8',
                label: 'ADVANCE TO STAFF',
            },
            {
                key: '3-9',
                label: 'OTHER RECEIVABLE',
            }, {
                key: '3-10',
                label: 'ACCUMULATED DEPRECIATION',
            },
        ],
    },
    {
        key: '4', label: 'REVENUE',
        children: [
            {
                key: '4-1',
                label: 'SALES',
            },
            {
                key: '4-2',
                label: 'OTHER INCOMES',
            },
        ],
    },
    {
        key: '5',
        label: 'COST AND EXPENSES',
        path: '/accounts/cash-voucher',
    },
    { key: '6', label: 'PROVISION FOR TAXATION' },
];

function Accounts() {
    const menu = (
        <Menu >
            {menuItems.map((item) =>
                item.children ? (
                    <Menu.SubMenu key={item.key} title={item.label} >
                        {item.children.map((child) => (
                            <Menu.Item key={child.key} >
                                {child.path ? (
                                    <Link to={child.path} style={{ color: 'inherit', textDecoration: 'none' }}>
                                        {child.label}
                                    </Link>
                                ) : (
                                    child.label
                                )}
                            </Menu.Item>
                        ))}
                    </Menu.SubMenu>
                ) : (
                    <Menu.Item key={item.key}   >
                        {item.path ? (
                            <Link to={item.path} style={{ color: 'inherit', textDecoration: 'none' }}>
                                {item.label}
                            </Link>
                        ) : (
                            item.label
                        )}
                    </Menu.Item>
                )
            )}
        </Menu>
    );
    return (
        <div style={{ padding: '25px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#FFFFFF' }}>
            <Space direction="vertical" style={{ width: '100%' }} size="large">
                <div>
                    <h1 style={{ color: "#606A72", fontSize: "14px" }} className='mb-5'>ACCOUNT MASTER FILE</h1>                    <Row gutter={[16, 16]} align="middle">
                        <Col span={6}>
                            <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter" >
                                <Button
                                    className="p-4"
                                    style={{
                                        borderRadius: '12px',
                                        width: '100%',
                                        border: '1px solid #333333',
                                        backgroundColor: "#B83D3D", color: "white"
                                    }}>
                                    CHART OF ACCOUNTS
                                    <ArrowDownOutlined />
                                </Button>
                            </Dropdown>
                        </Col>
                        <Col span={6}>
                            <Link to='/accounts/opening-balances'>
                                <Button className='p-4' style={{ borderRadius: "12px", width: "100%", border: "1px solid #333333", backgroundColor: "#B83D3D", color: "white" }} >OPENING BALANCES</Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
                {/* Transactions Section */}
                {/* <div>
                    <h1 style={{ color: "#606A72", fontSize: "14px" }} className='mt-8   mb-5'>TRANSACTIONS</h1>
                    <Row gutter={[16, 16]} align="middle" >
                        <Col span={6} >
                            <Link to='/accounts/cash-voucher' >
                                <Button className='p-4' style={{ width: '100%', borderRadius: "12px", border: "1px solid #333333", backgroundColor: "#B83D3D", color: "white" }}>CASH VOUCHER</Button>
                            </Link>
                        </Col>

                        <Col span={6}>
                            <Link to='/accounts/bank-voucher'>
                                <Button className='p-4' style={{ width: '100%', borderRadius: "12px", border: "1px solid #333333", backgroundColor: "#B83D3D", color: "white" }}>BANK VOUCHER</Button>
                            </Link>
                        </Col>

                        <Col span={6}>
                            <Button className='p-4' style={{ width: '100%', borderRadius: "12px", border: "1px solid #333333", backgroundColor: "#B83D3D", color: "white" }}>VENDOR BILL</Button>
                        </Col>
                        <Col span={6}>
                            <Link to='/accounts/journal-voucher'>
                                <Button className='p-4 ' style={{ width: '100%', borderRadius: "12px", border: "1px solid #333333", backgroundColor: "#B83D3D", color: "white" }} >JOURNAL VOUCHER</Button>
                            </Link>
                        </Col>
                    </Row>
                </div> */}
            </Space>
        </div>
    )
}
export default Accounts

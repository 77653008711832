import ajaxService from "./ajax-service";

class BookingService {
  async fetch(page) {
    const response = await ajaxService.get(`/booking-schedules?page=${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async viewbooking(id) {
    const response = await ajaxService.get(`/booking-schedules/${id}`);
    if (response ?? false) {
      return response;
    }
  }
  async addbooking(data) {
    const response = await ajaxService.post(`/booking-schedules/`, data);
    if (response?? false) {
      return response;
    }
  }
  async updatebooking(id, data) {
    const response = await ajaxService.put(`/booking-schedules/`, id, data);
    if (response ?? false) {
      return response;
    }
  }
  async deletebooking(id) {
    const response = await ajaxService.delete(`/booking-schedules/`, id);
    if (response ?? false) {
      return response;
    }
  }
}

const bookingService = new BookingService();

export default bookingService;

import { validationErrors } from "../utils";
import ajaxService from "./ajax-service";

class ReportService {
  async InvoiceStatus(page, data) {
    const response = await ajaxService.post(
      `/reports/invoices?page=${page}`,
      data
    );
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async LoadPlan(page, data) {
    const response = await ajaxService.post(
      `/reports/load-plans?page=${page}`,
      data
    );
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async CollectionAmount(page, data) {
    const response = await ajaxService.post(
      `/reports/collections?page=${page}`,
      data
    );
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async BankCollectionAmount(page, data) {
    const response = await ajaxService.post(
      `/reports/bank-collections?page=${page}`,
      data
    );
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async CustomerDues(page, data) {
    const response = await ajaxService.post(
      `/reports/customer-dues?page=${page}`,
      data
    );
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async CashFlowDue(page, data) {
    const response = await ajaxService.post(
      `/reports/cash-flow-dues?page=${page}`,
      data
    );
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async CashFlowSummary() {
    const response = await ajaxService.get(`/reports/cash-flow-summary`);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async MonthlyCashFlow(page, month, type) {
    const response = await ajaxService.get(
      `/reports/cash-flow-month/${month}?page=${page}&type=${type}`
    );
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async DailyCashFlow() {
    const response = await ajaxService.get(`/reports/cash-flow-daily`);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async DailyCashFlowDetails(month) {
    const response = await ajaxService.get(`/reports/cash-flow-daily/${month}`);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async SalesSummary(page, type, data) {
    const response = await ajaxService.post(
      `/reports/sales-summary?page=${page}&type=${type}`,
      data
    );
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async SalesDetails(page, type, data) {
    const response = await ajaxService.post(
      `/reports/sales-detail?page=${page}&type=${type}`,
      data
    );
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async CustomerAging(data) {
    const response = await ajaxService.post(`/reports/customer-aging`, data);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async CustomerAgingDetails(id, date) {
    const response = await ajaxService.get(
      `/reports/customer-aging/details/${id}/${date}`
    );
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async VendorLedger(page, data) {
    const response = await ajaxService.post(
      `/reports/vendor-ledger?page=${page}`,
      data
    );
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async VendorPayable(page, data) {
    const response = await ajaxService.post(
      `/reports/vendor-payable-payments?page=${page}`,
      data
    );
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
}

const reportService = new ReportService();
export default reportService;

import React, { useEffect, useState } from "react";
import { Table, Tag, Button, Row, Col, Space } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { appointmentService, authService } from "../../../services";

function Appoinments() {
  const [loading, setLoading] = useState(false);
  const [appoinments, setAppointments] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "#",
    //   key: "#   ",
    //   render: (text, record, index) =>
    //     (pagination.current - 1) * pagination.pageSize + index + 1,
    // },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => {
        const { approved, reject, pending } = record;
        // Define the status map for colors and text
        const statusMap = {
          0: { color: "#D68606", text: "Pending", count: pending },
          1: { color: "#06D652", text: "Approved", count: approved },
          3: { color: "#B83D3D", text: "Reject", count: reject },
        };

        // Conditionally render tags based on the status
        return (
          <Space>
            {status === 2 && (
              // If status is 2, show "Canceled"
              <Tag
                color="#F04949"
                style={{ fontSize: "12px", padding: "5px 5px" }}
              >
                Canceled
              </Tag>
            )}
            {/* // Otherwise, show Approved, Reject, and Pending based on their
            counts */}
            <>
              {approved > 0 && (
                <Tag
                  color="#06D652"
                  style={{ fontSize: "12px", padding: "5px 5px" }}
                >
                  Approved ({approved})
                </Tag>
              )}
              {reject > 0 && (
                <Tag
                  color="#B83D3D"
                  style={{ fontSize: "12px", padding: "5px 5px" }}
                >
                  Reject ({reject})
                </Tag>
              )}
              {pending > 0 && (
                <Tag
                  color="#D68606"
                  style={{ fontSize: "12px", padding: "5px 5px" }}
                >
                  Pending ({pending})
                </Tag>
              )}
            </>
          </Space>
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      render: (text, record) => (
        <Link to={`/operation/appoinments/view/${record.id}`}>
          <Button type="primary" size="small">
            <EyeOutlined />
          </Button>
        </Link>
      ),
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.pageSize) => {
    setLoading(true);
    const { success, data } = await appointmentService.fetch(page);
    if (success) {
      setAppointments(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);

  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Appointments</h1>
        </Col>
      </Row>
      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={appoinments}
        style={{ marginTop: "20px" }}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        loading={loading}
      />
    </div>
  );
}

export default Appoinments;

import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form, Input, Button, Typography, notification, Radio, Row, Col, } from "antd";
import { MailOutlined, LockOutlined, UserOutlined } from "@ant-design/icons";
import { authService, countryService, driverService } from "../services";

const { Title } = Typography;

const SignForm = () => {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedValue, setSelectedValue] = useState(true);
  const [options, setOptions] = useState([]);

  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    const data = {
      name: values.username,
      email: values.email,
      password: values.password,
      country: values.country,
      phone: values.phone,
      driver: selectedValue,
    };

    const authResponse = await authService.signUpPost(data);

    if (authResponse.success) {
      notification.success({
        message: "Signup Successful!",
        description: "You have successfully signed up.",
      });
      navigate("/login");
    } else {
      notification.error({
        message: "Signup Failed",
        description: authResponse.message || "An error occurred during signup.",
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error("Form Submission Failed:", errorInfo);
  };

  const fetchCountries = async () => {
    const response = await countryService.countryGet();
    if (response?.success) {
      setCountries(response.data);
    } else {
      console.error("Failed to fetch countries:", response.message);
    }
  };

  const fetchDrivers = async () => {
    const response = await driverService.driverGet();

    if (response.success) {
      const mappedOptions = response.data.map((driver, index) => ({
        label: `As A ${driver.name}`,
        value: `As A ${driver.name}`,
      }));
      setOptions(mappedOptions);
      setSelectedValue(mappedOptions[0]?.value || "");
    } else {
      console.error("Failed to fetch drivers:", response.message);
    }
  };

  const handleChange = (value) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    fetchCountries();
    fetchDrivers();
  }, []);

  return (
    <div className="login-container">
      <div style={{ marginBottom: 15 ,display: "flex", flexDirection: "column"}}>
          <img
            src="/images/Savannah.png"
            alt="ctrade-Logo"
            style={{ width: "370px", height: "120px" , }}
          />
        <Title style={{ fontSize: "20px" }}>Sign up</Title>
      </div>
      <Form
        layout="vertical"
        style={{ width: "350px" }}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Radio.Group
          block
          options={options.map((option, index) => ({
            ...option,
            label: (
              <span style={{ display: "flex", alignItems: "center", gap: 10 }}>
                {
                  <img
                    src={
                      selectedValue === option.value
                        ? option.value.includes("Trucker")
                          ? "/images/tr2.png" // Active Trucker icon
                          : "/images/db2.png" // Active Dispatcher icon
                        : option.value.includes("Trucker")
                          ? "/images/tr.png" // Default Trucker icon
                          : "/images/dp.png" // Default Dispatcher icon
                    }
                    alt={option.value}
                    style={{
                      width: "16px",
                      height: "16px",
                      marginRight: "8px",
                    }}
                  />
                }
                {option.label}
              </span>
            ),
          }))}
          value={selectedValue}
          onChange={(e) => handleChange(e.target.value)}
          optionType="button"
          buttonStyle="solid"
          style={{ marginBottom: 12 }}
        />
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Please input your Username!" }]}
          style={{ marginBottom: 12 }}
        >
          <Input
            prefix={<UserOutlined />}
            style={{ borderRadius: "16px", height: "40px" }}
            placeholder="Enter your Username"
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            { type: "email", message: "The input is not a valid email!" },
            { required: true, message: "Please input your email!" },
          ]}
          style={{ marginBottom: 12 }}
        >
          <Input
            prefix={<MailOutlined />}
            style={{ borderRadius: "16px", height: "40px" }}
            placeholder="Enter your email"
            allowClear
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
          style={{ marginBottom: 12 }}
        >
          <Input.Password
            prefix={<LockOutlined />}
            style={{ borderRadius: "16px", height: "40px" }}
            placeholder="Enter your password"
            allowClear
          />
        </Form.Item>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="phone"
              rules={[
                { required: true, message: "Please input your phone number!" },
              ]}
              style={{ marginBottom: 12 }}
            >
              <Input
                type="number"
                prefix={
                  <img
                    src="/images/ph.png"
                    alt="icon"
                    style={{ width: "12px", height: "12px" }}
                  />
                }
                placeholder="Phone No"
                style={{ borderRadius: "16px", height: "40px" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="country"
              rules={[{ required: true, message: "Please select a country!" }]}
              style={{ marginBottom: 12 }}
            >
              <select
                name="Select Country"
                style={{
                  height: "40px",
                  borderRadius: "16px",
                  padding: "5px",
                  color: "#000",
                }}
                className="border-2 border-gray w-full"
              >
                <option value="" style={{ color: "#B0B0B0" }} disabled selected>
                  Select a country
                </option>

                {countries.map((country) => (
                  <option key={country.id} value={country.iso_code}>
                    {country.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ marginBottom: 12 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: "100%", borderRadius: "16px", height: "40px" }}
          >
            Sign up
          </Button>
        </Form.Item>
        <div>
          <p style={{ textAlign: "right" }}>
            <Link to="/login" className="text-blue hover:underline">
              Already a member?
            </Link>
          </p>
        </div>
      </Form>
    </div>
  );
};

export default SignForm;

import ajaxService from "./ajax-service";

class CreditNote {
  async fetch(page) {
    const response = await ajaxService.get(
      `/invoices/credit-notes?page=${page}`
    );
    if (response ?? false) {
      return response;
    }
  }
  async addCreditNote(data) {
    const response = await ajaxService.post(`/invoices/credit-notes`, data);
    if (response ?? false) {
      return response;
    }
  }
}

const creditNoteService = new CreditNote();
export default creditNoteService;

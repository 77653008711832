import AWS from "aws-sdk";

class AwsService {
    async uploadS3Bucket(images, user_name = false, chasis_no, base_folder) {
        const S3_BUCKET = "ctrade-storage";
        const REGION = "us-east-1";

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: REGION,
            httpOptions: { timeout: 300000, connectTimeout: 30000 },
        });

        const s3 = new AWS.S3({ region: REGION });

        // Helper function to get MIME type
        const getContentType = (fileName) => {
            const ext = fileName.split('.').pop().toLowerCase();
            const mimeTypes = {
                jpeg: 'image/jpeg',
                jpg: 'image/jpeg',
                png: 'image/png',
                gif: 'image/gif',
                pdf: 'application/pdf',
                webp: 'image/webp'
            };
            return mimeTypes[ext] || 'application/octet-stream';
        };

        // Determine folder path
        const baseFolder = `${base_folder}/`;
        // const customerFolder = `${baseFolder}${user_name.replace(/\s+/g, "_")}/`;
        const customerFolder = user_name ? `${baseFolder}${user_name.replace(/\s+/g, "_")}/` : baseFolder;
        const vehicleOrContainerFolder = `${customerFolder}${chasis_no}/`;

        try {
            // Step 1: Check if the folder for `chasis_no` exists and delete its contents
            // const listParams = {
            //     Bucket: S3_BUCKET,
            //     Prefix: vehicleOrContainerFolder, // List only contents under the chasis_no folder
            // };
            // const listedObjects = await s3.listObjectsV2(listParams).promise();

            // if (listedObjects.Contents.length > 0) {
            //     console.log(`Deleting existing contents of folder: ${vehicleOrContainerFolder}`);
            //     const deleteParams = {
            //         Bucket: S3_BUCKET,
            //         Delete: {
            //             Objects: listedObjects.Contents.map((obj) => ({ Key: obj.Key })), // Map to object keys
            //         },
            //     };
            //     await s3.deleteObjects(deleteParams).promise();
            // }

            // Step 2: Upload new images to the folder
            console.log(`Uploading new images to folder: ${vehicleOrContainerFolder}`);
            const uploadPromises = images.map((image) => {
                const file = image.originFileObj;
                const fileName = file.name;
                const contentType = getContentType(fileName);

                const params = {
                    Bucket: S3_BUCKET,
                    Key: `${vehicleOrContainerFolder}${fileName}`,
                    Body: file,
                    ContentType: contentType,
                };

                return s3
                    .putObject(params)
                    .promise()
                    .then(() => `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${vehicleOrContainerFolder}${encodeURIComponent(fileName)}`)
                    .catch((err) => {
                        console.error(`Failed to upload ${fileName}:`, err);
                        return null;
                    });
            });

            const uploadedUrls = await Promise.all(uploadPromises);

            return uploadedUrls.filter(Boolean); // Remove failed uploads (null values)
        } catch (error) {
            console.error("Error during S3 operations:", error);
            throw new Error("Failed to upload images to S3");
        }
    }

    // CHANGES HIGHLIGHTED: New method for deleting images from AWS S3
    async deleteFromS3Bucket(imagesToDelete) {
        const S3_BUCKET = "ctrade-storage";
        const REGION = "us-east-1";

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: REGION,
        });

        const s3 = new AWS.S3({ region: REGION });

        try {
            const deletePromises = imagesToDelete.map((imageUrl) => {
                const key = imageUrl.split(`https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/`)[1];

                if (key) {
                    const params = {
                        Bucket: S3_BUCKET,
                        Key: key,
                    };
                    return s3.deleteObject(params).promise();
                }
                return null;
            });

            await Promise.all(deletePromises);
            console.log("Removed images from S3:", imagesToDelete);
        } catch (error) {
            console.error("Error deleting images from S3:", error);
            throw new Error("Failed to delete images from S3");
        }
    }
}

const awsService = new AwsService();
export default awsService;

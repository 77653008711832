import ajaxService from "./ajax-service";

class CustomerBalance {
  async fetch(page) {
    const response = await ajaxService.get(`/users-balances?page=${page}`);
    if (response ?? false) {
      return response;
    }
  }
}

const customerBalanceService = new CustomerBalance();
export default customerBalanceService;

import React, { useState } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Select,
  Input,
  DatePicker,
  Button,
  notification,
} from "antd";
import { debitNoteService } from "../../../../../services";

const AddModal = ({
  visible,
  setIsModalShow,
  onCancel,
  companies,
  customers,
  invoices,
  expenses,
  setRefresh
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleClose = () => {
    setRefresh(true)
    form.resetFields();
    setIsModalShow(false);
  };
  const onFinish = async (values) => {
    setLoading(true);
    const data = {
      ...values,
      issue_date: values.issue_date.format("YYYY-MM-DD"),
    };
    const response = await debitNoteService.addDebitNote(data);
    if (response.success) {
      notification.success({
        message: "Successfully Added!",
        description: response.message,
        placement: "topRight",
      });
    }
    setLoading(false);
    handleClose();
  };
  return (
    <Modal
      title={<div style={{ width: "100%" }}>Create Debit Note</div>}
      open={visible}
      onCancel={onCancel}
      footer={null}
      closable={true}
      width={700}
      style={{ padding: "15px" }}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        requiredMark={false}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Select Company"
              name="company_id"
              rules={[{ required: true, message: "Please select a company" }]}
            >
              <Select placeholder="Select Inter-Company">
                {companies.map((comp) => (
                  <Select.Option key={comp.id} value={comp.id}>
                    {comp.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Select Customer"
              name="user_id"
              rules={[{ required: true, message: "Please select a customer" }]}
            >
              <Select placeholder="Select Customer">
                {customers.map((cust) => (
                  <Select.Option key={cust.id} value={cust.id}>
                    {cust.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Select Invoice"
              name="invoice_id"
              rules={[{ required: true, message: "Please select an invoice" }]}
            >
              <Select placeholder="Select Invoice">
                {invoices.map((invoice) => (
                  <Select.Option key={invoice.id} value={invoice.id}>
                    {invoice.id}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Select Expense" name="expense_id">
              <Select placeholder="91099">
                {expenses.map((expense) => (
                  <Select.Option key={expense.id} value={expense.id}>
                    {expense.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Amount"
              name="amount"
              rules={[{ required: true, message: "Please enter the amount" }]}
            >
              <Input type="number" placeholder="91099" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Issue Date"
              name="issue_date"
              rules={[{ required: true, message: "Please select a date" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Description" name="description">
              <Input.TextArea
                placeholder="Enter special notes here..."
                rows={4}
              />
            </Form.Item>
          </Col>
        </Row>
        <Col span={24}>
          <Form.Item style={{ marginTop: 10, textAlign: "center" }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              style={{ width: "70%" }}
            >
              Create Debit Note
            </Button>
          </Form.Item>
        </Col>
      </Form>
    </Modal>
  );
};

export default AddModal;

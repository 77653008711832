import ajaxService from "./ajax-service";

class DebitNote {
  async fetch(page) {
    const response = await ajaxService.get(`/debit-notes?page=${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async addDebitNote(data) {
    const response = await ajaxService.post(`/debit-notes/store`, data);
    if (response ?? false) {
      return response;
    }
  }
}

const debitNoteService = new DebitNote();

export default debitNoteService;

import React, { useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Row, Select, Table } from 'antd';
import { CiSquarePlus } from "react-icons/ci";
import { Option } from 'antd/es/mentions';
import { DeleteOutlined } from '@ant-design/icons'

export default function BankVoucher() {
    const [form] = Form.useForm();
    const [data, setData] = useState([
        {
            key: "1",
            Account_Type: "User1",
            Account_entry: "User1",
            Fright_Charges: "",
            No_Of_Vehicles: "",
        }
    ]);

    const columns = [
        {
            title: "Chq #",
            dataIndex: 'Chq#',
            key: 'Chq#',
            render: (text, record) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title: "Ref #",
            dataIndex: 'Ref_#',
            key: 'Ref_#',
            render: (text, record) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title: "Level 3 account name (entry level",
            dataIndex: 'Account_entry',
            key: 'Account_entry ',
            render: (text, record) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title: "Account Code #",
            dataIndex: "Account_Code",
            key: "Account_Code",
            render: (text, record) => (
                <Select
                    defaultValue={text}
                    style={{ width: '100%' }}
                >
                    <Option value="User1">User1</Option>
                    <Option value="User2">User2</Option>
                    <Option value="User3">User3</Option>
                </Select>
            ),
        },
        {
            title: "Description",
            dataIndex: "Description",
            key: "Description",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title: "Amount",
            dataIndex: "Amount",
            key: "Amount",
            render: (text) => (
                <Input
                    defaultValue={text}
                    style={{ width: "100%" }}
                />
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (text, record, index) => {
                if (data.length === 1) return null;
                return (
                    <Button type="primary" size="small" onClick={() => handleDeleteRow(record.Id)}>
                        <DeleteOutlined />
                    </Button>
                );
            },
        },
    ]

    const handleAddRow = () => {
        setData([
            ...data,
            {
                Id: data.length + 1,
                Account_Type: "User1",
                Account_entry: "User1",
                "Fright Charges": "",
                "No Of Vehicles": "",
            },
        ]);
    };
    const handleDeleteRow = (Id) => {
        setData(data.filter((item) => item.Id !== Id));
    };
    return (
        <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
            <Form
                requiredMark={false}
                form={form}
                layout="vertical"
                initialValues={{ layout: "vertical" }}
            >
                <Row gutter={24} className='mt-2  pr-3 pl-3 ' >
                    <Col span={6}>
                        <img src="/images/ctrade-logo.png" alt="" style={{ width: "165px", height: "88px" }} />
                        <p>Phone:<span> 4077824523</span></p>
                        <p>Mobile:<span> 8328192622</span></p>
                        <p>www.ctrade.us</p>
                    </Col>
                    <Col span={9} className='mt-10'>
                        <Form.Item label="Bank Voucher Type : Payment/ReceiptVoucher # CPV / CRV" name="name">
                            <Input placeholder="" />
                        </Form.Item>
                    </Col>
                    <Col span={9} className='mt-10'>
                        <Form.Item label=" Transaction Date" name="Transaction_Date">
                            <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <h1 style={{ fontWeight: 'bolder', }} className='mt-5 mb-1'> Bank Voucher</h1>
                    </Col>
                </Row>

                <Table columns={columns} scroll={{ x: "max-content" }} dataSource={data} pagination={false} rowKey="Id" style={{ marginTop: '5px' }} className='custom-table' />

                <div style={{ marginTop: 20 }} className='flex flex-col justify-center items-center'>
                    <CiSquarePlus style={{ cursor: 'pointer', fontSize: '25px' }} onClick={handleAddRow} />
                </div>
                <Row justify="end">
                    <Col span={5} className="mt-10">
                        <Form.Item label="Total :" name="Total">
                            <Input placeholder="" style={{ width: '100%' }} />
                        </Form.Item>
                        <Button type="primary" style={{ width: '100%', alignItems: "flex-end", textAlign: "right" }} >
                            Save
                        </Button>
                    </Col>
                </Row>
            </Form>

            <style>
                {`
.custom-table .ant-table-thead > tr > th {
background-color: #33363F; /* Custom background color */
color: #ffffff; /* Custom text color */
font-weight: bold;
text-align: center;
padding: 10px;
}
`}
            </style>
        </div>

    )

}
import React, { useEffect, useState } from "react";
import { Table, Space, Row, Col, Input, Select, Button } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { invoiceService } from "../../../services";

function CustomerInvoices() {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });

  const options = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "9",
      label: "9",
    },
  ];
  const columns = [
    {
      title: "#",
      dataIndex: "Id",
      key: "_id",
      // render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Chassis Number",
      dataIndex: "vehicle_chasis",
      key: "vehicle_chasis",
    },
    {
      title: "Container Number",
      dataIndex: "container_number",
      key: "container_number",
      render: (text)=> text || "N/A"
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Action",
      key: "Action",
      render: (text, record, { _id }) => (
        <Space size="small">
          {/* <Link to="/vehicles/edit">
            <Button type="primary" size="small">
              <EditOutlined />
            </Button>
          </Link> */}

          <Link to={`/invoice/view/${record.id}`}>
            <Button type="primary" size="small">
              <EyeOutlined />
            </Button>
          </Link>
          {/* onClick={() => showModal(record)} */}
          {/* <Button type="primary" size="small">
            <DeleteOutlined />
          </Button> */}
        </Space>
      ),
    },
  ];
  const data = [{}];

  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    const { success, data } = await invoiceService.fetchCustomerInvoices(page);
    setLoading(false);
    if (success) {
      setInvoices(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);
  return (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={0} className="pl-4 pt-6">
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Invoices</h1>
        </Col>
      </Row>

      <Row
        gutter={0}
        style={{
          marginTop: "10px",
          background: "#F2F2F7",
          alignItems: "center", // Ensures alignment of items within the row
        }}
        className="pl-4 pr-4 pt-1 pb-1"
      >
        <Col
          span={4}
          xs={24}
          sm={12}
          md={6}
          lg={4}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Space direction="horizontal" size={10}>
            <p style={{ margin: 0 }}>Show</p>
            <Select
              defaultValue={10}
              options={options}
              size="small"
              style={{ width: 70, fontSize: 14 }}
            />
            <p style={{ margin: 0 }}>entries</p>
          </Space>
        </Col>

        {/* Second Column: Right-Aligned Search */}
        <Col
          span={20}
          xs={24}
          sm={12}
          md={18}
          lg={20}
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{
              width: "30%",
              borderRadius: "4px",
              fontSize: 14,
            }}
          />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={invoices}
            loading={loading}
            pagination={{
              current: pagination.current,
              total: pagination.total,
              pageSize: pagination.pageSize,
              onChange: (page) => initialize(page, pagination.pageSize),
              showSizeChanger: false,
            }}
            scroll={{ x: "max-content" }}
            style={{ marginTop: "10px" }}
          />
        </Col>
      </Row>
    </div>
  );
}

export default CustomerInvoices;

import React, { useState, useEffect } from "react";
import { Table, Space, Button, Row, Col, Input, Select, Card, Divider, Modal, } from "antd";
import { EyeOutlined, SearchOutlined, EditOutlined, DeleteOutlined, } from "@ant-design/icons";
import { customerService } from "../../../services";
import { Link, useNavigate, useParams } from "react-router-dom";

const { Meta } = Card;

function ViewCustomer() {
  const { id } = useParams();
  // const [isModalshow, setIsModalShow] = useState(false)
  const navigate = useNavigate()
  const [loading, setLoading] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, total: 0, pageSize: 10, });
  const [customer, setCustomer] = useState([]);
  // const [selectedDeleteRecord, setSelectedDeleteRecord] = useState(null);

  // const showModal = (id) => {
  //   setSelectedDeleteRecord(id)
  //   setIsModalShow(true)
  // }
  // const handleCancel = () => {
  //   setIsModalShow(false)
  // };

  // const handleDelete = async (id) => {
  //   const response = await customerService.deleteCustomer(id);
  //   if (response.success) {
  //     setCustomer((prevData) => prevData.filter((item) => item.id !== id));
  //   }
  //   setIsModalShow(false);
  // }

  const options = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "9",
      label: "9",
    },
  ];
  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "#",
    //   key: "#",
    //   render: (text, record, index) => index + 1,
    // },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Status",
      dataIndex: "car_status",
      key: "car_status",
    },
    {
      title: "Product",
      dataIndex: "product",
      key: "product",
    },
    {
      title: "Vehicle",
      dataIndex: "vehicle",
      key: "vehicle",
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Chassis Number",
      dataIndex: "chasis_no",
      key: "chasis_no",
    },
    {
      title: "Tracking Number",
      dataIndex: "tracking_no",
      key: "tracking_no",
    },
    {
      title: "Action",
      key: "Action",
      render: (text, { _id }) => (
        <Space size="small">
          <Button type="primary" size="small">
            <EditOutlined />
          </Button>

          <Link to={"/vehicles/view/"}>
            <Button type="primary" size="small">
              <EyeOutlined />
            </Button>
          </Link>

          <Button type="primary" size="small">
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];
  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    const { success, data } = await customerService.view(id, page);
    setLoading(false);
    if (success) {
      setVehicles(data.vehicles.data);
      setPagination((prev) => ({
        ...prev,
        total: data.vehicles.total,
        current: page,
        pageSize,
      }));
      setCustomer({
        id: data.customer.id || "Data not available",
        name: data.customer.name || "Data not available",
        email: data.customer.email || "Data not available",
        phone: data.customer.phone || "Data not available",
        address: data.customer.address || "Data not available",
        town: data.customer.town || "Data not available",
        city: data.customer.city || "Data not available",
        country: data.customer.country || "Data not available",
      });
    }
  };

  useEffect(() => {
    initialize();
  }, [])

  return (
    <>
      <Row gutter={24} wrap={false}>
        {/* First Column */}
        <Col flex="280px">
          <Card style={{ padding: "15px" }}>
            {/* First Section: Name and Email */}
            <Meta title="MAHMOOD" style={{ textAlign: "center" }} />
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              <p> {customer.email}</p>
            </div>
            <Divider />
            <div className="profile-info">
              <p>
                <strong>Phone:</strong> {customer.phone}
              </p>
              <p>
                <strong>Address:</strong> {customer.address}
              </p>
              <p>
                <strong>Town:</strong> {customer.town}
              </p>
              <p>
                <strong>City:</strong> {customer.city}
              </p>
              <p>
                <strong>Country:</strong> {customer.country}
              </p>
            </div>
            <Divider />
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "15px" }} >
              {/* <Link to={`/operation/customers/form/${id}`} rel="noopener noreferrer">  */}

              {/* </Link> */}
              <Button type="primary" size="small" onClick={() => navigate(`/operation/customers/form/${id}`)}>
                <EditOutlined />
              </Button>
              {/* <Button type="primary" size="small"  onClick={() => showModal(id)} >
                <DeleteOutlined />
              </Button> */}
            </div>
          </Card>
        </Col>
        {/* Second Column */}
        <Col flex="1">
          <div
            style={{
              padding: "15px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Row gutter={12}>
              <Col span={12}>
                <h1 style={{ fontWeight: "bold" }}>Customer Vehicles</h1>
              </Col>
            </Row>
            <Row
              gutter={8}
              style={{
                marginTop: "15px",
                background: "#F2F2F7",
                padding: "5px",
                alignItems: "center", // Ensures alignment of items within the row
              }}
            >
              <Col
                span={4}
                xs={24}
                sm={12}
                md={6}
                lg={4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Space direction="horizontal" size={10}>
                  <p style={{ margin: 0 }}>Show</p>
                  <Select
                    defaultValue={10}
                    options={options}
                    size="small"
                    style={{ width: 70, fontSize: 14 }}
                  />
                  <p style={{ margin: 0 }}>entries</p>
                </Space>
              </Col>

              {/* Second Column: Right-Aligned Search */}
              <Col
                span={20}
                xs={24}
                sm={12}
                md={18}
                lg={20}
                style={{
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Input
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  style={{
                    width: "30%",
                    borderRadius: "4px",
                    fontSize: 14,
                  }}
                />
              </Col>
            </Row>
            <Table
              columns={columns}
              scroll={{ x: "max-content" }}
              dataSource={vehicles}
              pagination={{
                current: pagination.current,
                total: pagination.total,
                pageSize: pagination.pageSize,
                onChange: (page) => initialize(page, pagination.pageSize),
                showSizeChanger: false,
              }}
              loading={loading}
              style={{ marginTop: "10px" }}
            />
          </div>
        </Col>
      </Row>
      <style>
        {`
  .profile-info {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 15px; /* Space between each <p> element */
  }

  .profile-info p {
    display: flex; /* Makes each <p> a flex container */
    align-items: center; /* Vertically align <strong> and text */
  }

  .profile-info strong {
    margin-right: 25px; /* Space between <strong> and <p> content */
    width: 100px; /* You can adjust this value to ensure equal space between all labels */
    text-align: left; /* Optional: Align the <strong> to the left */
  }
`}
      </style>

      {/* <Modal
        title={<div style={{ textAlign: 'center' }}>Confirm</div>}
        open={isModalshow}
        onOk={handleDelete}
        onCancel={handleCancel}
        centered
        styles={{
          body: {
            padding: "20px",

          }
        }}
        width={300}
        footer={
          <div className='flex justify-center gap-3'>
            <Button type="default" onClick={handleCancel} style={{ width: "30%" }}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleDelete} style={{ width: "30%" }}>
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: 'center' }}>Are you sure you want to delete this?</p>
      </Modal> */}
    </>
  );
}
export default ViewCustomer;

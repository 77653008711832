import { Table, Space, Button, Row, Col, Input, Select } from "antd";
import { SearchOutlined,SendOutlined,FilePdfOutlined,} from "@ant-design/icons";
import { PiMicrosoftExcelLogo } from "react-icons/pi";
import { LiaFilePdfSolid } from "react-icons/lia";
import { RiFileCopyLine } from "react-icons/ri";
import { AiOutlinePrinter } from 'react-icons/ai';
import { FaRegFile } from "react-icons/fa";
import { CiSquarePlus } from "react-icons/ci";
import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { invoiceReceiptService } from "../../../../../services";

function ReceiptCollect() {
  const [loading, setLoading] = useState(false);
  const [receipts, setReceipts] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const options = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "9",
      label: "9",
    },
  ];
  const columns = [
    {
      title: "Recipet Id",
      dataIndex: "id",
      key: "id",
      //   render: (text, record, index) => index + 1,
    },
    {
      title: "Receipt Ref Number",
      dataIndex: "reference_number",
      key: "reference_number",
    },
    {
      title: "Reciept Date",
      dataIndex: "receipt_date",
      key: "receipt_date",
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
    },
    {
      title: "Mode of Payment",
      dataIndex: "payment_mode",
      key: "payment_mode",
    },

    {
      title: "Customer",
      dataIndex: "user_id",
      key: "user_id",
    },
    {
      title: "Action",
      key: "Action",
      render: (text, { _id }) => (
        <Space size="small">
          <Button type="primary" size="small">
            <SendOutlined />
          </Button>
          <Button type="primary" size="small">
            <FilePdfOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    const { success, data } = await invoiceReceiptService.fetch(page);
    if (success) {
      setReceipts(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);
  return (
    <div
      style={{ padding: "15px", border: "1px solid #ccc", borderRadius: "8px" }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Receipt/Collection</h1>
        </Col>
      </Row>
      <Divider style={{ marginTop: 15 }} />
      <Row
        gutter={8}
        style={{
          marginTop: "15px",
          background: "#F2F2F7",
          padding: "5px",

          alignItems: "center", // Ensures alignment of items within the row
        }}
      >
        <Col
          span={4}
          xs={24}
          sm={12}
          md={6}
          lg={4}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Space direction="horizontal" size={10}>
            <p style={{ margin: 0 }}>Show</p>
            <Select
              defaultValue={10}
              options={options}
              size="small"
              style={{ width: 70, fontSize: 14 }}
            />
            <p style={{ margin: 0 }}>entries</p>
          </Space>
        </Col>

        {/* Second Column: Right-Aligned Search */}
        <Col
          span={20}
          xs={24}
          sm={12}
          md={18}
          lg={20}
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Link to={"/accounts/receipt-collects/add"}>
            <CiSquarePlus size={30} style={{ cursor: "pointer" }} />
          </Link>
        </Col>
      </Row>
      <Row
        gutter={8}
        style={{
          marginTop: "10px",
          background: "#F2F2F7",
          padding: "6px",
          borderRadius: "4px",
        }}
        className="flex justify-between"
      >
        <Col span={10}>
          <div className='flex' >
            <Button type='text' size='small' style={{ padding: '4px' }} >
              <AiOutlinePrinter style={{ fontSize: "24px" }} />
            </Button>

            <Button type='text' size='small' style={{ padding: '4px' }}>
              <FaRegFile style={{ fontSize: "20px" }} />
            </Button>

            <Button type='text' size='small' style={{ padding: '4px' }}>
              <PiMicrosoftExcelLogo style={{ fontSize: "24px" }} />
            </Button>

            <Button type='text' size='small' style={{ padding: '4px' }}>
              <LiaFilePdfSolid style={{ fontSize: "24px" }} />
            </Button>

            <Button type='text' size='small' style={{ padding: '4px' }}>
              <RiFileCopyLine style={{ fontSize: "24px" }} />
            </Button>
          </div>
        </Col>
        <Col span={14} style={{ textAlign: "right" }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={receipts}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        loading={loading}
        style={{ marginTop: "10px" }}
      />
    </div>
  );
}
export default ReceiptCollect;

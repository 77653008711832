import { BrowserRouter as Router, Route, Routes, useLocation, Navigate, } from "react-router-dom";
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import Forget from "../pages/auth/Forget";
import Dashboard from "../pages/adminPortal/Dashboard/index.js";
import Vechical from "../pages/adminPortal/vechicals/index.js";
import AddVehical from "../pages/adminPortal/vechicals/Add.jsx";
import ViewVehical from "../pages/adminPortal/vechicals/View.jsx";
import Customer from "../pages/adminPortal/customer/index.js";
import ViewCustomer from "../pages/adminPortal/customer/View.jsx";
import BlForm from "../pages/adminPortal/blform/index";
import Quotation from "../pages/adminPortal/quotation/index.js";
import QuotationView from "../pages/adminPortal/quotation/View.jsx";
import Vendors from "../pages/adminPortal/vendors/index.js";
import VendorQuotation from "../pages/adminPortal/vendorquotation/View.jsx";
import BrowseVendor from "../pages/adminPortal/vendorquotation/index.js";
import VendorQuotationForm from "../pages/adminPortal/vendorquotation/Form.jsx";
import VendorForm from "../pages/adminPortal/vendors/Form.jsx";
import ViewVendor from "../pages/adminPortal/vendors/View.jsx";
import BookSchedule from "../pages/adminPortal/schedule/index.js";
import Employees from "../pages/adminPortal/employees/index.js";
import EmployeeForm from "../pages/adminPortal/employees/Form.jsx";
import ViewEmployee from "../pages/adminPortal/employees/View.jsx";
import TowingTarf from "../pages/adminPortal/towingtarf/index.js";
import CreditNotes from "../pages/adminPortal/accounts/childfolder/CreditNotes/index.js";
import DebitNotes from "../pages/adminPortal/accounts/childfolder/DebitNotes/index.js";
import ReceiptCollect from "../pages/adminPortal/accounts/childfolder/InvoiceReceipt/index.js";
import AddRecepit from "../pages/adminPortal/accounts/childfolder/InvoiceReceipt/AddRecepit.jsx";
import Coasting from "../pages/adminPortal/accounts/childfolder/CostingExpenses/index.js";
import AddCoastngExpenses from "../pages/adminPortal/accounts/childfolder/CostingExpenses/EditCostingExpenses.jsx";

import PaymentVoucher from "../pages/adminPortal/accounts/childfolder/PaymentVoucher/index.js";
import ExpenseVoucher from "../pages/adminPortal/accounts/childfolder/ExpenseVoucher/index.js";
import VendorPaymentList from "../pages/adminPortal/accounts/childfolder/VendorPaymentList.jsx";
import VendorContainerPayment from "../pages/adminPortal/accounts/childfolder/VendorContainerPayment.jsx";
import Setting from "../pages/adminPortal/setting/Setting.jsx";
import ContainerSize from "../pages/adminPortal/setting/ContainerSize.jsx";
import Log from "../pages/adminPortal/setting/Log.jsx";
import Invoice from "../pages/adminPortal/invoices/index.js";
import LoadingPlan from "../pages/adminPortal/loadingplan/index.js";
import LoadingImo from "../pages/adminPortal/loadingimo/index.js";
import LoadingClient from "../pages/adminPortal/loadingclient/index.js";
import ViewLoadingContainer from "../pages/adminPortal/loadingplan/View.jsx";
import SaveViewContainer from "../pages/adminPortal/loadingsaveplan/View.jsx";
import EditSavePlan from "../pages/adminPortal/loadingsaveplan/Edit.jsx";
import EditLoadingPlan from "../pages/adminPortal/loadingplan/Edit.jsx";
import LoadingVehicleDetail from "../pages/adminPortal/loadingplan/LoadingVehicleDetail.jsx";
import LoadingAdd from "../pages/adminPortal/loadingplan/LoadingAdd.jsx";
import GenerateLoadPlan from "../pages/adminPortal/loadingplan/GenerateLoadPlan.jsx";
import ViewLoadingPlan from "../pages/adminPortal/loadingclient/View.jsx";
import SaveContainer from "../pages/adminPortal/loadingsaveplan/index.js";
import DockReceipt from "../pages/adminPortal/blform/View.jsx";
import Email from "../pages/adminPortal/headericon/Email.jsx";
import EditProfile from "../pages/adminPortal/headericon/EditProfile.jsx";
import AuthLayout from "../layout/auth";
import MainLayout from "../layout";
import CustomerForm from "../pages/adminPortal/customer/Form.jsx";
import QuotationForm from "../pages/adminPortal/quotation/Form.jsx";
// import Bl from "../pages/adminPortal/blform/Blform.jsx";
import Ledger from "../pages/adminPortal/accounts/childfolder/Ledger.jsx";
import AddInvoice from "../pages/adminPortal/invoices/add.jsx";
import Reports from "../pages/adminPortal/report/index.js";
import EditInvoice from "../pages/adminPortal/invoices/Edit.jsx";
import InvoiceView from "../pages/adminPortal/invoices/view.jsx";
import BrowseTranscation from "../pages/adminPortal/accounts/childfolder/BrowseTranscation.jsx";
import Accounts from "../pages/adminPortal/accounts/index.js";
import OpeningBalances from "../pages/adminPortal/accounts/OpeningBalance/OpeningBalances.jsx";
import EditOpeningBalances from "../pages/adminPortal/accounts/OpeningBalance/EditOpeningBalances.jsx";
import CashVoucher from "../pages/adminPortal/accounts/CashVoucher.jsx";
import BankVoucher from "../pages/adminPortal/accounts/BankVoucher.jsx";
import JournalVoucher from "../pages/adminPortal/accounts/JournalVoucher.jsx";
import PNL from "../pages/adminPortal/accounts/childfolder/PNL.jsx";
import Appoinments from "../pages/adminPortal/appoinments/index.js";
import ViewAppointments from "../pages/adminPortal/appoinments/View.jsx";
import AddAppointment from "../pages/adminPortal/appoinments/Add.jsx";
import VehicleEdit from "../pages/adminPortal/vechicals/Edit.jsx";
import InventoryReport from "../pages/adminPortal/invetoryreport/index.js";
import EditForm from "../pages/adminPortal/blform/Edit.jsx";

import { AtVechile, AtVehicleWithoutKey, AtVehicleWithoutTitle, CreateLoadPlans, CustomerAppoinments, CustomerDashbroad, CustomerInvoices, CustomerLedger, CustomerVechical, Loadedplan, LoadedVehicle, OpenSaveplan, OtherVehicles, SendLoadPlan, VehicleLoadPlan, VehicleWithBOS } from "../pages/customerPortal/index.js";
import CustomerAddAppointment from "../pages/customerPortal/customerappointment/Add.jsx";
import TermAndCondition from "../pages/auth/TermAndCondition.jsx";
import TermAndConditionCustomer from "../pages/auth/TermAndConditionCustomer.jsx";
import ImoEdit from "../pages/adminPortal/loadingimo/Edit.jsx";
import ImoView from "../pages/adminPortal/loadingimo/View.jsx";
import AccountsCustomerLedger from "../pages/adminPortal/accounts/childfolder/CustomerBalance.jsx";
import MonthlyCashFlow from "../pages/adminPortal/report/MonthlyCashFlow.jsx";
import Capital from "../pages/adminPortal/accounts/Capital.jsx";
import { ROLES } from "../constants/index.js";
import { useEffect, useState } from "react";
import Edit from "../pages/adminPortal/blform/Edit.jsx";
import LoadPlanPdf from "../pages/adminPortal/loadingplan/LoadPlanPdf.jsx";



// const ProtectedRoute = ({ component: Component, layout: Layout, title }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [isLoading, setIsLoading] = useState(true);
//   const location = useLocation();

//   useEffect(() => {
//     const token = localStorage.getItem("token");
//     setIsAuthenticated(!!token);
//     setIsLoading(false);
//   }, []);

//   if (isLoading) {
//     return <div> </div>;
//   }

//   if (!isAuthenticated) {
//     return <Navigate to="/login" replace state={{ from: location }} />;
//   }

//   return (
//     <Layout title={title}>
//       {Component}
//     </Layout>
//   );
// };

// Wrapper for public routes (login, signup, etc.)
// const PublicRoute = ({ component: Component, layout: Layout }) => {
//   const token = localStorage.getItem("token");

//   if (token) {
//     // Redirect authenticated users to the dashboard
//     return <Navigate to="/" replace />;
//   }

//   return <Layout>{Component}</Layout>;
// };

const AuthRedirect = ({ destination }) => {
  const location = useLocation();
  return <Navigate to={destination} replace state={{ from: location }} />;
};

const wrapper = (Layout, title = null, Component) => {
  let accessToken = localStorage.getItem("token");
  
  const redirect = <AuthRedirect destination={"/login"} />;

  if (accessToken == null || !accessToken) return redirect;
  return (
    <>
      <Layout title={title}>{Component}</Layout>
    </>
  )
}

function Navigation() {
  const [userRole, setUserRole] = useState(
    localStorage.getItem("role") ?? null
  );
  const [loginKey, setLoginKey] = useState(0);

  const isAdmin = userRole === ROLES.ADMIN;

  useEffect(() => {
    setUserRole(localStorage.getItem("role"));
  }, [loginKey]);

  const handleLogin = () => {
    setLoginKey((key) => key + 1);
  };
  const handleComponent = (AdminComponent = null, CustomerComponent = null) => {
    if (isAdmin && AdminComponent) {
      return <AdminComponent />;
    }
  
    if (!isAdmin && CustomerComponent) {
      return <CustomerComponent />;
    }
  
    return "NotFound";
  };
  const customerRoute = () => {
    return (
      <>
        <Route path="/customers-portal/atvechile" element={wrapper(MainLayout, "Dashbaord", <AtVechile />)} />
        <Route path="/customers-portal/atvehicle-without-key" element={wrapper(MainLayout, "Dashbaord", <AtVehicleWithoutKey />)} />
        <Route path="/customers-portal/atvehicle-without-title" element={wrapper(MainLayout, "Dashbaord", <AtVehicleWithoutTitle />)} />
        <Route path="/customers-portal/loaded-vehicle" element={wrapper(MainLayout, "Dashbaord", <LoadedVehicle />)} />
        <Route path="/customers-portal/other-vehicles" element={wrapper(MainLayout, "Dashbaord", <OtherVehicles />)} />
        <Route path="/customers-portal/vehicle-loadplan" element={wrapper(MainLayout, "Dashbaord", <VehicleLoadPlan />)} />
        <Route path="/customers-portal/vehicle-with-bos" element={wrapper(MainLayout, "Dashbaord", <VehicleWithBOS />)} />

        <Route path="/customers-portal/customer-vehicles" element={wrapper(MainLayout, "Vehicles", <CustomerVechical />)} />

        <Route path="/customers-portal/customer-invoices" element={wrapper(MainLayout, "Invoices", <CustomerInvoices />)} />

        <Route path="/customers-portal/customer-ledger" element={wrapper(MainLayout, "Ledger", <CustomerLedger />)} />

        <Route path="/customers-portal/appoinments" element={wrapper(MainLayout, "Appointments", <CustomerAppoinments />)} />
        <Route path="/customers-portal/add-appoinments" element={wrapper(MainLayout, "Appointments", <CustomerAddAppointment />)} />

        <Route path="/customers-portal/create-load-plans" element={wrapper(MainLayout, "Loading Plan", <CreateLoadPlans />)} />
        <Route path="/customers-portal/open-save-plan" element={wrapper(MainLayout, "Loading Plan", <OpenSaveplan />)} />
        <Route path="/customers-portal/send-load-plan" element={wrapper(MainLayout, "Loading Plan", <SendLoadPlan />)} />
        <Route path="/customers-portal/loaded-plan" element={wrapper(MainLayout, "Loading Plan", <Loadedplan />)} />
      </>
    )
  }

  return (
    <Router>

      <Routes>
        {/* Public Routes *
        {/* <Route
          path="/login"
          element={<PublicRoute component={<Login />} layout={AuthLayout} />}
        />
        <Route
          path="/signup"
          element={<PublicRoute component={<Signup />} layout={AuthLayout} />}
        />
        <Route
          path="/forget"
          element={<PublicRoute component={<Forget />} layout={AuthLayout} />}
        />

        {/* Protected Routes *
        <Route
          path="/"
          element={
            <ProtectedRoute
              component={<Dashboard />}
              layout={MainLayout}
              title="Dashboard"
            />
          }
        />
 */}

        {customerRoute()}

        {/* auth */}
        <Route path="/login" element={<AuthLayout><Login onLogin={handleLogin}/></AuthLayout>} />
        <Route path="/signup" element={<AuthLayout><Signup /></AuthLayout>} />
        <Route path="/forget" element={<AuthLayout><Forget /></AuthLayout>} />

        {/* dashboard  */}
        <Route path="/term-&-condition" element={wrapper(MainLayout, <TermAndCondition />)} />
        <Route path="/customers-portal/term-&-condition" element={wrapper(MainLayout, <TermAndConditionCustomer />)} />

        <Route path="/" element={wrapper(MainLayout, 'Dashboard', handleComponent(Dashboard, CustomerDashbroad))} />

        {/* vehicle */}
        <Route path="/operation/vehicles" element={wrapper(MainLayout, "Vehicles", <Vechical />)} />
        <Route path="/operation/vehicles/adds" element={wrapper(MainLayout, "Vehicles", <AddVehical />)} />
        <Route path="/operation/vehicles/edit/:id?" element={wrapper(MainLayout, "Edit Vehicles", <VehicleEdit />)} />
        <Route path="/operation/vehicles/view/:id?" element={wrapper(MainLayout, "Vehicles", <ViewVehical />)} />

        {/* customer  */}
        <Route path="/operation/customers" element={wrapper(MainLayout, 'Customers', <Customer />)} />
        <Route path="/operation/customers/form/:id?" element={wrapper(MainLayout, 'Customers', <CustomerForm />)} />
        <Route path="/operation/customers/view/:id?" element={wrapper(MainLayout, 'Add Customer', <ViewCustomer />)} />

        {/* bl-form  */}
        <Route path="/operation/bl-forms" element={wrapper(MainLayout, 'BL Forms', <BlForm />)} />
        <Route path="/operation/bl-forms/Edit/:id?" element={wrapper(MainLayout, 'Blform', <Edit/>)} />
        <Route path="/operation/bl-forms/View/:id?" element={wrapper(MainLayout, 'DockRecipt', <DockReceipt />)} />

        {/*  quotation */}
        <Route path="/operation/quotations" element={wrapper(MainLayout, 'Quotation', <Quotation />)} />
        <Route path="/operation/quotations/views/:id?" element={wrapper(MainLayout, 'Quotation', <QuotationView />)} />
        <Route path="/operation/quotations/form/:id?" element={wrapper(MainLayout, 'Quotation', <QuotationForm />)} />

        {/* vendor */}
        <Route path="/operation/vendors" element={wrapper(MainLayout, 'Vendors', <Vendors />)} />
        <Route path="/operation/vendors/form/:id?" element={wrapper(MainLayout, 'Vendors', <VendorForm />)} />
        <Route path="/operation/vendors/view/:id?" element={wrapper(MainLayout, 'Vendors', <ViewVendor />)} />

        {/* vendorQuotation */}
        <Route path="/operation/vendors/quotations" element={wrapper(MainLayout, 'Browse Vendors Quotation', <BrowseVendor />)} />
        <Route path="/operation/vendors/quotations/views/:id?" element={wrapper(MainLayout, 'Vendor Quotation', <VendorQuotation />)} />
        <Route path="/operation/vendors/quotations/form/:id?" element={wrapper(MainLayout, 'Vendor Quotation', <VendorQuotationForm />)} />

        {/* report */}
        <Route path="/accounts/reports" element={wrapper(MainLayout, 'Reports', <Reports />)} />
        <Route path="/accounts/reports/monthly_cash_flow/:month" element={wrapper(MainLayout, "", <MonthlyCashFlow />)} />

        <Route path="/operation/books" element={wrapper(MainLayout, "Booking Schedule", <BookSchedule />)} />

        {/* employee */}
        <Route path="/administrations/employees" element={wrapper(MainLayout, 'Employees', <Employees />)} />
        <Route path="/administrations/employees/form/:id?" element={wrapper(MainLayout, 'Employees', <EmployeeForm />)} />
        <Route path="/administrations/employees/view/:id?" element={wrapper(MainLayout, 'Employees', <ViewEmployee />)} />

        {/* towing */}
        <Route path="/operation/tariffs" element={wrapper(MainLayout, 'Towing Tariff', <TowingTarf />)} />

        {/* Acounts */}
        <Route path="/accounts" element={wrapper(MainLayout, 'Accounts', <Accounts />)} />
        <Route path="/accounts/opening-balances" element={wrapper(MainLayout, 'Accounts', <OpeningBalances />)} />
        <Route path="/accounts/opening-balances/edit/:id?" element={wrapper(MainLayout, 'Accounts', <EditOpeningBalances />)} />
        <Route path="/accounts/cash-voucher" element={wrapper(MainLayout, 'Accounts', <CashVoucher />)} />
        <Route path="/accounts/bank-voucher" element={wrapper(MainLayout, 'Accounts', <BankVoucher />)} />
        <Route path="/accounts/journal-voucher" element={wrapper(MainLayout, 'Accounts', <JournalVoucher />)} />
        <Route path="/accounts/browse-transcation" element={wrapper(MainLayout, 'Accounts', <BrowseTranscation />)} />
        <Route path="/accounts/credit-notes" element={wrapper(MainLayout, 'Accounts', <CreditNotes />)} />
        <Route path="/accounts/debit-notes" element={wrapper(MainLayout, 'Accounts', <DebitNotes />)} />
        <Route path="/accounts/receipt-collects" element={wrapper(MainLayout, 'Accounts', <ReceiptCollect />)} />
        <Route path="/accounts/receipt-collects/add" element={wrapper(MainLayout, 'Accounts', <AddRecepit />)} />
        <Route path="/accounts/coastings" element={wrapper(MainLayout, 'Accounts', <Coasting />)} />
        <Route path="/accounts/coasting/edit/:id?" element={wrapper(MainLayout, 'Accounts', <AddCoastngExpenses />)} />
        <Route path="/accounts/customer-ledgers" element={wrapper(MainLayout, 'Accounts', <AccountsCustomerLedger />)} />
        <Route path="/accounts/payment-vouchers" element={wrapper(MainLayout, 'Accounts', <PaymentVoucher />)} />
        <Route path="/accounts/expense-vouchers" element={wrapper(MainLayout, 'Accounts', <ExpenseVoucher />)} />
        <Route path="/accounts/vendor-payment-lists" element={wrapper(MainLayout, 'Accounts', <VendorPaymentList />)} />
        <Route path="/accounts/vendor-container-payments" element={wrapper(MainLayout, 'Accounts', <VendorContainerPayment />)} />
        <Route path="/accounts/ledger" element={wrapper(MainLayout, 'Ledger', <Ledger />)} />
        <Route path="/accounts/PNL" element={wrapper(MainLayout, 'PNL', <PNL />)} />
        <Route path="/accounts/chart-account/capital" element={wrapper(MainLayout, 'Accounts', <Capital/>)} />

        {/* setting */}
        <Route path="administrations/settings" element={wrapper(MainLayout, 'Settings', <Setting />)} />
        <Route path="administrations/settings/containers" element={wrapper(MainLayout, 'Settings', <ContainerSize />)} />
        <Route path="administrations/settings/logs" element={wrapper(MainLayout, 'Settings', <Log />)} />

        {/* invoice */}
        <Route path="/invoices" element={wrapper(MainLayout, 'Invoices', <Invoice />)} />
        <Route path="/invoices/add" element={wrapper(MainLayout, 'Invoices', <AddInvoice />)} />
        <Route path="/invoice/edit/:id?" element={wrapper(MainLayout, 'Invoices', <EditInvoice />)} />
        <Route path="/invoice/view/:id?" element={wrapper(MainLayout, 'Invoices', <InvoiceView />)} />

        {/*  loadingplan */}
        <Route path="/operation/loadings" element={wrapper(MainLayout, 'Loading Plan', <LoadingPlan />)} />
        <Route path="/operation/loadings/views/:id?" element={wrapper(MainLayout, 'Loading Container', <ViewLoadingContainer />)} />
        <Route path="/operation/loadings/edits/:id?" element={wrapper(MainLayout, 'Edit Loaded Plan', <EditLoadingPlan />)} />
        <Route path="/operation/loadings/pdf/:id?" element={wrapper(MainLayout, '', <LoadPlanPdf/>)} />

        {/* loadingimo */}
        <Route path="/operation/loadings/imos" element={wrapper(MainLayout, 'Loading IMO', <LoadingImo />)} />
        <Route path="/operation/loadings/imos/edit/:id?" element={wrapper(MainLayout, 'Loading IMO', <ImoEdit />)} />
        <Route path="/operation/loadings/imos/view/:id?" element={wrapper(MainLayout, 'Loading IMO', <ImoView />)} />


        {/* LoadingAdd */}
        <Route path="/operation/loadings/add" element={wrapper(MainLayout, 'Loading Plan', <LoadingAdd />)} />

        {/* loadingclient */}
        <Route path="/operation/loadings/clients" element={wrapper(MainLayout, 'Loading Plan', <LoadingClient />)} />
        <Route path="/operation/loadings/clients/views/:id?" element={wrapper(MainLayout, 'View Load Plan Booking', <ViewLoadingPlan />)} />

        {/* loadingsaveconatiner */}
        <Route path="/operation/loadings/save-containers" element={wrapper(MainLayout, 'Loading Plan', <SaveContainer />)} />
        <Route path="/operation/loadings/save-containers/views/:id?" element={wrapper(MainLayout, 'Saved Container', <SaveViewContainer />)} />
        <Route path="/operation/loadings/save-containers/edits/:id?" element={wrapper(MainLayout, 'Edit Saved Plan', <EditSavePlan />)} />

        <Route path="/operation/loadings/generates-plans/:id?" element={wrapper(MainLayout, "Generate Load Plan PDF", <GenerateLoadPlan />)} />
        <Route path="/operation/loadings/vehical-details" element={wrapper(MainLayout, "Loaded Vehicles Details", <LoadingVehicleDetail />)} />

        {/* header */}
        <Route path="/emails" element={wrapper(MainLayout, 'Email', <Email />)} />
        <Route path="/edit-profiles" element={wrapper(MainLayout, 'Edit Profile', <EditProfile />)} />

        {/* appointments */}
        <Route path="/operation/appoinments" element={wrapper(MainLayout, 'Appoinments', <Appoinments />)} />
        <Route path="/operation/appoinments/add" element={wrapper(MainLayout, 'Appoinments', <AddAppointment />)} />
        <Route path="/operation/appoinments/view/:id?" element={wrapper(MainLayout, 'Appoinments', <ViewAppointments />)} />

        {/* invetoryReport */}
        <Route path="/operation/inventoryreport" element={wrapper(MainLayout, 'Vehicle Report', <InventoryReport />)} />

      </Routes>
    </Router>
  );
}
export default Navigation



// const [userRole, setUserRole] = useState(localStorage.getItem('role') ?? null)
// const [loginKey, setLoginKey] = useState(0);

// const isAdmin = userRole === ADMIN;

// useEffect(() => {
//   setUserRole(localStorage.getItem('role'))
// }, [loginKey]);

// const handleLogin = () => {
//   setLoginKey(key => key + 1);
// };

// const handleComponent = (AdminComponent = null, ClientComponent = null) => {

//   if (isAdmin && AdminComponent) {
//     return <AdminComponent />;
//   }
//   // if (userRole === CLIENT && ClientComponent) {
//   if (!isAdmin && ClientComponent) {
//     return <ClientComponent />;
//   }
//   return "NotFound";
// }

import ajaxService from "./ajax-service";

class TransactionService {
  async fetch(page) {
    const response = await ajaxService.get(
      `/accounts/browse-transactions?page=${page}`
    );
    if (response ?? false) {
      return response;
    }
  }
  async viewTransaction(id) {
    const response = await ajaxService.get(
      `/accounts/browse-transactions/${id}`
    );
    if (response ?? false) {
      return response;
    }
  }
  async editTransaction(id, data) {
    const response = await ajaxService.post(
      `/accounts/browse-transactions/`,
      data,
      id,
    );
    if (response ?? false) {
      return response;
    }
  }
}

const transactionService = new TransactionService();
export default transactionService;

import ajaxService from "./ajax-service";

class LoadingPlanService {
  async fetchloaded(page) {
    const response = await ajaxService.get(
      `/containers?type=loaded&page=${page}`
    );
    if (response ?? false) {
      return response;
    }
  }
  async fetchsaved(page) {
    const response = await ajaxService.get(
      `/containers?type=saved&page=${page}`
    );
    if (response ?? false) {
      return response;
    }
  }
  async View(id, page) {
    const response = await ajaxService.get(`/containers/${id}?page=${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async addPlan(data) {
    const response = await ajaxService.post(`/containers`, data);
    if (response ?? false) {
      return response;
    }
  }
  async updatePlan(id, data) {
    const response = await ajaxService.put(`/containers/`, id, data);
    if (response  ?? false) {
      return response;
    }
  }
  async updatePlanFrieght(id, data) {
    const response = await ajaxService.post(
      `/containers/update/loadplan-vendors/`,
      data,
      id
    );
    if (response  ?? false) {
      return response;
    }
  }
  async deletePlan(id) {
    const response = await ajaxService.delete(`/containers/`, id);
    if (response  ?? false) {
      return response;
    }
  }

  /**Customer load plan APIs */

  async fetchCustomerLoadPlan(page, type) {
    const response = type
      ? await ajaxService.get(`/client-load-plans?type=${type}&page=${page}`)
      : await ajaxService.get(`/client-load-plans?&page=${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async addClientPlan(data) {
    const response = await ajaxService.post(`/client-load-plans`, data);
    if (response ?? false) {
      return response;
    }
  }
}

const loadingPlanService = new LoadingPlanService();

export default loadingPlanService;

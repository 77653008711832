import ajaxService from "./ajax-service";

class PaymentVoucher {
  async fetch(page) {
    const response = await ajaxService.get(`/payment-vouchers?page=${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async makePayment(data) {
    const response = await ajaxService.post(`/store-payable`, data);
    if (response ?? false) {
      return response;
    }
  }
  async deletePayment(id) {
    const response = await ajaxService.delete(`/payment-vouchers/`, id);
    if (response ?? false) {
      return response;
    }
  }
}

const paymentVoucherService = new PaymentVoucher();
export default paymentVoucherService;

import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, DatePicker, Table, Space, Button } from "antd";
import { Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  dropdownService,
  ledgerService,
} from "../../../../services";
import { ROLES } from "../../../../constants";

function Ledger() {
  const [loading, setLoading] = useState(false);
  const [ledger, setLedger] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [form] = Form.useForm();
  const role = localStorage.getItem("role");

  const status = [
    {
      id: 1,
      name: "All",
    },
    {
      id: 2,
      name: "Opened",
    },
    {
      id: 3,
      name: "Closed",
    },
  ];
  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (index) => index + 1,
    },
    {
      title: "STATUS",
      dataIndex: "STATUS",
      key: "STATUS",
    },
    {
      title: "INVOICE DATE",
      dataIndex: "INVOICE DATE",
      key: "INVOICE DATE",
    },
    {
      title: "INVOICE NUMBER",
      dataIndex: "INVOICE NUMBER",
      key: "INVOICE NUMBER",
      //   render: (text) => (
      //     <div
      //       style={{
      //         whiteSpace: "nowrap",
      //         textOverflow: "ellipsis",
      //         verticalAlign: "bottom",
      //       }}
      //       dangerouslySetInnerHTML={{ __html: text }}
      //     />
      //   ),
    },
    {
      title: "RECEIPT NUMBER",
      dataIndex: "RECEIPT NUMBER",
      key: "RECEIPT NUMBER",
    },
    {
      title: "CREDIT NUMBER",
      dataIndex: "CREDIT NUMBER",
      key: "CREDIT NUMBER",
    },
    {
      title: "DESCRIPTION",
      dataIndex: "DESCRIPTION",
      key: "DESCRIPTION",
    },
    {
      title: "INVOICE AMOUNT",
      dataIndex: "INVOICE AMOUNT",
      key: "INVOICE AMOUNT",
    },
    {
      title: "CREDIT AMOUNT",
      dataIndex: "CREDIT AMOUNT",
      key: "CREDIT_AMOUNT",
    },
    {
      title: "COLLECTED AMOUNT",
      dataIndex: "COLLECTED AMOUNT",
      key: "COLLECTED AMOUNT",
    },
    {
      title: "BALANCE",
      dataIndex: "BALANCE",
      key: "BALANCE",
    },
  ];
  const onFinish = async (values) => {
    const payload = {
      user_id: values.user,
      from_date: values.from_date
        ? values.from_date.format("YYYY-MM-DD")
        : null,
      to_date: values.to_date ? values.to_date.format("YYYY-MM-DD") : null,
      status: values.status === "All" ? null : values.status,
    };

    // Remove keys with undefined or null values
    const filteredData = Object.fromEntries(
      Object.entries(payload).filter(([_, value]) => value != null)
    );

    initialize(filteredData);
  };
  const initialize = async (data = null) => {
    setLoading(true);
    const { success, data: LedgerData } = await ledgerService.fetch(data);
    if (success) {
      setLedger(LedgerData);
    }
  };
  const getCustomers = async () => {
    const response = await dropdownService.fetchCustomers();
    if (response.success) {
      setCustomers(response.data);
    }
  };

  useEffect(() => {
    if (role === ROLES.ADMIN) {
      getCustomers();
    }
  }, []);
  return (
    <div
      style={{
        padding: "0 14px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Form
        requiredMark={false}
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={{
          status: "All",
        }}
      >
        <Row gutter={24} className="mt-8">
          <Col span={5}>
            <Form.Item label="From date" name="from_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item label="To date" name="to_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>
          {role === ROLES.ADMIN && (
            <Col span={5}>
              <Form.Item
                label="Customers"
                name="user"
                rules={[
                  {
                    required: true,
                    message: "Please input!",
                  },
                ]}
              >
                <Select
                  placeholder="No Customer"
                  showSearch
                  optionFilterProp="children"
                >
                  {customers?.map((cust) => (
                    <Select.Option key={cust.id} value={cust.id}>
                      {cust.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col span={5}>
            <Form.Item
              label="Status"
              name="status"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Select placeholder="No Customer">
                {status?.map((s) => (
                  <Select.Option key={s.id} value={s.name}>
                    {s.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item label={<></>}>
              <Button type="primary" htmlType="submit">
                <SearchOutlined style={{ fontSize: "15px" }} />
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row
        gutter={0}
        style={{
          marginTop: 20,
          padding: "10px",
          borderRadius: "4px",
          border: "1px solid #E8E8E9",
        }}
      >
        <Col span={24}>
          <h1 style={{ fontWeight: "bold" }}>Statement Of Account</h1>
        </Col>
      </Row>

      <Col span={24}>
        <Row
          gutter={0}
          style={{
            padding: "10px",
            marginTop: "2px",
            background: "#F2F2F7",
            borderRadius: "4px",
          }}
          className="flex justify-between"
        >
          <Col span={10}>
            <Space direction="horizontal" size={12}>
              <img src="/images/ve4.png" alt="" />
              <img src="/images/send-icon.png" alt="" />
            </Space>
          </Col>
          <Col span={14} style={{ textAlign: "right" }}>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              style={{ width: 200 }}
            />
          </Col>
        </Row>
        <div>
          <Table
            columns={columns}
            pagination={true}
            scroll={{ x: "max-content" }}
            dataSource={ledger.data}
            rowKey="Id"
            locale={{
              emptyText: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No data available in table
                </div>
              ),
            }}
            style={{ marginTop: "5px" }}
            footer={() => (
              <div>
                <div className="flex justify-end gap-20 mt-5 mb-5">
                  <p>TOTAL:</p>
                  <p>${ledger.receivables}</p>
                  <p>${ledger.creditTotal}</p>
                  <p>${ledger.collectedTotal}</p>
                  <p>${ledger.netAmount}</p>
                </div>
                <div
                  className="flex justify-between mt-5 mb-5"
                  style={{ paddingLeft: "30%" }}
                >
                  <p>NET BALANCE:</p>
                  <p>${ledger.balance}</p>
                </div>
              </div>
            )}
          />
        </div>
      </Col>
    </div>
  );
}

export default Ledger;

import {Table, Space,Row,Col,Input,Tag,Modal, Form, Select, Button, DatePicker, notification,} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { CiSquarePlus } from "react-icons/ci";
import React, { useEffect, useState } from "react";
import { paymentModeService, vendorPaymentService, vendorService,} from "../../../../services";
import moment from "moment";

function VendorPaymentList() {
  const [form] = Form.useForm();
  const [isModalShow, setIsModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vendorpayments, setVendorPayments] = useState([]);
  const [paymentmodes, setPaymentModes] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });

  const showModal = async (record) => {
    setIsModalShow(true);
    const vendor = vendors.find((v) => v.name === record.name);
    console.log(record.reference_number)
    form.setFieldsValue({
      ...record,
      name: vendor ? vendor.id : null,
      payment_date: moment(record.payment_date),
    });
  };

  const handleOk = () => {
    setIsModalShow(false);
  };
  const handleCancel = () => {
    setIsModalShow(false);
  };

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "invoice_id",
      key: "invoice_id",
    },
    {
      title: "Booking Number",
      dataIndex: "booking_number",
      key: "booking_number",
      render: (booking_number) => booking_number || "N/A",
    },
    {
      title: "Vendor Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Vendor Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Paid Amount",
      dataIndex: "debit_aomunt",
      key: "debit_aomunt",
    },
    {
      title: "Due Amount",
      dataIndex: "due_amount",
      key: "due_amount",
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (payment_date) => payment_date || "N/A",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        if (!record) return null;
        const { due_amount, debit_aomunt } = record;
        return (
          <Tag
            color={
              due_amount > 0 && debit_aomunt <= 0
                ? "#F04949"
                : due_amount > 0 && debit_aomunt > 0
                ? "#D68606"
                : due_amount <= 0
                ? "#06D652"
                : "transparent"
            }
            style={{ border: `1px solid`, padding: "5px" }}
          >
            {due_amount > 0 && debit_aomunt <= 0
              ? "Not Paid"
              : due_amount > 0 && debit_aomunt > 0
              ? "Partial"
              : due_amount <= 0
              ? "Completed"
              : "N/A"}
          </Tag>
        );
      },
    },

    {
      title: "Action",
      key: "Action",
      fixed:"right",
      render: (text, record, { _id }) => (
        <Space size="small">
          <CiSquarePlus
            size={30}
            style={{ cursor: "pointer" }}
            onClick={() => showModal(record)}
          />
        </Space>
      ),
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    const { success, data } = await vendorPaymentService.fetch(page);
    if (success) {
      setVendorPayments(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };
  const getPaymentModes = async () => {
    const response = await paymentModeService.fetch();
    if (response.success) {
      setPaymentModes(response.data);
    }
  };
  const getVendors = async () => {
    const response = await vendorService.fetchAll();
    if (response.success) {
      setVendors(response.data);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    const data = {
      invoice_id: values.invoice_id,
      vendor_id: values.name,
      booking_number: values.booking_number,
      amount: values.amount,
      balance: values.due_amount,
      debit_amount: values.pay_amount,
      payment_date: values.payment_date.format("YYYY-MM-DD"),
      payment_mode: values.payment_mode,
      status: values.status,
    };
    // console.log(data);
    const response = await vendorPaymentService.addTransaction(data);
    if (response.success) {
      notification.success({
        message: "Added Successfully!",
        description: response.message,
        placement: "topRight",
      });
      initialize(pagination.current, pagination.pageSize);
    } else {
      notification.error({
        message: "Error adding",
        description: response.message,
        placement: "topRight",
      });
    }
    setLoading(false);
    setIsModalShow(false);
  };
  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
    getPaymentModes();
    getVendors();
  }, [pagination.current, pagination.pageSize]);
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={24}>
        <Col span={10}>
          <h1 style={{ fontWeight: "bold" }}>Vendors Payment List</h1>
        </Col>
        <Col span={14} style={{ textAlign: "right" }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>

      <Modal
        title={<div style={{ width: "100%" }}>Add Transaction</div>}
        open={isModalShow}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        width={700}
        style={{ padding: "15px" }}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          requiredMark={false}
        >
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                label="Invoice Number"
                name="invoice_id"
                rules={[
                  {
                    required: true,
                    message: "Please enter the number of allowed appointments",
                  },
                ]}
              >
                <Input type="number" placeholder="Enter number" readOnly />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Vendor"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please select an appointment type",
                  },
                ]}
              >
                <Select placeholder="Select type">
                  {vendors.map((vendor) => (
                    <Select.Option key={vendor.id} value={vendor.id}>
                      {vendor.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Booking Number" name="booking_number">
                <Input placeholder="Enter start time" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Vendor Amount"
                name="amount"
                rules={[
                  { required: true, message: "Please enter the start time" },
                ]}
              >
                <Input placeholder="Enter start time" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Amount Due"
                name="due_amount"
                rules={[
                  { required: true, message: "Please enter the end time" },
                ]}
              >
                <Input placeholder="Enter end time" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Pay Amount"
                name="pay_amount"
                rules={[
                  { required: true, message: "Please enter the end time" },
                ]}
              >
                <Input placeholder="Enter end time" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Payment Date"
                name="payment_date"
                rules={[
                  {
                    required: true,
                    message: "Please select an appointment type",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Mode Of Payment"
                name="payment_mode"
                rules={[
                  {
                    required: true,
                    message: "Please select an appointment type",
                  },
                ]}
              >
                <Select placeholder="Select type">
                  {paymentmodes.map((paymentmode) => (
                    <Select.Option
                      key={paymentmode.id}
                      value={paymentmode.name}
                    >
                      {paymentmode.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Payment Status"
                name="status"
                rules={[
                  {
                    required: true,
                    message: "Please select an appointment type",
                  },
                ]}
              >
                <Select placeholder="Select type">
                  <Select.Option value={0}>Not Paid</Select.Option>
                  <Select.Option value={1}>Partial</Select.Option>
                  <Select.Option value={2}>Complete</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {/* </Row> */}
            <Col span={24}>
              <Form.Item>
                <div style={{ textAlign: "center", marginTop: "12px" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "60%" }}
                    // onClick={handleOk}
                    loading={loading}
                  >
                    Add Debit Amount
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={vendorpayments}
        style={{ marginTop: "10px" }}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        loading={loading}
      />
    </div>
  );
}

export default VendorPaymentList;

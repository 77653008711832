import React, { useEffect, useState } from "react";
import { Table, Space, Button, Row, Col, Input, Tag } from "antd";
import {
  SearchOutlined,
  SendOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { loadingPlanService } from "../../../services";
import { Link } from "react-router-dom";

function OpenSaveplan() {
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Container Id",
      dataIndex: "container_id",
      key: "container_id",
    },
    {
      title: "Booking Number",
      dataIndex: "booking_no",
      key: "booking_no",
    },
    {
      title: "Container Number",
      dataIndex: "c_container_number",
      key: "c_container_number",
      render: (text) => text || "N/A",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        const { is_client_approved_loadplan, reject } = record;

        let statusText = "";
        let statusColor = "";

        if (is_client_approved_loadplan === 0 && reject === 0) {
          statusText = "Pending";
          statusColor = "yellow";
        } else if (reject === 1) {
          statusText = "Rejected";
          statusColor = "#B83D3D";
        } else {
          statusText = "Approved";
          statusColor = "#06D652";
        }

        return (
          <Tag
            color={statusColor}
            style={{ fontSize: "12px", padding: "5px 5px" }}
          >
            {statusText}
          </Tag>
        );
      },
    },
    {
      title: "Actions",
      key: "Actions",
      render: (text, record, { _id }) => (
        <Space size="small">
          <Button type="primary" size="small">
            <SendOutlined />
          </Button>
          <Link to={`/operation/loadings/views/${record.container_id}`}>
            <Button type="primary" size="small">
              <EyeOutlined />
            </Button>
          </Link>
          <Button type="primary" size="small">
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];
  const data = [
    {
      Container_Id: "Lorem Ipsum",
      Booking_Number: "Lorem Ipsum",
      Container_Number: "Lorem Ipsum",
      Location: "Lorem Ipsum",
    },
    {
      Container_Id: "Lorem Ipsum",
      Booking_Number: "Lorem Ipsum",
      Container_Number: "Lorem Ipsum",
      Location: "Lorem Ipsum",
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    const { success, data } = await loadingPlanService.fetchCustomerLoadPlan(
      page,
      "booking_saved"
    );
    setLoading(false);
    if (success) {
      setPlans(data.containers.data);
      setPagination((prev) => ({
        ...prev,
        total: data.containers.total,
        current: page,
        pageSize,
      }));
    }
  };
  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);
  return (
    <div
      style={{
        padding: "8px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row
        gutter={24}
        style={{ padding: "5px", borderRadius: "4px" }}
        className="flex justify-between"
      >
        <Col span={10}>
          <h5 className="font-bold">Saved Load Plans</h5>
        </Col>
        <Col span={14} style={{ textAlign: "right" }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={plans}
        loading={loading}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        style={{ marginTop: "5px" }}
      />
    </div>
  );
}
export default OpenSaveplan;

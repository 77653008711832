import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Divider, Spin } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import employeeService from "../../../services/employee-service";

export default function ViewEmployee() {
  const { id } = useParams();
  const [employee, setEmployee] = useState(null);

  const initialize = async () => {
    const { success, data } = await employeeService.viewEmployee(id);
    if (success) {
      setEmployee(data);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  // console.log(employee);
  return (
    <div
      style={{
        padding: "0 14px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        paddingBottom: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: "auto",
          marginLeft: "auto",
          padding: "10px",
          borderRadius: "8px",
          width: "200px",
          textAlign: "center",
        }}
      >
        {/* Round Image */}
        <div
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            overflow: "hidden",
            marginBottom: "15px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          <img
            src="/images/empview.png"
            alt="User"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>

        {/* Name */}
        <div
          style={{
            fontSize: "13px",
            fontWeight: "bold",
            marginBottom: "5px",
          }}
        >
          {employee?.name}
        </div>

        {/* Email */}
        <div
          style={{
            fontSize: "10px",
            color: "#888",
            marginBottom: "20px",
          }}
        >
          {employee?.username}
        </div>

        {/* Edit and Delete Icons */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <EditOutlined
            style={{ fontSize: "20px", cursor: "pointer", marginRight: "10px" }}
          />
          <DeleteOutlined style={{ fontSize: "20px", cursor: "pointer" }} />
        </div>
      </div>
      <Divider />
      <Row gutter={24}>
        {[
          { label: "Address", value: employee?.address },
          { label: "CNIC", value: employee?.cnic },
          { label: "Date Of Birth", value: employee?.dob },
          { label: "Qualification", value: employee?.qualification },
          { label: "Salary", value: employee?.salary },
          { label: "Designation", value: employee?.designation },
          {
            label: "Status",
            value: employee?.status === 1 ? "Active" : "Inactive",
          },
        ].map((item, index) => (
          <Col span={24} key={index}>
            <div
              style={{
                display: "flex",
                width: "100%",
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                marginBottom: 2,
              }}
            >
              <div
                style={{
                  flexBasis: "20%",
                  padding: "4px 11px",
                  backgroundColor: "#f5f5f5",
                  borderRight: "1px solid #d9d9d9",
                }}
              >
                {item.label}
              </div>
              <p
                style={{
                  flexBasis: "40%",
                  flexGrow: 1,
                  margin: 0,
                  padding: "4px 11px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {item.value}
              </p>
            </div>
          </Col>
        ))}
        <Col span={24}>
          <div
            style={{
              display: "flex",
              width: "100%",
              border: "1px solid #d9d9d9",
              borderRadius: 4,
              marginBottom: 2,
            }}
          >
            <div
              style={{
                padding: "4px 11px",
                backgroundColor: "#f5f5f5",
                borderRight: "1px solid #d9d9d9",
                flexBasis: "20%",
              }}
            >
              Permissions
            </div>
            <p
              style={{
                flexBasis: "40%",
                flexGrow: 1,
                margin: 0,
                padding: "4px 11px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {employee?.permissions.map((perm) => perm.name).join(", ")}
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import {
  Table,
  Space,
  Button,
  Row,
  Col,
  Input,
  Modal,
  notification,
} from "antd";
import {
  EditOutlined,
  SearchOutlined,
  FilePdfOutlined,
  FileAddOutlined,
  FileTextOutlined,
  EyeOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { loadingPlanService } from "../../../services";
function LoadingPlan() {
  const [loading, setLoading] = useState(false);
  const [isModalshow, setIsModalShow] = useState(false);
  const [selectedDeleteRecord, setSelectedDeleteRecord] = useState(null);
  const [loadingPlan, setLoadingPlan] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const showModal = (record) => {
    setSelectedDeleteRecord(record);
    setIsModalShow(true);
  };

  const handleCancel = () => {
    setIsModalShow(false);
    setSelectedDeleteRecord(null);
  };
  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "_id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Container ID",
      dataIndex: "container_id",
      key: "ContainerID",
    },
    {
      title: "Container Number",
      dataIndex: "c_container_number",
      key: "ContainerNumber",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "Location",
    },

    {
      title: "Actions",
      key: "Actions",
      render: (text, record, { _id }) => (
        <Space size="small">
          <Link to={`/operation/loadings/edits/${record.container_id}`}>
            <Button type="primary" size="small">
              <EditOutlined />
            </Button>
          </Link>
          <Link to={`/operation/loadings/views/${record.container_id}`}>
            <Button type="primary" size="small">
              <EyeOutlined />
            </Button>
          </Link>
          <Button type="primary" size="small" onClick={() => showModal(record)}>
            <DeleteOutlined />
          </Button>
          <Link to={`/operation/loadings/generates-plans/${record.container_id}`}>
            <Button type="primary" size="small">
              <FilePdfOutlined />
            </Button>
          </Link>
          <Button type="primary" size="small">
            <FileAddOutlined />
          </Button>
          <Link to={"/operation/loadings/vehical-details"}>
            <Button type="primary" size="small">
              <FileTextOutlined />
            </Button>
          </Link>
        </Space>
      ),
    },
  ];

  const handleDelete = async () => {
    if (!selectedDeleteRecord || !selectedDeleteRecord.container_id) {
      console.error("No entry selected for deletion.");
      return;
    }
    const response = await loadingPlanService.deletePlan(
      selectedDeleteRecord.container_id
    );
    if (response.success) {
      // setLoadingPlan((prevData) =>
      //   prevData.filter((item) => item.container_id !== selectedDeleteRecord.container_id)
      // );
      notification.success({
        message: "Delete Successfully!",
        description: response.message,
        placement: "topRight",
      });
      initialize(pagination.current, pagination.pageSize);
    } else {
      console.error("Failed to delete the customer.");
    }
    setIsModalShow(false);
  };

  const initialize = async (page = 1, pageSize = pagination.current) => {
    try {
      setLoading(true);

      const { success, data, message } = await loadingPlanService.fetchloaded(
        page
      );

      if (success) {
        if (data && data.data && data.data.length > 0) {
          setLoadingPlan(data.data);
          setPagination((prev) => ({
            ...prev,
            total: data.total,
            current: page,
            pageSize,
          }));
        } else {
          console.warn("No loading plan data found.");
          setLoadingPlan([]);
        }
      } else {
        console.error(
          `Error: ${message || "Failed to fetch loading plan data."}`
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={16}>
        <Col span={10}>
          <h5 className="font-bold"> Loaded Container</h5>
        </Col>
        <Col span={14} style={{ textAlign: "right" }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={loadingPlan}
        style={{ marginTop: "10px" }}
        loading={loading}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
      />
      <Modal
        title={<div style={{ textAlign: "center" }}>Confirm</div>}
        open={isModalshow}
        onOk={handleDelete}
        onCancel={handleCancel}
        centered
        styles={{
          body: {
            padding: "20px",
          },
        }}
        width={300}
        footer={
          <div className="flex justify-center gap-3">
            <Button
              type="default"
              onClick={handleCancel}
              style={{ width: "30%" }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleDelete}
              style={{ width: "30%" }}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: "center" }}>
          Are you sure you want to delete this?
        </p>
      </Modal>
    </div>
  );
}
export default LoadingPlan;

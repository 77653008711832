import ajaxService from "./ajax-service";
import { validationErrors } from "../utils";
class CustomerService {
  async fetch(page) {
    const response = await ajaxService.get(`/customers?page=${page}`);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async fetchCustomers() {
    const response = await ajaxService.get(`/customers?all=true`);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async view(id, page) {
    const response = await ajaxService.get(`/customers/${id}`);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async addCustomer(data) {
    const response = await ajaxService.post(`/customers`, data);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }

  async fetchCustomerStatus(id) {
    const response = await ajaxService.get(`/customers/status/${id}`);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async editCustomer(id, data) {
    const response = await ajaxService.put(`/customers/`, id, data);
    console.log(response);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }

  async deleteCustomer(id) {
    const response = await ajaxService.get(`/customers/delete/${id}`);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
}

const customerService = new CustomerService();
export default customerService;

import React, { useEffect, useState } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Select,
  Input,
  DatePicker,
  Button,
  notification,
} from "antd";
import { creditNoteService } from "../../../../../services";

function AddCreditNoteModal({
  isVisible,
  setIsModalShow,
  invoices,
  creditNote,
  initialize,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    form.resetFields();
    setIsModalShow(false);
  };
  const handleOk = async (values) => {
    setLoading(true);
    const { CreditNumber, ...rest } = values;
    const data = {
      ...rest,
      issue_date: rest.issue_date.format("YYYY-MM-DD"),
    };
    const response = await creditNoteService.addCreditNote(data);
    if (response.success) {
      notification.success({
        message: "Successfully Added!",
        description: response.message,
        placement: "topRight",
      });
    }
    setLoading(false);
    initialize();
    handleCancel();
  };
  useEffect(() => {
    form.setFieldsValue({
      CreditNumber: Number(creditNote[0]?.id) + 1,
    });
  }, [creditNote]);
  return (
    <Modal
      title={<div style={{ width: "100%" }}>Add Credit Note</div>}
      open={isVisible}
      onCancel={handleCancel}
      footer={null}
      closable={true}
      width={500}
      style={{ padding: "15px" }}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={handleOk}
        requiredMark={false}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Invoice Number"
              name="invoice"
              rules={[
                { required: true, message: "Please select an invoice number" },
              ]}
            >
              <Select placeholder="Select Invoice #">
                {invoices.map((invoice) => (
                  <Select.Option key={invoice.id} value={invoice.id}>
                    {invoice.id}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Credit Number"
              name="CreditNumber"
              rules={[
                { required: true, message: "Please enter the credit number" },
              ]}
            >
              <Input type="number" placeholder="91099" readOnly />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Amount"
              name="amount"
              rules={[{ required: true, message: "Please enter the amount" }]}
            >
              <Input type="number" placeholder="91099" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Date"
              name="issue_date"
              rules={[{ required: true, message: "Please select a date" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Description" name="description">
              <Input.TextArea
                placeholder="Enter special notes here..."
                rows={5}
              />
            </Form.Item>
          </Col>
        </Row>
        <Col span={24}>
          <Form.Item style={{ marginTop: 20 }}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loading}
            >
              Create Credit Note
            </Button>
          </Form.Item>
        </Col>
      </Form>
    </Modal>
  );
}

export default AddCreditNoteModal;

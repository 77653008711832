import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import "./Edit.css";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space } from "antd";

export const EditInvoice = () => {
  const [val1, setVal1] = useState([]);
  const [isModalshow, setIsModalShow] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);

  const showModal = () => {
    setIsModalShow(true)
  }

  const handleCancel = () => {
    setIsModalShow(false)
    setIsModalOpen(false)
  };

  const handleDelete = async () => {
    setIsModalShow(false);

  };

  const handleOk = () => {
    setIsModalOpen(false)
  }



  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div
        style={{
          padding: "15px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF",
          width: "850px",
          // alignItems:"center"
        }}
      >
        <div className="main">
          {/* /////top start//////// */}
          <div className="top">
            <div>
              <img
                src="/images/ctrade-logo.png"
                alt=""
                style={{ width: "100px", height: "55px" }}
              />
              <h3>CTRADE USA INC</h3>
            </div>
            <div style={{ fontSize: "14px" }}>
              3902 SWINGLE RD
              <br />
              HOUSTON TX 77047
              <br />
              UNITED STATES
              <br />
              Mobile: +1 832-819-2622 <br />
              Email: trade@ctrade.us
            </div>
          </div>
          {/* /////top end//////// */}
          <h1 className="heading">INVOICE Number: 5519</h1>
          <div className="seperator"></div>
          {/* /////second start//////// */}
          <div className="second">
            <h4 style={{ fontWeight: "lighter" }}>
              Bill-to Party:   US TRANS LLC
            </h4>
            <div>
              <h4 style={{ fontWeight: "lighter" }}>
                Invoice Date:   2024-10-23
              </h4>
              <h4 style={{ fontWeight: "lighter" }}>
                Invoice Due:   2024-10-23
              </h4>
              <h4 style={{ marginTop: "5vh", fontWeight: "lighter" }}>
                Payment terms:   Payable immediately
              </h4>
            </div>
          </div>
          {/* /////second end//////// */}
          <h3
            className="subheading"
            style={{ fontWeight: "500", fontSize: "18px" }}
          >
            BOOKING Number: 63644094
          </h3>
          <div className="seperatorTwo"></div>
          {/* /////third start//////// */}
          <div className="third">
            <div style={{ marginLeft: "1vw" }}>
              <h4 style={{ fontWeight: "lighter" }}>POL: HOUSTON  </h4>
              <h4 style={{ fontWeight: "lighter" }}>POD:   </h4>
              <h4 style={{ fontWeight: "lighter" }}>
                Product Id: Ctrade U.S.A
              </h4>
            </div>
            <div style={{ marginLeft: "3vw" }}>
              <h4 style={{ fontWeight: "lighter" }}>
                Place of Receipt: Houston
              </h4>
              <h4 style={{ fontWeight: "lighter" }}> Place of Delivery:  </h4>
            </div>
          </div>
          {/* /////third end//////// */}
          <div className="seperator"></div>
          <h2 className="subheading">TOTAL AMOUNT DUE</h2>
          {/* /////fourth start//////// */}
          <tr
            className="table"
            style={{
              border: "2px solid #B83D3D",
              paddingLeft: "1vw",
              paddingRight: "1vw",
            }}
          >
            <td style={{ fontWeight: "bold", fontSize: "14px" }}>Condition</td>
            <td style={{ fontWeight: "bold", fontSize: "14px" }}>Rate</td>
            <td style={{ fontWeight: "bold", fontSize: "14px" }}>BaseValue</td>
            <td style={{ fontWeight: "bold", fontSize: "14px" }}>Total (USD)</td>
          </tr>
          <tr
            className="table"
            style={{
              paddingLeft: "1vw",
              paddingRight: "1vw",
            }}
          >
            <td style={{ fontSize: "14px" }}>Net Value </td>
            <td></td>
            <td></td>
            <td style={{ fontSize: "14px" }}> 3,550.00</td>
          </tr>
          <tr
            className="table"
            style={{
              paddingLeft: "1vw",
              paddingRight: "1vw",
            }}
          >
            <td style={{ fontSize: "14px" }}>Discount</td>
            <td></td>
            <td></td>
            <td style={{ fontSize: "14px" }}> 0.00</td>
          </tr>
          <tr
            className="table"
            style={{
              border: "2px solid #B83D3D",
              paddingLeft: "1vw",
              paddingRight: "1vw",
            }}
          >
            <td style={{ fontWeight: "bold", fontSize: "16px" }}>Amount Due</td>
            <td></td>
            <td></td>
            <td style={{ fontWeight: "bold" }}></td>
            <td style={{ fontWeight: "bold", fontSize: "16px" }}>3,550.00</td>
          </tr>
          {/* /////fourth end//////// */}
          {/* /////fifth start//////// */}
          <div>
            <h5 style={{ marginLeft: "3vw" }}>Vehicles Load</h5>
            <div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "3vw",

                  height: "5vh",
                }}
              >
                <h5 style={{}}>1.</h5>
                <h5 style={{ marginLeft: "3vw", width: "12vw" }}>2015 TOYOTA </h5>
                <h5 style={{ marginLeft: "3vw" }}> ( 5TDYK3DC4FS530383 )</h5>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "3vw",
                  height: "5vh",
                }}
              >
                <h5 style={{}}>2.</h5>
                <h5 style={{ marginLeft: "3vw", width: "12vw" }}>
                  2021 CHEVROLET
                </h5>
                <h5 style={{ marginLeft: "3vw" }}> ( 5TDYK3DC4FS530383 )</h5>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "3vw",
                  height: "5vh",
                }}
              >
                <h5 style={{}}>3.</h5>
                <h5 style={{ marginLeft: "3vw", width: "12vw" }}>
                  2017 LAND ROVER
                </h5>
                <h5 style={{ marginLeft: "3vw" }}> ( 5TDYK3DC4FS530383 )</h5>
              </div>
            </div>
          </div>
          {/* /////fifth end//////// */}
          <div className="seperator" style={{ marginTop: "3vh" }}></div>
          <h2 className="subheading">Details</h2>

          {/* /////six start//////// */}
          <div style={{ border: "1px solid #b83d3d" }}>
            <tr
              className="table"
              style={{
                border: "2px solid #B83D3D",
                paddingLeft: "1vw",
                paddingRight: "1vw",
                padding: "1vw",
              }}
            >
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  width: "140px",
                }}
              >
                Chasis#
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  width: "100px",
                }}
              >
                Freight
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  width: "100px",
                }}
              >
                Towing
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  width: "100px",
                }}
              >
                Yard
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  width: "100px",
                }}
              >
                Loading
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  width: "100px",
                }}
              >
                Chasis
              </td>
              <td
                style={{
                  fontWeight: "bold",
                  fontSize: "12px",
                  width: "100px",
                }}
              >
                Total Amount
              </td>
            </tr>
            <tr
              className="table"
              style={{
                paddingLeft: "1vw",
                paddingRight: "1vw",
                padding: "1vw",
              }}
            >
              <td
                style={{
                  fontSize: "12px",
                  width: "140px",
                }}
              >
                WDBJF65J51B178935
              </td>
              <td>
                <input
                  value={val1}
                  style={{
                    height: "3vh",
                    border: "1px solid #d9d9d9",
                    width: "100px",
                  }}
                />
              </td>
              <td>
                <input
                  value={val1}
                  style={{
                    height: "3vh",
                    border: "1px solid #d9d9d9",
                    width: "100px",
                  }}
                />
              </td>
              <td>
                <input
                  value={val1}
                  style={{
                    height: "3vh",
                    border: "1px solid #d9d9d9",
                    width: "100px",
                  }}
                />
              </td>
              <td>
                <input
                  value={val1}
                  style={{
                    height: "3vh",
                    border: "1px solid #d9d9d9",
                    width: "100px",
                  }}
                />
              </td>
              <td>
                <input
                  value={val1}
                  style={{
                    width: "10vw",
                    height: "3vh",
                    border: "1px solid #d9d9d9",
                    width: "100px",
                  }}
                />
              </td>
              <td
                style={{
                  fontSize: "12px",
                  width: "12vw",
                  width: "100px",
                }}
              >
                $0
              </td>
            </tr>
            <tr
              className="table"
              style={{
                paddingLeft: "1vw",
                paddingRight: "1vw",
                padding: "1vw",
              }}
            >
              <td
                style={{
                  fontSize: "12px",
                  width: "140px",
                }}
              >
                5NMSG13DX8H206933
              </td>
              <td>
                <input
                  value={val1}
                  style={{
                    height: "3vh",
                    border: "1px solid #d9d9d9",
                    width: "100px",
                  }}
                />
              </td>
              <td>
                <input
                  value={val1}
                  style={{
                    height: "3vh",
                    border: "1px solid #d9d9d9",
                    width: "100px",
                  }}
                />
              </td>
              <td>
                <input
                  value={val1}
                  style={{
                    height: "3vh",
                    border: "1px solid #d9d9d9",
                    width: "100px",
                  }}
                />
              </td>
              <td>
                <input
                  value={val1}
                  style={{
                    height: "3vh",
                    border: "1px solid #d9d9d9",
                    width: "100px",
                  }}
                />
              </td>
              <td>
                <input
                  value={val1}
                  style={{
                    width: "10vw",
                    height: "3vh",
                    border: "1px solid #d9d9d9",
                    width: "100px",
                  }}
                />
              </td>
              <td
                style={{
                  fontSize: "12px",
                  width: "12vw",
                  width: "100px",
                }}
              >
                $0
              </td>
            </tr>
            <tr
              className="table"
              style={{
                paddingLeft: "1vw",
                paddingRight: "1vw",
                padding: "1vw",
              }}
            >
              <td
                style={{
                  fontSize: "12px",
                  width: "140px",
                }}
              >
                5TBEV54137S462947
              </td>
              <td>
                <input
                  value={val1}
                  style={{
                    height: "3vh",
                    border: "1px solid #d9d9d9",
                    width: "100px",
                  }}
                />
              </td>
              <td>
                <input
                  value={val1}
                  style={{
                    height: "3vh",
                    border: "1px solid #d9d9d9",
                    width: "100px",
                  }}
                />
              </td>
              <td>
                <input
                  value={val1}
                  style={{
                    height: "3vh",
                    border: "1px solid #d9d9d9",
                    width: "100px",
                  }}
                />
              </td>
              <td>
                <input
                  value={val1}
                  style={{
                    height: "3vh",
                    border: "1px solid #d9d9d9",
                    width: "100px",
                  }}
                />
              </td>
              <td>
                <input
                  value={val1}
                  style={{
                    width: "10vw",
                    height: "3vh",
                    border: "1px solid #d9d9d9",
                    width: "100px",
                  }}
                />
              </td>
              <td
                style={{
                  fontSize: "12px",
                  width: "12vw",
                  width: "100px",
                }}
              >
                $0
              </td>
            </tr>
          </div>
          {/* /////six end//////// */}
          {/* /////seven start//////// */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                width: "400px",
              }}
            >
              <div
                style={{
                  width: "400px",

                  display: "flex",
                }}
              >
                <h5>Extra Charges </h5>
                <h5 style={{ marginLeft: "4vw" }}>Charges </h5>
              </div>
              <div
                style={{
                  width: "400px",

                  display: "flex",

                  borderTop: "1px solid #b83d3d",
                }}
              >
                <h5 style={{ fontWeight: "lighter" }}>Extra Charges </h5>
                <h5 style={{ fontWeight: "lighter", marginLeft: "4vw" }}>
                  Extra Charge Amo
                </h5>
                <h5 style={{ marginLeft: "4vw" }}>Add Icon</h5>
              </div>
            </div>
            <div
              style={{
                borderTop: "1px solid #b83d3d",
                width: "300px",
                marginTop: "10vh",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "120px" }}>
                <h5>Extra Charges: $ </h5>
                <h5>SubTotal: $ </h5>
                <h5> Discount: $ </h5>
                <h5> Total Due: $ </h5>
              </div>
              <div style={{ width: "120px" }}>
                <h5 style={{ fontWeight: "lighter" }}>0.00 </h5>
                <h5 style={{ fontWeight: "lighter" }}>0.00 </h5>
                <h5 style={{ fontWeight: "lighter" }}> 0 </h5>
                <h5 style={{ fontWeight: "lighter" }}> 0.00 </h5>
              </div>
            </div>
          </div>
          {/* /////seven end//////// */}
          {/* /////eight start//////// */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                width: "400px",
                marginLeft: "3vw",
              }}
            >
              <div
                style={{
                  width: "400px",

                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ width: "160px" }}>No </h5>
                <h5 style={{ width: "260px" }}>Container Number</h5>
                <h5 style={{ width: "120px" }}>Size</h5>
              </div>
              <div
                style={{
                  width: "400px",

                  display: "flex",
                  justifyContent: "space-between",
                  borderTop: "1px solid #666",
                }}
              >
                <h5 style={{ fontWeight: "lighter", width: "160px" }}>
                  Extra Charges
                </h5>
                <h5 style={{ fontWeight: "lighter", width: "260px" }}>
                  Extra Charge Amo
                </h5>
                <h5 style={{ width: "120px", fontWeight: "lighter" }}>45</h5>
              </div>
            </div>
          </div>
          {/* /////eight end//////// */}
          <div className="seperator" style={{ marginTop: "3vh" }}></div>
          <h5 style={{ marginLeft: "0vw", marginBottom: "20px", marginTop: "20px", fontWeight: "bolder" }}>
            Account Information
          </h5>
          {/* /////nine start//////// */}
          <div
            style={{
              display: "flex",
              marginLeft: "0vw",
              height: "3vh",
            }}
          >
            <h5
              style={{
                fontWeight: "lighter",
                width: "15vw",
              }}
            >
              Account Name
            </h5>
            <h5 style={{ fontWeight: "lighter" }}> : CTRADE USA INC </h5>
          </div>
          <div style={{ display: "flex", marginLeft: "0vw", height: "3vh" }}>
            <h5
              style={{
                fontWeight: "lighter",
                width: "15vw",
              }}
            >
              Bank Name
            </h5>
            <h5 style={{ fontWeight: "lighter" }}> : BANK OF AMERICA </h5>
          </div>
          <div style={{ display: "flex", marginLeft: "0vw", height: "3vh" }}>
            <h5
              style={{
                fontWeight: "lighter",
                width: "15vw",
              }}
            >
              Account Number
            </h5>
            <h5 style={{ fontWeight: "lighter" }}> : 4881 2067 0589</h5>
          </div>
          <div style={{ display: "flex", marginLeft: "0vw", height: "3vh" }}>
            <h5
              style={{
                fontWeight: "lighter",
                width: "15vw",
              }}
            >
              Routing Number
            </h5>
            <h5 style={{ fontWeight: "lighter" }}>
              : 111000023/111000025 (PAPER & ELECTRONICS){" "}
            </h5>
          </div>
          <div style={{ display: "flex", marginLeft: "0vw", height: "3vh" }}>
            <h5
              style={{
                fontWeight: "lighter",
                width: "15vw",
              }}
            >
              Routing Number
            </h5>
            <h5 style={{ fontWeight: "lighter" }}>
              : 026009593 ( WIRE TRANSFER - DOMESTIC ){" "}
            </h5>
          </div>
          <div style={{ display: "flex", marginLeft: "0vw", height: "3vh" }}>
            <h5
              style={{
                fontWeight: "lighter",
                width: "15vw",
              }}
            >
              Swift Code
            </h5>
            <h5 style={{ fontWeight: "lighter" }}> : (US DOLLAR) BOFAUS3N </h5>
          </div>
          <div style={{ display: "flex", marginLeft: "0vw", height: "3vh" }}>
            <h5
              style={{
                fontWeight: "lighter",
                width: "15vw",
              }}
            >
              Branch Address
            </h5>
            <h5 style={{ fontWeight: "lighter" }}>
              : BANK OF AMERICA, N.A, 222 BROADWAY, NEWYORK, NY 10038
            </h5>
          </div>
          {/* /////nine end//////// */}
          <h5
            style={{
              fontWeight: "lighter",
              marginTop: "5vh",
              marginLeft: "0vw",
              marginRight: "0vw",
              color: "#53575A"
            }}
          >
            We do not accept CARD payments, only cashier's cheques or direct bank
            transfers.Any additional charges Government charges if any are applied
            will be charged at actual Payment should fall into the CTrade USA Inc
            account within 10 days after loading the container.
            <br />
            Any dispute in invoice should be raised within 07 days from the issue
            date otherwise it will be considered as confirmed.
            <br />
            <br /> Any invoice delayed from the due date will be subject to $100
            as late fees15 Day free at facilities there after $15 Day/Car from the
            day of receiving in and out charges will be $100 subject to storage
            charges.
          </h5>
          <h5 style={{ marginLeft: "0vw", marginRight: "3vw", fontWeight: "bolder" }}>
            Note*: Payment against this invoice will be accepted as terms and
            conditions confirmed. In case of delay in payment ctrade usa will not
            be responsbile for any storage demarruages/detentions
          </h5>
          <div
            className="seperatorTwo"
            style={{
              marginTop: "3vh",
              height: "1px",
              backgroundColor: "#8F9CA6",
            }}
          ></div>
          <h5
            style={{
              textAlign: "center",
              color: "#8F9CA6",
              fontWeight: "lighter",
              marginTop: "12px"
            }}
          >
            THANK YOU FOR DOING BUSINESS WIITH C TRADE USA INC.
          </h5>
        </div>
      </div>
      <div style={{ marginTop: "20px", marginBottom: "25px", display:"flex",justifyContent:"flex-end",  width: "850px" }}>
        <Space>
          <Button type="primary" onClick={showModal}>Approve</Button>
          <Button type="primary" onClick={openModal}>Make Payment</Button>
        </Space>
      </div>

      <Modal
        title={<div style={{ width: "100%" }}>Add Transaction</div>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        width={700}
        bodyStyle={{ padding: "15px" }}
      >
        <Form layout="vertical" requiredMark={false}>
          <Row gutter={24}>

            <Col span={8}>
              <Form.Item
                label="Invoice"
                name="Invoice"
                rules={[{ required: true, message: "Please select an appointment type" }]}
              >
                <Select placeholder="5560-Amount Due:3646 - Date:2024-11-04">
                  <Select.Option value="Online">Online</Select.Option>
                  <Select.Option value="Offline">Offline</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Ref. Number"
                name="Ref. Number"
                rules={[{ required: true, message: "Please enter the number of allowed appointments" }]}
              >
                <Input type="number" placeholder="" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Payment Date"
                name="Payment Date"
                rules={[{ required: true, message: "Please enter the start time" }]}
              >
                <DatePicker style={{ width: "100%" }} placeholder={"mm/dd/yyyy"} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Invoice Amount"
                name="Invoice Amount"
                rules={[{ required: true, message: "Please enter the start time" }]}
              >
                <Input placeholder="3500.00" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Transaction Amount"
                name="Transaction Amount"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="0.00" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Balance"
                name="Balance"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="3500.00" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Mode Of Payment"
                name="Mode Of Payment"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="Bank Transfer" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Bank"
                name="Bank"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="BANK OF AMERICA" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Payment Status"
                name="Payment Status"
                rules={[{ required: true, message: "Please enter the end time" }]}
              >
                <Input placeholder="Not Paid" />
              </Form.Item>
            </Col>

            {/* </Row> */}
            <Col span={24}>
              <Form.Item>
                <div style={{ textAlign: "center", marginTop: "12px" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "60%" }}
                    onClick={handleOk}
                  >
                    Add Transaction
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={<div style={{ textAlign: 'center' }}>Confirm</div>}
        visible={isModalshow}
        onOk={handleDelete}
        onCancel={handleCancel}
        centered
        styles={{
          body: {
            padding: "10px",
          }
        }}
        width={300}
        footer={
          <div className='flex justify-center gap-3'>
            <Button type="default" onClick={handleCancel} style={{ width: "30%" }}>
              Cancel
            </Button>
            <Button type="primary" onClick={handleDelete} style={{ width: "30%" }}>
              Yes do it
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: 'center' }}>You sure you want to approve this invoice and send to customer?</p>
      </Modal>

    </div>

  );
};

export default EditInvoice;
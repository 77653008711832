import React, { useEffect, useState } from "react";
import { Table, Space, Button, Modal, notification } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import blformService from "../../../services/blform-service";
import { Link } from "react-router-dom";

function BlForm() {
  const [blForms, setBlForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 1,
    pageSize: 10,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalshow, setIsModalShow] = useState(false);
  const [selectedDeleteRecord, setSelectedDeleteRecord] = useState(null);

  const showModalHandler = () => setIsModalVisible(true);
  const showModal = (record) => {
    setSelectedDeleteRecord(record);
    setIsModalShow(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    notification.success({
      message: "Data Sent Successfully ",
      description: "Your data was send",
      duration: 1,
    });
  };

  const handleDelete = async () => {
    setLoading(true);
    if (!selectedDeleteRecord || !selectedDeleteRecord.id) {
      console.error("No entry selected for deletion.");
      return;
    }
    const response = await blformService.deleteForm(selectedDeleteRecord.id);
    setLoading(false);
    if (response.success) {
      notification.success({
        message: "Deleted Successfully!",
        description: response.message,
        placement: "topRight",
      });
      setBlForms((prevData) =>
        prevData.filter((item) => item.id !== selectedDeleteRecord.id)
      );
      initialize(pagination.current, pagination.pageSize);
    } else {
      console.error("Failed to delete the customer.");
    }
    setIsModalShow(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalShow(false);
    setSelectedDeleteRecord(null);
  };

  // const options = [
  //   {
  //     value: "10",
  //     label: "10",
  //   },
  //   {
  //     value: "9",
  //     label: "9",
  //   },
  // ];

  const initialize = async (page = 1, pageSize = pagination.pageSize) => {
    setLoading(true);
    const { success, data } = await blformService.fetch(page);
    setLoading(false);
    if (success) {
      setBlForms(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
  };

  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "#",
    //   key: "#",
    //   render: (text, record, index) => index + 1,
    // },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Booking No",
      dataIndex: "booking_no",
      key: "booking_no",
    },
    {
      title: "Container No",
      dataIndex: "container_nos",
      key: "container_nos",
      render: (text) => <p>{text ? text : "-"}</p>,
    },

    {
      title: "Action",
      key: "Action",
      render: (text, record) => (
        <Space size="small">
          <Link to={`/operation/bl-forms/Edit/${record.id}`}>
            <Button type="primary" size="small">
              <EditOutlined />
            </Button>
          </Link>
          <Link to={`/operation/bl-forms/View/${record.id}`}>
            <Button type="primary" size="small">
              <EyeOutlined />
            </Button>
          </Link>
          <Button type="primary" size="small" onClick={() => showModal(record)}>
            <DeleteOutlined />
          </Button>
          <Button
            disabled={!record.showGlobexBtn}
            type="primary"
            size="small"
            onClick={showModalHandler}
          >
            Globex Button
          </Button>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Modal
        title={<div style={{ textAlign: "center" }}>Confirm</div>}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        style={{
          padding: "20px",
        }}
        width={300}
        footer={
          <div className="flex justify-center gap-3">
            <Button
              type="default"
              onClick={handleCancel}
              style={{ width: "30%" }}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: "center" }}>
          Are you sure you want to send this?
        </p>
      </Modal>

      <Table
        columns={columns}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page),
          showSizeChanger: false,
        }}
        loading={loading}
        dataSource={blForms}
      />
      <Modal
        title={<div style={{ textAlign: "center" }}>Confirm</div>}
        open={isModalshow}
        onOk={handleDelete}
        onCancel={handleCancel}
        centered
        styles={{
          body: {
            padding: "20px",
          },
        }}
        width={300}
        footer={
          <div className="flex justify-center gap-3">
            <Button
              type="default"
              onClick={handleCancel}
              style={{ width: "30%" }}
              loading={loading}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleDelete}
              style={{ width: "30%" }}
              loading={loading}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: "center" }}>
          Are you sure you want to delete this?
        </p>
      </Modal>
    </div>
  );
}

export default BlForm;

import React, { useState } from 'react'
import ReportInvoice from './DailyCashFlow'
import InvoiceStatus from './InvoiceStatus'
import LoadPlan from './ReportLoadPlan'
import CollectionAmount from './CollectionAmount'
import BankCollectionAmount from './BankCollectionAmount'
import CustomerOverdueInvoice from './CustomerOverdueInvoice'
import InvoiceCollectionDueCopy from './InvoiceCollectionDueCopy'
import CashFlow from './ExpectedActualCashFlow'
import SalesExpensesReport from './SalesExpensesReport'
import CustomerAgingReport from './CustomerAgingReport'
import VendorLedgerReport from './VendorLedgerReport'
import VendorPayableReport from './VendorPayableReport'
import MonthlyCashFlow from './MonthlyCashFlow'
import { Divider, Form, Select } from 'antd'
import SalesDetailReport from './SalesDetailReport'

export default function Reports() {
  const [selectedReports, setSelectedReports] = useState(null)

  const RenderReports = () => {
    switch (selectedReports) {
      case 'ReportInvoice':
        return <ReportInvoice />;
      case 'InvoiceStatus':
        return <InvoiceStatus />;
      case 'LoadPlan':
        return <LoadPlan />;
      case 'CollectionAmount':
        return <CollectionAmount />;
      case 'BankCollectionAmount':
        return <BankCollectionAmount />;
      case 'CustomerOverdueInvoice':
        return <CustomerOverdueInvoice />;
      case 'InvoiceCollectionDueCopy':
        return <InvoiceCollectionDueCopy />;
      case 'ExpectedActualCashFlow':
        return <CashFlow />;
      case 'SalesSummaryReport':
        return <SalesExpensesReport />;
        case 'SalesDetailReport':
          return <SalesDetailReport/>;
      case 'CustomerAgingReport':
        return <CustomerAgingReport />;
      case 'VendorLedgerReport':
        return <VendorLedgerReport />;
      case 'VendorPayableReport':
        return <VendorPayableReport />;
      case 'MonthlyCashFlow':
        return <MonthlyCashFlow />;
    }
  }
  const handleSelectChange = (value) => {
    setSelectedReports(value)
  }
  const reportOptions = [
    { value: 'InvoiceStatus', label: 'Invoice Status' },
    { value: 'LoadPlan', label: 'Load Plan' },
    { value: 'CollectionAmount', label: 'Collection/Receipt Report' },
    { value: 'BankCollectionAmount', label: 'Bank Collection' },
    { value: 'CustomerOverdueInvoice', label: 'Customer Due/Overdue' },
    { value: 'InvoiceCollectionDueCopy', label: 'Cash Flow Due' },
    { value: 'ExpectedActualCashFlow', label: 'Cash Flow Summary' },
    { value: 'ReportInvoice', label: 'Daily Flow Summary' },
    { value: 'SalesSummaryReport', label: 'Sales Summary Report' },
    { value: 'SalesDetailReport', label: 'Sales Detail Report' },
    { value: 'CustomerAgingReport', label: 'Customer Aging Report' },
    { value: 'VendorLedgerReport', label: 'Vendor Ledger' },
    { value: 'VendorPayableReport', label: 'Vendor Payable' },
    { value: 'MonthlyCashFlow', label: 'Monthly Cash Flow' },
  ]
  return (
    <>
      <div style={{ padding: '15px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
        <Form layout="horizontal" style={{ width: '100%' }}>
          <Form.Item
            label="Choose Report: "
            wrapperCol={{ flex: '1' }}
            style={{ width: '40%', }}>
            <Select
              value={selectedReports}
              style={{
                width: '100%',
                height: '35px',
                fontSize: '16px',
              }}
              placeholder="Select Reports"
              onChange={handleSelectChange}
            >
              {reportOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        <Divider />
        <RenderReports />
      </div>
    </>


  )
}

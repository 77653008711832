import React, { useEffect, useState } from "react";
import {Button, Col, Form, Input, notification, Row, Select,Table,} from "antd";
import { CiSquarePlus } from "react-icons/ci";
import { DeleteOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { costingExpenseService } from "../../../../../services";
import { useParams } from "react-router-dom";

export default function AddCoastngExpenses() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState([]);
  const [vendors, setVendors] = useState([]);
  // const [transporter, setTransporter] = useState([]);
  // const [invoice_vendor, setInvoice_vendor] = useState([]);
  // const [invoice_transporter, setInvoice_transporter] = useState([]);
  const [form] = Form.useForm();
  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Vendor",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Select
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(value) => handleDataChange(value, "name", record.Id)}
        >
          {invoice?.vendors?.map((vendor) => (
            <Select.Option key={vendor.id} value={vendor.id}>
              {vendor.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) =>
            handleDataChange(e.target.value, "amount", record.Id)
          }
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) =>
            handleDataChange(e.target.value, "description", record.Id)
          }
        />
      ),
    },
    {
      title: (
        <div>
          <DeleteOutlined style={{ fontSize: "18px" }} />
        </div>
      ),
      key: "Delete",
      render: (text, record, index) => {
        if (data.length === 1) return null;
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleDeleteRow(record.Id)}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];
  const columns2 = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Vendor",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Select
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(value) => handleData2Change(value, "name", record.Id)}
        >
          {invoice?.transporters?.map((transporter) => (
            <Select.Option key={transporter.id} value={transporter.id}>
              {transporter.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) =>
            handleData2Change(e.target.value, "amount", record.Id)
          }
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text, record) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) =>
            handleData2Change(e.target.value, "description", record.Id)
          }
        />
      ),
    },
    {
      title: (
        <div>
          <DeleteOutlined style={{ fontSize: "18px" }} />
        </div>
      ),
      key: "Delete",
      render: (text, record, index) => {
        if (data2.length === 1) return null;
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleDeleteRow2(record.Id)}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];
  const [data, setData] = useState([]);

  const handleAddRow = () => {
    setData([
      ...data,
      {
        Id: uuidv4(),
        name: "",
        amount: "",
        description: "",
      },
    ]);
  };

  const handleDeleteRow = (Id) => {
    // console.log(data);
    // console.log(data2);
    setData(data.filter((item) => item.Id !== Id));
  };
  const [data2, setData2] = useState([]);

  const handleAddRow2 = () => {
    setData2([
      ...data2,
      {
        Id: uuidv4(),
        name: "",
        amount: "",
        description: "",
      },
    ]);
  };
  const handleDeleteRow2 = (Id) => {
    setData2(data2.filter((item) => item.Id !== Id));
  };

  // Function to handle changes in data
  const handleDataChange = (value, field, id) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.Id === id ? { ...item, [field]: value } : item
      )
    );
  };

  // Function to handle changes in data2
  const handleData2Change = (value, field, id) => {
    setData2((prevData2) =>
      prevData2.map((item) =>
        item.Id === id ? { ...item, [field]: value } : item
      )
    );
  };
  const initialize = async () => {
    const { success, data } = await costingExpenseService.viewExpense(id);
    if (success) {
      setInvoice(data);
      form.setFieldsValue({
        ...data.ledger,
        invoice_id: data.ledger.id,
        booking_no: data.booking_no,
        container_no: data.container_no,
      });

      const formattedVendors = data.invoice_vendors.map((vendor) => ({
        Id: uuidv4(),
        name: vendor.vendor_id,
        amount: vendor.amount,
        description: vendor.description,
      }));
      const formattedTransporter = data.invoice_transporters.map(
        (transporter) => ({
          Id: uuidv4(),
          name: transporter.vendor_id,
          amount: transporter.amount,
          description: transporter.description,
        })
      );

      setData(formattedVendors);
      setData2(formattedTransporter);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    const payload = {
      ...values,
      vendor: data.map((data) => data.name),
      amount: data.map((data) => data.amount),
      description: data.map((data) => data.description),
      tvendor: data2.map((data) => data.name),
      tamount: data2.map((data) => data.amount),
      tdescription: data2.map((data) => data.description),
    };
    const response = await costingExpenseService.editExpense(id, payload);
    if (response.success) {
      notification.success({
        message: "Successfully updated!",
        description: response.message,
        placement: "topRight",
      });
      initialize();
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize();
  }, []);
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Form
        requiredMark={false}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{ layout: "vertical" }}
      >
        <Row gutter={24}>
          <Col span={24}>
            <h1 style={{ fontWeight: "bold" }} className="pt-2  pb-5">
              Edit Expense
            </h1>
          </Col>
          <Col span={8}>
            <Form.Item label="Invoice Number" name="invoice_id">
              <Input placeholder="" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Booking Number" name="booking_no">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Container Number" name="container_no">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Transport Charges"
              name="total_transportation_charges"
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Material Charges" name="total_material_charges">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Labour Charges" name="total_labour_charges">
              <Input placeholder="OTHER" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Extra /Buying Charges" name="buying_charges">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Selling Charges" name="selling_charges">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Freight Booking Charges"
              name="total_booking_charges"
            >
              <Input placeholder="" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <div
              style={{
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                marginTop: 20,
              }}
            >
              <h1 style={{ fontWeight: "bold" }}>ADD FRIGHT FORWARD DETAILS</h1>
              <Table
                columns={columns}
                scroll={{ x: "max-content" }}
                dataSource={data}
                pagination={false}
                rowKey="Id"
                style={{ marginTop: "10px" }}
              />
              <div
                style={{ marginTop: 20 }}
                className="flex flex-col justify-center items-center"
              >
                <CiSquarePlus
                  style={{ cursor: "pointer", fontSize: "25px" }}
                  onClick={handleAddRow}
                />
              </div>
            </div>
          </Col>
          <Col span={24}>
            <div
              style={{
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                marginTop: 20,
              }}
            >
              <h1 style={{ fontWeight: "bold" }}>ADD TRANSPORTATION DETAILS</h1>
              <Table
                columns={columns2}
                scroll={{ x: "max-content" }}
                dataSource={data2}
                pagination={false}
                rowKey="Id"
                style={{ marginTop: "10px" }}
              />
              <div
                style={{ marginTop: 20 }}
                className="flex flex-col justify-center items-center"
              >
                <CiSquarePlus
                  style={{ cursor: "pointer", fontSize: "25px" }}
                  onClick={handleAddRow2}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item style={{ marginTop: 20 }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                loading={loading}
              >
                Update
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

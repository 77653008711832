
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ajaxService from '../../services/ajax-service';

export const fetchCountries = createAsyncThunk(
  'countries/fetch', 
  async (_, { rejectWithValue }) => {
      const response = await ajaxService.get('/countries'); 
      if (response?.success) {
        return response.data;
      } else {
        return rejectWithValue(response.message || 'Failed to fetch countries');
      }
  }
);
const countriesSlice = createSlice({
  name: 'countries',
  initialState: {
    data: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload; // Directly set fetched data
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default countriesSlice.reducer;

import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Tag,
  Button,
  Row,
  Col,
  Modal,
  Form,
  notification,
} from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import ScheduleForm from "./ScheduleForm";
import { bookingService } from "../../../services";

function BookSchedule() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editData, setEditData] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [loading, setLoading] = useState(false);
  const [booking, setBooking] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState();
  const [viewBooking, setViewBooking] = useState([]);

  const handleEdit = (record) => {
    setEditData(record);
    setModalVisible(true);
  };
  const handleModalClose = () => {
    initialize(pagination.current, pagination.pageSize);
    setModalVisible(false);
  };
  const showModal = (record) => {
    // setSelectedRecord(record);
    setIsModalOpen(true);
    getbooking(record);
  };
  const visibleModal = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    setLoading(true);
    const response = await bookingService.deletebooking(selectedRecord);
    if (response.success) {
      notification.success({
        message: "Success",
        description: "Booking deleted successfully!",
        placement: "topRight",
      });
      setBooking((prevData) =>
        prevData.filter((item) => item.id !== selectedRecord)
      );
    } else {
      const errors = response.data?.errors || [response.message];
      notification.error({
        message: "Error",
        description: `Error: ${errors.join(", ")}`,
        placement: "topRight",
      });
    }
    initialize(pagination.current, pagination.pageSize);
    setLoading(false);
    setIsModalOpen(false);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // setIsModalShow(false)
    setIsModalVisible(false);
  };

  const columns = [
    // {
    //   title: "#",
    //   dataIndex: "id",
    //   key: "# ",
    //   render: (text, record, index) => index + 1,
    // },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "Date",
    },
    {
      title: "Start time",
      dataIndex: "start_time",
      key: "Starttime",
    },
    {
      title: "End time",
      dataIndex: "end_time",
      key: "Endtime",
    },
    {
      title: "Appointment Allowed",
      dataIndex: "allowed_appointments",
      key: "AppointmentAllowed",
      align: "center",
    },
    {
      title: "Appointment Confirm",
      dataIndex: "booked_appointments",
      key: "AppointmentConfirm",
      align: "center",
    },
    {
      title: "Appointment Left",
      dataIndex: "left_appointments",
      key: "AppointmentLeft",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "Status",
      render: (status) => (
        <Tag
          color="#06D652"
          style={{ border: "1px solid #06D652", padding: "5px" }}
        >
          {status === 1 ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "Actions",
      render: (text, record) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            onClick={() => handleEdit(record)}
          >
            <EditOutlined />
          </Button>

          <Button
            type="primary"
            size="small"
            onClick={() => {
              showModal(record.id);
            }}
          >
            {/* / <a href="/quotationview"> */}
            <EyeOutlined />
            {/* </a> */}
          </Button>

          <Button
            type="primary"
            size="small"
            onClick={() => visibleModal(record.id)}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    const { success, data } = await bookingService.fetch(page);
    setLoading(false)
    if (success) {
      setBooking(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
  
  };

  const getbooking = async (record) => {
    const response = await bookingService.viewbooking(record);
    if (response.success) {
      setViewBooking(response.data);
    }
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);

  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Booking Schedule</h1>
        </Col>
      </Row>
      {/* Edit modal */}
      <ScheduleForm
        visible={modalVisible}
        onClose={handleModalClose}
        editData={editData}
      />
      {/* view modal */}
      <Modal
        title={<div style={{ width: "100%" }}>View Booking Schedule</div>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        width={600}
        style={{ padding: "15px" }}
      >
        <Form layout="vertical" requiredMark={false}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Date" name="date">
                {/* Use plain text instead of DatePicker */}
                <div>{viewBooking?.date || "-"}</div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Start Time" name="start_time">
                {/* Use plain text instead of TimePicker */}
                <div>{viewBooking?.start_time || "-"}</div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="End Time" name="end_time">
                {/* Use plain text instead of TimePicker */}
                <div>{viewBooking?.end_time || "-"}</div>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Allowed Appointments"
                name="allowed_appointments"
              >
                {/* Use plain text instead of Input */}
                <div>{viewBooking?.allowed_appointments || "0"}</div>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            {/* Use plain text for the checkbox */}
            <div>
              {viewBooking?.isActive
                ? "Appointment Active"
                : "Appointment Inactive"}
            </div>
          </Form.Item>
        </Form>
      </Modal>

      {/* <Modal
        title={<div style={{ width: "100%" }}>View Booking Schedule</div>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        width={600}
        style={{ padding: "15px" }}
      >
        <Form layout="vertical" requiredMark={false}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Date"
                name="date"
                rules={[{ required: true, message: "Please select a date" }]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Start Time"
                name="start_time"
                rules={[
                  { required: true, message: "Please select a start time" },
                ]}
              >
                <TimePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="End Time"
                name="end_time"
                rules={[
                  { required: true, message: "Please select an end time" },
                ]}
              >
                <TimePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Allowed Appointments"
                name="allowed_appointments"
                rules={[
                  {
                    required: true,
                    message: "Please enter the number of allowed appointments",
                  },
                ]}
              >
                <Input type="number" placeholder="Enter number" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Checkbox>Appointment Active</Checkbox>
          </Form.Item>
        </Form>
      </Modal> */}

      {/* delete modal */}
      <Modal
        title={<div style={{ textAlign: "center" }}>Confirm</div>}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        style={{
          padding: "20px",
        }}
        width={300}
        footer={
          <div className="flex justify-center gap-3">
            <Button
              type="default"
              onClick={handleCancel}
              style={{ width: "30%" }}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={handleOk} style={{ width: "30%" }}>
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: "center" }}>
          Are you sure you want to booking date this record? Please note it will
          also delete all the details of this booking date.
        </p>
      </Modal>

      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={booking}
        style={{ marginTop: "20px" }}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        loading={loading}
      />
    </div>
  );
}

export default BookSchedule;

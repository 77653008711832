import ajaxService from "./ajax-service";

class QuotationService {
  async fetch(page) {
    const response = await ajaxService.get(`/quotations?page=${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async view(id) {
    const response = await ajaxService.get(`/quotations/${id}`);
    if (response ?? false) {
      return response;
    }
  }
  async addQuotation(data) {
    const response = await ajaxService.post(`/quotations/`, data);
    if (response ?? false) {
      return response;
    }
  }
  async editQuotation(id, data) {
    const response = await ajaxService.put(`/quotations/`, id, data);
    if (response ?? false) {
      return response;
    }
  }
  async deleteQuotation(id) {
    const response = await ajaxService.delete(`/quotations/`, id);
    if (response ?? false) {
      return response;
    }
  }
}

const quotationService = new QuotationService();

export default quotationService;

import React, { useEffect, useState } from "react";
import { Table, Space, Row, Col, Card, Divider, Form, Input, Button } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { loadingClient } from "../../../services";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountries } from "../../../redux/slices/countriesSlice";

const { Meta } = Card;


function ViewLoadingPlan() {

  const { id } = useParams();
  const navigate=useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [viewClient, setViewClient] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const fieldData = [
    { label: "Container ID", value: viewClient[0]?.container_id },
    { label: "Destination", value: viewClient[0]?.destination },
    { label: "Shipping Line", value: "" },
    { label: "ERD Date", value: viewClient[0]?.erd_date },

    { label: "ETD Date", value: viewClient[0]?.etd_date },
    {
      label: "Title Cut Off",
      value: moment(viewClient[0]?.title_cut_off).format("YYYY-MM-DD"),
    },
    {
      label: "Cargo Cut Off ",
      value: moment(viewClient[0]?.cargo_cut_off).format("YYYY-MM-DD"),
    },
    { label: "NO FILE SHARED", value: "Enter country name" },
    { label: "ETA Date", value: viewClient[0]?.eta_date },
    { label: "Location", value: viewClient[0]?.location },
    {
      label: "Booking Type",
      value: viewClient[0]?.is_dg == 1 ? "DG" : "NON-DG",
    },
    { label: "Container Size", value: viewClient[0]?.container_size },
    { label: "Booking Number", value: viewClient[0]?.c_booking_number },
    {
      label: "Auto Cut Off",
      value: moment(viewClient[0]?.auto_cut_off).format("YYYY-MM-DD"),
    },
    {
      label: "Doc Cut Off",
      value: moment(viewClient[0]?.doc_cut_off).format("YYYY-MM-DD"),
    },
    {
      label: "Doc Cut Off",
      value: moment(viewClient[0]?.doc_cut_off).format("YYYY-MM-DD"),
    },
  ];
  const columns = [
    {
      title: "S.no",
      dataIndex: "S.no",
      key: "S.no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Year",
      dataIndex: ["vehicle", "year"],
      key: "Year",
    },
    {
      title: "Make",
      dataIndex: ["vehicle", "product"],
      key: "Make",
    },
    {
      title: "Model",
      dataIndex: ["vehicle", "product"],
      key: "Model",
    },
    {
      title: "Vin #",
      dataIndex: ["vehicle", "chasis_no"],
      key: "Vin ",
    },
    {
      title: "Title",
      dataIndex: ["vehicle", "title"],
      key: "Title",
    },
    {
      title: "Title State",
      dataIndex: ["vehicle", "title_state"],
      key: "TitleState",
    },
    {
      title: "Value",
      dataIndex: ["vehicle", "value"],
      key: "Value",
    },
    {
      title: "Weight",
      dataIndex: ["vehicle", "weight"],
      key: "Weight",
    },

    {
      title: "Image",
      dataIndex: ["vehicle", "images"],
      key: "Image",
      render: (text, record) => (
        <img
          src={record.Image} // URL of the image
          alt="Vehicle"
          style={{
            width: "70px",
            height: "50px",
            objectFit: "contain",
            borderRadius: "4px",
          }}
        />
      ),
    },
  ];

  const initialize = async () => {
    try {
      setLoading(true);

      const response = await dispatch(fetchCountries()).unwrap();
      setCountries(response);

      const { success, data, message } = await loadingClient.View(id);

      if (success) {
        setViewClient([data]);
        setCustomer(data.vehicle?.customer);

        form.setFieldsValue({
          special_note: data.client_loading_note,
          update_note: data.client_loading_update_note,
        });
      } else {
        console.error(`Error: ${message || "Failed to fetch client details."}`);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      <Row gutter={24} wrap={false}>
        {/* First Column */}
        <Col flex="370px">
          <Card style={{ padding: "15px" }}>
            {/* First Section: Name and Email */}
            <Meta title={customer?.name || "N/A"} style={{ textAlign: "center" }} />
            <div style={{ marginTop: "10px", textAlign: "center" }}>
              <p> {customer?.email || "N/A"}</p>
            </div>
            <Divider />
            <div className="profile-info">
              <p>
                <strong>Phone:</strong> {customer?.phone || "N/A"}
              </p>
              <p>
                <strong>Address:</strong> {customer?.address || "N/A"}
              </p>
              <p>
                <strong>Town:</strong> {customer?.town || "N/A"}
              </p>
              <p>
                <strong>City:</strong> {customer?.city || "N/A"}
              </p>
              <p>
                <strong>Country:</strong>
                {countries.length > 0
                  ? countries.find((c) =>
                      c.iso_code.includes(customer?.country)
                    )?.name || "N/A"
                  : "Loading..."}
              </p>
              <p>
                <strong>Secondary Email:</strong>
                {customer?.secondary_email
                  ? customer?.secondary_email
                  : "-----------"}
              </p>
            </div>
            <Divider />
            {/* <div style={{ textAlign: "center" }}>
        <Button type="primary" style={{ marginRight: "10px" }} onClick={() => navigate(`/operation/${id}`)}>

                <EditOutlined style={{ fontSize: "20px", cursor: "pointer" }} />
        </Button>
                {/* <DeleteOutlined
                  style={{ fontSize: "20px", cursor: "pointer" }}
                />
            </div> */}
          </Card>
        </Col>
        {/* Second Column */}
        <Col flex="1">
          <div
            style={{
              padding: "15px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Row gutter={12}>
              <Col span={12}>
                <h1 style={{ fontWeight: "bold" }}>View Details</h1>
              </Col>
            </Row>

            <Table
              columns={columns}
              scroll={{ x: "max-content" }}
              dataSource={viewClient}
              style={{ marginTop: "10px" }}
              loading={loading}
            />
          </div>

          <div
            style={{
              padding: "15px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
              marginTop: "20px",
            }}
          >
            <Row gutter={12}>
              <Col span={12}>
                <h1 style={{ fontWeight: "bold" }}>Load Plan Details</h1>
              </Col>
            </Row>
            <Row gutter={24} className="mt-3">
              {fieldData.map((field, index) => (
                <Col key={field.id || index} span={12}>
                  <div
                    style={{
                      display: "flex",
                      width: 310,
                      border: "1px solid #d9d9d9",
                      borderRadius: 4,
                      marginBottom: 2,
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        padding: "4px 11px",
                        backgroundColor: "#f5f5f5",
                        borderRight: "1px solid #d9d9d9",
                      }}
                    >
                      {field.label}
                    </div>
                    <p
                      style={{
                        flex: 1,
                        margin: 0,
                        padding: "4px 11px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {field.value}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>

          <Form
            requiredMark={false}
            layout="vertical"
            form={form}
            initialValues={{ layout: "vertical" }}
            className="mt-5"
          >
            <Row gutter={24} align="bottom">
              <Col span={12}>
                <Form.Item
                  label={
                    <span style={{ fontWeight: "bold" }}>Special Note:</span>
                  }
                  name="special_note"
                >
                  <Input.TextArea
                    placeholder="Enter special notes here..."
                    rows={5}
                  />
                </Form.Item>
              </Col>

              <Col span={12} style={{ textAlign: "right" }}>
                <Form.Item
                  label={
                    <span style={{ fontWeight: "bold" }}>Updation Note:</span>
                  }
                  name="update_note"
                >
                  <Input.TextArea
                    placeholder="Enter special notes here..."
                    rows={5}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Row gutter={24} className="mb-5">
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  width: 695,
                  border: "1px solid #d9d9d9",
                  borderRadius: 10,
                  marginBottom: 2,
                }}
              >
                <div
                  style={{
                    flex: 0.5,
                    padding: "4px 11px",
                    color: "#b91111",
                    border: "1px solid #b91111",
                    borderRadius: 5,

                    // backgroundColor: "#b91111",
                    borderRight: "1px solid #b91111",
                  }}
                >
                  Booking has been rejected
                </div>
                <p
                  style={{
                    flex: 1.5,
                    margin: 0,
                    padding: "4px 11px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Reason: Container unavailability / wooden floor not available
                  at the port.
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <style>
        {`
  .profile-info {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 15px; /* Space between each <p> element */
  }

  .profile-info p {
    display: flex; /* Makes each <p> a flex container */
     justify-content:space-between;
  }

  .profile-info strong {
    margin-right: 25px; /* Space between <strong> and <p> content */
    width: 100px; /* You can adjust this value to ensure equal space between all labels */
    text-align: left; /* Optional: Align the <strong> to the left */
  }
`}
      </style>
    </>
  );
}

export default ViewLoadingPlan;

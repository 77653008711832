import React, { useState } from "react";
import { Table, Button, Modal, Input, Form } from "antd";
import { EditOutlined, } from "@ant-design/icons";
import { CiSquarePlus } from "react-icons/ci";

const data = [
    {
        id: "1",
        booking_no: "asdasd"
    },
    {
        id: "2",
        booking_no: "asdasd"
    },
    {
        id: "3",
        booking_no: "asdasd"
    }
]
function Capital() {
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModalHandler = () => setIsModalVisible(true);

    // const handleOk = () => {
    //     setIsModalVisible(false);
    //     notification.success({
    //         message: "Data Sent Successfully ",
    //         description: "Your data was send",
    //         duration: 1,
    //     });
    // };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            dataIndex: "id",
            key: "id",
            width: 130,
        },
        {
            dataIndex: "booking_no",
            key: "booking_no",
            width: 900,
            render: (text, record, index) => (
                <Input
                    value={text}
                    placeholder='Select Operating Port '
                    style={{ width: '100%' }} // Make sure input fills the column width]
                />
            ),
        },
        {
            key: "Action",
            render: (text, record) => (
                <Button type="primary" size="small" onClick={showModalHandler}>
                    <EditOutlined />
                </Button>
            ),
        },
    ];

    const rowClassName = (record, index) => {
        return "row-light" // Alternate row colors
    };

    return (
        <div style={{ border: "1px solid #ccc", borderRadius: "2px", backgroundColor: "#FFFFFF" }}>
            <div style={{ borderBottom: "1px solid #E8E8E9", }}>
                <h1 className="mt-2 ml-2 p-2" style={{ fontWeight: "bolder" }}>
                    Chart Of Accounts
                </h1>
            </div>
            <div style={{ margin: "0px 15px", padding: "8px", borderBottom: "1px solid #E8E8E9" }} className="flex justify-end">
                <CiSquarePlus size={30} style={{ cursor: "pointer" }}
                    onClick={showModalHandler}
                />
            </div>
            <div style={{ padding: 10 }}>
                <Table columns={columns} dataSource={data} pagination={false} showHeader={false} rowKey="id" rowClassName={rowClassName} />
            </div>

            <Modal
                title={<div style={{ textAlign: "", width: "100%" }}> Capital </div>}
                width={450}
                styles={{
                    body: {
                        padding: "10px",
                    }
                }}
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}>

                <Form layout="vertical" requiredMark={false}
                    form={form}>
                    <Form.Item label="Code" name="Code" rules={[{ required: true, message: "Please enter a size" }]}>
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item label="Name" name="Name" rules={[{ required: true, message: "Please enter a size" }]}>
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item label="Type" name="Type" rules={[{ required: true, message: "Please enter a size" }]}>
                        <Input placeholder="" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: "100%" }} >
                            Add Account
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <style>
                {  `
    .row-light {
    background-color: #FBFBFB; /* Light background color */
} `}
            </style>
        </div>
    );
}

export default Capital;

import {
  Table,
  Space,
  Button,
  Row,
  Col,
  Input,
  Select,
  Modal,
  notification,
} from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { CiSquarePlus } from "react-icons/ci";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { openingBalanceService } from "../../../../services";

function OpeningBalances() {
  const [loading, setLoading] = useState(false);
  const [balances, setBalances] = useState([]);
  const [isModalshow, setIsModalShow] = useState(false);
  const [selectedDeleteRecord, setSelectedDeleteRecord] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });

  const showModal = (record) => {
    setSelectedDeleteRecord(record);
    setIsModalShow(true);
  };
  const handleCancel = () => {
    setIsModalShow(false);
    setSelectedDeleteRecord(null);
  };

  const handleDelete = async () => {
    setLoading(true);
    if (!selectedDeleteRecord || !selectedDeleteRecord.id) {
      console.error("No entry selected for deletion.");
      return;
    }
    const response = await openingBalanceService.deleteBalance(
      selectedDeleteRecord.id
    );

    if (response.success) {
      setBalances((prevData) =>
        prevData.filter((item) => item.id !== selectedDeleteRecord.id)
      );
      initialize(pagination.current, pagination.pageSize);
    } else {
      console.error("Failed to delete the customer.");
    }
    setLoading(false);
    setIsModalShow(false);
  };
  const options = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "9",
      label: "9",
    },
  ];
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      //   render: (text, record, index) => index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Types",
      dataIndex: "types",
      key: "types",
      render: (text) => text?.join(","),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (createdAt) => {
        const date = new Date(createdAt);

        const formattedDate = date.toISOString().slice(0, 10);
        const formattedTime = date.toTimeString().slice(0, 8);

        return `${formattedDate} ${formattedTime}`;
      },
    },
    {
      title: "Action",
      key: "Action",
      render: (text, record, { _id }) => (
        <Space size="small">
          <Link to={`/accounts/opening-balances/edit/${record.id}`}>
            <Button type="primary" size="small">
              <EditOutlined />
            </Button>
          </Link>
          <Button type="primary" size="small" onClick={() => showModal(record)}>
            <DeleteOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    const { success, data } = await openingBalanceService.fetch(page);
    if (success ?? false) {
      setBalances(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    } else {
      notification.error({
        message: "Error fetching data!",
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);

  return (
    <div
      style={{
        padding: "15px",
        paddingTop: 8,
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row
        gutter={24}
        style={{
          marginBottom: 8,
          padding: "5px",
          borderRadius: "2px",
          borderBottom: "1px solid #D9D9D9",
        }}
      >
        <Col span={24}>
          <h1 style={{ fontWeight: "bold" }}>Opening Balances</h1>
        </Col>
      </Row>

      <Col
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Link to={`/accounts/opening-balances/edit`}>
          <CiSquarePlus size={30} style={{ cursor: "pointer" }} />
        </Link>
      </Col>
      <Row
        gutter={14}
        style={{
          marginTop: "15px",
          background: "#F2F2F7",
          padding: "10px",
          borderRadius: "4px",
        }}
        className="flex justify-between"
      >
        <Col span={10}>
          <Space direction="horizontal" size={15}>
            <p>Show</p>
            <Select
              defaultValue="10"
              options={options}
              size="small"
              style={{
                width: 60,
                height: 25,
                fontSize: 14,
              }}
            />
            <p>entries</p>
          </Space>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
          />
        </Col>
      </Row>

      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        dataSource={balances}
        loading={loading}
        style={{ marginTop: "10px" }}
      />

      <Modal
        title={<div style={{ textAlign: "center" }}>Confirm</div>}
        open={isModalshow}
        onOk={handleDelete}
        onCancel={handleCancel}
        centered
        styles={{
          body: {
            padding: "20px",
          },
        }}
        width={300}
        footer={
          <div className="flex justify-center gap-3">
            <Button
              type="default"
              onClick={handleCancel}
              style={{ width: "30%" }}
              loading={loading}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleDelete}
              style={{ width: "30%" }}
              loading={loading}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <p style={{ textAlign: "center" }}>
          Are you sure you want to delete this?
        </p>
      </Modal>
    </div>
  );
}

export default OpeningBalances;

import React from "react";
import { Modal, Table, Form, Input, Button, Space } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";

const EditModal = ({
  isModalVisible,
  handleOk,
  handleCancel,
  invoice,
  columns,
  onFinish,
  form,
}) => {
  return (
    <Modal
      title={
        <div style={{ width: "100%" }}>
          DETAILS
          <div
            style={{
              borderTop: "1px solid #ccc",
              marginTop: "15px",
              marginBottom: "12px",
            }}
          ></div>
        </div>
      }
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={true}
      width={750}
      style={{ padding: "10px" }}
    >
      <div
        style={{
          padding: "10px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Table
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={invoice?.invoice?.vehicles}
          pagination={false}
        />
        <Form
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          form={form}
          layout="vertical"
          style={{
            paddingTop: "20px",
          }}
          autoComplete="off"
        >
          <Form.List name="charges">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 8,
                    }}
                    size={12}
                  >
                    <Form.Item
                      {...restField}
                      label="Extra Charges"
                      name={[name, "first"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing description",
                        },
                      ]}
                    >
                      <Input placeholder="Description" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label="Charges"
                      name={[name, "last"]}
                      rules={[
                        {
                          required: true,
                          message: "Missing Charges",
                        },
                      ]}
                    >
                      <Input placeholder="Charges" />
                    </Form.Item>
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      style={{ marginTop: "10px" }}
                    />
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()}>
                    Add Extra Charges
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item
            label="Discount"
            name="discount"
            rules={[
              {
                required: true,
                message: "Missing last name",
              },
            ]}
          >
            <Input placeholder="" style={{ width: "50%" }} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default EditModal;

import React from 'react';
import { Col, Form, Input, Row, DatePicker, Table, Space } from 'antd';
import { Select } from 'antd';
import { SearchOutlined, SendOutlined } from '@ant-design/icons'

function CustomerLedger() {
    const [form] = Form.useForm();
    const columns = [
        {
            title: "#",
            dataIndex: '#',
            key: '#',
            render: (text, record, index) => index + 1,
        },
        {
            title: "STATUS",
            dataIndex: 'STATUS',
            key: 'STATUS',

        },
        {
            title: "INVOICE DATE",
            dataIndex: "INVOICE_DATE",
            key: "INVOICE_DATE",

        },
        {
            title: "INVOICE NUMBER",
            dataIndex: "INVOICE_NUMBER",
            key: "INVOICE_NUMBER",

        },
        {
            title: "RECEIPT NUMBER",
            dataIndex: "RECEIPT_NUMBER",
            key: "RECEIPT_NUMBER",

        }, {
            title: "CREDIT NUMBER",
            dataIndex: "CREDIT_NUMBER",
            key: "CREDIT_NUMBER",


        }, {
            title: "DESCRIPTION",
            dataIndex: "DESCRIPTION",
            key: "DESCRIPTION",

        },
        {
            title: "INVOICE AMOUNT",
            dataIndex: "INVOICE_AMOUNT",
            key: "INVOICE_AMOUNT",

        },
        {
            title: "CREDIT AMOUNT",
            dataIndex: "CREDIT_AMOUNT",
            key: "CREDIT_AMOUNT",

        },
        {
            title: "COLLECTED AMOUNT",
            dataIndex: "COLLECTED_AMOUNT",
            key: "COLLECTED_AMOUNT",

        },
        {
            title: "BALANCE",
            dataIndex: "BALANCE",
            key: "BALANCE",

        },
    ]

    const data = [
        {
            STATUS: "Opened",
            BALANCE: "900",
            COLLECTED_AMOUNT: "-",
            CREDIT_AMOUNT: "-",
            INVOICE_AMOUNT: "$900",
            DESCRIPTION: "TCKU7171069",
            CREDIT_NUMBER: "-",
            RECEIPT_NUMBER: "-",
            INVOICE_NUMBER: "INVOICE # 5508",
            INVOICE_DATE: "21 Oct, 2024",
        },
        {
            STATUS: "Opened",
            BALANCE: "900",
            COLLECTED_AMOUNT: "-",
            CREDIT_AMOUNT: "-",
            INVOICE_AMOUNT: "$900",
            DESCRIPTION: "TCKU7171069",
            CREDIT_NUMBER: "-",
            RECEIPT_NUMBER: "-",
            INVOICE_NUMBER: "INVOICE # 5508",
            INVOICE_DATE: "21 Oct, 2024",
        },
        {
            STATUS: "Opened",
            BALANCE: "900",
            COLLECTED_AMOUNT: "-",
            CREDIT_AMOUNT: "-",
            INVOICE_AMOUNT: "$900",
            DESCRIPTION: "TCKU7171069",
            CREDIT_NUMBER: "-",
            RECEIPT_NUMBER: "-",
            INVOICE_NUMBER: "INVOICE # 5508",
            INVOICE_DATE: "21 Oct, 2024",
        },
        {
            STATUS: "Opened",
            BALANCE: "900",
            COLLECTED_AMOUNT: "-",
            CREDIT_AMOUNT: "-",
            INVOICE_AMOUNT: "$900",
            DESCRIPTION: "TCKU7171069",
            CREDIT_NUMBER: "-",
            RECEIPT_NUMBER: "-",
            INVOICE_NUMBER: "INVOICE # 5508",
            INVOICE_DATE: "21 Oct, 2024",
        }
    ]

    return (
        <div
            style={{
                padding: '0 14px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                backgroundColor: '#FFFFFF',

            }} >

            <Form
                requiredMark={false}
                layout="vertical"
                form={form} >
                <Row gutter={24} className='mt-8'>

                    <Col span={6}>
                        <Form.Item label="From date">
                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="To date">
                            <DatePicker style={{ width: '100%' }} placeholder="Select Date" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Status"
                            name="Status"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input!',
                                },
                            ]}
                        >
                            <Select placeholder="No Customer" />
                        </Form.Item>
                    </Col>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src="/images/search.png" alt="" width={32} />
                    </div>
                </Row>
            </Form>

            <Row
                gutter={0}
                style={{
                    marginTop: 20,
                    padding: '10px',
                    borderRadius: '4px',
                    border: '1px solid #E8E8E9',
                }}
            >
                <Col span={24}>
                    <h1 style={{ fontWeight: 'bold' }}>Statement Of Account</h1>
                </Col>
            </Row>

            <Col span={24}>
                <Row gutter={0} style={{ padding: '5px', marginTop: '2px', background: "#F2F2F7", borderRadius: '4px' }} className='flex justify-between'>
                    <Col span={10}  >         
                            <img src="/images/ve4.png" alt="" />
                    </Col>
                    <Col span={14} style={{ textAlign: "right" }} >
                        <Input
                            placeholder="Search"
                            prefix={<SearchOutlined />}
                            style={{ width: 200 }}
                        />
                    </Col>
                </Row>
                <div>

                    <Table
                        columns={columns}
                        pagination={true}
                        scroll={{ x: "max-content" }}
                        dataSource={data}
                        rowKey="Id"
                        style={{ marginTop: '5px' }}
                        footer={()=>(
                            <div>
                            <div className="flex justify-end gap-20 mt-5 mb-5" >
                            <p>TOTAL:</p>
                            <p>$4570</p>
                            <p>$0</p>
                            <p>$0</p>
                            <p>$4570</p>
                        </div>
                        <div className="flex justify-between mt-5 mb-5" style={{ paddingLeft: "30%" }}>
                            <p>NET BALANCE:</p>
                            <p>$4570</p>
                        </div>
                         </div>
                        )}
                    />
                   
                </div >
            </Col>

        </div >
    );
}

export default CustomerLedger;

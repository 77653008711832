import ajaxService from "./ajax-service";

class LedgerService {
  async fetch(data) {
    const response = await ajaxService.post(`/ledger`, data);
    if (response ?? false) {
      return response;
    }
  }
}

const ledgerService = new LedgerService();

export default ledgerService;

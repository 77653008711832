import React, { useEffect, useState } from "react";
import { Button, Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { FolderOpenOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { MdOutlineDashboard } from "react-icons/md";
import { FiUsers } from "react-icons/fi";
import { TbUsersGroup } from "react-icons/tb";
import { PiUsersThreeLight } from "react-icons/pi";
import { TbTruckDelivery } from "react-icons/tb";
import { RiFile2Line } from "react-icons/ri";
import { MdOutlineSettings } from "react-icons/md";
import { RxDotFilled } from "react-icons/rx";
import { ROLES } from "../constants";

const { Sider } = Layout;

const usersChild1 = [
  {
    label: "Operation Dashboard",
    key: "Operation Dashboard",
    link: "",
    icon: <MdOutlineDashboard />,
  },
  {
    label: "Management Dashboard",
    key: "Management Dashboard",
    link: "",
    icon: <MdOutlineDashboard />,
  },
  {
    label: "Finance Dashboard",
    key: "Finance Dashboard",
    link: "",
    icon: <MdOutlineDashboard />,
  },
  {
    label: "Back Office Dashboard",
    key: "Back Office Dashboard",
    link: "",
    icon: <MdOutlineDashboard />,
  },
  {
    label: "Yard Operations Dashboard",
    key: "Yard Operations Dashboard",
    link: "",
    icon: <MdOutlineDashboard />,
  },
];

const usersChild = [
  {
    label: "Vehicles",
    key: "Vehicles",
    link: "/operation/vehicles",
    icon: <TbTruckDelivery />,
  },
  {
    label: "Customers",
    key: "Customers",
    link: "/operation/customers",
    icon: <PiUsersThreeLight />,
  },
  {
    label: "Vendors",
    key: "Vendors",
    link: "/operation/vendors",
    icon: <TbUsersGroup />,
  },
  {
    label: "Loading Plan",
    key: "Loading Plan",
    link: "/operation/loadings",
    icon: <RiFile2Line />,
  },
  {
    label: "Towing Tariff",
    key: "Towing Tariff",
    link: "/operation/tariffs",
    icon: <RiFile2Line />,
  },

  {
    label: "BL Form/Doc Receipt",
    key: "BL Form/Doc Receipt",
    link: "/operation/bl-forms",
    icon: <RiFile2Line />,
  },
  {
    label: "Quotations",
    key: "Quotations",
    link: "/operation/quotations",
    icon: <RiFile2Line />,
  },
  {
    label: "Appoinment",
    key: "Appoinment",
    link: "/operation/appoinments",
    icon: <RiFile2Line />,
  },
  {
    label: "Inventory Report",
    key: "Inventory Report",
    link: "/operation/inventoryreport",
    icon: <RiFile2Line />,
  },
  {
    label: "Schedule Booking",
    key: "Schedule Booking",
    link: "/operation/books",
    icon: <RiFile2Line />,
  },
];

const usersChild2 = [
  {
    label: "PNL",
    key: "PNL",
    link: "/accounts/PNL",
    icon: <RiFile2Line />,
  },
  {
    label: "Ledger",
    key: "Ledger",
    link: "/accounts/ledger",
    icon: <RiFile2Line />,
  },
  {
    label: "Invoices",
    key: "Invoices",
    link: "/invoices",
    icon: <RiFile2Line />,
  },
  {
    label: "Reports",
    key: "Reports",
    link: "/accounts/reports",
    icon: <RiFile2Line />,
  },
];

const usersChild3 = [
  {
    label: "Employees",
    key: "Employees",
    link: "/administrations/employees",
    icon: <PiUsersThreeLight />,
  },
  {
    label: "Settings",
    key: "Settings",
    link: "/administrations/settings",
    icon: <MdOutlineSettings />,
  },
];

const additionalFeatures = [
  {
    label: "Browse Transaction",
    key: "BrowseTransaction",
    link: "/accounts/browse-transcation",
    icon: <RxDotFilled />,
  },
  {
    label: "Vendor Payments List",
    key: "VendorPaymentsList",
    link: "/accounts/vendor-payment-lists",
    icon: <RxDotFilled />,
  },
];

const additionalFeatures2 = [
  {
    label: "Expense Voucher",
    key: "Expense Voucher",
    link: "/accounts/expense-vouchers",
    icon: <RxDotFilled />,
  },
  {
    label: "Payment Voucher",
    key: "Payment Voucher",
    link: "/accounts/payment-vouchers",
    icon: <RxDotFilled />,
  },
  {
    label: "Credit Note",
    key: "Credit Note",
    link: "/accounts/credit-notes",
    icon: <RxDotFilled />,
  },
  {
    label: "Debit Note",
    key: "Debit Note",
    link: "/accounts/debit-notes",
    icon: <RxDotFilled />,
  },
  {
    label: "Invoice Receipt",
    key: "Invoice Receipt",
    link: "/accounts/receipt-collects",
    icon: <RxDotFilled />,
  },
  {
    label: "Costing Expense",
    key: "Costing Expense",
    link: "/accounts/coastings",
    icon: <RxDotFilled />,
  },
  {
    label: "Customer Balance",
    key: "Customer Balance",
    link: "/accounts/customer-ledgers",
    icon: <RxDotFilled />,
  },
  {
    label: "Vendors Containers Payments",
    key: "Vendors Containers Payments",
    link: "/accounts/vendor-container-payments",
    icon: <RxDotFilled />,
  },
];

const adminSidebar = [
  {
    label: (
      <Link to="/" style={{ textDecoration: "none", color: "bg-primary" }}>
        Dashboard
      </Link>
    ),
    key: "/dashboard",
    icon: <MdOutlineDashboard />,
    link: "/",
    // children: usersChild1,
  },
  {
    label: (
      <Link to="" style={{ textDecoration: "none", color: "bg-primary" }}>
        Operations
      </Link>
    ),
    key: "Operations",
    icon: <FiUsers />,
    children: usersChild,
  },
  {
    label: (
      <Link to="/accounts" style={{ textDecoration: "none", color: "inherit" }}>
        Accounts
      </Link>
    ),
    key: "accounts",
    link: "/accounts",
    icon: <FolderOpenOutlined />,
    children: [
      ...usersChild2,
      { type: "divider" },
      ...additionalFeatures,
      { type: "divider" },
      ...additionalFeatures2,
    ],
    // link: '/accounts',
  },
  {
    label: (
      <Link to="" style={{ textDecoration: "none", color: "bg-primary" }}>
        Administrations
      </Link>
    ),
    key: 'Administrations',
    icon: <FolderOpenOutlined />,
    link: "",
    children: usersChild3,
  },
];

const customerItems = [
  {
    label: (
      <Link
        to="/"
        style={{ textDecoration: "none", color: "bg-primary" }}
      >
        Dashboard
      </Link>
    ),
    key: "dashboard",
    icon: <MdOutlineDashboard />,
    link: "/",
  },
  {
    label: (
      <Link
        to="/operation/vehicles"
        style={{ textDecoration: "none", color: "bg-primary" }}
      >
        Vehicles
      </Link>
    ),
    key: "Vehicles",
    link: "/operation/vehicles",
    icon: <TbTruckDelivery />,
  },
  {
    label: (
      <Link
        to="/customers-portal/customer-invoices"
        style={{ textDecoration: "none", color: "bg-primary" }}
      >
        Invoices
      </Link>
    ),
    key: "Invoices",
    link: "/customers-portal/customer-invoices",
    icon: <RiFile2Line />,
  },
  {
    label: (
      <Link
        to={"/accounts/ledger"}
        style={{ textDecoration: "none", color: "bg-primary" }}
      >
        Ledger
      </Link>
    ),
    key: "Ledger",
    link: "/accounts/ledger",
    icon: <RiFile2Line />,
  },
  {
    label: (
      <Link
        to={"/customers-portal/create-load-plans"}
        style={{ textDecoration: "none", color: "bg-primary" }}
      >
        Loading Plan
      </Link>
    ),
    key: "Loading Plan",
    link: "/customers-portal/create-load-plans",
    icon: <RiFile2Line />,
  },
  {
    label: (
      <Link
        to={"/operation/appoinments"}
        style={{ textDecoration: "none", color: "bg-primary" }}
      >
        Appointment
      </Link>
    ),
    key: "Appoinment",
    link: "/operation/appoinments",
    icon: <RiFile2Line />,
  },
];

const Sidebar = ({ collapsed, setCollapsed }) => {

  const location = useLocation();
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const role = localStorage.getItem("role");
  let items = role === ROLES.ADMIN ? adminSidebar : role === ROLES.CUSTOMER ? customerItems : [];

  useEffect(() => {
    const currentPath = location.pathname;
    // Extract the base route for matching (e.g., "/operation/vehicles" from "/operation/vehicles/add")
    const baseRoute = currentPath.split('/').slice(0, 3).join('/');
    // Find the parent menu
    const parentMenu = items.find((item) =>
      item.children?.some((child) => child.link === baseRoute)
    );
    if (parentMenu) {
      // Set the parent menu as open
      setOpenKeys([parentMenu.key]);
      // Set the child menu as selected
      setSelectedKeys([baseRoute]);
    } else {
      // Fallback for unmatched routes
      setOpenKeys([]);
      setSelectedKeys([currentPath]);
    }
  }, [location, items]);

  const handleOpenChange = (keys) => {
    // Only keep the last opened key (close others)
    const latestKey = keys[keys.length - 1];
    setOpenKeys(latestKey ? [latestKey] : []);
  };

  return (
    <Sider
      collapsible collapsed={collapsed} onCollapse={setCollapsed}
      trigger={
        <Button
          type="primary"
          icon={collapsed ? <RightOutlined /> : <LeftOutlined />}
          onClick={() => setCollapsed(!collapsed)}
          style={{

            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "48px",
            backgroundColor: "#B83D3D",
            color: "#fff",
            borderRadius: 0,
          }}
        />
      }
      width={250}
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        bottom: 50,
        background: "#FFFFFF",
        borderRight: "1px solid #E8E8E9",
      }}
      className="sidebar-scrollable"
    >

      <div style={{marginTop:"100px",marginBottom:"20px",display: "flex", justifyContent: "center", alignItems: "center", }}>
        {collapsed ? <img
          src="/images/Fav.png"
          alt="ctrade-Logo"
          className="text-center"
          style={{width: "40px", height: "36px", transition: "all 0.5s ease-in-out", }}

        />
          : <img
            src="/images/ctrade-logo.png"
            alt="ctrade-Logo"
            className="text-center"
            style={{ width: "89px", height: "49px", transition: "all 0.1s ease-in-out", }}
          />}
      </div>

      <Menu
        mode="inline"
        className="sidebar-menu"
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        onOpenChange={handleOpenChange}
        style={{ padding: 0 }}
      >
        {items.map((item) =>
          item.children ? (
            <Menu.SubMenu
              key={item.key}
              icon={
                <Link
                  to={`/${item.key}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                  onClick={(e) => e.stopPropagation()} // Prevent toggling submenu when clicking the icon
                >
                  {item.icon}
                </Link>
              }
              title={
                <Link
                  to={`/${item.key}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                  onClick={(e) => e.stopPropagation()} // Prevent toggling submenu
                >
                  {item.label}
                </Link>
              }
            >
              {item.children.map((subItem, subIndex) =>
                subItem.type === "divider" ? (
                  <Menu.Divider
                    key={`divider-${subIndex}`}
                    style={{
                      margin: 7,
                      borderWidth: "0.1px",
                      borderColor: "#ccc",
                    }}
                  />
                ) : (
                  <Menu.Item key={subItem.link} icon={subItem.icon}>
                    <Link to={subItem.link}>{subItem.label}</Link>
                  </Menu.Item>
                )
              )}
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={item.key} icon={item.icon}>
              <Link to={item.key}>{item.label}</Link>
            </Menu.Item>
          )
        )}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
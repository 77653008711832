import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Table,
} from "antd";
import { CiSquarePlus } from "react-icons/ci";
import { DeleteOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { acc_types } from "../../../../constants";
import { openingBalanceService } from "../../../../services";
import { v4 as uuidv4 } from "uuid";

export default function EditOpeningBalances() {
  const { id } = useParams();
  const isEditMode = id !== undefined;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [openBal, setOpenBal] = useState([]);

  const handleChange = (value, record) => {
    const updatedBalance = [...balance];
    const rowIndex = updatedBalance.findIndex((row) => row.id === record.id);
    if (rowIndex !== -1) {
      updatedBalance[rowIndex].filteredAccounts = accounts.filter(
        (acc) => acc.level === 3 && acc.type === value
      );
      setBalance(updatedBalance);
    }
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Account Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => (
        <Select
          defaultValue={text}
          placeholder="Select Type"
          style={{ width: "100%" }}
          onChange={(value) => {
            handleChange(value, record);
            setBalance((prevBalance) =>
              prevBalance.map((row) =>
                row.id === record.id
                  ? {
                      ...row,
                      type: value,
                      filteredAccounts: accounts.filter(
                        (acc) => acc.level === 3 && acc.type === value
                      ),
                    }
                  : row
              )
            );
          }}
        >
          {acc_types.map((type, index) => (
            <Select.Option key={index} value={type}>
              {type}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Lvl 3 Account (entry level)",
      dataIndex: "chart_of_account_id",
      key: "chart_of_account_id",
      render: (text, record) => {
        const account = accounts.find(
          (acc) => acc.level === 3 && acc.id === text
        );
        const filteredAccounts = record.filteredAccounts || [];
        return (
          <Select
            defaultValue={account ? account.name : undefined}
            style={{ width: "100%" }}
            onChange={(value) => {
              setBalance((prevBalance) =>
                prevBalance.map((row) =>
                  row.id === record.id
                    ? { ...row, chart_of_account_id: value }
                    : row
                )
              );
            }}
          >
            {filteredAccounts?.map((facc) => (
              <Select.Option key={facc.id} value={facc.id}>
                {facc.name}
              </Select.Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      render: (text, record) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) => {
            setBalance((prevBalance) =>
              prevBalance.map((row) =>
                row.id === record.id
                  ? { ...row, ["debit"]: parseInt(e.target.value) }
                  : row
              )
            );
          }}
        />
      ),
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      render: (text, record) => (
        <Input
          defaultValue={text}
          style={{ width: "100%" }}
          onChange={(e) => {
            setBalance((prevBalance) =>
              prevBalance.map((row) =>
                row.id === record.id
                  ? { ...row, ["credit"]: parseInt(e.target.value) }
                  : row
              )
            );
          }}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record, index) => {
        if (index === 0) return null;
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleDeleteRow(record.id)}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];

  const handleAddRow = () => {
    const newRow = {
      id: balance.length + 1,
      type: "",
      chart_of_account_id: "",
      debit: "",
      credit: "",
      filteredAccounts: [],
    };
    setBalance([...balance, newRow]);
  };

  const handleDeleteRow = (id) => {
    const updatedBalance = balance.filter((row) => row.id !== id);
    setBalance(updatedBalance);
  };

  const initialize = async () => {
    setLoading(true);
    if (isEditMode) {
      const { success, data } = await openingBalanceService.viewBalance(id);
      const res = await getChartAccounts();
      if (success) {
        const updatedBalance = data.data.map((row) => ({
          ...row,
          id: uuidv4(),
          filteredAccounts: res.filter(
            (acc) => acc.level === 3 && acc.type === row.type
          ),
        }));
        setBalance(updatedBalance);
        form.setFieldsValue({
          title: data.opening_balance.title,
        });
      }
    } else if (!isEditMode) {
      handleAddRow();
    }
    setLoading(false);
  };
  const getChartAccounts = async () => {
    const response = await openingBalanceService.ChartAccounts();
    if (response.success) {
      setAccounts(response.data);
    }
    return response.data;
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (isEditMode) {
      const payload = {
        title: form.getFieldsValue("title").title,
        data: {
          account_type: balance.map((bal) => bal.type),
          account_id: balance.map((bal) => bal.chart_of_account_id),
          debit: balance.map((bal) => bal.debit),
          credit: balance.map((bal) => bal.credit),
        },
      };
      const response = await openingBalanceService.updateBalance(id, payload);
      if (response.success) {
        notification.success({
          message: "Updated Successfully!",
          description: response.message,
          placement: "topRight",
        });
      }
    } else {
      const payload = {
        title: form.getFieldsValue("title").title,
        data: {
          account_type: balance.map((bal) => bal.type),
          account_id: balance.map((bal) => bal.chart_of_account_id),
          debit: balance.map((bal) => bal.debit),
          credit: balance.map((bal) => bal.credit),
        },
      };
      const response = await openingBalanceService.addBalance(payload);
      if (response.success) {
        notification.success({
          message: "Added Successfully!",
          description: response.message,
          placement: "topRight",
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize();
    getChartAccounts();
  }, []);
  return (
    <div
      style={{
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Form
        requiredMark={false}
        form={form}
        layout="vertical"
        initialValues={{ layout: "vertical" }}
      >
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: 20, marginTop: 10 }}
        >
          <Col>
            <h1 style={{ fontWeight: "bold" }} className="ml-2 mb-2">
              {isEditMode ? "Edit Opening Balance" : "Add Opening Balance"}
            </h1>
          </Col>
          <Col>
            <Form.Item label="Title:" name="title">
              <Input
                placeholder="Enter Title"
                style={{
                  width: "300px",
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Table
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={balance}
          pagination={false}
          rowKey="Id"
          style={{ marginTop: "10px" }}
          loading={loading}
        />
        <div
          style={{ marginTop: 20 }}
          className="flex flex-col justify-center items-center"
        >
          <CiSquarePlus
            style={{ cursor: "pointer", fontSize: "25px" }}
            onClick={handleAddRow}
          />
        </div>
        <Row>
          <Col
            span={23}
            style={{ margin: "20px 0 20px 0", textAlign: "right" }}
          >
            <Button
              type="primary"
              style={{ width: "20%" }}
              onClick={handleSubmit}
              loading={loading}
            >
              {isEditMode ? "Update Balance" : "Add Balance"}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

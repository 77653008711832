import ajaxService from "./ajax-service";
import { validationErrors } from "../utils";

class DashboradService {

    async fetchDashbord() {
        const response = await ajaxService.get(`/dashboard`);
        if (response ?? false) {
            return response;
        }
    }
    async vehicleYard(page) {
        const response = await ajaxService.get(`/dashboard/vehicles-at-yard?page=${page}`);
        if (response ?? false) {
            return response;
        }
    }
    async noTitleYard(page) {
        const response = await ajaxService.get(`/dashboard/no-title?page=${page}`);
        if (response ?? false) {
            return response;
        }
    }
    async Invoicedue(page) {
        const response = await ajaxService.get(`/dashboard/due-invoices?page=${page}`);
        if (response ?? false) {
            return response;
        }
    }
    async invoiceOverDue(page) {
        const response = await ajaxService.get(`/dashboard/over-due-invoices?page=${page}`);
        if (response ?? false) {
            return response;
        }
    }
    async customerDue(page) {
        const response = await ajaxService.get(`/dashboard/users-due?page=${page}`);
        if (response ?? false) {
            return response;
        }
    }
    async customerOverDue(page) {
        const response = await ajaxService.get(`/dashboard/users-over-due?page=${page}`);
        if (response ?? false) {
            return response;
        }
    }
}

const dashboardService = new DashboradService();
export default dashboardService;

import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Select,
  Input,
  Form,
  Checkbox,
  Upload,
  DatePicker,
  notification,
} from "antd";
import {
  awsService,
  containerService,
  destinationService,
  dropdownService,
  vehicalService,
} from "../../../services";
import { useParams } from "react-router-dom";
import moment from "moment";
import ImageUploader from "../../../components/ImageUploader";
import { VEHICLES } from "../../../constants";
// import { DeleteOutlined } from "@ant-design/icons";

const enginetypes = [
  {
    id: "1",
    name: "Gasoline",
  },
  {
    id: "2",
    name: "Hybrid",
  },
  {
    id: "3",
    name: "Ev",
  },
];

const BOS = [
  {
    id: "1",
    name: "Auction",
  },
  {
    id: "2",
    name: "Notarized",
  },
  {
    id: "3",
    name: "Non-Notarized",
  },
];

function VehicleEdit() {
  const { id } = useParams();
  const [form] = Form.useForm();
  // const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [containerSize, setContainerSize] = useState([]);
  const [towing, setTowing] = useState([]);
  const [vehicleType, setVehicleType] = useState([]);

  const [fileList, setFileList] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);

  const handleChange = (info) => {
    let updatedFileList = [...info.fileList];

    // CHANGES HIGHLIGHTED: Handle removed files
    if (info.file.status === "removed") {
      const removedFile = info.file;

      if (removedFile.url) {
        setRemovedImages((prev) => [...prev, removedFile.url]);
      }
    }

    setFileList(updatedFileList);
  };

  const onFinish = async (values) => {
    setLoading(true);
    let images = [];

    if (fileList.length > 0) {
      const newFiles = fileList.filter((file) => file.originFileObj);

      if (newFiles.length > 0) {
        images = await awsService.uploadS3Bucket(
          newFiles,
          vehicle.username,
          values.chasis_no,
          VEHICLES
        );
      }

      const existingImages = fileList
        .filter((file) => file.url && !removedImages.includes(file.url)) // CHANGES HIGHLIGHTED: Exclude removed images
        .map((file) => file.url);

      images = [...images, ...existingImages];

      // CHANGES HIGHLIGHTED: Delete removed images from AWS S3
      if (removedImages.length > 0) {
        await awsService.deleteFromS3Bucket(removedImages);
      }
    }

    const data = {
      ...values,
      images,
      date_rcd: values.date_rcd ? values.date_rcd.format("YYYY-MM-DD") : null,
      arrival_date: values.arrival_date
        ? values.arrival_date.format("YYYY-MM-DD")
        : null,
    };

    const response = await vehicalService.updateVehicle(id, data);
    setLoading(false);

    if (response.success) {
      notification.success({
        message: "Updated Vehicle Successfully",
        description: response.message,
        placement: "topRight",
      });
      setRemovedImages([]);
      loadData();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const getDestination = async () => {
    const { success, data } = await destinationService.fetchDestination();
    if (success) {
      setDestinations(data);
    }
  };

  const getContainerSize = async () => {
    const { success, data } = await containerService.fetchsizes();
    if (success) {
      setContainerSize(data);
    }
  };

  const getVehicleType = async () => {
    const { success, data } = await dropdownService.VehicleTypes();
    if (success) {
      setVehicleType(data);
    }
  };

  const getTowingLocations = async () => {
    const { success, data } = await dropdownService.towingLocation();
    if (success) {
      setTowing(data);
    }
  };

  const loadData = async () => {
    setLoading(true);
    const { success, data } = await vehicalService.view(id);
    if (success) {
      setVehicle(data.vehicle);

      if (data.images) {
        const fileList = data.images.map((image) => ({
          uid: image.id.toString(),
          name: image.filename.substring(image.filename.lastIndexOf("/") + 1),
          status: "done",
          url: image.filename,
        }));

        setFileList(fileList);
      }

      form.setFieldsValue({
        ...data.vehicle,
        date_rcd: moment(data.vehicle.date_rcd),
        arrival_date: data.vehicle.arrival_date
          ? moment(data.vehicle.arrival_date)
          : null,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
    getDestination();
    getContainerSize();
    getVehicleType();
    getTowingLocations();
  }, []);

  // useEffect(() => {
  //   const fetchedImages = [
  //     { id: 1, url: "/images/image5.png" },
  //     { id: 2, url: "/images/image5.png" },
  //     { id: 3, url: "/images/image5.png" },
  //     { id: 4, url: "/images/image5.png" },
  //     { id: 5, url: "/images/image5.png" },
  //     { id: 6, url: "/images/image5.png" },
  //     { id: 7, url: "/images/image5.png" },
  //     { id: 8, url: "/images/image5.png" },
  //     { id: 9, url: "/images/image5.png" },
  //     { id: 10, url: "/images/image5.png" },
  //     { id: 11, url: "/images/image5.png" },
  //     { id: 12, url: "/images/image5.png" },
  //     { id: 13, url: "/images/image5.png" },
  //     { id: 14, url: "/images/image5.png" },
  //   ];
  //   setImages(fetchedImages);
  // }, []);

  // const removeImage = (imageId) => {
  //   setImages(images.filter((image) => image.id !== imageId));
  // };

  return (
    <>
      <Row gutter={22} wrap={false}>
        <Col flex="280px">
          <div
            style={{
              paddingTop: "8px",
              paddingRight: "8px",
              paddingLeft: 8,
              // border: "1px solid #ccc",
              // borderRadius: 5,
              // backgroundColor: "#FFFFFF",
            }}
          >
            <Row gutter={16} justify="center">
              <ImageUploader
                fileList={fileList}
                beforeUpload={true}
                handleChange={handleChange}
                length={20}
              />

              {/* {images.map((image) => (
                <Col xs={24} sm={12} lg={12}>
                  <div style={{ textAlign: "center", marginBottom: "12px" }}>
                    <img
                      src={image.filename}
                      alt="No images found"
                      style={{
                        width: "100%",
                        height: "auto",
                        maxWidth: "320px",
                        objectFit: "contain",
                      }}
                    />

                    <DeleteOutlined
                      style={{
                        cursor: "pointer",
                        marginTop: "5px",
                        fontSize: "16px",
                      }}
                      onClick={() => removeImage(image.id)}
                    />
                  </div>
                </Col>
              ))} */}
            </Row>
          </div>
        </Col>
        <Col flex="1">
          <div
            style={{
              padding: "15px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Row gutter={24}>
              <Form
                // requiredMark={false}
                layout="vertical"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={{ layout: "vertical" }}
              >
                <Row gutter={24} style={{ padding: 20 }}>
                  <Col span={8}>
                    <Form.Item
                      label="Destination"
                      name="destination"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please input!",
                      //   },
                      // ]}
                    >
                      <Select
                        placeholder="Select Destination"
                        showSearch
                        optionFilterProp="children"
                      >
                        {destinations?.map((dest) => (
                          <Select.Option key={dest.id} value={dest.id}>
                            {dest.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Container Size"
                      name="container_size"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please input!",
                      //   },
                      // ]}
                    >
                      <Select placeholder="">
                        {containerSize?.map((size) => (
                          <Select.Option key={size.id} value={size.id}>
                            {size.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Towing Location"
                      name="towing_location"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please input!",
                      //   },
                      // ]}
                    >
                      <Select
                        placeholder=""
                        showSearch
                        optionFilterProp="children"
                      >
                        {towing?.map((tow) => (
                          <Select.Option key={tow.id} value={tow.id}>
                            {tow.auctions}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Date Received"
                      name="date_rcd"
                      rules={[
                        { required: true, message: "Please select a date" },
                      ]}
                    >
                      <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Vehicle Type"
                      name="vehicle_type"
                      rules={[
                        {
                          required: true,
                          message: "Please input!",
                        },
                      ]}
                    >
                      <Select placeholder="car">
                        {vehicleType?.map((type) => (
                          <Select.Option key={type} value={type}>
                            {type}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Engine Type"
                      name="engine_type"
                      rules={[
                        {
                          required: true,
                          message: "Please input!",
                        },
                      ]}
                    >
                      <Select placeholder="">
                        {enginetypes.map((type) => (
                          <Select.Option key={type.id} value={type.id}>
                            {type.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="BOS"
                      name="BOS"
                      rules={[
                        {
                          required: true,
                          message: "Please input!",
                        },
                      ]}
                    >
                      <Select placeholder="Select BOS">
                        {BOS.map((b) => (
                          <Select.Option key={b.id} value={b.id}>
                            {b.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Car Status" name="car_status">
                      <Input placeholder="At yard" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Lot #" name="lot_#">
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Invoice No" name="invoice_no">
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Invoice Amount" name="invoice_amount">
                      <Input placeholder="0" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Product" name="product">
                      <Input placeholder="TUNDRA" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Vehicle" name="vehicle">
                      <Input placeholder="TOYOTA" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label="Year" name="year">
                      <Input placeholder="2016" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label="Chassis No" name="chasis_no">
                      <Input placeholder="5TFHW5F1XGX515569" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label="Value" name="value">
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Weight" name="weight">
                      <Input placeholder="2232" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Operating Port" name="operating_port">
                      <Input placeholder="HOUSTON" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Booking No"
                      name="booking_no"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please input!",
                      //   },
                      // ]}
                    >
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Carrier Name" name="carrier_name">
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Storage" name="storage">
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label="Bol" name="bol">
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Title Owner" name="title_owner">
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Title" name="title">
                      <Input placeholder="YES" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Title Number" name="title_number">
                      <Input placeholder=" 01130145348250011" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Title State " name="title_state">
                      <Input placeholder="TEXAS" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Keys" name="keys">
                      <Input placeholder="yes" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label="Notes" name="notes">
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Container No" name="container_no">
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Tracking No" name="tracking_no">
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Arrival Date" name="arrival_date">
                      <DatePicker style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Release Status" name="release_status">
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item label="File Ref" name="file_ref">
                      <Input placeholder="PS-1104" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Shipping Class Id"
                      name="shipping_class_id"
                    >
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col span={6} className="mt-4">
                    <Form.Item
                      style={{ textAlign: "left" }}
                      name="cutting_car"
                      valuePropName="checked"
                      normalize={(value) => (value ? 1 : 0)}
                    >
                      <Checkbox>Is Cutting Car?</Checkbox>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    {/* <Upload
                      id="uploadInput"
                      action="/upload"
                      listType="picture"
                    >
                      <Button
                        type="dashed"
                        style={{
                          width: "100%",
                          border: "1px solid #ccc",
                          padding: 15,
                        }}
                      >
                        <img src="/images/Group.png" alt="" width="25px" />
                        Upload Image
                      </Button>
                    </Upload> */}

                    {/* <ImageUploader
                      fileList={fileList}
                      beforeUpload={true}
                      handleChange={handleChange}
                      length={20}
                    /> */}
                  </Col>

                  <Col span={12} className="mt-4 mb-20">
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      loading={loading}
                    >
                      Update Vehicle
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}
export default VehicleEdit;

import ajaxService from "./ajax-service";
import { validationErrors } from "../utils";

class ContainerService {
  async fetch(page) {
    const response = await ajaxService.get(`/container-sizes?page=${page}`);
    if (response?? false) {
      return response;
    }
  }
  async fetchsizes() {
    const response = await ajaxService.get(`/container-sizes?all=true`);
    if (response ?? false) {
      return response;
    }
  }
  async fetchlatestContainerId() {
    const response = await ajaxService.get(`/container/get-id`);
    if (response ?? false) {
      return response;
    }
  }

  async add(data) {
    const response = await ajaxService.post(`/container-sizes`, data);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }

  async edit(id, data) {
    const response = await ajaxService.put(`/container-sizes/`, id, data);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }

  async delete(id) {
    const response = await ajaxService.delete(`/container-sizes/${id}`);
    if (response ?? false) {
      return response;
    }
  }

  async view(id) {
    const response = await ajaxService.get(`/container-sizes/${id}`);
    if (response ?? false) {
      return response;
    }
  }
}
const containerService = new ContainerService();
export default containerService;

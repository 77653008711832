import { Table, Space, Button,Row,Col, Input,Select, Modal, Tag,} from "antd";
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { customerBalanceService } from "../../../../services";

function AccountsCustomerLedger() {
  const [loading, setLoading] = useState(false);
  const [balances, setBalances] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [invoices, setInvoices] = useState([])

  const openModal = (invoices) => {
    setInvoices(invoices)
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const options = [
    {
      value: "10",
      label: "10",
    },
    {
      value: "9",
      label: "9",
    },
  ];
  const columns = [
    {
      title: "User",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Total Invoice",
      dataIndex: "invoice_amount",
      key: "invoice_amount",
      render: (text) => "$ " + text,
    },
    {
      title: "Paid",
      dataIndex: "amount",
      key: "amount",
      render: (text) => "$ " + text,
    },
    {
      title: "Balance",
      dataIndex: "Balance",
      key: "Balance",
      render: (text, record) => {
        const balance = record.invoice_amount - record.amount;
        return "$ " + balance;
      },
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      render: (text, record) => {
        const balance = record.invoice_amount - record.amount;
        const status = balance === 0 ? "Paid" : "Pending";
        return (
          <Tag
            style={{
              backgroundColor: status === "Paid" ? "#1fd655" : "yellow",
              color: status === "Paid" ? "White" : "Black",
            }}
          >
            {status}
          </Tag>
        );
      },
    },

    {
      title: "Action",
      key: "Action",
      render: (text, record, { _id }) => (
        <Space size="small">
          <Button
            type="primary"
            size="small"
            onClick={() => openModal(record.invoices)}
          >
            <EyeOutlined />
          </Button>
        </Space>
      ),
    },
  ];

  // const dummyInvoices = Array.from({ length: 71 }, (_, i) => 10000 + i);


  const initialize = async (page = 1, pageSize = pagination.current) => {
    setLoading(true);
    const { success, data } = await customerBalanceService.fetch(page);
    if (success) {
      setBalances(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);

  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={16}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>Customers Accounts Ledger</h1>
        </Col>
      </Row>
      <Divider style={{ marginTop: 15 }} />
      <Row
        gutter={8}
        style={{
          marginTop: "15px",
          background: "#F2F2F7",
          padding: "5px",

          alignItems: "center", // Ensures alignment of items within the row
        }}
      >
        <Col
          span={4}
          xs={24}
          sm={12}
          md={6}
          lg={4}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Space direction="horizontal" size={10}>
            <p style={{ margin: 0 }}>Show</p>
            <Select
              defaultValue={10}
              options={options}
              size="small"
              style={{ width: 70, fontSize: 14 }}
            />
            <p style={{ margin: 0 }}>entries</p>
          </Space>
        </Col>

        {/* Second Column: Right-Aligned Search */}
        <Col
          span={20}
          xs={24}
          sm={12}
          md={18}
          lg={20}
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            style={{
              width: "30%",
              borderRadius: "4px",
              fontSize: 14,
            }}
          />
        </Col>
      </Row>

      <Modal
        title={<div style={{ width: "100%" }}>Invoice Numbers</div>}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={true}
        width={850}
        style={{
          padding: "20px",
        }}
      >
        <div
          style={{
            border: "1px solid #ccc",
            padding: "20px",
            borderRadius: "12px",
          }}
        >
          {/* Scrollable Container for Invoices */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "5px",
              justifyContent: "space-between",
              maxHeight: "150px", // Set the scrollable height
              overflowY: "auto", // Enable vertical scrolling
            }}
          >
            {invoices.map((invoice, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: "#B83D3D",
                  color: "white",
                  padding: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  textAlign: "center",
                  width: "13%", // 7 items per row (adjust for margins and gaps)
                  boxSizing: "border-box",
                }}
              >
                {invoice}
              </div>
            ))}
          </div>
        </div>
      </Modal>

      <Table
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={balances}
        style={{ marginTop: "10px" }}
        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page, pagination.pageSize),
          showSizeChanger: false,
        }}
        loading={loading}
      />
    </div>
  );
}

export default AccountsCustomerLedger;

// Api url
export const API_URL = process.env.REACT_APP_API_URL + '/api'

// Role 
export const ROLES = {
  ADMIN: "ADMIN",
  CUSTOMER: "CUSTOMER",
};
export const ADMIN = "admin";
export const MEMBER = "member";
export const CLIENT = "client";

//vendor details is_dg

export const DG = "DG"; //is_dg = 1
export const NON_DG = "NON-DG"; //is_dg = 0

// opening balance account types
export const acc_types = [
  "CAPITAL & LIABLITIES",
  "FIXED AND CURRENT ASSETS",
  "REVENUE AND EXPENSE ACCOUNT"
];

// Aws Base Folder Names
export const VEHICLES = "vehicles";
export const CONTAINERS = "containers";

export const PERPAGE_OPTIONS = [10, 20, 50, 100];


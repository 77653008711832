import React, { useEffect, useState } from "react";
import { Space, Button, Row, Col, Carousel } from "antd";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { customerService, vehicalService } from "../../../services";

function ViewVehical() {
  const { id } = useParams();
  const [vehicle, setVehicle] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [images, setImages] = useState([])

  const fieldData = [
    { label: "Customer", value: vehicle?.username },
    { label: "Title Number", value: vehicle?.title },
    { label: "Car Status", value: vehicle?.car_status },
    { label: "Title State", value: vehicle?.title_state },
    { label: "Lot #", value: "" },
    { label: "Keys", value: vehicle?.keys },
    { label: "Invoice No", value: "Enter country name" },
    { label: "Notes", value: vehicle?.notes },
    { label: "Invoice Amount", value: vehicle?.invoice_amount },
    { label: "Container No", value: vehicle?.container_no },
    { label: "Cutting Car", value: vehicle?.cutting_car },
    { label: "Tracking No", value: vehicle?.tracking_no },
    { label: "Product", value: vehicle?.product },
    { label: "Arrival Date", value: vehicle?.arrival_date },
    { label: "Vehicle", value: vehicle?.vehicle },
    { label: "Release Status", value: vehicle?.notes },
    { label: "Vehicle Type", value: vehicle?.vehicle_type },
    { label: "Destination", value: vehicle?.destination },
    { label: "Engine Type", value: vehicle?.engine_type },
    { label: "Container Size", value: vehicle?.container_size },
    { label: "Year", value: vehicle?.year },
    { label: "File Ref", value: vehicle?.file_ref },
    { label: "Chassis No", value: vehicle?.chasis_no },
    { label: "Shipping Class Id", value: vehicle?.shipping_class_id },
    { label: "Date Rcd", value: vehicle?.date_rcd },
    { label: "Price", value: vehicle?.price },
    { label: "Value", value: vehicle?.values },
    { label: "Towing Charges", value: vehicle?.towing_charges },
    { label: "Weight", value: vehicle?.weight },
    { label: "Freight Charges", value: vehicle?.freight_charges },
    { label: "Operating Port", value: vehicle?.operating_port },
    { label: "Buying Charges", value: vehicle?.buying_charges },
    { label: "Towing Location", value: vehicle?.towing_location },
    { label: "Selling Charges", value: vehicle?.selling_charges },
    { label: "Booking No", value: vehicle?.booking_no },
    { label: "Yard Charges", value: vehicle?.yard_charges },
    { label: "Carrier Name", value: vehicle?.carrier_name },
    { label: "Loading Charges", value: vehicle?.loading_charges },
    { label: "Storage", value: vehicle?.storage },
    { label: "Transportation", value: vehicle?.transportation },
    { label: "Bol", value: vehicle?.bol },
    { label: "Labour", value: vehicle?.labour },
    { label: "BOS", value: vehicle?.BOS },
    { label: "Material", value: vehicle?.material },
    { label: "Title Owner", value: vehicle?.title_owner },
    { label: "Chasis", value: vehicle?.chasis },
    { label: "Title", value: vehicle?.title },
    { label: "Quotation Id", value: vehicle?.quotation_id },
  ];

  const initialize = async () => {
    const { success, data } = await vehicalService.view(id);
    if (success) {
      setVehicle(data.vehicle);
      getCustomer(data.vehicle.user_id)
      setImages(data.images)
    }
  };

  const getCustomer = async (id)=>{
    const response = await customerService.view(id)
    if(response.success){
      setCustomer(response.data)
    }
  }

  useEffect(() => {
    initialize();
  }, []);


  const CustomArrow = ({ className, style, onClick, isLeft }) => (
    <div className={className}
      style={{ ...style, color: '#B83D3D', fontSize: '40px', zIndex: 1, }}
      onClick={onClick}
    >
      {isLeft ? '' : ''} {/* Customize the arrow symbol */}
    </div>
  );
  // // Generate fieldData dynamically based on vehicle state
  // const generateFieldData = () => {
  //   if (!vehicle) return [];
  //   return Object.keys(vehicle).map((key) => ({
  //     label: key
  //       .replace(/_/g, " ")
  //       .replace(/\b\w/g, (char) => char.toUpperCase()), // Format the label
  //     value: vehicle[key] || "", // Use the value from vehicle or an empty string
  //   }));
  // };

  // const fieldData = generateFieldData();
  return (
    <>
      <Row gutter={[24, 24]} wrap>
        <Col xs={24} sm={24} md={8} lg={6} xl={6} flex="280px">
          <div
            style={{
              border: "1px solid #ccc",
              padding: 10,
              borderRadius: 5,
              backgroundColor: "#FFFFFF",
            }}
          >
            <div style={{ textAlign: "center" }}>
            <Carousel
                arrows
                arrowSize={200}
                // infinite={false}
                prevArrow={<CustomArrow isLeft />}
                nextArrow={<CustomArrow />}
              >
                {
                  images.map(image => (
                    <img
                      src={image.filename}
                      alt="Profile"
                      style={{
                        // width: "320px",
                        // height: "200px",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                  ))
                }
              </Carousel>
              {/* <Button type="primary" style={{ marginTop: "10px" }}>
                Download Image
              </Button> */}
            </div>
            <div
              style={{
                marginTop: "20px",
                border: "1px solid #f0f0f0",
                borderRadius: "8px",
                borderBottom: "1px solid #f0f0f0",
                backgroundColor: "#FFFFFF",
              }}
            >
              <div
                style={{
                  backgroundColor: "#F04949",
                  padding: "15px",
                  borderTopLeftRadius: "8px",
                  borderTopRightRadius: "8px",
                }}
              >
                <h3 style={{ margin: 0, color: "white", textAlign: "center" }}>
                  Customer Details
                </h3>
              </div>

              <div
                style={{
                  padding: "20px",
                }}
              >
                <div
                  style={{
                    alignItems: "center",
                    marginBottom: "15px",
                  }}
                  className="flex flex-col gap-4"
                >
                  <p style={{ margin: 0 }}>{customer?.customer?.email}</p>
                  <Space>
                    <EyeOutlined
                      style={{ fontSize: "16px", cursor: "pointer" }}
                    />
                    <EditOutlined
                      style={{ fontSize: "16px", cursor: "pointer" }}
                    />
                  </Space>
                </div>
                <div className="profile-info">
                  <p>
                    <strong>Phone:</strong> {customer?.customer?.phone}
                  </p>
                  <p>
                    <strong>Town:</strong> {customer?.customer?.town}
                  </p>
                  <p>
                    <strong>City:</strong> {customer?.customer?.city}
                  </p>
                  <p>
                    <strong>Country:</strong> {customer?.customer?.country}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Col>

        <Col flex="1">
          <div
            style={{
              padding: "15px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Row gutter={24}>
              {fieldData.map((field, index) => (
                <Col span={12}>
                  <div
                    style={{
                      display: "flex",
                      width: 320,
                      border: "1px solid #d9d9d9",
                      borderRadius: 4,
                      marginBottom: 2,
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        padding: "4px 11px",
                        backgroundColor: "#f5f5f5",
                        borderRight: "1px solid #d9d9d9",
                      }}
                    >
                      {field.label}
                    </div>
                    <p
                      style={{
                        flex: 1,
                        margin: 0,
                        padding: "4px 11px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {field.value}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Col>
      </Row>

      <style>
        {`
  .profile-info {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 5px; /* Space between each <p> element */
  }

  .profile-info p {
    display: flex; /* Makes each <p> a flex container */
  
  }

  .profile-info strong {
   margin-right: 10px; /* Space between <strong> and <p> content */
    width: 100px; /* You can adjust this value to ensure equal space between all labels */
  }

  
`}
      </style>
    </>
  );
}

export default ViewVehical;

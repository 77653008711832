import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ConfigProvider
        theme={{
            token: {
                colorPrimary: "#B83D3D",
                fontFamily: 'Poppins',
                fontSize: 12.5
            },
            components: {
                Table: {
                    fontSize: 12.5,
                },
            }
        }}>
     
      <App />
   
    </ConfigProvider>

);
reportWebVitals();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Table,
  Upload,
} from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import React, { useState, useMemo } from "react";
import { expenseVoucherService } from "../../../../../services";
import { v4 as uuidv4 } from "uuid";
import { CiSquarePlus } from "react-icons/ci";
import moment from "moment";

function PayablePayment({
  isVisible,
  setIsModalOpen,
  paymentmodes,
  banks,
  companies,
  vendors,
  vouchers,
  setRefresh,
}) {
  const [form2] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showBankField, setShowBankField] = useState(false);
  const [data2, setData2] = useState([]);
  const totalPayingAmount = useMemo(() => {
    return data2.reduce(
      (total, record) => total + Number(record.PayAmount || 0),
      0
    );
  }, [data2]);
  const columns2 = [
    {
      title: "Expense Account",
      dataIndex: "ExpenseAccount",
      key: "ExpenseAccount",
      render: (text, record) => (
        <Select
          value={record.ExpenseAccount}
          onChange={(value) => {
            handleDueAmount(value, record.Id);
            handleChange(value, "ExpenseAccount", record.Id);
          }}
          style={{ width: "100%" }}
        >
          {vouchers
            .filter((voucher) => voucher.is_paid === 0)
            .map((voucher) => (
              <Select.Option key={voucher.id} value={voucher.id}>
                {voucher.voucher_number} - {voucher.id}
              </Select.Option>
            ))}
        </Select>
      ),
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      render: (text, record) => (
        <Input
          value={record.Description}
          onChange={(e) =>
            handleChange(e.target.value, "Description", record.Id)
          }
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Due Amount",
      dataIndex: "DueAmount",
      key: "DueAmount",
      render: (text, record) => (
        <Input value={record.DueAmount} style={{ width: "100%" }} readOnly />
      ),
    },
    {
      title: "Pay Amount",
      dataIndex: "PayAmount",
      key: "PayAmount",
      render: (text, record) => (
        <Input
          value={record.PayAmount}
          onChange={(e) => handleChange(e.target.value, "PayAmount", record.Id)}
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Bal. Amount",
      dataIndex: "Bal.Amount",
      key: "Bal.Amount",
      render: (text, record) => (
        <Input
          value={record.BalAmount}
          onChange={(e) => handleChange(e.target.value, "BalAmount", record.Id)}
          style={{ width: "100%" }}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "Delete",
      key: "Delete",
      render: (text, record) => {
        if (data2.length === 1) return null;
        return (
          <Space size="small">
            <Button
              type="primary"
              size="small"
              onClick={() => handleDeleteRow(record)}
            >
              <DeleteOutlined />
            </Button>
          </Space>
        );
      },
    },
  ];
  const handleDueAmount = async (value, recordId) => {
    const selectedVoucher = vouchers.find(
      (voucher) => voucher.id === value
    );
    if (selectedVoucher) {
      setData2((prevData) =>
        prevData.map((item) =>
          item.Id == recordId
            ? {
                ...item,
                DueAmount: selectedVoucher.amount || 0,
                BalAmount: selectedVoucher.amount - item.PayAmount,
              }
            : item
        )
      );
    }
  };
  const handleChange = (value, field, recordId) => {
    setData2((prevData) =>
      prevData.map((record) => {
        if (record.Id === recordId) {
          const updatedRecord = { ...record, [field]: value };

          if (field === "PayAmount") {
            updatedRecord.BalAmount = (updatedRecord.DueAmount || 0) - value;
          }

          return updatedRecord;
        }
        return record;
      })
    );
  };
  const handleAddRow = () => {
    setData2([
      ...data2,
      {
        Id: uuidv4(),
        ExpenseAccount: "",
        Description: "",
        DueAmount: "",
        PayAmount: "",
        BalAmount: "",
      },
    ]);
  };
  const handleDeleteRow = (record) => {
    setData2(data2.filter((item) => item.Id !== record.Id));
  };
  const handleCancel = () => {
    setRefresh(true);
    setData2([]);
    form2.resetFields();
    setShowBankField(false);
    setIsModalOpen(false);
  };
  const handlePaymentChange = (value) => {
    if (value === 1) {
      setShowBankField(true);
    } else {
      setShowBankField(false);
    }
  };
  const handleOk = async (values) => {
    setLoading(true);
    const expenseVoucherArray = data2.map((item) => item.ExpenseAccount);
    const descriptionArray = data2.map((item) => item.Description);
    const dueArray = data2.map((item) => item.DueAmount);
    const amountArray = data2.map((item) => item.PayAmount);
    const balanceArray = data2.map((item) => item.BalAmount);

    const data = {
      ...values,
      description: values.description ? values.description : "",
      bank_account: values.bank_account ? values.bank_account : "",
      payment_date: values.payment_date.format("YYYY-MM-DD"),
      voucher_date: values.voucher_date.format("YYYY-MM-DD"),
      expense_voucher: expenseVoucherArray,
      ev_description: descriptionArray,
      due_amount: dueArray,
      amount: amountArray,
      balance_amount: balanceArray,
      attachment: values.attachment ? values.attachment : null,
      totalVoucherAmount: totalPayingAmount,
    };
    const response = await expenseVoucherService.makePayment(data);
    if (response?.success) {
      notification.success({
        message: "Successfully Added!",
        description: response.message,
        placement: "topRight",
      });
    }
    setLoading(false);
    handleCancel();
  };
  return (
    <Modal
      title={<div style={{ width: "100%" }}>Make a Payment</div>}
      open={isVisible}
      onCancel={handleCancel}
      footer={null}
      closable={true}
      width={700}
      style={{ padding: "15px" }}
    >
      <Form
        layout="vertical"
        form={form2}
        onFinish={handleOk}
        requiredMark={false}
        initialValues={{
          voucher_date: moment(),
        }}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Payment Mode"
              name="payment_mode"
              rules={[
                {
                  required: true,
                  message: "Please select an appointment type",
                },
              ]}
            >
              <Select
                placeholder="Select Payment Mode"
                onChange={(value) => handlePaymentChange(value)}
              >
                {paymentmodes.map((payment) => (
                  <Select.Option key={payment.id} value={payment.id}>
                    {payment.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          {showBankField && (
            <Col span={8}>
              <Form.Item
                label="Select Bank"
                name="bank_account"
                rules={[
                  {
                    required: true,
                    message: "Please select a bank",
                  },
                ]}
              >
                <Select placeholder="Select Bank">
                  {banks.map((bank) => (
                    <Select.Option key={bank.id} value={bank.id}>
                      {bank.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          <Col span={8}>
            <Form.Item
              label="Inter-Company"
              name="company"
              rules={[
                {
                  required: true,
                  message: "Please select an appointment type",
                },
              ]}
            >
              <Select placeholder="Select Inter-Company">
                {companies?.map((company) => (
                  <Select.Option key={company.id} value={company.id}>
                    {company.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Payment Voucher Number"
              name="voucher_number"
              rules={[{ required: true, message: "Please enter the end time" }]}
            >
              <Input placeholder="2024-11-06" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Voucher Date"
              name="voucher_date"
              rules={[{ required: true, message: "Please enter the end time" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Vendor Name"
              name="vendor"
              rules={[
                {
                  required: true,
                  message: "Please select an appointment type",
                },
              ]}
            >
              <Select placeholder="Select type">
                {vendors?.map((vendor) => (
                  <Select.Option key={vendor.id} value={vendor.id}>
                    {vendor.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Actual Payment Date"
              name="payment_date"
              rules={[{ required: true, message: "Please enter the end time" }]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item label="Special Note:" name="description">
              <Input.TextArea
                placeholder="Enter special notes here..."
                rows={5}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Attachment" name="attachment">
              <Upload
                id="uploadInput"
                action="/upload"
                beforeUpload={() => false}
                listType="picture"
                style={{
                  width: "100%",
                }}
              >
                <Button
                  type="dashed"
                  icon={<UploadOutlined />}
                  style={{
                    width: "100%",
                    border: "1px solid #ccc",
                    padding: 18,
                  }}
                >
                  No File Chosen
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Table
              columns={columns2}
              scroll={{ x: "max-content" }}
              dataSource={data2}
              pagination={false}
              rowKey="Id"
              style={{ marginTop: "10px" }}
            />
            <div className="flex justify-between mt-5">
              <p>Total Paying Amount</p>
              <p>${totalPayingAmount}</p>
            </div>
            <Divider />
            <div
              style={{ marginTop: 20 }}
              className="flex flex-col justify-center items-center"
            >
              <CiSquarePlus
                style={{ cursor: "pointer", fontSize: "25px" }}
                onClick={handleAddRow}
              />
            </div>
          </Col>
        </Row>
        <Form.Item style={{ textAlign: "right", marginTop: 20 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: "20%" }}
            loading={loading}
          >
            Make a Payment
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PayablePayment;

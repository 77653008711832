import React, { useEffect, useState } from "react";
import { Table, Row, Col, Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { reportService } from "../../../services";

function CashFlow() {
  const [loading, setLoading] = useState(false);
  const [expectedPayments, setExpectedPayments] = useState([]);
  const [expectedCollections, setExpectedCollections] = useState([]);
  const [actualFlows, setActualFlows] = useState([]);
  const [cashSumm, setCashSumm] = useState([]);
  const navigate = useNavigate();

  const columns2 = [
    {
      title: "",
      dataIndex: "empty",
      key: "empty",
      width: 100,
    },
    {
      title: "Over Due",
      dataIndex: "overDue",
      key: "overDue",
      width: 150,
    },
    ...expectedCollections?.map((_, i) => ({
      title: `Week ${i + 1}`,
      children: [
        {
          title: "Start Date",
          dataIndex: `week${i + 1}Start`,
          key: `week${i + 1}Start`,
          width: 150,
        },
        {
          title: "End Date",
          dataIndex: `week${i + 1}End`,
          key: `week${i + 1}End`,
          width: 150,
        },
      ],
    })),
  ];
  const numberFormat = (number) => {
    const formattedNumber = new Intl.NumberFormat("en-US").format(number);
    return formattedNumber;
  };
  // Prepare data
  const data2 = [
    {
      key: "1",
      empty: "",
      ...expectedPayments?.reduce((acc, week, i) => {
        acc[`week${i + 1}Start`] = week["Start Date"];
        acc[`week${i + 1}End`] = week["End Date"];
        return acc;
      }, {}),
    },
    {
      key: "2",
      empty: "Expected Collection",
      // overDue: `$${cashSumm?.overDueAmounts?.toFixed(2)}`,
      overDue: `$${numberFormat(cashSumm?.overDueAmounts)}`,

      ...expectedCollections?.reduce((acc, week, i) => {
        acc[`week${i + 1}Start`] = `$${numberFormat(week["Sum"])}`;
        acc[`week${i + 1}End`] = "";
        return acc;
      }, {}),
    },
    {
      key: "3",
      empty: "Expected Payments",
      overDue: "-",
      ...expectedPayments?.reduce((acc, week, i) => {
        acc[`week${i + 1}Start`] = `$${numberFormat(week["Sum"])}`;
        acc[`week${i + 1}End`] = "";
        return acc;
      }, {}),
    },
    {
      key: "4",
      empty: "Net Expected Cash Flow",
      overDue: "-",
      ...expectedPayments?.reduce((acc, week, i) => {
        const netFlow = (expectedCollections[i]?.Sum || 0) - (week?.Sum || 0);
        acc[`week${i + 1}Start`] = `$${numberFormat(netFlow)}`;
        acc[`week${i + 1}End`] = "";
        return acc;
      }, {}),
    },
  ];

  const columns = [
    {
      title: "Month",
      dataIndex: "Month",
      key: "Month",
    },
    {
      title: "Collections",
      dataIndex: "Collections",
      key: "Collections",
    },
    {
      title: "Payments",
      dataIndex: "Payments",
      key: "Payments",
    },
    {
      title: "Details",
      dataIndex: "Month",
      key: "Month",
      render: (text, { _id }) => (
        <Tag
          style={{
            color: "white",
            backgroundColor: "#B83D3D",
            cursor: "pointer",
          }}
          onClick={() => window.open(`/accounts/reports/monthly_cash_flow/${text}`,"_blank")}
        >
          Details
        </Tag>
      ),
    },
  ];

  const initialize = async () => {
    setLoading(true);
    const { success, data } = await reportService.CashFlowSummary();
    if (success) {
      setCashSumm(data);
      setExpectedPayments(data.expectedPayments);
      setExpectedCollections(data.expectedCollections);
      setActualFlows(data.actualFlows);
    }
    setLoading(false);
  };

  useEffect(() => {
    initialize();
  }, []);
  return (
    <>
      <div
        style={{
          padding: "15px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF",
        }}
        className="mt-8"
      >
        <Row gutter={16}>
          <Col span={12}>
            <h1 style={{ fontWeight: "bold" }}>EXPECTED CASH FLOW</h1>
          </Col>
        </Row>
        <Table
          className="compact-table"
          columns={columns2}
          dataSource={data2}
          loading={loading}
          bordered
          pagination={false}
          scroll={{ x: "100%" }}
        />
      </div>

      <div
        style={{
          width: "80%",
          padding: "15px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          backgroundColor: "#FFFFFF",
        }}
        className="mt-10"
      >
        <h1 style={{ fontWeight: "bold" }}>ACTUAL CASH FLOW</h1>
        <Table
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={actualFlows}
          loading={loading}
          pagination={false}
          style={{ marginTop: "10px" }}
        />
      </div>
    </>
  );
}

export default CashFlow;

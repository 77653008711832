import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, DatePicker, Table, Button, Pagination } from "antd";
import { Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { customerService, pnlService } from "../../../../services";
import { PiMicrosoftExcelLogo } from "react-icons/pi";
import { LiaFilePdfSolid } from "react-icons/lia";
import { RiFileCopyLine } from "react-icons/ri";
import { AiOutlinePrinter } from 'react-icons/ai';
import { FaRegFile } from "react-icons/fa";

function PNL() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [pnl, setPnl] = useState([]);
  const [totals, setTotals] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      // render: (text, record, index) => index + 1,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
    },
    {
      title: "Port",
      dataIndex: "port",
      key: "port",
      render: (port) => (port ? port : "N/A"),
    },
    {
      title: "Container",
      dataIndex: "container",
      key: "container",
      render: (container) => (container ? container : "N/A"),
    },
    {
      title: "No of Vehicle",
      dataIndex: "no_of_vehicle",
      key: "no_of_vehicle",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "INV",
      dataIndex: "invoice_id",
      key: "invoice_id",
    },
    {
      title: "INV Amount",
      dataIndex: "invoice_amount",
      key: "invoice_amount",
    },
    {
      title: "TRPT",
      dataIndex: "TRP_total",
      key: "TRP_total",
    },
    {
      title: "Chassis",
      dataIndex: "chasis_charges",
      key: "chasis_charges",
    },
    {
      title: "Pull Out Date",
      dataIndex: "pull_out_date",
      key: "pull_out_date",
      render: (pull_out_date) => (pull_out_date ? pull_out_date : "N/A"),
    },
    {
      title: "Get In Date",
      dataIndex: "gate_in_date",
      key: "gate_in_date",
      render: (gate_in_date) => (gate_in_date ? gate_in_date : "N/A"),
    },
    {
      title: "Days",
      dataIndex: "days",
      key: "days",
    },
    {
      title: "TRP Total",
      dataIndex: "TRP_total",
      key: "TRP_total",
    },
    {
      title: "STRG",
      dataIndex: "storage_charges",
      key: "storage_charges",
    },
    {
      title: "Labour",
      dataIndex: "labour_charges",
      key: "labour_charges",
    },
    {
      title: "Material",
      dataIndex: "material_charges",
      key: "material_charges",
    },
    {
      title: "Loading",
      dataIndex: "loading_charges",
      key: "loading_charges",
    },

    {
      title: "Other Inv Charges",
      dataIndex: "other_charges",
      key: "other_charges",
    },
    {
      title: "Extra/Buy",
      dataIndex: "extra_buy",
      key: "extra_buy",
    },
    {
      title: "Booking Charges",
      dataIndex: "booking_charges",
      key: "booking_charges",
    },
    {
      title: "CREDIT NUMBER",
      dataIndex: "credit_amount",
      key: "credit_amount",
    },
    {
      title: "Towing",
      dataIndex: "towing_charges",
      key: "towing_charges",
    },
    {
      title: "Total Expense",
      dataIndex: "expenses_amount",
      key: "expenses_amount",
    },
    {
      title: "CREDIT AMOUNT",
      dataIndex: "credit_amount",
      key: "credit_amount",
    },
    {
      title: "Credit Note Amount",
      dataIndex: "Credit_Note_Amount",
      key: "Credit_Note_Amount",
    },
    {
      title: "P&L",
      dataIndex: "profit_loss",
      key: "profit_loss",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const initialize = async (
    page = 1,
    pageSize = pagination.pageSize,
    payload
  ) => {
    const { success, data } = await pnlService.fetch(page, payload);
    if (success) {
      setPnl(data.data);
      setTotals(data.totals);
      setPagination((prev) => ({
        ...prev,
        total: data.pagination.total,
        current: page,
        pageSize,
      }));
    }
  };

  const onFinish = async (values) => {
    const from_date = values.from_date.format("YYYY-MM-DD");
    const to_date = values.to_date.format("YYYY-MM-DD");
    const payload = {
      from_date,
      to_date,
      user_id: 0,
    };
    initialize(pagination.current, pagination.pageSize, payload);
  };

  const getCustomers = async () => {
    const { success, data } = await customerService.fetchCustomers();
    if (success) {
      setCustomers(data);
    }
  };

  useEffect(() => {
    getCustomers();
  }, []);
  return (
    <div
      style={{
        padding: "0 14px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Form
        requiredMark={false}
        onFinish={onFinish}
        layout="vertical"
        form={form}
      >
        <Row gutter={24} className="mt-8">
          <Col span={6}>
            <Form.Item label="From date" name="from_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="To date" name="to_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Customers"
              name="user_id"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Select placeholder="No Customer">
                {customers.map((customer) => {
                  return (
                    <Select.Option key={customer.id} value={customer.id}>
                      {customer.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>

          <Col span={2} >
            <Form.Item label={<></>}>
              <Button type='primary' htmlType="submit"><SearchOutlined style={{ fontSize: '15px' }} /></Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Col span={24}>
        <Row
          gutter={0}
          style={{
            padding: "10px",
            marginTop: "2px",
            background: "#F2F2F7",
            borderRadius: "4px",
          }}
          className="flex justify-between"
        >
          <Col span={10}>
            <div className='flex' >
              <Button type='text' size='small' style={{ padding: '4px' }} >
                <AiOutlinePrinter style={{ fontSize: "24px" }} />
              </Button>

              <Button type='text' size='small' style={{ padding: '4px' }}>
                <FaRegFile style={{ fontSize: "20px" }} />
              </Button>

              <Button type='text' size='small' style={{ padding: '4px' }}>
                <PiMicrosoftExcelLogo style={{ fontSize: "24px" }} />
              </Button>

              <Button type='text' size='small' style={{ padding: '4px' }}>
                <LiaFilePdfSolid style={{ fontSize: "24px" }} />
              </Button>

              <Button type='text' size='small' style={{ padding: '4px' }}>
                <RiFileCopyLine style={{ fontSize: "24px" }} />
              </Button>
            </div>
          </Col>
          <Col span={14} style={{ textAlign: "right" }}>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              style={{ width: 200 }}
            />
          </Col>
        </Row>

        <div
          style={{ overflowX: "auto", whiteSpace: "nowrap" }}
          className="scrollable-container"
        >
          <div style={{ display: "inline-block", }}>
            <Table
              columns={columns}
              dataSource={pnl}
              pagination={false}
              locale={{
                emptyText: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginLeft: "20%"
                      // minHeight: "200px", // Adjust as per your table height
                    }}
                  >
                    No data available in table  
                  </div>
                ),
              }}
              loading={loading}
              rowKey="Id"
              style={{ marginTop: "5px", marginBottom: "10px" }}
            />

            <div
              className="flex flex-col gap-4 mb-5 p-2 "
              style={{
                backgroundColor: "#B83D3D",
                color: "white",
                border: "1px solid #ccc",
              }}
            >
              <div className="flex  justify-around">
                <p>
                  TOTAL:<div> {totals?.totalInvoices}</div>
                </p>
                <p>
                  DRAYAGE<div> {totals?.totalDranage}</div>
                </p>
                <p>
                  CHASSIS<div>{totals?.totalChasis}</div>
                </p>
                <p>
                  TOTAL TRP<div> {totals?.totalTRP}</div>
                </p>
                <p>
                  TOTAL STORAGE<div> {totals?.totalStorage}</div>
                </p>
                <p>
                  TOTAL LABOUR<div>{totals?.totalLabour}</div>
                </p>
                <p>
                  TOTAL MATERIAL<div> {totals?.totalMaterial}</div>
                </p>
                <p>
                  TOTAL LOADER<div>{totals?.totalLoader}</div>
                </p>
                <p>
                  EXTRA<div> {totals?.totalExtra}</div>
                </p>
                <p>
                  BUYING<div> {totals?.totalExtra}</div>
                </p>
                <p>
                  BOOKING<div>{totals?.totalBooking}</div>
                </p>
                <p>
                  TOWING<div> {totals?.totalTowing}</div>
                </p>
                <p>
                  EXP<div>{totals?.totalExpense}</div>
                </p>
                <p>
                  PROFIT & LOSS<div>{totals?.totalProfitLoss}</div>
                </p>
                <p>
                  CREDIT AMOUNT<div>{totals?.totalCreditNote}</div>
                </p>
                <p>
                  UNPAID<div>{totals?.totalUnpaid}</div>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            bottom: "0",
            backgroundColor: "white",
            borderTop: "1px solid #ccc",
            padding: "10px",
            display: "flex", // Flexbox for alignment
            justifyContent: "flex-end",
          }}
        >
          <Pagination
            current={pagination.current}
            total={pagination.total}
            pageSize={pagination.pageSize}
            onChange={(page) => initialize(page, pagination.pageSize)}
            showSizeChanger={false}
          />
        </div>
      </Col>

      <style>
        {`
        .scrollable-container {
        overflow-x: auto;
        white-space: nowrap;
        scrollbar-width: thin; /* For Firefox */
        scrollbar-color: #B83D3D #F5F5F5; /* Thumb color and track color for Firefox */
    }

    .scrollable-container::-webkit-scrollbar {
        width: 12px; /* Set the width of the scrollbar */
        height: 12px; /* For horizontal scrollbar */
    }

    .scrollable-container::-webkit-scrollbar-track {
        background: #B83D3D; /* Track color */
    }

    .scrollable-container::-webkit-scrollbar-thumb {
        background-color: #B83D3D; /* Thumb color */
        border-radius: 10px; /* Rounded corners */
    }

    .scrollable-container::-webkit-scrollbar-thumb:hover {
        background-color: #B83D3D; /* Thumb color on hover */
    }
`}
      </style>
    </div>
  );
}

export default PNL;

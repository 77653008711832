import ajaxService from "./ajax-service";

class LogService {

  async fetch(page) {
    const response = await ajaxService.get(`/logs?page=${page}`);
    if (response ?? false) {
      return response
    }
  }

}
const logService = new LogService();
export default logService;
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, notification, Row, Spin, } from 'antd';
import { Select, Typography } from 'antd';
import moment from 'moment';
import { countryService, profileService, vehicalService } from '../../../services';

const { Title } = Typography;
const { Option } = Select;

const dummyData = [
    { id: '1', name: 'vehile' },
    { id: '2', name: 'invoice_no' },
    { id: '3', name: 'operating_port' },
    { id: '4', name: 'towing_location' },
    { id: '5', name: 'operating_port' },
    { id: '6', name: 'carrier_name' },
    { id: '7', name: 'year' },
    { id: '8', name: 'invoice_no' },
    { id: '9', name: 'invoice_no' },
    { id: '10', name: 'invoice_no' },
    { id: '12', name: 'invoice_no' },

];

function EditProfile() {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [submitLoad, setSubmitLoad] = useState(false);
    const [countries, setCountries] = useState([]);
    const [columns, setColumn] = useState([]);

    const onFinish = async (values) => {
        const data = { ...values, columns_view: selectedEntities }
        const response = await profileService.updateProfile(0, data)
        if (response.success) {
            setLoading(false);
            notification.success({
                message: "Success",
                description:  "Profile updated successfully!",
                placement: "topRight",
            });
        } else {
            notification.error({
                message: "Error",
                description: `Error: ${response.message}`,
                placement: "topRight",
            });
        }
    }


const onFinishFailed = (errorInfo) => {
    console.error("Failed:", errorInfo);
};

const loadData = async () => {
    const response = await profileService.fetchLoggedUser()
    if (response?.success ?? false) {
        const vehicleColumns = response.data.columns_view
        setSelectedEntities(JSON.parse(vehicleColumns))
        form.setFieldsValue({ ...response.data })
    }
}
const loadCountries = async () => {
    const response = await countryService.countryGet()
    if (response?.success) {
        setCountries(response.data);
    }
}
const loadVehicleColumns = async () => {
    const response = await vehicalService.getColumns()
    console.log(response.data)
    if (response?.success) {
        setColumn(response.data);
    }
}
// const initialize = async () => {
//     setLoading(true);
//     const { success: fetchsuccess, data: fetchdata } =
//         await editProfileService.view();
//     if (fetchsuccess) {
//         setPermissions(fetchdata);
//         //   setSelectedEntities(fetchdata.map((entity) => entity.id));
//     }
//     setLoading(true);
//     const { success: viewsuccess, data: viewData } = await editProfileService.view();
//     if (viewsuccess) {
//       setEmpInfo(viewData);
//       const selectedPermissionObjects = viewData.permissions;
//       setSelectedEntities(selectedPermissionObjects);

//       // Set initial values in the form
//       form.setFieldsValue({

//         joining_date: viewData.joining_date
//           ? moment(viewData.joining_date)
//           : null,

//       });

//     }
//   setLoading(false);
// };

const handleChange = (value) => {
    setSelectedEntities(value);
};

const calculateWidth = (dataLength) => {
    const baseWidth = 400;
    const additionalWidth = dataLength * 20;
    return baseWidth + additionalWidth;
};



useEffect(() => {
    loadData()
    loadCountries()
    loadVehicleColumns()
    // initialize();
}, []);

// Form submission handler
// const handleSubmit = async () => {
//     setSubmitLoad(true);
//     // For adding an employee, send the data to addEmployee API without an id
//     const values = await form.validateFields();
//     const stringifiedEntities = selectedEntities.map((entity) =>
//         entity.id.toString()
//     );
//     const data = {
//         ...values,
//         permissions: stringifiedEntities,
//     };
//    const response = await editProfileService.addProfile(data);

//     if (response.success) {
//         setSubmitLoad(false);
//         form.resetFields();

//         notification.success({
//             message: "Success",
//             description: "Profile added successfully",
//             placement: "topRight",
//         });
//     } else {
//         notification.error({
//             message: "Error",
//             description: `Error: ${response.message}`,
//             placement: "topRight",
//         });
//     }
// }

// if (loading) {
//     return (
//         <div className="flex justify-center items-center">
//             <Spin spinning={loading} size={"large"} tip="Loading..." />
//         </div>
//     );
// }
return (
    <div style={{ padding: '0 14px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#FFFFFF' }}>
        <Row
            gutter={26}
            style={{
                backgroundColor: '#FBFBFB', marginBottom: 15, padding: '14px 0px', borderRadius: '4px', borderBottom: '1px solid #E8E8E9',
            }}
        >
            <Col span={12}>
                <h1 style={{ fontWeight: 'bold' }}>Edit Profile</h1>
            </Col>
        </Row>
        <Form
            layout='vertical'
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label="Name" name="name">
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label="Email" name="email">
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name="address" label="Address">
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name="town" label="Town ">
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name="city" label="City ">
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Country"
                        name="country"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    ><Select>
                            {countries.map((country) => (
                                <Option key={country.id} value={country.iso_code}>
                                    {country.name}
                                </Option>))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item name="phone" label="Phone">
                        <Input placeholder="" />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label="Live Editing"
                        name="ajax_editing"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Select>
                            <Option key={0} value={0}>Disabled</Option>
                            <Option key={1} value={1}>Enabled</Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: calculateWidth(dummyData.length), margin: '30px' }}>

                        <Form.Item>
                            <Title level={5}>View Columns</Title>
                            <Select
                                mode="multiple"  // Allow multiple selections
                                style={{ width: '100%' }}
                                onChange={handleChange}
                                value={selectedEntities}
                            >
                                {columns.map((column) => (
                                    <Option key={column} value={column}>
                                        {column}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                </Col>

                <Col span={24}>
                    <Form.Item style={{ textAlign: 'center', marginTop: 10 }}>
                        <Button htmlType="submit" type="primary" style={{ width: '50%' }}
                            // onClick={handleSubmit}
                            loading={submitLoad}>
                            UPDATE PROFILE
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </div>
);
}

export default EditProfile;

import React, { useEffect, useState } from 'react'
import { Table, Space, Row, Col, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons'
import logService from '../../../services/log-service';
import moment from 'moment';

function Log() {
  const [logs, setLog] = useState([])
  const [filteredLog, setFilteredLog] = useState([])
  const [searchTerm, setSearchTerm] = useState()
  const [pagination, setPagination] = useState({ current: 1, total: 1, pageSize: 10 });
  const [loading, setLoading] = useState(false);

  const initialize = async (page = 1, pageSize = pagination.pageSize) => {
    setLoading(true);
    const { success, data } = await logService.fetch(page);
    setLoading(false);
    if (success) {
      setLog(data.data);
      setFilteredLog(data.data);
      setPagination((prev) => ({
        ...prev,
        total: data.total,
        current: page,
        pageSize,
      }));
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    const filteredData = logs.filter((log) =>
      log.user?.email.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredLog(filteredData);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: 'id',
      key: 'id',
      // render: (text, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "User",
      dataIndex: 'user',
      key: 'user',
      render: (user) => user?.email || '-',
    },
    {
      title: "Event",
      dataIndex: 'event',
      key: 'event',
    },
    {
      title: "Module",
      dataIndex: 'module',
      key: 'module',
      render: (text) => (
        <p>{text ? text : '-'}</p>
      ),
    },
    {
      title: "Identifier",
      dataIndex: 'message',
      key: 'message',
      render: (message) => <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', verticalAlign: 'bottom', }}
        dangerouslySetInnerHTML={{ __html: message }} />
    },
    {
      title: "Action Performed At",
      dataIndex: 'created_at',
      key: 'created_at',
      render: (createdAt) => {
        const specificDate = moment(createdAt); // Parse the created_at date
        return specificDate.format('YYYY-MM-DD HH:mm:ss'); // Format it as 'DD MMMM YYYY'
      },
    },
  ];

  useEffect(() => {
    initialize();
  }, [])

  return (
    <div style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: "#FFFFFF" }}>
      <Row gutter={24} style={{ padding: "5px", borderRadius: '4px' }} className='flex justify-between'>
        <Col span={10}  >
          <Space direction="horizontal" size={15} >
            <h5 className='font-bold'>Logs</h5>
          </Space>
        </Col>
        <Col span={14} style={{ textAlign: "right" }} >
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            className="w-auto lg:w-2/5"
            value={searchTerm}
            onChange={handleSearch}
          />
        </Col>
      </Row>
      <Table columns={columns} scroll={{ x: "max-content" }}
        loading={loading}
        dataSource={filteredLog}

        pagination={{
          current: pagination.current,
          total: pagination.total,
          pageSize: pagination.pageSize,
          onChange: (page) => initialize(page),
          showSizeChanger: false,
        }}
        style={{ marginTop: '10px' }} />
    </div>
  )
}
export default Log

import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Table,
  Select,
  Input,
  Divider,
  Form,
  Checkbox,
  Upload,
  notification,
  AutoComplete,
} from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { CiSquarePlus } from "react-icons/ci";
import { Option } from "antd/es/mentions";
import {
  awsService,
  containerService,
  destinationService,
  dropdownService,
  loadingPlanService,
  shippingLineService,
} from "../../../services";
import { useParams } from "react-router-dom";
import ImageUploader from "../../../components/ImageUploader";
import { CONTAINERS } from "../../../constants";

function EditSavePlan() {
  const { id } = useParams();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [customerOwnLoading, setCustomerOwnLoading] = useState(0);
  const [images, setImages] = useState([]);
  const [ledger, setLedger] = useState([]);
  const [loadingPlan, setLoadingPlan] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [newCarOptions, setNewCarOptions] = useState([]);
  const [shippingLine, setShippingLine] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [containerSize, setContainerSize] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });

  const [fileList, setFileList] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);

  const handleChange = (info) => {
    let updatedFileList = [...info.fileList];

    // CHANGES HIGHLIGHTED: Handle removed files
    if (info.file.status === "removed") {
      const removedFile = info.file;

      if (removedFile.url) {
        setRemovedImages((prev) => [...prev, removedFile.url]);
      }
    }

    setFileList(updatedFileList);
  };



  // API call function
  const fetchCarOptions = async (query, setOptions) => {
    if (!query) {
      setOptions([]);
      return;
    }
    try {
      const response = await dropdownService.fetchVehiclesOnSearch({ query });
      if (response.success) {
        setOptions(
          response.data.map((car) => ({
            value: `${car.vehicle} - ${car.chasis_no}`,
            label: `${car.vehicle} - ${car.chasis_no}`,
            data: car,
          }))
        );
      }
    } catch (error) {
      console.error("Error fetching vehicle options:", error);
      setOptions([]);
    }
  };
  // Handle selection from dropdown
  const handleSelect = (value, option) => {
    const { data } = option;
    const selectedCar = {
      id: data.id,
      vehicle: data.vehicle,
      chasis_no: data.chasis_no,
      value: data.value,
      weight: data.weight,
      engine_type: data.engine_type,
    };
    setVehicles((prev) => [...prev, selectedCar]);
    form1.setFieldsValue({
      new_car: "",
    });
  };

  const handleAddRow = () => {
    setLedger([
      ...ledger,
      {
        id: ledger.length > 0 ? ledger[ledger?.length - 1].id + 1 : 1,
        vendor_id: "",
        description: "",
      },
    ]);
  };
  const handleFieldChange = (id, field, value) => {
    setLedger((prevLedger) =>
      prevLedger.map((entry) =>
        entry.id === id ? { ...entry, [field]: value } : entry
      )
    );
  };
  // Delete handler
  const handleDelete = (id) => {
    setVehicles((prevData) => prevData.filter((item) => item.id !== id));
  };
  const columns = [
    {
      title: "Vehicle",
      dataIndex: "vehicle",
      key: "Vehicle",
    },
    {
      title: "Chassis Number",
      dataIndex: "chasis_no",
      key: "Chassis_Number",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "Value",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "Weight",
    },
    {
      title: "Engine Type",
      dataIndex: "engine_type",
      key: "Engine_Type",
    },
    {
      title: (
        <div>
          <DeleteOutlined style={{ fontSize: "18px" }} />
        </div>
      ),
      key: "Delete",
      render: (text, record, { _id }) => (
        <Button
          type="primary"
          size="small"
          onClick={() => handleDelete(record.id)}
        >
          <DeleteOutlined />
        </Button>
      ),
    },
  ];
  const columns2 = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      // render: (text, record, index) => index + 1,
    },
    {
      title: "Vendor",
      dataIndex: "vendor_id",
      key: "vendor_id",
      width: "300px",
      render: (text, record) => (
        <Select
          value={record.vendor_id}
          onChange={(value) => handleFieldChange(record.id, "vendor_id", value)}
          style={{ width: "100%" }}
        >
          {vendors.map((ven) => (
            <Select.Option key={ven.id} value={ven.id}>
              {ven.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "300px",
      render: (text, record) => (
        <Input
          value={record.description}
          style={{ width: "100%" }}
          onChange={(e) =>
            handleFieldChange(record.id, "description", e.target.value)
          }
        />
      ),
    },

    {
      title: (
        <div>
          <DeleteOutlined style={{ fontSize: "18px" }} />
        </div>
      ),
      key: "Delete",
      render: (text, record, index) => {
        if (ledger.length === 1) return null;
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleDeleteRow(record.id)}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];
  const handleDeleteRow = (id) => {
    setLedger((prevLedger) => prevLedger.filter((entry) => entry.id !== id));
  };

  // useEffect(() => {
  //   const fetchedImages = [
  //     { id: 1, url: "/images/image.png" },
  //     { id: 2, url: "/images/image2.png" },
  //     { id: 3, url: "/images/image.png" },
  //     { id: 4, url: "/images/image2.png" },
  //     { id: 5, url: "/images/image.png" },
  //     { id: 6, url: "/images/image2.png" },
  //     { id: 7, url: "/images/image.png" },
  //     { id: 8, url: "/images/image2.png" },
  //     { id: 9, url: "/images/image.png" },
  //     { id: 10, url: "/images/image2.png" },
  //     { id: 11, url: "/images/image.png" },
  //     { id: 12, url: "/images/image2.png" },
  //     { id: 13, url: "/images/image.png" },
  //     { id: 14, url: "/images/image2.png" },
  //     { id: 15, url: "/images/image.png" },
  //     { id: 16, url: "/images/image2.png" },
  //     { id: 17, url: "/images/image.png" },
  //     { id: 18, url: "/images/image2.png" },
  //   ];
  //   setImages(fetchedImages);
  // }, []);

  // const removeImage = (imageId) => {
  //   setImages(images.filter((image) => image.id !== imageId));
  // };

  const onFinish = async (val) => {
    setLoading(true);
    const data1 = form1.getFieldsValue();
    const data2 = form2.getFieldsValue();
    const additionalData = val === "Update - Load" ? { load: "Update - Load" } : val === "Update - Save" ? { save: "Update - Save" } : {};
    const merged = { ...data1, ...data2 };
    const carid = vehicles.map((car) => car.id);
    const { new_car, reload_car, ...values } = merged;
    const { c_booking_number, container_id, c_container_number, shipping_line_id, is_customer_own_loading, ...newValues } = values;

    const payload = {
      ...newValues, load_number: values.container_id, booking_number: values.c_booking_number,
      container_number: values.c_container_number,
      shipping_line: values.shipping_line_id,
      checksave: loadingPlan?.status,
      ...(customerOwnLoading ? { is_customer_own_loading: "on" } : {}),
      carid,
      ...additionalData,
    };

    let images = [];
    if (fileList.length > 0) {

      const newFiles = fileList.filter((file) => file.originFileObj);

      if (newFiles.length > 0) {
        images = await awsService.uploadS3Bucket(newFiles, false, payload.load_number, CONTAINERS);
      }

      const existingImages = fileList
        .filter((file) => file.url && !removedImages.includes(file.url)) // CHANGES HIGHLIGHTED: Exclude removed images
        .map((file) => file.url);

      images = [...images, ...existingImages];

      // CHANGES HIGHLIGHTED: Delete removed images from AWS S3
      if (removedImages.length > 0) {
        await awsService.deleteFromS3Bucket(removedImages);
      }
    }

    const data = { ...payload, images };

    const response = await loadingPlanService.updatePlan(id, data);
    setLoading(false);

    if (response.success) {
      notification.success({ message: "Successfully updated!", description: response.message, placement: "topRight" });
      setRemovedImages([]);
      initialize();
    }

  };

  const handleFreight = async () => {
    setLoading(true);
    const payload2 = {
      vendor_id: ledger.map((item) => item.vendor_id),
      description: ledger.map((item) => item.description),
    };
    // console.log(payload2);
    const response = await loadingPlanService.updatePlanFrieght(id, payload2);
    if (response.success) {
      notification.success({
        message: "Successfully updated!",
        description: response.message,
        placement: "topRight",
      });
    }
    setLoading(false);
  };

  const initialize = async (page = 1, pageSize = pagination.current) => {
    const { success, data } = await loadingPlanService.View(id, page);
    if (success) {
      setLoadingPlan(data.container);
      const vehicleList = data.containers.data.map((item) => item.vehicle).filter((vehicle) => vehicle !== null);
      setLedger(data.ledger);
      setVehicles(vehicleList);

      if (data.container.myimages) {
        const fileList = data.container.myimages.map((image) => ({
          uid: image.id.toString(),
          name: (image.image).substring(image.image.lastIndexOf('/') + 1),
          status: "done",
          url: image.image,
        }));

        setFileList(fileList);
      }

      setPagination((prev) => ({
        ...prev,
        total: data.containers.total,
        current: page,
        pageSize,
      }));

      setCustomerOwnLoading(data.container.is_customer_own_loading);
      form1.setFieldsValue({ ...data.container });
      form2.setFieldsValue({ ...data.container });
    }
  };

  const getQuotationVendors = async () => {
    const response = await dropdownService.fetchQuotationVendors();
    if (response.success) {
      setVendors(response.data);
    }
  };

  const getShippingLine = async () => {
    const response = await shippingLineService.fetch();
    if (response.success) {
      setShippingLine(response.data);
    }
  };

  const getDestinations = async () => {
    const response = await destinationService.fetchDestination();
    if (response.success) {
      setDestinations(response.data);
    }
  };
  const getContainerSize = async () => {
    const response = await containerService.fetchsizes();
    if (response.success) {
      setContainerSize(response.data);
    }
  };

  useEffect(() => {
    getShippingLine();
    getQuotationVendors();
    getDestinations();
    getContainerSize();
    initialize(pagination.current, pagination.pageSize);
  }, [pagination.current, pagination.pageSize]);
  return (
    <>
      <Row gutter={24} wrap={false}>
        <Col flex="280px">
          <div
            style={{
              paddingTop: "8px",
              paddingRight: "8px",
              paddingLeft: 8,
              // border: "1px solid #ccc",
              // borderRadius: 5,
              // backgroundColor: "#FFFFFF",
            }}
          >
            <Row gutter={16} justify="center">

              <ImageUploader
                fileList={fileList}
                beforeUpload={true}
                handleChange={handleChange}
                length={20}
              />

              {/* {images.map((image) => (
                <Col xs={24} sm={12} lg={12}>
                  <div style={{ textAlign: "center", marginBottom: "12px" }}>
                    <img
                      src={image.url}
                      alt="No images found"
                      style={{
                        width: "100%",
                        height: "auto",
                        maxWidth: "320px",
                        objectFit: "contain",
                      }}
                    />
                    <p
                      style={{
                        cursor: "pointer",
                        color: "red",
                        marginTop: "5px",
                      }}
                      onClick={() => removeImage(image.id)}
                    >
                      Remove Image
                    </p>
                  </div>
                </Col>
              ))} */}
            </Row>
          </div>
        </Col>
        <Col flex="1">
          <div
            style={{
              padding: "15px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Row gutter={24}>
              <Form
                requiredMark={false}
                layout="vertical"
                form={form1}
                initialValues={{ layout: "vertical" }}
              >
                <Row gutter={24} style={{ padding: 20 }}>
                  <Col span={8}>
                    <Form.Item
                      label="Load Number"
                      name="container_id"
                      rules={[
                        {
                          required: true,
                          message: "Please input!",
                        },
                      ]}
                    >
                      <Select placeholder="HOUSTON-3323-90164" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Booking Number" name="c_booking_number">
                      <Input placeholder="EBKG10639832  " />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label="Container Number"
                      name="c_container_number"
                    >
                      <Input placeholder="" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Add New Car" name="new_car">
                      <AutoComplete
                        options={newCarOptions}
                        onSearch={(query) =>
                          fetchCarOptions(query, setNewCarOptions)
                        }
                        onSelect={(value, option) =>
                          handleSelect(value, option)
                        }
                        placeholder="Search and select a car"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Shipping Line" name="shipping_line_id">
                      <Select placeholder="Select a shipping line">
                        {shippingLine.map((ship) => (
                          <Select.Option key={ship.id} value={ship.id}>
                            {ship.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Location " name="location">
                      <Input placeholder="HOUSTON-3323" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <Form
                requiredMark={false}
                layout="vertical"
                form={form2}
                initialValues={{ layout: "vertical" }}
              >
                <Row gutter={24} style={{ padding: 20 }}>
                  <Col span={8}>
                    <Form.Item
                      label="Destination"
                      name="destination"
                      rules={[
                        {
                          required: true,
                          message: "Please input!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Destination"
                        showSearch
                        optionFilterProp="children"
                      >
                        {destinations.map((destination) => (
                          <Select.Option
                            key={destination.id}
                            value={destination.name}
                          >
                            {destination.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Container Size" name="container_size">
                      <Select>
                        {containerSize.map((size) => (
                          <Select.Option key={size.id} value={size.name}>
                            {size.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Pull Out Date" name="pull_out_date">
                      <Input placeholder="dd----yyyy" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Gate In Date" name="gate_in_date">
                      <Input placeholder="dd----yyyy" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="Customised Date" name="customised_date">
                      <Input placeholder="dd----yyyy" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item label="ETA Date" name="eta_date">
                      <Input placeholder="dd----yyyy" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      style={{ textAlign: "right", marginTop: 30 }}
                      name="is_customer_own_loading"
                    >
                      <Checkbox
                        checked={customerOwnLoading}
                        onChange={(e) =>
                          setCustomerOwnLoading(e.target.checked)
                        }
                      >
                        Customer Own Loading
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Row>
            <div
              style={{
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                margin: 5,
                marginTop: 10,
              }}
            >
              <Table
                columns={columns}
                pagination={false}
                scroll={{ x: "max-content" }}
                dataSource={vehicles}
                style={{ marginTop: "5px" }}
              />
            </div>

            <Row
              gutter={24}
              style={{
                marginTop: 40,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {/* <Col span={6}>
                <Upload id="uploadInput" action="/upload" listType="picture">
              <Col span={6}>
                <Upload
                  id="uploadInput"
                  beforeUpload={() => false}
                  action="/upload"
                  listType="picture"
                >
                  <Button
                    type="dashed"
                    icon={<UploadOutlined />}
                    style={{
                      width: "100%",
                      border: "1px solid #ccc",
                      padding: 15,
                    }}
                  >
                    Upload Image
                  </Button>
                </Upload>
              </Col> */}

              <Col span={6}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={() => onFinish("Update - Save")}
                  loading={loading}
                >
                  Update - Save
                </Button>
              </Col>
              <Col span={6}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={() => onFinish("Update - Load")}
                  loading={loading}
                >
                  Update - Load
                </Button>
              </Col>
            </Row>

            <div
              style={{
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                backgroundColor: "#FFFFFF",
                margin: 5,
                marginTop: 40,
              }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <h1 style={{ fontWeight: "bold" }}>
                    Freight Forwarder / Transporter
                  </h1>
                </Col>
              </Row>
              <Table
                columns={columns2}
                pagination={false}
                scroll={{ x: "max-content" }}
                dataSource={ledger}
                style={{ marginTop: "5px" }}
              />

              <div
                style={{ marginTop: 20 }}
                className="flex flex-col justify-center items-center"
              >
                <CiSquarePlus
                  style={{ cursor: "pointer", fontSize: "25px" }}
                  onClick={handleAddRow}
                />
              </div>
            </div>
            <Row
              gutter={24}
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Col span={6}>
                <Button
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={handleFreight}
                  loading={loading}
                >
                  Update - Save
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default EditSavePlan;

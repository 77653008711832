import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Row, Select, notification } from "antd";
import { useParams } from "react-router-dom";
import { vendorService, countryService } from "../../../services";

function VendorForm() {
  const { id } = useParams();
  // console.log("vendor", id)
  const [form] = Form.useForm();
  const isEditMode = id !== undefined;
  const [vendor, setVendor] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vendorCategory, setVendorCategory] = useState([]);
  const [country, setcountry] = useState([]);

  const initialize = async () => {
    try {
      const response = await countryService.countryGet();
      if (response?.success) {
        setcountry(response.data);
      } else {
        console.error(
          "Error fetching countries:",
          response.message || "Unknown error"
        );
      }

      if (isEditMode) {
        const { success, data, message } = await vendorService.viewVendor(id);
        if (success) {
          setVendor(data);
          form.setFieldsValue({
            ...data,
            vendors_categorys: data.vendors_categorys_id,
          });
        } else {
          console.error(`Error fetching vendor: ${message || "Unknown error"}`);
        }
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    }
  };

  const getVendorCategories = async () => {
    try {
      const { success, data } = await vendorService.fetchCategories();
      if (success) {
        setVendorCategory(data);
      } else {
        console.error("Failed to fetch vendor categories", data);
      }
    } catch (error) {
      console.error("Error fetching vendor categories:", error);
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const data = { ...values };

      const response = isEditMode
        ? await vendorService.updateVendor(id, data)
        : await vendorService.addVendor(data);
      if (response.success) {
        notification.success({
          message: "Success",
          description: isEditMode
            ? "Vendor updated successfully!"
            : "Vendor added successfully",
          placement: "topRight",
        });
      } else {
        const errors = response.data?.errors || [response.message];
        notification.error({
          message: "Error",
          description: `Error: ${errors.join(", ")}`,
          placement: "topRight",
        });
      }
      setLoading(false);
    } catch (err) {
      console.error("Update Error", err);
    }
  };

  useEffect(() => {
    initialize();
    getVendorCategories();
  }, []);
  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row gutter={26} style={{ marginBottom: 10, padding: "5px" }}>
        <Col span={12}>
          <h1 style={{ fontWeight: "bold" }}>
            {" "}
            {isEditMode ? "Edit Vendor" : "Add Vendors"}
          </h1>
        </Col>
      </Row>

      <Form
        requiredMark={false}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ layout: "vertical" }}
      >
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              label="Vendor Category"
              name="vendors_categorys"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Select placeholder="Select a category">
                {vendorCategory.map((category) => (
                  <Select.Option key={category.id} value={category.id}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Name" name="name">
              <Input placeholder="OTHER" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Email" name="email">
              <Input placeholder="other@ctrade.us" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Town" name="town">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Address" name="address">
              <Input placeholder="" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="City" name="city">
              <Input placeholder="" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Country"
              name="country"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Select>
                {country.map((country) => (
                  <Select.Option key={country.id} value={country.iso_code}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Phone" name="phone">
              <Input placeholder="" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item style={{ textAlign: "center", marginTop: 30 }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "50%" }}
                loading={loading}
              >
                {" "}
                {isEditMode ? "Update Vendor" : "Add Vendor"}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default VendorForm;

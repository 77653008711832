import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, DatePicker, Table, Space, Button } from "antd";
import { Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { dropdownService, VCPaymentService } from "../../../../services";
import { PiMicrosoftExcelLogo } from "react-icons/pi";
import { LiaFilePdfSolid } from "react-icons/lia";
import { RiFileCopyLine } from "react-icons/ri";
import { AiOutlinePrinter } from 'react-icons/ai';
import { FaRegFile } from "react-icons/fa";

function VendorContainerPayment() {
  const [loading, setLoading] = useState(false);
  const [vcpayments, setVCPayments] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const [form] = Form.useForm();
  const columns = [
    {
      title: "#",
      dataIndex: "#",
      key: "#",
      render: (text, record, index) =>
        (pagination.current - 1) * pagination.pageSize + index + 1,
    },
    {
      title: "Container Id",
      dataIndex: "container_id",
      key: "container_id",
    },
    {
      title: "Vendor",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Fright Charges",
      dataIndex: "freight_charges",
      key: "freight_charges",
      render: (text) => "$" + text,
    },
    {
      title: "Chassis",
      dataIndex: "chassis_charges",
      key: "chassis_charges",
      render: (text) => "$" + text,
    },

    {
      title: "Total",
      dataIndex: "total_charges",
      key: "total_charges",
      render: (text) => "$" + text,
    },
  ];

  const onFinish = (values) => {
    const data = {
      ...values,
      from_date: values.from_date
        ? values.from_date.format("YYYY-MM-DD")
        : null,
      to_date: values.to_date ? values.to_date.format("YYYY-MM-DD") : null,
    };

    initialize(pagination.current, pagination.pageSize, data);
  };
  const initialize = async (
    page = 1,
    pageSize = pagination.current,
    data = null
  ) => {
    setLoading(true);
    const response = await VCPaymentService.fetch(page, data);
    if (response.success) {
      setVCPayments(response.data.data.data);
      setPagination((prev) => ({
        ...prev,
        total: response.data.data.total,
        current: page,
        pageSize,
      }));
      setLoading(false);
    }
  };

  const getVendors = async () => {
    const response = await dropdownService.fetchVendors();
    if (response.success) {
      setVendors(response.data);
    }
  };

  useEffect(() => {
    initialize(pagination.current, pagination.pageSize);
    getVendors();
  }, [pagination.current, pagination.pageSize]);
  return (
    <div
      style={{
        padding: "0 14px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Row
        gutter={24}
        style={{
          marginBottom: 15,
          padding: "10px",
          borderRadius: "4px",
          borderBottom: "1px solid #E8E8E9",
        }}
      >
        <Col span={24}>
          <h1 style={{ fontWeight: "bold" }}> Vendors Containers Payment</h1>
        </Col>
      </Row>

      <Form
        requiredMark={false}
        layout="vertical"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item label="From Date" name="from_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="To Date" name="to_date">
              <DatePicker style={{ width: "100%" }} placeholder="Select Date" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Vendor"
              name="vendor_id"
              rules={[
                {
                  required: true,
                  message: "Please input!",
                },
              ]}
            >
              <Select placeholder="No Vendor">
                {vendors.map((vendor) => (
                  <Select.Option key={vendor.id} value={vendor.id}>
                    {vendor.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              type="text"
              htmlType="`submit"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
              }}
            >
              <img src="/images/search.png" alt="Search" width={35} />
            </Button>
          </div>
        </Row>
      </Form>

      <Col span={24}>
        <Row
          gutter={22}
          style={{
            padding: "10px",
            marginTop: "10px",
            background: "#F2F2F7",
            borderRadius: "4px",
          }}
          className="flex justify-between"
        >
          <Col span={10}>
            <div className='flex' >
              <Button type='text' size='small' style={{ padding: '4px' }} >
                <AiOutlinePrinter style={{ fontSize: "24px" }} />
              </Button>

              <Button type='text' size='small' style={{ padding: '4px' }}>
                <FaRegFile style={{ fontSize: "20px" }} />
              </Button>

              <Button type='text' size='small' style={{ padding: '4px' }}>
                <PiMicrosoftExcelLogo style={{ fontSize: "24px" }} />
              </Button>

              <Button type='text' size='small' style={{ padding: '4px' }}>
                <LiaFilePdfSolid style={{ fontSize: "24px" }} />
              </Button>

              <Button type='text' size='small' style={{ padding: '4px' }}>
                <RiFileCopyLine style={{ fontSize: "24px" }} />
              </Button>
            </div>
          </Col>
          <Col span={14} style={{ textAlign: "right" }}>
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              style={{ width: 200 }}
            />
          </Col>
        </Row>
        <Table
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={vcpayments}
          loading={loading}
          pagination={{
            current: pagination.current,
            total: pagination.total,
            pageSize: pagination.pageSize,
            onChange: (page) => initialize(page, pagination.pageSize),
            showSizeChanger: false,
          }}
          rowKey="Id"
          style={{ marginTop: "10px" }}
        />
        <div
          className="flex justify-end gap-32 mt-5 mb-5"
          style={{ fontWeight: "bold" }}
        >
          <p>Total Amount Collected :</p>
          <p>$0</p>
        </div>
      </Col>
    </div>
  );
}

export default VendorContainerPayment;

import ajaxService from "./ajax-service";

class EmployeeService {
  async fetch(page) {
    const response = await ajaxService.get(`/employees?page=${page}`);
    if (response ?? false) {
      return response;
    }
  }
  async addEmployee(data) {
    const response = await ajaxService.post(`/employees`, data);
    if (response ?? false) {
      return response;
    }
  }

  async changeStatus(id) {
    const response = await ajaxService.get(`/employees/status/${id}`);
    if (response ?? false) {
      return response;
    }
  }
  async viewEmployee(id) {
    const response = await ajaxService.get(`/employees/${id}`);
    if (response ?? false) {
      return response;
    }
  }
  async fetchPermissions() {
    const response = await ajaxService.get(`/permissions`);
    if (response ?? false) {
      return response;
    }
  }
  async editEmployee(id, updatedEmployee) {
    const response = await ajaxService.put(`/employees/`, id, updatedEmployee);
    if (response ?? false) {
      return response;
    }
  }

  async deleteEmployee(id) {
    const response = await ajaxService.delete(`/employees/`, id);
    if (response ?? false) {
      return response;
    }
  }
}

const employeeService = new EmployeeService();
export default employeeService;

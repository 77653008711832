import React, { useEffect, useState } from "react";
import {
  Table,
  Space,
  Row,
  Col,
  Input,
  Select,
  Button,
  Form,
  Checkbox,
  Upload,
  DatePicker,
  notification,
} from "antd";
import { CiSquarePlus } from "react-icons/ci";
import { DeleteOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import {
  awsService,
  dropdownService,
  vehicalService,
} from "../../../services";
import { VEHICLES } from "../../../constants";

function AddVehical() {
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [cars, setCars] = useState([]);
  const [selectedCar, setSelectedCar] = useState(null);
  const [visible, setVisible] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [first_id, setFirst_id] = useState();

  const [fileList, setFileList] = useState([]);

  const handleInputChange = (value, recordId, field) => {
    setTableData((prev) =>
      prev.map((row) =>
        row.Id === recordId
          ? {
            ...row,
            [field]: value, // Update the specific field with the new value
          }
          : row
      )
    );
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "Id",
      key: "Id",
      render: (text, record, index) => record.Id,
    },
    {
      title: "BOS",
      dataIndex: "BOS",
      key: "BOS",
      width: 150,
      render: (text, record) => (
        <Select
          defaultValue={text}
          onChange={(value) => handleInputChange(value, record.Id, "BOS")}
          style={{ width: "100%" }}
          placeholder={"select the"}
        >
          <Option value="Auction">Auction</Option>
          <Option value="Notarized">Notarized</Option>
          <Option value="Non-Notarized">Non-Notarized</Option>
        </Select>
      ),
    },
    {
      title: "Car Status",
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => {
        let val;
        if (text === 1) {
          val = "AT YARD";
        }
        return (
          <Input
            value={val}
            placeholder="Select Car Status"
            onChange={(e) =>
              handleInputChange(e.target.value, record.Id, "status")
            }
            style={{ width: "100%" }} // Make sure input fills the column width
          />
        );
      },
    },
    {
      title: "Chasis No",
      dataIndex: "vin",
      key: "vin",
      render: (text, record, index) => (
        <Input
          value={text}
          placeholder="Select Chasis No"
          onChange={(e) => handleInputChange(e.target.value, record.Id, "vin")}
          style={{ width: "100%" }} // Make sure input fills the column width
        />
      ),
    },
    {
      title: "Cutting Car",
      dataIndex: "CuttingCar",
      key: "CuttingCar",
      render: (checked, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={checked}
            onChange={(e) =>
              handleInputChange(e.target.checked, record.Id, "CuttingCar")
            }
          />
        </div>
      ),
    },
    {
      title: "Date Rcd",
      dataIndex: "DateRcd",
      key: "DateRcd",
      render: (text, record, index) => (
        <DatePicker
          // value={text}
          placeholder="YYYY/MM/DD"
          onChange={(date, dateString) =>
            handleInputChange(dateString, record.Id, "DateRcd")
          }
          style={{ width: "100%" }} // Make sure input fills the column width
        />
      ),
    },
    {
      title: "Engine Type",
      dataIndex: "EngineType",
      key: "EngineType",
      width: 150,
      render: (text, record) => (
        <Select
          placeholder="Select Engine Type"
          defaultValue={text}
          style={{ width: "100%" }}
          dropdownStyle={{ minWidth: 180 }}
          onChange={(value) =>
            handleInputChange(value, record.Id, "EngineType")
          }
        >
          <Option value="Gasoline">Gasoline</Option>
          <Option value="Hybrid">Hybrid</Option>
          <Option value="EV">EV</Option>
        </Select>
      ),
    },
    {
      title: "File Ref",
      dataIndex: "FileRef",
      key: "FileRef",
      render: (text, record, index) => (
        <Input
          value={text}
          placeholder="Select File Ref  "
          onChange={(e) =>
            handleInputChange(e.target.value, record.Id, "FileRef")
          }
          style={{ width: "100%" }} // Make sure input fills the column width
        />
      ),
    },
    {
      title: "Keys",
      dataIndex: "isKey",
      key: "isKey",
      render: (text, record, index) => {
        let val;
        if (text === 1) {
          val = "YES";
        } else if (text === 0) {
          val = "NO";
        }

        return (
          <Input
            value={val}
            placeholder="Select Keys"
            onChange={(value) => handleInputChange(value, record.Id, "isKey")}
            style={{ width: "100%" }} // Make sure input fills the column width
          />
        );
      },
    },
    {
      title: "Lot#",
      dataIndex: "Lot_no",
      key: "Lot_no",
      render: (text, record, index) => (
        <Input
          value={text}
          placeholder="Select Car Status"
          onChange={(e) =>
            handleInputChange(e.target.value, record.Id, "Lot_no")
          }
          style={{ width: "100%" }} // Make sure input fills the column width
        />
      ),
    },
    {
      title: "Notes",
      dataIndex: "Notes",
      key: "Notes",
      render: (text, record, index) => (
        <Input
          value={text}
          placeholder="Select Notes  "
          onChange={(e) =>
            handleInputChange(e.target.value, record.Id, "Notes")
          }
          style={{ width: "100%" }} // Make sure input fills the column width
        />
      ),
    },
    {
      title: "Operating Port",
      dataIndex: "OperatingPort",
      key: "OperatingPort",
      render: (text, record, index) => (
        <Input
          value={text}
          placeholder="Select Operating Port "
          onChange={(e) =>
            handleInputChange(e.target.value, record.Id, "OperatingPort")
          }
          style={{ width: "100%" }} // Make sure input fills the column width]
        />
      ),
    },
    {
      title: "Product",
      dataIndex: "make",
      key: "make",
      render: (text, record, index) => (
        <Input
          value={text}
          placeholder="Select Car Status"
          onChange={(e) => handleInputChange(e.target.value, record.Id, "make")}
          style={{ width: "100%" }} // Make sure input fills the column width
        />
      ),
    },
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
      render: (text, record, index) => (
        <Input
          value={text}
          placeholder="Select Title "
          onChange={(e) =>
            handleInputChange(e.target.value, record.Id, "Title")
          }
          style={{ width: "100%" }} // Make sure input fills the column width
        />
      ),
    },
    {
      title: "Title Number",
      dataIndex: "TitleNumber",
      key: "TitleNumber",
      render: (text, record, index) => (
        <Input
          value={text}
          placeholder="Select Title Number "
          onChange={(e) =>
            handleInputChange(e.target.value, record.Id, "TitleNumber")
          }
          style={{ width: "100%" }} // Make sure input fills the column width
        />
      ),
    },
    {
      title: "Title Owner",
      dataIndex: "TitleOwner",
      key: "TitleOwner",
      render: (text, record, index) => (
        <Input
          value={text}
          placeholder="Select Title Owner "
          onChange={(e) =>
            handleInputChange(e.target.value, record.Id, "TitleOwner")
          }
          style={{ width: "100%" }} // Make sure input fills the column width
        />
      ),
    },
    {
      title: "User",
      dataIndex: "user_id",
      key: "user_id",
      width: 150,
      render: (text, record) => {
        return (
          customers?.length > 0 && (
            <Select
              value={text} // Force to number
              style={{ width: "100%" }}
              placeholder="Select User"
              onChange={(value) =>
                handleInputChange(value, record.Id, "user_id")
              }
              dropdownStyle={{ minWidth: 200 }} // Minimum width for the dropdown menu
            >
              {customers?.map((cust) => (
                <Select.Option key={cust.id} value={cust.id}>
                  {cust.name}
                </Select.Option>
              ))}
            </Select>
          )
        );
      },
    },

    {
      title: "Value",
      dataIndex: "Value",
      key: "Value",
      render: (text, record, index) => (
        <Input
          value={text}
          placeholder="Select Value "
          onChange={(e) =>
            handleInputChange(e.target.value, record.Id, "Value")
          }
          style={{ width: "100%" }} // Make sure input fills the column width
        />
      ),
    },

    {
      title: "Vehicle",
      dataIndex: "model",
      key: "model",
      render: (text, record, index) => (
        <Input
          value={text}
          placeholder="Select Car Status"
          onChange={(e) =>
            handleInputChange(e.target.value, record.Id, "model")
          }
          style={{ width: "100%" }} // Make sure input fills the column width
        />
      ),
    },
    {
      title: "Type",
      dataIndex: "delivery_type",
      key: "delivery_type",
      width: 150,
      render: (text, record) => {
        return (
          <Select
            placeholder="select Type"
            value={text}
            style={{ width: "100%" }}
            onChange={(value) =>
              handleInputChange(value, record.Id, "delivery_type")
            }
            dropdownStyle={{ minWidth: 150 }}
          >
            <Option value="Car">Car</Option>
            <Option value="Bike">Bike</Option>
            <Option value="Boat">Boat</Option>
            <Option value="General Cargo">General Cargo</Option>
            <Option value="Heavy Equipment">Heavy Equipment</Option>
            <Option value="Miscellaneous">Miscellaneous</Option>
          </Select>
        );
      },
    },
    {
      title: "Weight",
      dataIndex: "Weight",
      key: "Weight",
      render: (text, record, index) => (
        <Input
          value={text}
          placeholder="Select Weight "
          onChange={(e) =>
            handleInputChange(e.target.value, record.Id, "Weight")
          }
          style={{ width: "100%" }} // Make sure input fills the column width
        />
      ),
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      render: (text, record, index) => (
        <Input
          value={text}
          placeholder="Select Year "
          onChange={(e) =>
            handleInputChange(e.target.value, record.Id, "EngineType")
          }
          style={{ width: "100%" }} // Make sure input fills the column width
        />
      ),
    },
    {
      title: "Pictures",
      dataIndex: "FileUpload",
      key: "FileUpload",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Upload
            // maxCount={20}
            maxCount={1}
            fileList={fileList}
            showUploadList={false}
            beforeUpload={() => false} // Prevent automatic upload
            onChange={(info) => handleFileChange(info, record)} // Correct event handler
          >
            <Button>Choose File</Button>
          </Upload>
          <span>
            {record.FileUpload ? record.FileUpload.name : "No file chosen"}
          </span>
        </div>
      ),
    },
    {
      title: "Delete",
      key: "Delete",
      render: (text, record, index) => {
        // console.log(record)
        return (
          <Button
            type="primary"
            s
            size="small"
            onClick={() => handleDeleteRow(record.Id)}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];

  const handleToggle = () => {
    setVisible(!visible);
    console.log("data");
  };

  const handleFileChange = (info, record) => {

    let updatedFileList = [...info.fileList];

    setFileList(updatedFileList);

    const updatedData = tableData.map((item) => {
      if (item.Id === record.Id) {
        return { ...item, FileUpload: updatedFileList };
      }
      return item;
    });
    setTableData(updatedData);
  };

  const handleSelectChange = (value) => {
    const selectedCar = cars.find((car) => car.id === value);
    setSelectedCar(selectedCar);
  };

  const addRow = () => {
    setTableData((prevData) => {
      const newId =
        prevData.length > 0 ? prevData[prevData.length - 1].Id + 1 : first_id;
      const newRow = {
        Id: newId,
        status: "",
        user_id: "",
        Lot_no: "",
        CuttingCar: false,
        make: "",
        model: "",
        delivery_type: "",
        EngineType: "",
        year: "",
        vin: "",
        DateRcd: "",
        Value: "",
        Weight: "",
        OperatingPort: "",
        BOS: "",
        TitleOwner: "",
        Title: "",
        TitleNumber: "",
        isKey: "",
        Notes: "",
        FileRef: "",
        FileUpload: null,
      };

      return [...prevData, newRow];
    });
  };

  const getAppointedCars = async () => {
    const response = await dropdownService.fetchApprovedCars();
    if (response.success) {
      setCars(response.data);
    }
  };

  const handleAdd = () => {
    if (selectedCar && tableData.length > 0) {
      setTableData((prevTableData) => {
        const emptyRowIndex = prevTableData.findIndex(
          (row) => !row.make && !row.model && !row.vin
        );

        const targetIndex =
          emptyRowIndex !== -1 ? emptyRowIndex : prevTableData.length - 1;

        const { id, ...selectedCarData } = selectedCar;

        const updatedData = [...prevTableData];
        updatedData[targetIndex] = {
          ...updatedData[targetIndex],
          ...selectedCarData,
        };

        return updatedData;
      });
    }
  };

  const getLatestId = async () => {
    const response = await vehicalService.getLatestID();
    if (response.success) {
      const latestId = response.data[0].highest_id + 1;

      const initialRow = {
        Id: latestId,
        status: "",
        user_id: "",
        Lot_no: "",
        CuttingCar: false,
        make: "",
        model: "",
        delivery_type: "",
        EngineType: "",
        year: "",
        vin: "",
        DateRcd: "",
        Value: "",
        Weight: "",
        OperatingPort: "",
        BOS: "",
        TitleOwner: "",
        Title: "",
        TitleNumber: "",
        isKey: "",
        Notes: "",
        FileRef: "",
        FileUpload: null,
      };

      setTableData([initialRow]);

      setFirst_id(latestId);
    }
  };
  const handleDeleteRow = (Id) => {
    setTableData((prev) => {
      // Step 1: Find the smallest ID in the original data
      const baseId = Math.min(...prev.map((row) => row.Id));

      // Step 2: Filter out the row to delete
      const filteredData = prev.filter((row) => row.Id !== Id);

      // Step 3: Reassign IDs sequentially, starting from the original smallest ID
      const updatedData = filteredData.map((row, index) => ({
        ...row,
        Id: baseId + index,
      }));

      return updatedData;
    });
  };

  const onFinish = async () => {
    // setLoading(true);
    // const newData = tableData.map(async (acc, row) => {

    //   console.log(acc);
    //   console.log(row);

    //   let formData = {
    //     BOS: row.BOS,
    //     car_status: row.status === 1 ? "AT YARD" : "LOADED",
    //     chasis_no: row.vin,
    //     cutting_car: row.CuttingCar ? 1 : 0,
    //     date_rcd: row.DateRcd,
    //     engine_type: row.EngineType,
    //     file_ref: row.FileRef,
    //     keys: row.isKey ? "YES" : "NO",
    //     "lot_#": row.Lot_no,
    //     notes: row.Notes,
    //     operating_port: row.OperatingPort,
    //     product: row.make,
    //     title: row.Title,
    //     title_number: row.TitleNumber,
    //     title_owner: row.TitleOwner,
    //     user_id: row.user_id,
    //     value: row.Value,
    //     vehicle: row.model,
    //     vehicle_type: row.delivery_type,
    //     weight: row.Weight,
    //     year: row.year,
    //     FileUpload: Array.isArray(row.FileUpload) ? row.FileUpload : [row.FileUpload],
    //   };

    //   // let images = [];



    //   const vehicleImages = formData.FileUpload;

    //   if (vehicleImages.length > 0) {

    //     const userName = customers.find(i => i.id === row.user_id).name;

    //     try {
    //       const uploadedImages = await awsService.uploadS3Bucket(vehicleImages, userName, formData.chasis_no, VEHICLES);

    //       formData.FileUpload = uploadedImages;
    //     } catch (error) {
    //       console.error(`Failed to upload images for ${row.vin}:`, error);
    //       formData.FileUpload = []; // Set as empty array if upload fails
    //     }

    //   }

    //   acc[row.Id] = formData;
    //   return acc;
    // });

    // let requestData = newData;

    // console.log(requestData);


    const newData = await Promise.all(
      tableData.map(async (row) => {

        let formData = {
          BOS: row.BOS,
          car_status: row.status === 1 ? "AT YARD" : "LOADED",
          chasis_no: row.vin,
          cutting_car: row.CuttingCar ? 1 : 0,
          date_rcd: row.DateRcd,
          engine_type: row.EngineType,
          file_ref: row.FileRef,
          keys: row.isKey ? "YES" : "NO",
          "lot_#": row.Lot_no,
          notes: row.Notes,
          operating_port: row.OperatingPort,
          product: row.make,
          title: row.Title,
          title_number: row.TitleNumber,
          title_owner: row.TitleOwner,
          user_id: row.user_id,
          value: row.Value,
          vehicle: row.model,
          vehicle_type: row.delivery_type,
          weight: row.Weight,
          year: row.year,
          FileUpload: Array.isArray(row.FileUpload) ? row.FileUpload : [row.FileUpload],
        };

        const vehicleImages = formData.FileUpload;

        if (vehicleImages.length > 0) {
          const userName = customers.find((i) => i.id === row.user_id).name;

          try {
            const uploadedImages = await awsService.uploadS3Bucket(vehicleImages, userName, formData.chasis_no, VEHICLES);
            formData.FileUpload = uploadedImages;
          } catch (error) {
            console.error(`Failed to upload images for ${row.vin}:`, error);
            formData.FileUpload = []; // Set as empty array if upload fails
          }
        }

        return { [row.Id]: formData };
      })
    );

    // Merge the array of objects into a single object
    const requestData = newData.reduce((acc, cur) => ({ ...acc, ...cur }), {});

    const response = await vehicalService.addVehicle(requestData);
    setLoading(false);
    if (response.success) {
      notification.success({
        message: "Added Successfully!",
        description: response.message,
        placement: "topRight",
      });
    }
  };

  const getCustomer = async () => {
    const response = await dropdownService.fetchCustomers();
    if (response.success) {
      const filter = response.data.sort((a, b) =>
        a.name.toUpperCase().localeCompare(b.name.toUpperCase())
      );

      const capitalizedFilter = filter.map((customer) => ({
        ...customer,
        name: customer.name.toUpperCase(),
      }));
      setCustomers(capitalizedFilter);
    }
  };

  useEffect(() => {
    // getColumns();
    getCustomer();
    getLatestId();
    getAppointedCars();
  }, []);

  return (
    <div
      style={{
        padding: "15px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div style={{ marginTop: 20 }}>
        <Form requiredMark={false} layout="vertical">
          <Row gutter={[9, 24]}>
            {/* First Column: Vin Input */}
            <Col xs={24} sm={8}>
              <Form.Item label="Approved Appointment Cars Vin #">
                <Select
                  placeholder="select"
                  onChange={(value) => handleSelectChange(value)}
                >
                  {cars.map((car) => (
                    <Select.Option key={car.id} value={car.id}>
                      {car.vin} - {car.make} - {car.model}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            {/* Second Column: Add Button */}
            <Col xs={24} sm={1}>
              <Form.Item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "28px",
                }}
              >
                <Button
                  type="primary"
                  style={{ textAlign: "center" }}
                  onClick={handleAdd}
                >
                  Add
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Row
        gutter={[0, 0]}
        style={{
          marginTop: "10px",
          background: "#F2F2F7",
          padding: "10px",
          borderRadius: "4px",
        }}
        justify="space-between" // Align items in the row
        align="middle" // Vertically align items in the center
      >
        {/* Left section */}
        <Col
          xs={24}
          md={8}
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Space size={10}>
            <CiSquarePlus
              style={{ cursor: "pointer", fontSize: "22px" }}
              onClick={addRow}
            />
          </Space>
        </Col>

        {/* Right section */}

        {/* md={16} */}
        <Col
          xs={24}
          md={16}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Space wrap>
            <Button type="primary" onClick={handleToggle}>
              Show/Hide Sheet
            </Button>
            <Button
              type="primary"
              onClick={onFinish}
              loading={loading}
            >
              Save Records
            </Button>
          </Space>
        </Col>
      </Row>
      {visible && (
        <div>
          <Table
            columns={columns}
            scroll={{ x: "max-content" }}
            dataSource={tableData}
            rowKey="Id"
            style={{ marginTop: "10px" }}
            pagination={false}
          />
        </div>
      )}
    </div>
  );
}
export default AddVehical;

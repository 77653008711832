import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Image, Space, Upload } from "antd";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const ImageUploader = ({ fileList, beforeUpload = true, handleChange, length = 1 }) => {

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const customRequest = (e) => {
        e.onSuccess();
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    return (
        <>
            <Space>
                <Upload
                    name="image"
                    accept=".jpg,.jpeg,.png,.svg"
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    fileList={fileList}
                    listType="picture-card"
                    customRequest={customRequest}
                    maxCount={length}
                    onPreview={handlePreview}
                >
                    {fileList.length < length && (
                        <div>
                            <UploadOutlined />
                            <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                    )}
                </Upload>
            </Space>

            {previewImage && (
                <Image
                    wrapperStyle={{
                        display: 'none',
                    }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}

        </>
    );
};

export default ImageUploader;
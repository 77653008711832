import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Card, Row, Col, Statistic, Spin } from 'antd';
import { CiDeliveryTruck, CiFileOn } from "react-icons/ci";
import { MdOutlineGroups } from "react-icons/md";
import dashboardService from '../../../services/dashbord-service';

// Lazy-loaded components
const TableBook = lazy(() => import('./TableBook'));
const TableContainer = lazy(() => import('./TableContainer'));
const MissingContainer = lazy(() => import('./MissingContainer'));
const Vechicalyard = lazy(() => import('./Vechicalyard'));
const NoTitleTable = lazy(() => import('./NoTitleTable'));
const InvoiceDue = lazy(() => import('./InvoiceDue'));
const InvoiceChart = lazy(() => import('./InvoiceChart'));
const InvoicesOverdue = lazy(() => import('./InvoicesOverdue'));
const CustomerOverdue = lazy(() => import('./CustomerOverdue'));
const CustomerDueSummary = lazy(() => import('./CustomerDueSummary'));

export default function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const loadDashboard = async () => {
    setLoading(true);
    const { success, data } = await dashboardService.fetchDashbord();
    setLoading(false);
    if (success) {
      
      setData(data);
    }
  };

  useEffect(() => {
    loadDashboard();
  }, []);

  return (
    <div className="dashboard-container">
      {/* Top Cards Section */}
      <Row gutter={24}>
        <Col span={8}>
          <Card>
            <div className="flex justify-between" loading={loading}>
              <Statistic title="Total Customers" value={data.total_customers} />
              <MdOutlineGroups className="bg-primary" style={iconStyle} />
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <div className="flex justify-between" loading={loading}>
              <Statistic title="Total Vehicles" value={data.total_vehicles} />
              <CiDeliveryTruck className="bg-primary" style={iconStyle} />
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <div className="flex justify-between" loading={loading}>
              <Statistic title="Total Saved Load Plans" value={data.total_load_plans} />
              <CiFileOn className="bg-primary" style={iconStyle} />
            </div>
          </Card>
        </Col>
      </Row>
      <br />
      {/* Tables Section */}
      <Row gutter={[24, 24]}>
        <Col xs={24} lg={12}>
          <Suspense fallback={<Spin />}>
            <TableBook bookingNo={data.booking_numbers} loading={loading}/>
          </Suspense>
        </Col>
        <Col xs={24} lg={12}>
          <Suspense fallback={<Spin />}>
            <TableContainer containerNo={data.container_numbers} />
          </Suspense>
        </Col>
      </Row>

      {/* Components Section */}
      <div className="flex flex-col gap-12 mt-10">
        <Suspense fallback={<Spin />}>
          <MissingContainer saveload={data.saved_loading} />
          <Vechicalyard />
          <NoTitleTable />
        </Suspense>
      </div>

      {/* Invoice Section */}
      <Row gutter={[24, 24]} className="mt-8">
        <Col xs={24} lg={12}>
          <Suspense fallback={<Spin />}>
            <InvoiceDue />
          </Suspense>
        </Col>
        <Col xs={24} lg={12}>
          <Suspense fallback={<Spin />}>
            <InvoiceChart chart={data.invoices} pieChart={data.pieChart}/>
          </Suspense>
        </Col>
      </Row>

      {/* Overdue Section */}
      <div className="mt-10">
        <Suspense fallback={<Spin />}>
          <InvoicesOverdue />
        </Suspense>
      </div>
      <Row gutter={[24, 24]} className="mt-8">
        <Col xs={24} lg={12}>
          <Suspense fallback={<Spin />}>
            <CustomerOverdue />
          </Suspense>
        </Col>
        <Col xs={24} lg={12}>
          <Suspense fallback={<Spin />}>
            <CustomerDueSummary />
          </Suspense>
        </Col>
      </Row>
    </div>
  );
}

const iconStyle = {
  fontSize: '40px',
  color: 'white',
  padding: '6px',
  borderRadius: '10px',
};

import { validationErrors } from "../utils";
import ajaxService from "./ajax-service";

class OpeningBalance {
  async fetch(page) {
    const response = await ajaxService.get(`/accounts/balances?page=${page}`);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async viewBalance(id) {
    const response = await ajaxService.get(`/accounts/balances/${id}`);
    if (response ?? false) {
      return response;
    }
  }
  async addBalance(data) {
    const response = await ajaxService.post(`/accounts/balances`, data);
    if (response ?? false) {
      return response;
    }
  }
  async ChartAccounts() {
    const response = await ajaxService.get(`/chart-accounts`);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async updateBalance(id, data) {
    const response = await ajaxService.put(`/accounts/balances/`, id, data);
    if (response ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
  async deleteBalance(id) {
    const response = await ajaxService.delete(`/accounts/balances/`, id);
    if (response  ?? false) {
      return response;
    } else {
      const errors = response.data.errors;
      return validationErrors(errors);
    }
  }
}

const openingBalanceService = new OpeningBalance();
export default openingBalanceService;
